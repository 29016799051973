import * as React from "react";
import Svg, { Path, Mask, G } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={95}
            height={87}
            viewBox="0 0 95 87"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.958 0c24.486 0 54.375 13.995 54.375 38.48 0 24.487-22.36 48.52-46.846 48.52C23 87 .64 62.967.64 38.48.64 13.996 15.471 0 39.958 0z"
                fill="#FFDE35"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.26 5.1c21.329 0 47.364 12.19 47.364 33.52 0 21.329-19.477 42.263-40.806 42.263-21.329 0-40.806-20.934-40.806-42.263 0-21.33 12.92-33.52 34.248-33.52z"
                fill="#073763"
            />
            <Mask
                id="a"
                style={{
                    maskType: "luminance",
                }}
                maskUnits="userSpaceOnUse"
                x={30}
                y={23}
                width={32}
                height={41}>
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.105 23.632h31.426v39.735H30.105V23.632z"
                    fill="#fff"
                />
            </Mask>
            <G mask="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="#fff">
                <Path d="M51.529 59.089c-.907 2.508-3.107 4.278-5.678 4.278-2.57 0-4.77-1.77-5.677-4.278 2.657-1.56 7.254-.22 11.355 0zM30.211 54.626c.158-1.13.68-3.085 2.375-4.947 2.224-2.442.206-6.138.286-9.295.042-1.689.934-10.316 9.472-12.943 0 0-.088-3.785 3.474-3.81 3.562.025 3.473 3.81 3.473 3.81 8.539 2.627 8.822 11.254 8.864 12.943.08 3.157-1.33 6.853.895 9.295 1.696 1.862 2.217 3.817 2.374 4.947.059.374.09.793.106 1.279a1.19 1.19 0 01-1.19 1.23s-7.452-.099-12.72-1.145c-5.267-1.047-16.325 1.146-16.325 1.146a1.19 1.19 0 01-1.19-1.231c.017-.486.048-.905.106-1.28" />
            </G>
        </Svg>
    );
}

export default SvgComponent;
