import { QUIZ_QUESTION_TYPE } from "../../../../constants";
import { getUniqColor } from "./QuizUtils";

export const remapQuestionDetails = (response) => {
    const scheduleTest = response?.scheduleTest[0];
    const curSubject = scheduleTest?.subjectStructures[0];

    let result = {
        totalMarks: scheduleTest.totalMarks,
        startDate: scheduleTest.startDate,
        endDate: scheduleTest.endDate,
        scheduleId: scheduleTest.scheduleId,
        subjectId: curSubject.subjectId,
        subjectName: curSubject.subjectName,
        questionListDetails: {},
        studentTestId: response.studentTestId,
        duration: scheduleTest.duration,
    };

    result.questionListDetails = curSubject.sections
        .reduce((result, curSection) => {
            const remappedQuestion = remapQuestion(curSection, scheduleTest);
            return [
                ...result,
                ...(remappedQuestion.questionTypeRemapped ===
                QUIZ_QUESTION_TYPE.NONE
                    ? []
                    : remappedQuestion),
            ];
        }, [])
        .map((question, index) => ({
            ...question,
            index,
        }));

    return result;
};

const remapQuestion = (curSection, scheduleTest) =>
    curSection.questionListDetails.map((questionDetails) => {
        const qData = questionDetails.question_data;
        const questionTypeRemapped = remapQuestionType(qData.questiontype);

        let question = {
            questionType: qData.questiontype,
            questionTypeRemapped,
            question_id: qData.question_id,
            testQuestion_id: qData.queId,
            question_text: qData.question.question_text,
            answer_options: qData.question.answer_options,
            correct_answer: qData.question.correct_answer,
            positiveMarks: qData?.lmsPostiveMarks,
            negativeMarks: qData?.lmsNegativemarks,
            noAttemptMarks: curSection.noAttemptMarks,
            scheduleId: scheduleTest.scheduleId,
            correct: qData?.correct,
            marks: qData?.marks,
            response: qData?.response,
        };
        question.userAnswer = remapAnswer(question);

        return question;
    });

const remapQuestionType = (questionType) => {
    switch (questionType) {
        case "20fbaaec-85fa-42be-8d10-7d442e982301":
            return QUIZ_QUESTION_TYPE.SINGLE_CORRECT;
        case "ffc0b3e6-f1c4-45df-a302-918fda45e122":
            return QUIZ_QUESTION_TYPE.MULTI_CORRECT;
        case "8998cef8-52eb-47df-a01f-849d0b376629":
            return QUIZ_QUESTION_TYPE.TRUE_FALSE;
        case "c63c8423-28e9-4fde-b855-feff8a8567a1":
            return QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_REGULAR;
        case "2aac5cab-861c-4992-8726-aa819558632e":
        case "ec2f3aec-e64c-11ec-8fea-0242ac120002":
            return QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_NUMERIC;
        case "08796d93-de43-4ca2-bced-79972e6f6dac":
        case "5f0aed9b-8b11-4653-93bc-65941bf3da3f":
            return QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_INTEGER;
        case "9379ac6a-25b2-4a16-8b8b-2e1b414e1b12":
            return QUIZ_QUESTION_TYPE.MATCH_THE_OPTIONS;
        default:
            return QUIZ_QUESTION_TYPE.NONE;
    }
};

export const remapMatchQuestion = (str) => {
    const getPTag = (string) => {
        const from = str.indexOf("<p>");
        const to = str.indexOf(",<p>");

        return {
            el: string.slice(from, to),
            less: string.slice(to + 1),
        };
    };

    const newArr = [];
    const countEl = str.split("</p>,<p>").length;

    for (let i = 0; i < countEl; i++) {
        const { el, less } = getPTag(str);
        newArr.push(el);
        str = less;
    }

    return newArr;
};

const remapAnswer = (question) => {
    if (!question?.response) {
        return undefined;
    }

    switch (question.questionTypeRemapped) {
        case QUIZ_QUESTION_TYPE.SINGLE_CORRECT:
        case QUIZ_QUESTION_TYPE.TRUE_FALSE:
            return parseChoiceAnswer(question);
        case QUIZ_QUESTION_TYPE.MULTI_CORRECT:
            return parseChoiceAnswer(question);
        case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_REGULAR:
        case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_NUMERIC:
        case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_INTEGER:
            return parseFillAnswer(question);
        case QUIZ_QUESTION_TYPE.MATCH_THE_OPTIONS:
            return parseMatchAnswer(question);
        default:
            return 0;
    }
};

const parseChoiceAnswer = (question) => {
    return {
        question_id: question.question_id,
        options: question.response.split(",")?.map((value) => ({
            optionId: `${question.question_id}_${Number(value) - 1}`,
            value: question.answer_options[Number(value) - 1],
        })),
        correct: !!question.correct,
        response: question?.response,
    };
};
const parseFillAnswer = (question) => {
    return {
        question_id: question.question_id,
        options: question.response.split("~`")?.map((value, index) => ({
            optionId: `${question.question_id}_${index + 1}`,
            value: value,
        })),
        correct: !!question.correct,
        response: question.response,
    };
};
const parseMatchAnswer = (question) => {
    const { correct_answer, question_id, response } = question;

    return {
        question_id,
        currentPickedOption: null,
        options: response.split("~`")?.reduce((acc, value, aIndex) => {
            if (Number(value) > 0) {
                const bIndex = Number(value) - 1;

                const aCorrectIndex = Number(correct_answer[aIndex]) - 1;
                const bCorrectIndex = correct_answer.findIndex(
                    (answerIndex) => Number(answerIndex) - 1 === bIndex
                );

                acc.push({
                    a: aIndex,
                    b: bIndex,
                    isCorrect:
                        aCorrectIndex === bIndex && aIndex === bCorrectIndex,
                    color: getUniqColor(acc),
                });
            }

            return acc;
        }, []),
        correct: !!question.correct,
        response: question?.response,
    };
};
