const images = {
    mainBG: require("../assets/images/main/mainBg.png"),
    authBG: require("../assets/images/auth/authBg.png"),
    headerBG: require("../assets/images/header/Cloud.png"),
    logo: require("../assets/images/auth/logo2.png"),
    mainBGGreen: require("../assets/images/main/mainBGGreen.png"),
    mainBGRed: require("../assets/images/main/mainBGRed.png"),
    mainBGPurple: require("../assets/images/main/mainBGPurple.png"),
    mainBGPurple2: require("../assets/images/main/bgtest.png"),
    mainBGBlue: require("../assets/images/main/mainBGBlue.png"),
    mainBGWhite: require("../assets/images/main/mainBGWhite.png"),
    mainBGYellow: require("../assets/images/main/mainBgYellow.png"),
    mainBGPink: require("../assets/images/main/mainBgPink.png"),
    mainBGPink2: require("../assets/images/main/mainBgPink2.png"),
    mainBGPink3: require("../assets/images/main/mainBgPink3.png"),
    redBtnBG: require("../assets/images/main/mainRedBtnBG.png"),
    greenBtnBG: require("../assets/images/main/btn-bg-green.png"),
    purpleBtnBG: require("../assets/images/main/btn-bg-purple.png"),
    saladBtnBG: require("../assets/images/main/btn-bg-salad.png"),
    blueBtnBG: require("../assets/images/main/mainBlueBtnBG.png"),
    btnImage1: require("../assets/images/main/btn-image-1.png"),
    btnImage2: require("../assets/images/main/btn-image-2.png"),
    btnImage3: require("../assets/images/main/btn-image-3.png"),
    btnImage4: require("../assets/images/main/btn-image-4.png"),
    btnImage5: require("../assets/images/main/time-table-icon.png"),
    academicContentBG: require("../assets/images/main/academicContentBG.png"),
    academicPlannerBG: require("../assets/images/main/mainBGLightGreen.png"),
    testBrownBG: require("../assets/images/main/mainTestBG.png"),
    importantIcon: require("../assets/images/components/important-icon.png"),
    pinBg: require("../assets/images/components/pinBg.png"),
    pinBgError: require("../assets/images/components/pinBgError.png"),
    inputBg: require("../assets/images/components/inputBg.png"),
    inputBg2: require("../assets/images/components/inputBg2.png"),
    inputBgError: require("../assets/images/components/inputBgError.png"),
    englishIcon: require("../assets/images/booksCategories/english.png"),
    vedicMathIcon: require("../assets/images/booksCategories/vedic.png"),
    abacusIcon: require("../assets/images/booksCategories/abacus.png"),
    categoryPlaceholderIcon: require("../assets/images/booksCategories/categoryPlaceholder.png"),
    comingSoonBg: require("../assets/gif/comingSoon.gif"),
    contentTestBG: require("../assets/images/test/contentBG.png"),
    contentDairyBG: require("../assets/images/diaryIcons/contentBG.png"),
    quizzBg: require("../assets/images/quiz/quizzBg.png"),
    timerBg: require("../assets/images/quiz/timerBg.png"),
    quizzStepBg: require("../assets/images/quiz/quizzStepBg.png"),
    quiz_option_ok: require("../assets/images/quiz/option_ok.png"),
    quiz_option_wrong: require("../assets/images/quiz/option_wrong.png"),
    finishTestBG: require("../assets/images/test/finishTestBG.png"),
    quizBee: require("../assets/images/quiz/bee.png"),
    quizHoney: require("../assets/images/quiz/honey.png"),
    quizLion: require("../assets/images/quiz/lion.png"),
    quizRankStar: require("../assets/images/quiz/RankStar.png"),
    quizRankScoreBg: require("../assets/images/quiz/ScoreBg.png"),
};

const letsReadIcons = {
    rhymesIcon: require("../assets/images/letsReadIcons/rhymeIcon.png"),
    phonicsIcon: require("../assets/images/letsReadIcons/phonicsIcon.png"),
    softSkillsIcon: require("../assets/images/letsReadIcons/softSkillsIcon.png"),
    storiesIcon: require("../assets/images/letsReadIcons/storiesIcon.png"),
    readingIcon: require("../assets/images/letsReadIcons/readingIcon.png"),
};

const storiesPlaceholders = {
    0: require("../assets/images/placeholders/story_1.png"),
    1: require("../assets/images/placeholders/story_2.png"),
    2: require("../assets/images/placeholders/story_3.png"),
};
const chapterThumbnails = {
    computerScience: [
        require("../assets/images/chapterThumbnails/computerScience/1.png"),
        require("../assets/images/chapterThumbnails/computerScience/2.png"),
        require("../assets/images/chapterThumbnails/computerScience/3.png"),
        require("../assets/images/chapterThumbnails/computerScience/4.png"),
        require("../assets/images/chapterThumbnails/computerScience/5.png"),
        require("../assets/images/chapterThumbnails/computerScience/6.png"),
        require("../assets/images/chapterThumbnails/computerScience/7.png"),
        require("../assets/images/chapterThumbnails/computerScience/8.png"),
        require("../assets/images/chapterThumbnails/computerScience/9.png"),
        require("../assets/images/chapterThumbnails/computerScience/10.png"),
        require("../assets/images/chapterThumbnails/computerScience/11.png"),
        require("../assets/images/chapterThumbnails/computerScience/12.png"),
        require("../assets/images/chapterThumbnails/computerScience/13.png"),
        require("../assets/images/chapterThumbnails/computerScience/14.png"),
        require("../assets/images/chapterThumbnails/computerScience/15.png"),
        require("../assets/images/chapterThumbnails/computerScience/16.png"),
        require("../assets/images/chapterThumbnails/computerScience/17.png"),
        require("../assets/images/chapterThumbnails/computerScience/18.png"),
        require("../assets/images/chapterThumbnails/computerScience/19.png"),
        require("../assets/images/chapterThumbnails/computerScience/20.png"),
    ],
    english: [
        require("../assets/images/chapterThumbnails/english/1.png"),
        require("../assets/images/chapterThumbnails/english/2.png"),
        require("../assets/images/chapterThumbnails/english/3.png"),
        require("../assets/images/chapterThumbnails/english/4.png"),
        require("../assets/images/chapterThumbnails/english/5.png"),
        require("../assets/images/chapterThumbnails/english/6.png"),
        require("../assets/images/chapterThumbnails/english/7.png"),
        require("../assets/images/chapterThumbnails/english/8.png"),
        require("../assets/images/chapterThumbnails/english/9.png"),
        require("../assets/images/chapterThumbnails/english/10.png"),
        require("../assets/images/chapterThumbnails/english/11.png"),
        require("../assets/images/chapterThumbnails/english/12.png"),
        require("../assets/images/chapterThumbnails/english/13.png"),
        require("../assets/images/chapterThumbnails/english/14.png"),
        require("../assets/images/chapterThumbnails/english/15.png"),
        require("../assets/images/chapterThumbnails/english/16.png"),
        require("../assets/images/chapterThumbnails/english/17.png"),
        require("../assets/images/chapterThumbnails/english/18.png"),
        require("../assets/images/chapterThumbnails/english/19.png"),
        require("../assets/images/chapterThumbnails/english/20.png"),
    ],
    evs: [
        require("../assets/images/chapterThumbnails/evs/1.png"),
        require("../assets/images/chapterThumbnails/evs/2.png"),
        require("../assets/images/chapterThumbnails/evs/3.png"),
        require("../assets/images/chapterThumbnails/evs/4.png"),
        require("../assets/images/chapterThumbnails/evs/5.png"),
        require("../assets/images/chapterThumbnails/evs/6.png"),
        require("../assets/images/chapterThumbnails/evs/7.png"),
        require("../assets/images/chapterThumbnails/evs/8.png"),
        require("../assets/images/chapterThumbnails/evs/9.png"),
        require("../assets/images/chapterThumbnails/evs/10.png"),
        require("../assets/images/chapterThumbnails/evs/11.png"),
        require("../assets/images/chapterThumbnails/evs/12.png"),
        require("../assets/images/chapterThumbnails/evs/13.png"),
        require("../assets/images/chapterThumbnails/evs/14.png"),
        require("../assets/images/chapterThumbnails/evs/15.png"),
        require("../assets/images/chapterThumbnails/evs/16.png"),
        require("../assets/images/chapterThumbnails/evs/17.png"),
        require("../assets/images/chapterThumbnails/evs/18.png"),
        require("../assets/images/chapterThumbnails/evs/19.png"),
        require("../assets/images/chapterThumbnails/evs/20.png"),
    ],
    hindi: [
        require("../assets/images/chapterThumbnails/hindi/1.png"),
        require("../assets/images/chapterThumbnails/hindi/2.png"),
        require("../assets/images/chapterThumbnails/hindi/3.png"),
        require("../assets/images/chapterThumbnails/hindi/4.png"),
        require("../assets/images/chapterThumbnails/hindi/5.png"),
        require("../assets/images/chapterThumbnails/hindi/6.png"),
        require("../assets/images/chapterThumbnails/hindi/7.png"),
        require("../assets/images/chapterThumbnails/hindi/8.png"),
        require("../assets/images/chapterThumbnails/hindi/9.png"),
        require("../assets/images/chapterThumbnails/hindi/10.png"),
        require("../assets/images/chapterThumbnails/hindi/11.png"),
        require("../assets/images/chapterThumbnails/hindi/12.png"),
        require("../assets/images/chapterThumbnails/hindi/13.png"),
        require("../assets/images/chapterThumbnails/hindi/14.png"),
        require("../assets/images/chapterThumbnails/hindi/15.png"),
        require("../assets/images/chapterThumbnails/hindi/16.png"),
        require("../assets/images/chapterThumbnails/hindi/17.png"),
        require("../assets/images/chapterThumbnails/hindi/18.png"),
        require("../assets/images/chapterThumbnails/hindi/19.png"),
        require("../assets/images/chapterThumbnails/hindi/20.png"),
    ],
    math: [
        require("../assets/images/chapterThumbnails/math/1.png"),
        require("../assets/images/chapterThumbnails/math/2.png"),
        require("../assets/images/chapterThumbnails/math/3.png"),
        require("../assets/images/chapterThumbnails/math/4.png"),
        require("../assets/images/chapterThumbnails/math/5.png"),
        require("../assets/images/chapterThumbnails/math/6.png"),
        require("../assets/images/chapterThumbnails/math/7.png"),
        require("../assets/images/chapterThumbnails/math/8.png"),
        require("../assets/images/chapterThumbnails/math/9.png"),
        require("../assets/images/chapterThumbnails/math/10.png"),
        require("../assets/images/chapterThumbnails/math/11.png"),
        require("../assets/images/chapterThumbnails/math/12.png"),
        require("../assets/images/chapterThumbnails/math/13.png"),
        require("../assets/images/chapterThumbnails/math/14.png"),
        require("../assets/images/chapterThumbnails/math/15.png"),
        require("../assets/images/chapterThumbnails/math/16.png"),
        require("../assets/images/chapterThumbnails/math/17.png"),
        require("../assets/images/chapterThumbnails/math/18.png"),
        require("../assets/images/chapterThumbnails/math/19.png"),
        require("../assets/images/chapterThumbnails/math/20.png"),
    ],
    science: [
        require("../assets/images/chapterThumbnails/science/1.png"),
        require("../assets/images/chapterThumbnails/science/2.png"),
        require("../assets/images/chapterThumbnails/science/3.png"),
        require("../assets/images/chapterThumbnails/science/4.png"),
        require("../assets/images/chapterThumbnails/science/5.png"),
        require("../assets/images/chapterThumbnails/science/6.png"),
        require("../assets/images/chapterThumbnails/science/7.png"),
        require("../assets/images/chapterThumbnails/science/8.png"),
        require("../assets/images/chapterThumbnails/science/9.png"),
        require("../assets/images/chapterThumbnails/science/10.png"),
        require("../assets/images/chapterThumbnails/science/11.png"),
        require("../assets/images/chapterThumbnails/science/12.png"),
        require("../assets/images/chapterThumbnails/science/13.png"),
        require("../assets/images/chapterThumbnails/science/14.png"),
        require("../assets/images/chapterThumbnails/science/15.png"),
        require("../assets/images/chapterThumbnails/science/16.png"),
        require("../assets/images/chapterThumbnails/science/17.png"),
        require("../assets/images/chapterThumbnails/science/18.png"),
        require("../assets/images/chapterThumbnails/science/19.png"),
        require("../assets/images/chapterThumbnails/science/20.png"),
    ],
    telugu: [
        require("../assets/images/chapterThumbnails/telugu/1.png"),
        require("../assets/images/chapterThumbnails/telugu/2.png"),
        require("../assets/images/chapterThumbnails/telugu/3.png"),
        require("../assets/images/chapterThumbnails/telugu/4.png"),
        require("../assets/images/chapterThumbnails/telugu/5.png"),
        require("../assets/images/chapterThumbnails/telugu/6.png"),
        require("../assets/images/chapterThumbnails/telugu/7.png"),
        require("../assets/images/chapterThumbnails/telugu/8.png"),
        require("../assets/images/chapterThumbnails/telugu/9.png"),
        require("../assets/images/chapterThumbnails/telugu/10.png"),
        require("../assets/images/chapterThumbnails/telugu/11.png"),
        require("../assets/images/chapterThumbnails/telugu/12.png"),
        require("../assets/images/chapterThumbnails/telugu/13.png"),
        require("../assets/images/chapterThumbnails/telugu/14.png"),
        require("../assets/images/chapterThumbnails/telugu/15.png"),
        require("../assets/images/chapterThumbnails/telugu/16.png"),
        require("../assets/images/chapterThumbnails/telugu/17.png"),
        require("../assets/images/chapterThumbnails/telugu/18.png"),
        require("../assets/images/chapterThumbnails/telugu/19.png"),
        require("../assets/images/chapterThumbnails/telugu/20.png"),
    ],
};

const letsCreateThumbnails = {
    4334: require("../assets/images/letsCreateThumbnails/4334.png"),
    4394: require("../assets/images/letsCreateThumbnails/4394.png"),
    4392: require("../assets/images/letsCreateThumbnails/4392.png"),
    4393: require("../assets/images/letsCreateThumbnails/4393.png"),
};
const letsCreateIcons = {
    ArtCraft: require("../assets/images/letsCreateIcons/ArtCraft.png"),
    DrawingImage: require("../assets/images/letsCreateIcons/DrawingBoard.png"),
    ColoringImage: require("../assets/images/letsCreateIcons/ColouringBoard.png"),
    TracingImage: require("../assets/images/letsCreateIcons/TracingBoard.png"),
};
export const letsLearnIcons = {
    Music: require("../assets/images/letsLearnIcons/music.png"),
    Dance: require("../assets/images/letsLearnIcons/dance.png"),
    Abacus: require("../assets/images/letsLearnIcons/abacus.png"),
};

const diaryIcons = {
    EventsIcon: require("../assets/images/diaryIcons/EventsIcon.png"),
    HomeworkIcon: require("../assets/images/diaryIcons/HomeworkIcon.png"),
    TimeTableIcon: require("../assets/images/diaryIcons/TimeTableIcon.png"),
};
const cameraIcons = {
    CONTROLS_BG: require("../assets/images/Camera/CameraControls.png"),
    TRIGGER_ICON: require("../assets/images/Camera/TakePictureIcon.png"),
    GALERY_ICON: require("../assets/images/Camera/GaleryIcon.png"),
};

const notificationsImages = {
    BG_GREY: require("../assets/images/notifications/notifications-bg-grey.png"),
};

const parentsCornerIcons = {
    SUDENTS_PROFILE: require("../assets/images/parentsCornerIcons/studentsProfile.png"),
    ATTENDANCE_RECORD: require("../assets/images/parentsCornerIcons/attendanceRecord.png"),
    APP_ACTIVITY: require("../assets/images/parentsCornerIcons/AppActivity.png"),
    ACADEMIC_PLANNER: require("../assets/images/parentsCornerIcons/AcademicPlanner.png"),
};

const getSubjectThumbnail = (index, id) => {
    const scienceIDs = [5348, 6050];
    const computureScience = [29578];
    const evs = [21889];
    const english = [7511];
    const telugu = [12424];
    const hindi = [12428];
    const math = [3, 37233];
    switch (true) {
        case scienceIDs.includes(id):
            return chapterThumbnails?.science[index];
        case computureScience.includes(id):
            return chapterThumbnails?.computerScience[index];
        case evs.includes(id):
            return chapterThumbnails?.evs[index];
        case english.includes(id):
            return chapterThumbnails?.english[index];
        case math.includes(id):
            return chapterThumbnails?.math[index];
        case telugu.includes(id):
            return chapterThumbnails?.telugu[index];
        case hindi.includes(id):
            return chapterThumbnails?.hindi[index];
        default:
            return "";
    }
};

export {
    images,
    letsReadIcons,
    storiesPlaceholders,
    chapterThumbnails,
    letsCreateThumbnails,
    getSubjectThumbnail,
    letsCreateIcons,
    diaryIcons,
    cameraIcons,
    notificationsImages,
    parentsCornerIcons,
};
