import { View } from "react-native";
import { useStylesUtils } from "../../../../hooks";
import { EditBtn } from "../components/EditBtn";
import { ProfileInput } from "../components/ProfileInput";

export const ParentsDetails = () => {
    const { styles, height } = useStylesUtils(getStyles);

    return (
        <View style={styles.tab}>
            <View style={styles.inputsContainer}>
                <View>
                    <ProfileInput
                        lable={"Father’s Name"}
                        inputWidth={"55%"}
                        margins={{ marginBottom: height * 0.02 }}
                    />
                    <View style={styles.inputsGroup}>
                        <ProfileInput
                            lable={"Contact number:"}
                            inputWidth={"40%"}
                            margins={{ marginRight: "3%" }}
                        />
                        <ProfileInput lable={"E-mail ID:"} inputWidth={"55%"} />
                    </View>
                </View>
                <View>
                    <ProfileInput
                        lable={"Mother’s Name"}
                        inputWidth={"55%"}
                        margins={{ marginBottom: height * 0.02 }}
                    />
                    <View style={styles.inputsGroup}>
                        <ProfileInput
                            lable={"Alternate Contact number:"}
                            inputWidth={"40%"}
                            margins={{ marginRight: "3%" }}
                        />
                        <ProfileInput
                            lable={"Secondary E-mail ID:"}
                            inputWidth={"55%"}
                        />
                    </View>
                </View>
            </View>
            <View style={styles.btnContainer}>
                <EditBtn onPress={() => {}} />
            </View>
        </View>
    );
};

const getStyles = ({ height, width }) => ({
    tab: {
        width: width * 0.6,
        height: height * 0.7,
        borderRadius: 25,
        borderWidth: 3,
        borderColor: "#7E1121",
        backgroundColor: "#FFFFFF",
        padding: width * 0.02,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    inputsContainer: {
        height: "100%",
        width: "85%",
        justifyContent: "space-around",
    },
    inputsGroup: {
        flexDirection: "row",
        width: "100%",
    },

    btnContainer: {
        height: "100%",
        justifyContent: "flex-end",
    },
});
