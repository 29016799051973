import { useEffect, useMemo, useState } from "react";
import { ScrollView, View } from "react-native";
import { COLOURS } from "../constants";
import { useStylesUtils } from "../hooks";

export const CustomScrollView = ({
    children,
    showIndicator,
    colors = {},
    scrollIndicatorBGStyles = {},
    setRef = () => {},
    layoutSizes = () => {},
    ...rest
}) => {
    const { indicatorBG = COLOURS.purple11, indicator = COLOURS.purple17 } =
        colors;

    const { styles, isWeb } = useStylesUtils({
        scrollBGView: {
            position: "absolute",
            right: 0,
            marginBottom: 8,
            width: 8,
            height: "100%",
            backgroundColor: indicatorBG,
            zIndex: 100,
            overflow: "hidden",
            borderRadius: 10,
            ...scrollIndicatorBGStyles,
        },
    });

    const [sizes, setSizes] = useState({
        y: 0,
        contentHeight: 0,
        scrollView: {},
    });

    const [scrollRef, setScrollRef] = useState(null);

    useEffect(() => {
        setRef(scrollRef);
    }, [scrollRef]);

    const { scrollPosition, indicatorHeight, showScroll } = useMemo(() => {
        layoutSizes({
            contentHeight: sizes.contentHeight,
            scrollView: sizes.scrollView,
        });

        const indicatorHeight =
            (sizes.scrollView?.height || 0) / (sizes.contentHeight / 100);

        return {
            showScroll:
                showIndicator && sizes.scrollView?.height < sizes.contentHeight,
            indicatorHeight,
            scrollPosition:
                (sizes.y / (sizes.contentHeight - sizes.scrollView?.height)) *
                (100 - indicatorHeight),
        };
    }, [sizes, showIndicator]);

    const setParams = (props) => setSizes((prev) => ({ ...prev, ...props }));

    const onScroll = (e) => setParams(e.nativeEvent.contentOffset);
    const onContentSizeChange = (_, height) =>
        setParams({ contentHeight: height });
    const onLayout = (e) => setParams({ scrollView: e.nativeEvent.layout });

    return (
        <>
            {showScroll && !isWeb && (
                <View style={styles.scrollBGView}>
                    <View
                        style={{
                            top: `${Number(scrollPosition || 0).toFixed(0)}%`,
                            height: `${indicatorHeight}%`,
                            backgroundColor: indicator,
                        }}
                    />
                </View>
            )}
            <ScrollView
                {...rest}
                ref={setScrollRef}
                showsVerticalScrollIndicator={
                    !showIndicator || (isWeb && showIndicator)
                }
                showsHorizontalScrollIndicator={
                    !showIndicator || (isWeb && showIndicator)
                }
                scrollEventThrottle={16}
                onScroll={onScroll}
                onContentSizeChange={onContentSizeChange}
                onLayout={onLayout}>
                {children}
            </ScrollView>
        </>
    );
};
