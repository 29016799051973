import { View, Text, Pressable } from "react-native";
import { useStylesUtils } from "../../hooks";
import { COLOURS } from "../../constants";
import { CardsImage } from "./components/CardsImage";

export const BookCard = ({ title = "cardTitle", image = false, onPress }) => {
    const { styles } = useStylesUtils(getStyles);

    return (
        <View style={styles.container}>
            <Pressable style={styles.bookContainer} onPress={onPress}>
                <CardsImage source={image} style={styles.cardImage} />
                <View style={styles.title}>
                    <Text
                        numberOfLines={2}
                        ellipsizeMode="tail"
                        style={styles.titleText}>
                        {title}
                    </Text>
                </View>
            </Pressable>
        </View>
    );
};

const getStyles = ({ biggerSize }) => ({
    container: {
        marginRight: biggerSize * 0.02,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    bookContainer: {
        width: biggerSize * 0.17,
        height: biggerSize * 0.23,
        paddingTop: 5,
    },
    cardImage: {
        zIndex: -5,
        width: biggerSize * 0.16,
        height: biggerSize * 0.217,
    },
    title: {
        position: "absolute",
        bottom: biggerSize * 0.2 * 0.2,
        left: biggerSize * 0.025,
        width: biggerSize * 0.13,
        justifyContent: "center",
    },
    titleText: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        fontSize: 18,
        textAlign: "center",
    },
});
