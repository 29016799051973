import * as NavigationBar from "expo-navigation-bar";
import React, { useEffect } from "react";
import { ImageBackground } from "react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { useStylesUtils } from "../../../hooks";
import { HardwareBackProvider } from "../../../providers/hardwareBackProvider";
import { HeaderComponent } from "../Header";
import { useMainLayoutHook } from "./useMainLayoutHook";

export const MainLayout = ({
    children,
    headerProps,
    viewStyle = {},
    isCenteredContent = true,
    isSaveArea = true,
    tabs = null,
}) => {
    const { styles, isAndroid } = useStylesUtils(getStyles(isCenteredContent), {
        viewStyle,
    });

    const { checkedHeaderProps, content, image, bgColor } = useMainLayoutHook({
        headerProps,
        children,
        tabs,
        styles,
        isSaveArea,
    });

    useEffect(() => {
        if (isAndroid) {
            NavigationBar.setBehaviorAsync("overlay-swipe");
            NavigationBar.setVisibilityAsync("hidden");
        }
    }, []);

    return (
        <GestureHandlerRootView style={{ flex: 1, overflow: "hidden" }}>
            <HardwareBackProvider>
                <ImageBackground
                    resizeMode="cover"
                    source={image}
                    style={[styles.container, { backgroundColor: bgColor }]}>
                    <HeaderComponent {...checkedHeaderProps} />
                    {content}
                </ImageBackground>
            </HardwareBackProvider>
        </GestureHandlerRootView>
    );
};

const getStyles = (isCenteredContent) => () => {
    return {
        container: {
            flex: 1,
        },
        content: {
            flex: 1,
            justifyContent: "center",
            alignItems: isCenteredContent ? "center" : "flex-start",
            flexDirection: "row",
            zIndex: 4,
        },
        contentBgStyle: {
            flex: 1,
            height: "100%",
            zIndex: 3,
            width: "100%",
        },
    };
};
