export const results = [
    { id: 1, title: "Science & Technology" },
    { id: 2, title: "Science & Technology" },
    { id: 3, title: "Science & Technology 111" },
    { id: 4, title: "Science & Technology & ce & tech" },
    { id: 5, title: "Science & Technology" },
    { id: 6, title: "Science & Technology" },
    { id: 7, title: "Science & Technology" },
    { id: 8, title: "Science & Technology" },
];
