import * as React from "react";
import Svg, { Path } from "react-native-svg";

/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent(props) {
    return (
        <Svg
            width={160}
            height={155}
            viewBox="0 0 160 155"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                d="M64.376 3.252c39.919 0 88.646 22.815 88.646 62.734s-36.453 79.098-76.371 79.098c-39.92 0-76.372-39.179-76.372-79.098 0-39.92 24.178-62.734 64.097-62.734z"
                fill="#fff"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M63.239 11.567c34.771 0 77.215 19.873 77.215 54.645 0 34.772-31.752 68.9-66.524 68.9-34.771 0-66.524-34.128-66.524-68.9 0-34.772 21.061-54.645 55.833-54.645z"
                fill="#0A3056"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M112.257 62.478c-3.412-.808-6.825-1.625-10.239-2.445A32.132 32.132 0 0099.3 53.6c1.796-2.949 3.565-5.874 5.3-8.77.388-.647.259-1.51-.307-2.084a1256.596 1256.596 0 01-6.94-7.084c-.561-.578-1.405-.708-2.037-.31a1340.556 1340.556 0 00-8.554 5.432 30.77 30.77 0 00-6.282-2.776c-.804-3.492-1.604-6.984-2.394-10.475-.177-.78-.835-1.34-1.586-1.347-3.08-.028-6.16-.028-9.241 0-.751.007-1.409.567-1.586 1.347-.79 3.49-1.59 6.983-2.394 10.475a30.765 30.765 0 00-6.282 2.776 1334.95 1334.95 0 00-8.554-5.432c-.632-.398-1.476-.268-2.037.31a1256.56 1256.56 0 01-6.94 7.084c-.566.574-.695 1.437-.307 2.085 1.735 2.895 3.504 5.82 5.3 8.77a32.207 32.207 0 00-2.719 6.432c-3.413.82-6.826 1.637-10.239 2.445a1.702 1.702 0 00-1.316 1.626 545.622 545.622 0 000 9.489c.006.77.554 1.445 1.317 1.626 3.412.808 6.825 1.624 10.238 2.445a32.21 32.21 0 002.72 6.433 1388.938 1388.938 0 00-5.301 8.77c-.388.647-.26 1.51.306 2.084a1266.93 1266.93 0 016.94 7.084c.562.578 1.406.708 2.038.31 2.824-1.777 5.677-3.591 8.554-5.432a30.78 30.78 0 006.282 2.776c.804 3.492 1.604 6.984 2.394 10.475.177.78.835 1.34 1.586 1.347 3.08.028 6.16.028 9.241 0 .75-.007 1.409-.567 1.586-1.347.79-3.491 1.59-6.983 2.394-10.475a30.787 30.787 0 006.282-2.776c2.877 1.84 5.73 3.655 8.554 5.432.632.398 1.476.268 2.038-.31 2.302-2.37 4.618-4.733 6.939-7.084.566-.574.695-1.437.307-2.085-1.734-2.895-3.504-5.82-5.3-8.77a32.131 32.131 0 002.718-6.432c3.414-.82 6.827-1.637 10.239-2.445a1.702 1.702 0 001.317-1.626 535.88 535.88 0 000-9.489 1.702 1.702 0 00-1.317-1.626zM71.879 88.472C61.413 88.455 52.718 79.57 52.7 68.85c.018-10.72 8.713-19.607 19.18-19.624 10.466.017 19.16 8.903 19.178 19.623-.017 10.72-8.712 19.607-19.179 19.624z"
                fill="#fff"
            />
        </Svg>
    );
}

export default SvgComponent;
