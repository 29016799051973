import * as React from "react";
import Svg, { Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent(props) {
    return (
        <Svg
            width="68"
            height="116"
            viewBox="0 0 68 116"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M65.9356 48.6206C65.3636 47.5057 64.2157 46.8047 62.9627 46.8047H43.5467L49.1184 6.1903C49.3305 4.64512 48.445 3.15838 46.9849 2.6089C45.5251 2.05963 43.8792 2.59339 43.0197 3.89548L2.25019 65.6435C1.57279 66.6695 1.51351 67.9848 2.09614 69.0676C2.67877 70.1504 3.80897 70.8257 5.0386 70.8257H24.1769L15.9337 110.419C15.612 111.964 16.4158 113.524 17.8609 114.159C18.2954 114.35 18.752 114.441 19.2036 114.441C20.2539 114.441 21.2753 113.945 21.9193 113.048L65.6772 52.0945C66.4079 51.0767 66.5076 49.7353 65.9356 48.6206"
            />
            <Path
                d="M65.9356 48.6206C65.3636 47.5057 64.2157 46.8047 62.9627 46.8047H43.5467L49.1184 6.1903C49.3305 4.64512 48.445 3.15838 46.9849 2.6089C45.5251 2.05963 43.8792 2.59339 43.0197 3.89548L2.25019 65.6435C1.57279 66.6695 1.51351 67.9848 2.09614 69.0676C2.67877 70.1504 3.80897 70.8257 5.0386 70.8257H24.1769L15.9337 110.419C15.612 111.964 16.4158 113.524 17.8609 114.159C18.2954 114.35 18.752 114.441 19.2036 114.441C20.2539 114.441 21.2753 113.945 21.9193 113.048L65.6772 52.0945C66.4079 51.0767 66.5076 49.7353 65.9356 48.6206"
                stroke="#330A75"
                stroke-width="3"
            />
        </Svg>
    );
}

export default SvgComponent;
