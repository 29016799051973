import React, { useState, useCallback } from "react";
import RNDateTimePicker from "@react-native-community/datetimepicker";
import { useStylesUtils } from "../hooks/useStylesUtils";
import { Text, TouchableOpacity } from "react-native";
import moment from "moment";
import { COLOURS } from "../constants";
import { DatePickerModal } from "react-native-paper-dates";

export const DatePicker = ({
    value = new Date(),
    onChange,
    TextColor = "#000000",
    minDate,
    maxDate,
    mode = "date",
    isVisible = false,
    style,
}) => {
    const { styles, isAndroid, isWeb } = useStylesUtils({
        text: {
            fontSize: 20,
            fontWeight: 700,
            color: COLOURS.dark_purple,
        },
    });

    const [visible, setVisible] = useState(isVisible);

    const onChangeFunc = (e) => {
        setVisible(false);
        onChange(e?.nativeEvent?.timestamp);
    };

    const onDismissSingle = useCallback(() => {
        setVisible(false);
    }, []);

    const onConfirmSingle = useCallback(
        (params) => {
            setVisible(false);
            onChange(params.date);
        },
        [setVisible, onChange]
    );

    return (
        <>
            {isWeb && (
                <DatePickerModal
                    locale="en"
                    mode="single"
                    visible={visible}
                    onDismiss={onDismissSingle}
                    date={value}
                    onConfirm={onConfirmSingle}
                    validRange={{ startDate: minDate, endDate: maxDate }}
                />
            )}
            {((isAndroid && visible) || !isAndroid) && (
                <RNDateTimePicker
                    mode={mode}
                    value={value}
                    style={style}
                    minimumDate={minDate}
                    maximumDate={maxDate}
                    textColor={TextColor}
                    onChange={onChangeFunc}
                />
            )}
            {(isAndroid || isWeb) && (
                <TouchableOpacity
                    onPress={() => setVisible(true)}
                    style={{
                        flex: 1,
                        alignItems: "center",
                    }}>
                    <Text style={styles.text}>
                        {moment(value).format("Do MMM y")}
                    </Text>
                </TouchableOpacity>
            )}
        </>
    );
};
