import { View, TouchableOpacity } from "react-native";
import { Btn1Icon } from "../../../assets/svg";
import React, { useMemo, useContext } from "react";
import { useStylesUtils } from "../../../hooks";
import GlobalAudioContext from "../../../providers/GlobalAudioContext";

export const AddonButton = ({
    children,
    onPress = () => {},
    width: iconWidth,
    height: iconHeight,
    ratio: iconRatio,
    style,
}) => {
    const defaultSize = { width: 319, height: 144, ratio: 6.1 };
    const { biggerSize: windowWidth, styles } = useStylesUtils(getStyles);
    const { playClickSound } = useContext(GlobalAudioContext);

    const { width, height } = useMemo(() => {
        const coeff = defaultSize.width / defaultSize.height;
        let width = windowWidth / (iconRatio ? iconRatio : defaultSize.ratio);

        if (iconWidth) {
            width = iconWidth;
        }

        let height = width / coeff;

        return { width, height };
    }, [iconWidth, iconHeight, iconRatio]);

    const rewriteOnPress = (...props) => {
        playClickSound();
        onPress(...props);
    };

    return (
        <TouchableOpacity
            style={[styles.container, style]}
            onPress={rewriteOnPress}>
            <Btn1Icon styles={styles.bg} width={width} height={height} />
            <View style={styles.view}>{children}</View>
        </TouchableOpacity>
    );
};

const getStyles = () => ({
    container: {
        justifyContent: "center",
        alignItems: "center",
    },
    bg: {
        position: "absolute",
        zIndex: 1,
    },
    view: {
        width: "70%",
        position: "absolute",
        zIndex: 2,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
});
