export const navigation = {
    SPLASH: "/",
    LOGIN: "/login",
    HOME: "/home",
    DIARY: "/diary",
    LETS_READ: "/letsRead",
    LETS_CREATE: "/letsCreate",
    LETS_LEARN: "/letsLearn",
    LETS_EXPLORE: "/LetsExplore",
    READ_PLAYLISTS: "/ReadPlaylists",
    ENTER_ADMISSION_NUMBER: "/EnterAdmissionNumber",
    ENTER_REGISTERED_PHONE: "/EnterRegisteredPhone",
    ENTER_PASS_CODE: "/EnterPassCode",
    ENTER_VERIFY_OTP: "/EnterVerifyOtp",
    SET_NEW_PASSCODE: "/SetNewPasscode",
    PLAYLIST: "/PLAYLIST",
    BOOKS_SCREEN: "/BooksScreen",
    CHAPTERS_SCREEN: "/ChaptersScreen",
    MUSIC_SCREEN: "/MusicScreen",
    STORY_SCREEN: "/StoryScreen",
    WEB_STORY_SCREEN: "/WebStoryScreen",
    SUBJECTS_SCREEN: "/SubjectsScreen",
    OOPS_SCREEN: "/OopsScreen",
    STORIES_SCREEN: "/StoriesScreen",
    VIDEOS_SCREEN: "/VideosScreen",
    COMING_SOON_SCREEN: "/ComingSoonScreen",
    TRACING_BOARD_SCREEN: "/TracingBoardScreen",
    HOME_WORK_SCREEN: "/Index",
    UPLOAD_HOMEWORK_SCREEN: "/UploadHomeWorkScreen",
    CAMERA_SCREEN: "/CameraScreen",
    PREVIEW_SCREEN: "/PreviewScreen",
    GALLERY_SCREEN: "/GalleryScreen",
    CLASS_ACTIVITY_SCREEN: "/ClassActivityScreen",
    Events_SCREEN: "/EventsScreen",
    TIME_TABLE_SCREEN: "/TimeTableScreen",
    SEARCH_SCREEN: "/SearchScreen",
    PARENTS_CORNER_SCREEN: "/ParentsCorner",
    PROFILE_SCREEN: "/ProfileScreen",
    ACADEMIC_SCREEN: "/AcademicScreen",
    ACADEMIC_PLANNER_SCREEN: "/AcademicPlannerScreen",
    DOCUMENT_SCREEN: "/DocumentScreen",
    VIDEO_SCREEN: "/VideoScreen",
    FAVORITE_SCREEN: "/FavoriteScreen",
    TEST_SCREEN: "/TestScreen",
    QUIZZ_SCREEN: "/Quizz",
    FINISH_TEST_SCREEN: "/FinishTestScreen",
};
