import * as React from "react";
import Svg, { G, Mask, Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={60}
            height={42}
            viewBox="0 0 60 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Mask
                id="a"
                style={{
                    maskType: "luminance",
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={60}
                height={42}>
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 0h59.63v42H0V0z"
                    fill="#fff"
                />
            </Mask>
            <G mask="url(#a)" fillRule="evenodd" clipRule="evenodd">
                <Path
                    d="M59.63 20.53c.01 5.47-.148 10.94-.474 16.402-.134 2.164-2.042 4.063-4.222 4.218-10.94.738-21.808.995-32.697.773-2.17-.049-3.992-1.948-4.054-4.22a673.478 673.478 0 01-.207-17.19c.004-2.276 1.827-4.134 4.058-4.148 11.177-.048 22.36.007 33.524.167 2.228.04 4.066 1.831 4.072 3.998zM20.682 37.758c.018.85.694 1.553 1.509 1.57 9.751.173 19.491.013 29.264-.48.817-.043 1.515-.748 1.556-1.573.27-5.58.4-11.166.392-16.753a1.52 1.52 0 00-1.512-1.506c-9.946-.052-19.909-.068-29.866-.05-.832.002-1.509.694-1.51 1.545-.002 5.752.054 11.504.167 17.247"
                    fill="#B7245C"
                />
                <Path
                    d="M57.474 26.259c-.03 1.69-.077 3.38-.137 5.07a1.187 1.187 0 01-1.158 1.086.991.991 0 01-1.017-1.008c.057-1.703.1-3.405.129-5.109a1.128 1.128 0 011.14-1.065 1.042 1.042 0 011.043 1.026z"
                    fill="#F5ADCB"
                />
                <Path
                    d="M31.967 9.765a.263.263 0 01-.314-.149C27.68 1.008 18.298-2.494 9.997 1.898 1.637 6.317-2.477 15.896 1.572 23.999c2.59 5.276 7.918 9.105 13.78 9.76l.097.002c.616.014 1.116-.466 1.145-1.097.019-.66-.478-1.236-1.124-1.3-7.792-.902-13.847-7.87-13.023-15.51.849-7.645 8.042-13.857 15.652-13.416 4.802.296 9.038 3.304 11.257 7.845a.227.227 0 01-.132.313l-2.319.715a.171.171 0 00-.032.31c1.55.866 3.104 1.737 4.662 2.61a.37.37 0 00.511-.154c.837-1.594 1.67-3.186 2.494-4.773a.184.184 0 00-.08-.24.17.17 0 00-.122-.014c-.79.237-1.58.475-2.37.715"
                    fill="#F686BD"
                />
                <Path
                    d="M17.07 4.955c-.643.017-1.18.57-1.198 1.232a564.37 564.37 0 00-.173 9.93 1349.13 1349.13 0 01-5.203 5.26 1.192 1.192 0 00-.02 1.681c.457.474 1.205.49 1.672.034l.025-.026c1.772-1.788 3.558-3.599 5.347-5.422.334-.225.536-.605.539-1.011.022-3.502.072-7.003.148-10.5.015-.667-.494-1.194-1.137-1.178"
                    fill="#F686BD"
                />
                <Path
                    d="M22.121 18.464c9.806-.677 19.51-.677 29.315 0 1.055.075 1.977.994 2.041 2.04.333 5.524.333 11.064 0 16.588-.063 1.047-.986 1.965-2.04 2.04-9.806.677-19.51.677-29.316 0-1.054-.075-1.977-.994-2.04-2.04a138.183 138.183 0 010-16.588c.063-1.047.986-1.965 2.04-2.04z"
                    fill="#F5ADCB"
                />
            </G>
        </Svg>
    );
}

export default SvgComponent;
