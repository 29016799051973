import { useCallback } from "react";
import { Platform } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-native";
import { navigation } from "../constants";
import { getUserInfoForQueries } from "../store/auth/selectors";
import { getStoryThunk } from "../store/main/thunks";
import { useAuth } from "./useAuth";
import { useDiaryHook } from "./useDiaryHook";
import { cookiesSeter } from "../utils/cookiesUtil";

export const useCreateHook = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const platform = Platform.OS;
    const { checkRejectResponse } = useAuth();
    const { tenantId } = useSelector(getUserInfoForQueries);

    const { changeHwStatus } = useDiaryHook();

    const getStorysName = useCallback(async ({ params = [], location }) => {
        let storiesArr = [];
        for (let i = 0; i < params.length; i++) {
            const result = await dispatch(
                getStoryThunk({ storyId: params[i], tenantId: tenantId })
            );
            if (getStoryThunk.fulfilled.match(result)) {
                const story = {
                    storyId: result.payload.storyId,
                    title: result.payload.title,
                };
                storiesArr.push(story);
            }

            if (getStoryThunk.rejected.match(result)) {
                await checkRejectResponse(result);
            }
        }

        navigate(location, { state: storiesArr });
    }, []);

    const getStory = useCallback(async ({ params = {} }) => {
        const result = await dispatch(getStoryThunk({ ...params, tenantId }));
        if (getStoryThunk.fulfilled.match(result)) {
            if (result != null) {
                const url = new URL(result.payload?.storyURL);
                const hostname = url.host;
                const regex = /^[^.]+\./;
                const editedHostname = hostname.replace(regex, "");
                // console.log("hostname", hostname);
                // console.log("editedHostname", editedHostname);
                const state = {
                    policy: result.payload.policy,
                    keyPairId: result.payload.keyPairId,
                    signature: result.payload.signature,
                    uri: result.payload.storyURL,
                    hostname: editedHostname,
                };

                if (platform === "web") {
                    cookiesSeter(
                        state.policy,
                        state.keyPairId,
                        state.signature
                    );
                    setTimeout(() => {
                        //window.open(state.uri, "_blank");
                        navigate(navigation.WEB_STORY_SCREEN, {
                            state,
                        });
                    }, 1000);

                    // navigate(navigation.WEB_STORY_SCREEN, { state });
                } else {
                    navigate(navigation.STORY_SCREEN, { state });
                }
            }
            changeHwStatus(params);
        }

        if (getStoryThunk.rejected.match(result)) {
            await checkRejectResponse(result);
        }
    }, []);

    return {
        getStory,
        getStorysName,
    };
};
