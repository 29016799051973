import React from "react";
import { Text, View } from "react-native";
import { COLOURS } from "../../../../constants";
import { useStylesUtils } from "../../../../hooks/useStylesUtils";
import { Button } from "../../../Button";

export const ClassActivityCheckAcknowledgingContent = () => {
    const { styles } = useStylesUtils(getStyles);

    return (
        <View style={{ flex: 1 }}>
            <Text style={styles.text}>
                Please click <Text style={styles.boldText}>CONTINUE</Text> to
                proceed with Acknowledgement, or click{" "}
                <Text style={styles.boldText}>VIEW</Text> button to view
                Homework.
            </Text>
            <View style={styles.buttonsContainer}>
                <Button
                    label={"Continue"}
                    style={{ marginRight: 20 }}
                    iconProps={{ ratio: 0.12 }}
                />
                <Button
                    label={"View"}
                    fillColor={COLOURS.purple17}
                    iconProps={{ ratio: 0.12 }}
                />
            </View>
        </View>
    );
};

const getStyles = ({ width }) => ({
    text: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: 600,
        color: COLOURS.dark_purple,
    },
    buttonsContainer: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        marginTop: 30,
        paddingBottom: width * 0.01,
    },
});
