import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
    return (
        <Svg
            width={172}
            height={158}
            viewBox="0 0 172 158"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M155.563 32.7306C180.244 68.5932 177.307 118.825 141.444 143.506C105.582 168.187 36.8873 160.906 12.2064 125.043C-12.4742 89.1808 3.62434 39.8883 39.4867 15.2074C75.3493 -9.47356 130.882 -3.13176 155.563 32.7306Z"
                fill="#E93F0D"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M86.2013 12.2021H86.1997H86.1995C76.9028 12.2021 69.5586 20.0901 70.2213 29.3635L75.3757 101.486C75.7816 107.164 80.5067 111.564 86.1995 111.564H86.1997H86.2013C91.8943 111.564 96.6195 107.164 97.0253 101.486L102.18 29.3635C102.842 20.0901 95.498 12.2021 86.2013 12.2021Z"
                fill="white"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M99.1432 133.241C99.1432 140.388 93.3491 146.183 86.2016 146.183C79.0541 146.183 73.2598 140.388 73.2598 133.241C73.2598 126.093 79.0541 120.299 86.2016 120.299C93.3491 120.299 99.1432 126.093 99.1432 133.241Z"
                fill="white"
            />
        </Svg>
    )
}

export default SvgComponent
