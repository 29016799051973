import { View, Text, Image } from "react-native";
import React from "react";
import { useStylesUtils } from "../../../../hooks/useStylesUtils";
import { COLOURS } from "../../../../constants";
import RenderHtml from "react-native-render-html";

export const StartTestContent = ({ syllabus, instructionText, details }) => {
    const { styles, width } = useStylesUtils(getStyles);

    const source = instructionText
        ? {
              html: `${instructionText}`,
          }
        : undefined;

    const assessmentsList = [
        {
            id: 1,
            show: !!details.duration,
            text: `${details.duration} Minutes`,
            image: require("../../../../assets/images/test/clock.png"),
        },
        {
            id: 2,
            show: !!details.totalMarks,
            text: `${details.totalMarks} Marks`,
            image: require("../../../../assets/images/test/mode.png"),
        },
        {
            id: 3,
            show: !!details.totalQuestions,
            text: `${details.totalQuestions} Ques`,
            image: require("../../../../assets/images/test/ques.png"),
        },
    ].filter(({ show }) => show);

    return (
        <>
            <View>
                <Text style={styles.title}>Formative Assessment</Text>
                <View
                    style={{
                        flexDirection: "row",
                        paddingVertical: width * 0.005,
                    }}>
                    {assessmentsList.map(({ id, text, image }, i, all) => (
                        <View
                            key={"assessment" + id}
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                            }}>
                            {image && (
                                <Image
                                    source={image}
                                    style={{
                                        width: width * 0.02,
                                        height: width * 0.02,
                                        marginRight: width * 0.005,
                                    }}
                                />
                            )}
                            {text && (
                                <Text style={styles.text}>
                                    {text}
                                    {i === all.length - 1 ? "" : " | "}
                                </Text>
                            )}
                        </View>
                    ))}
                </View>

                <View style={{ flexDirection: "row", marginTop: width * 0.01 }}>
                    {syllabus?.chapters?.length && (
                        <View style={{ flex: 1 }}>
                            <Text style={styles.title}>Syllabus </Text>
                            {syllabus?.chapters?.map(
                                ({ chapterName, chapterId }) => (
                                    <Text
                                        key={"Syllabus" + chapterId}
                                        style={styles.text}>
                                        {chapterName}
                                    </Text>
                                )
                            )}
                        </View>
                    )}
                    {syllabus?.chapters?.length && source && (
                        <View style={styles.line} />
                    )}
                    {source && (
                        <View style={{ flex: 1 }}>
                            <RenderHtml
                                contentWidth={width / 2}
                                source={source}
                                style={styles.text}
                            />
                        </View>
                    )}
                </View>
            </View>
        </>
    );
};

const getStyles = ({ width, height }) => ({
    title: {
        fontSize: 24,
        fontWeight: 700,
        color: COLOURS.brown4,
    },
    text: {
        fontSize: 18,
        fontWeight: 700,
        marginRight: width * 0.005,
    },
    line: {
        borderWidth: 2,
        marginRight: width * 0.05,
        borderColor: COLOURS.dark_purple,
        backgroundColor: COLOURS.dark_purple,
        borderRadius: 10,
    },
});
