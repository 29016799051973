import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { CarrouselComponent, MainLayout } from "../../../components";
import { useRead } from "../../../hooks";
import { getPlaylistsForUserRedux } from "../../../store/main/selectors";
import { getDeepCopy, parseListsUtils } from "../../../utils/parse-lists.utils";
import { CardView } from "./CardView";
import { letsReedCategoriesNames, letsReedGetIcon } from "./optons";
import { TitleContent } from "./TitleContent";

export const LetsReadScreen = () => {
    const { getVideosFor } = useRead();
    const playListData = useSelector(getPlaylistsForUserRedux);

    const parseList = useMemo(() => {
        const apiResultCopy = getDeepCopy(playListData);

        const editedResult = letsReedCategoriesNames.reduce((acc, value) => {
            const find = apiResultCopy.find(({ title }) =>
                title.toLowerCase().includes(value)
            );
            if (find) {
                acc.push({ ...find, title: value });
            }

            return acc;
        }, []);

        return parseListsUtils(editedResult, (item) => ({
            key: item?.id,
            type: "playlist",
            image: letsReedGetIcon(item?.title),
            onPress: () =>
                getVideosFor(
                    item?.id,
                    item?.title,
                    letsReedGetIcon(item?.title)
                ),
        }));
    }, [playListData]);

    return (
        <MainLayout
            headerProps={{
                backButton: true,
                homeButton: true,
                titleContent: <TitleContent />,
            }}>
            <CarrouselComponent data={parseList} CardView={CardView} />
        </MainLayout>
    );
};
