import React from "react";
import { Image } from "react-native";
import { useStylesUtils } from "../../hooks";
import { images } from "../../constants";

export const TitleContent = () => {
    const { styles, width } = useStylesUtils(getStyles);

    const logoWidth = width * 0.2;

    return (
        <Image
            source={images.logo}
            style={[
                styles.logo,
                { height: logoWidth / 1.75, width: logoWidth },
            ]}
            resizeMode="cover"
        />
    );
};

const getStyles = ({ biggerSize }) => ({
    logo: {
        marginVertical: biggerSize * 0.05,
    },
});
