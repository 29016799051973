import React from "react";
import { useNavigate } from "react-router-native";
import { CarrouselComponent, MainLayout } from "../../../components";
import { navigation, parentsCornerIcons } from "../../../constants";
import { CardView } from "./CardView";
import { TitleContent } from "./TitleContent";

export const ParentsCornerScreen = () => {
    const navigate = useNavigate();

    const categories = [
        {
            id: 1,
            onPress: () => navigate(navigation.ACADEMIC_SCREEN),
            title: "App activity\n& Reports",
            image: parentsCornerIcons.APP_ACTIVITY,
            color: "#FFF3F8",
        },
        {
            id: 2,
            onPress: () => console.log("Attendance\nRecord"),
            title: "Attendance\nRecord",
            image: parentsCornerIcons.ATTENDANCE_RECORD,
            color: "#F1F6FF",
        },
        {
            id: 3,
            onPress: () => console.log("Students\nProfile"),
            title: "Students\nProfile",
            image: parentsCornerIcons.SUDENTS_PROFILE,
            color: "#FFE8E8",
        },
        {
            id: 4,
            onPress: () => navigate(navigation.ACADEMIC_PLANNER_SCREEN),
            title: "Academic\nPlanner",
            image: parentsCornerIcons.ACADEMIC_PLANNER,
            color: "#F0FFE8",
        },
    ];

    return (
        <MainLayout
            headerProps={{
                backButton: true,
                homeButton: true,
                searchButton: true,
                titleContent: <TitleContent />,
            }}>
            <CarrouselComponent data={categories} CardView={CardView} />
        </MainLayout>
    );
};
