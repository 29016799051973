import * as React from "react"
import Svg, { Path, Mask, G } from "react-native-svg"

function SvgComponent(props) {
    return (
        <Svg
            width={130}
            height={120}
            viewBox="0 0 130 120"
            fill="#073763"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.87 0c33.605 0 74.623 19.205 74.623 52.81 0 33.603-30.686 66.585-64.29 66.585C31.6 119.395.913 86.414.913 52.81.913 19.205 21.266 0 54.87 0z"
                fill="#fff"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M53.913 7c29.271 0 65 16.729 65 46s-26.729 58-56 58c-29.27 0-56-28.729-56-58s17.73-46 47-46z"
            />
            <Mask
                id="a"
                style={{
                    maskType: "luminance"
                }}
                maskUnits="userSpaceOnUse"
                x={28}
                y={26}
                width={70}
                height={66}
            >
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M28.756 26.064h68.313v65.874H28.756V26.064z"
                    fill="#fff"
                />
            </Mask>
            <G mask="url(#a)">
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M96.08 57.34c-6.884-12.603-18.563-22.284-32.09-30.838a1.557 1.557 0 00-2.166 0c-14.275 8.917-25.722 18.91-32.075 30.839a3.251 3.251 0 00-.14 4.535 3.127 3.127 0 002.302 1.012h5.776c-1.73 7.797-1.408 15.924 0 24.227 0 2.663 2.12 4.822 4.735 4.822h10.005c.872 0 1.579-.72 1.579-1.607-.603-3.792-.64-8.229 0-13.44 0-2.664 2.119-4.823 4.735-4.823h8.348c2.614 0 4.735 2.16 4.735 4.823.775 3.905.863 8.32 0 13.44 0 .887.706 1.607 1.578 1.607h9.99c2.614 0 4.735-2.159 4.735-4.822 1.209-8.514 1.26-16.609 0-24.227h5.792c1.74-.002 3.151-1.44 3.15-3.215a3.246 3.246 0 00-.988-2.332"
                    fill="#000"
                />
            </G>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M73.402 91.937c-.873 0-1.577-.719-1.577-1.608.862-5.12.775-9.535 0-13.44 0-2.663-2.12-4.823-4.736-4.822H58.74c-2.616 0-4.735 2.16-4.735 4.823-.64 5.211-.604 9.649 0 13.44 0 .888-.707 1.607-1.58 1.607H42.423c-2.616 0-4.736-2.158-4.736-4.823-1.408-8.301-1.73-16.43 0-24.227H31.91a3.124 3.124 0 01-2.3-1.011 3.25 3.25 0 01.138-4.535c6.353-11.93 17.8-21.922 32.076-30.84a1.56 1.56 0 011.025-.437h.113c.37.014.737.16 1.026.437 13.527 8.554 25.208 18.236 32.092 30.84.63.606.987 1.45.988 2.332.001 1.774-1.41 3.213-3.15 3.214h-5.792c1.259 7.618 1.21 15.713 0 24.227 0 2.665-2.12 4.823-4.735 4.823h-9.99z"
                fill="#fff"
            />
        </Svg>
    )
}

export default SvgComponent
