import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={31}
            height={31}
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.644 4.452l-3.51-3.51a3.165 3.165 0 00-4.47 0L17.887 4.72l-.005.005-.004.004-9.176 9.177a1.25 1.25 0 00-.356.72L7.4 21.782a1.252 1.252 0 001.405 1.405l7.156-.947c.273-.036.526-.16.72-.355L29.645 8.923a3.165 3.165 0 000-4.471M15.212 19.814l-5.117.677.677-5.117 7.996-7.995 4.44 4.44-7.996 7.995zM27.874 7.153l-2.896 2.896-4.44-4.44 2.896-2.896a.653.653 0 01.465-.193c.176 0 .341.068.466.193l3.509 3.509a.659.659 0 010 .93"
                fill="#7E1121"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.76 18.589c-.692 0-1.253.56-1.253 1.252v5.674a2.57 2.57 0 01-2.567 2.568H5.07a2.57 2.57 0 01-2.568-2.568V6.647A2.57 2.57 0 015.071 4.08h5.675a1.252 1.252 0 000-2.503H5.07A5.077 5.077 0 000 6.647v18.868a5.077 5.077 0 005.071 5.07H23.94a5.077 5.077 0 005.07-5.07V19.84c0-.69-.56-1.251-1.25-1.251z"
                fill="#7E1121"
            />
        </Svg>
    );
}

export default SvgComponent;
