const fieldsOptions = {
    id: [
        "storyId",
        "chapterId",
        "playlistId",
        "videoId",
        "nodeId",
        "studentAssignmentId",
        "testId",
    ],
    title: ["chapterName", "name", "nodeName", "categoryName", "testName"],
    image: ["thumbnail", "thumbnailURL", "thumbnailUrl"],
    url: ["URL", "videoURL"],
    type: ["assetType"],
    category: ["subjectName", "testCategoryName"],
    status: ["testStatus"],
};

export const parseListsUtils = (list = [], itemProps = {}) => {
    if (list?.length <= 0) {
        return [];
    }

    const parseItem = (item, index) => {
        const itemReduce = (acc, [itemKey, itemValue]) => {
            const find = Object.entries(fieldsOptions).find(([, value]) =>
                value.includes(itemKey)
            );
            acc[find ? find[0] : itemKey] = itemValue;
            return acc;
        };
        const newItem = Object.entries(item).reduce(itemReduce, {});
        const newProps =
            typeof itemProps === "function"
                ? itemProps(newItem, index)
                : itemProps;
        return { ...newItem, ...newProps };
    };
    return list?.map(parseItem);
};

export const fixNoExistData = (value) => {
    switch (true) {
        case value === "null":
            return null;

        default:
            return value;
    }
};

export const concatAllVideosChapters = (list) => {
    if (list?.length <= 0) {
        return [];
    }

    return [...new Set(list.flatMap(({ videos }) => videos))].filter(
        (value, index, self) =>
            self.findIndex((v) => v.videoId === value.videoId) === index
    );
};

export const getDeepCopy = (data) => {
    const json = JSON.stringify(data);
    return JSON.parse(json);
};
