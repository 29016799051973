import { Camera, CameraType, FlashMode } from "expo-camera";
import * as ImagePicker from "expo-image-picker";
import { useRef, useState } from "react";
import {
    Image,
    ImageBackground,
    Text,
    TouchableOpacity,
    View,
} from "react-native";
import { useNavigate } from "react-router-native";
import { BackArrowIcon, FlashIcon } from "../../../assets/svg";
import { cameraIcons, navigation } from "../../../constants";
import { useHaptic, usePermissions, useStylesUtils } from "../../../hooks";

export const CameraScreen = () => {
    const camera = useRef(null);
    const { hapticImpact } = useHaptic();
    const navigate = useNavigate();
    const { styles, width, imageSizes } = useStylesUtils(getStyles());
    const { isAccessToCamera } = usePermissions();

    const cameraType = CameraType.back;

    const [flashIsActive, setFlashIsActive] = useState(false);

    const takePicture = async () => {
        hapticImpact(1);

        if (camera) {
            const data = await camera.current.takePictureAsync(null);
            navigate(navigation.PREVIEW_SCREEN, { state: { image: data } });
        }
    };

    const toggleFlashMode = () => {
        if (flashIsActive) {
            setFlashIsActive(false);
        } else {
            setFlashIsActive(true);
        }
    };

    const pickImage = async () => {
        hapticImpact(1);

        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            quality: 1,
        });

        if (!result.canceled) {
            navigate(navigation.PREVIEW_SCREEN, {
                state: { image: result.assets[0] },
            });
        }
    };
    const navigateBack = () => {
        hapticImpact(1);
        navigate(-1);
    };

    if (isAccessToCamera === false) {
        return <Text>No access to camera</Text>;
    }

    return (
        <View style={styles.container}>
            <Camera
                ref={camera}
                style={styles.camera}
                type={cameraType}
                FlashMode={flashIsActive ? FlashMode.torch : FlashMode.off}>
                <View style={styles.controls}>
                    <ImageBackground
                        style={styles.controlsBg}
                        source={cameraIcons.CONTROLS_BG}>
                        <TouchableOpacity onPress={toggleFlashMode}>
                            <FlashIcon
                                width={width * 0.06}
                                height={width * 0.06}
                                fill={flashIsActive ? "#330A75" : "none"}
                            />
                        </TouchableOpacity>
                        <TouchableOpacity onPress={takePicture}>
                            <Image
                                source={cameraIcons.TRIGGER_ICON}
                                style={styles.triggerIcon}
                            />
                        </TouchableOpacity>
                        <TouchableOpacity onPress={pickImage}>
                            <Image
                                source={cameraIcons.GALERY_ICON}
                                style={styles.galeryIcon}
                            />
                        </TouchableOpacity>
                    </ImageBackground>
                </View>
            </Camera>
            <TouchableOpacity style={styles.backButton} onPress={navigateBack}>
                <BackArrowIcon {...imageSizes.standard} />
            </TouchableOpacity>
        </View>
    );
};

const getStyles =
    () =>
    ({ height, width }) => ({
        container: {
            flex: 1,
            flexDirection: "row",
        },
        camera: {
            flex: 1,
            alignItems: "flex-end",
        },
        controls: {
            height: height,
            width: width * 0.15,
        },
        controlsBg: {
            height: height,
            width: width * 0.15,
            resizeMode: "contain",
            flexDirection: "column",
            alignItems: "center",
            paddingVertical: height * 0.05,
            justifyContent: "space-between",
            justifyItems: "space-between",
        },
        triggerIcon: {
            width: width * 0.1,
            height: width * 0.1,
        },
        galeryIcon: {
            width: width * 0.045,
            height: width * 0.04,
        },
        backButton: {
            position: "absolute",
            zIndex: 100,
            top: 10,
            left: 0,
            marginTop: 10,
            marginLeft: 20,
        },
    });
