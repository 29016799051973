import { Text, View, TouchableOpacity } from "react-native";

import { useStylesUtils } from "../../../../hooks";
import { COLOURS } from "../../../../constants";
import {
    ChildsPasswordIcon,
    ParentsPasswordIcon,
} from "../../../../assets/svg";

export const UserSettings = () => {
    const { styles, imageSizes, width } = useStylesUtils(getStyles);

    return (
        <View style={styles.tab}>
            <TouchableOpacity>
                <View style={styles.btn}>
                    <ChildsPasswordIcon
                        width={width * 0.1}
                        height={width * 0.1}
                    />
                    <Text style={styles.lable}>
                        {"Change Students\nPasscode"}
                    </Text>
                </View>
            </TouchableOpacity>
            <TouchableOpacity>
                <View style={styles.btn}>
                    <ParentsPasswordIcon
                        width={width * 0.1}
                        height={width * 0.1}
                    />
                    <Text style={styles.lable}>
                        {"Change Parents\nPasscode"}
                    </Text>
                </View>
            </TouchableOpacity>
        </View>
    );
};

const getStyles = ({ height, width }) => ({
    tab: {
        width: width * 0.6,
        height: height * 0.7,
        borderRadius: 25,
        borderWidth: 3,
        borderColor: "#7E1121",
        backgroundColor: "#FFFFFF",
        padding: width * 0.05,
        alignItems: "center",
        justifyContent: "space-around",
        flexDirection: "row",
    },
    btn: {
        width: width * 0.23,
        height: width * 0.18,
        backgroundColor: COLOURS.lightPink,
        borderRadius: width * 0.02,
        justifyContent: "space-around",
        alignItems: "center",
        borderWidth: 3,
        borderColor: COLOURS.orange2,
    },
    lable: {
        textAlign: "center",
        color: COLOURS.blue4,
        fontWeight: 700,
    },
});
