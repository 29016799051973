import React, { useEffect, useCallback, useRef, useMemo } from "react";
import { View, Image, Text } from "react-native";
import { Button, PaginationCarousel } from "../../../components";
import { COLOURS } from "../../../constants";
import { useStylesUtils, useTestsHook } from "../../../hooks";
import { useSelector } from "react-redux";
import {
    getUserTestFilterData,
    getUserTests,
} from "../../../store/tests/selectors";
import { getCategoriesListRedux } from "../../../store/main/selectors";
import { useNavigate } from "react-router-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export const ContentContainer = () => {
    const navigate = useNavigate();
    const insets = useSafeAreaInsets();

    const { styles } = useStylesUtils(getStyles(insets));
    const { getTestsListFor, getStudentTestInstruction, studentResultPreview } =
        useTestsHook();
    const { status } = useSelector(getUserTestFilterData);

    let { testsList, filter, pageNo, totalPages, hasMoreRecords } =
        useSelector(getUserTests);
    const categoriesList = useSelector(getCategoriesListRedux);

    const carouselRef = useRef();

    useEffect(() => {
        carouselRef?.current?.scrollToIndex({ index: 0 });
        getTestsListFor({
            pageNo: 1,
        });
    }, [filter]);

    const fetchMore = useCallback(() => {
        if (!hasMoreRecords) {
            return;
        }

        getTestsListFor({
            loader: false,
            pageNo: pageNo + 1,
            addToExist: true,
        });
    }, [pageNo, totalPages, hasMoreRecords]);

    const onCardPressed = (item) => {
        // console.log("onCardPressed", item);
        if (status === "SCHEDULED") {
            getStudentTestInstruction(item).catch((e) => console.log(e));
        } else {
            studentResultPreview({
                studentTestId: item?.studentTestId,
            }).catch((e) => console.log("err", e));
        }
    };

    const parseTestsLists = useMemo(() => {
        const catList = categoriesList?.data?.childNodes;
        if (catList?.length <= 0 || testsList?.length <= 0) {
            return [];
        }

        return testsList.map((test) => {
            const find = catList?.find(
                (category) =>
                    category.nodeName === test.subjects[0]?.subjectName
            );

            return { ...test, image: find?.icon };
        });
    }, [categoriesList, testsList]);

    return (
        <View style={styles.container}>
            {parseTestsLists?.length > 0 ? (
                <PaginationCarousel
                    data={parseTestsLists}
                    type={"test"}
                    onFetch={fetchMore}
                    showLoader={hasMoreRecords}
                    ref={carouselRef}
                    onPressCard={onCardPressed}
                />
            ) : (
                <>
                    <Image
                        source={require("../../../assets/images/test/img_2.png")}
                        style={styles.image1}
                    />
                    <View style={styles.board}>
                        <Image
                            style={styles.boardImage}
                            source={require("../../../assets/images/test/board2.png")}
                            resizeMode={"contain"}
                        />
                        <View style={styles.textWrapper}>
                            <Text style={styles.boardContentText}>
                                No pending tests for you today!
                            </Text>
                        </View>
                        <Button
                            label={"Let’s Explore"}
                            iconProps={{ ratio: 0.13 }}
                            onPress={() => navigate(-1)}
                        />
                    </View>
                    <Image
                        source={require("../../../assets/images/test/owl.png")}
                        style={styles.bottomImage}
                        resizeMode={"contain"}
                    />
                </>
            )}
        </View>
    );
};

const getStyles =
    (insets) =>
    ({ width, height, biggerSize }) => ({
        container: {
            flex: 5,
            height: "100%",
            zIndex: 1,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
        },
        board: {
            width: biggerSize * 0.4,
            maxHeight: (biggerSize * 0.4) / 1.56,
            flex: 1,
            alignItems: "center",
            justifyContent: "space-between",
        },
        boardImage: {
            position: "absolute",
            width: biggerSize * 0.4,
            maxHeight: (biggerSize * 0.4) / 1.56,
            height: "100%",
        },
        boardContent: {
            flex: 1,
            alignItems: "center",
        },
        boardContentText: {
            maxWidth: "70%",
            fontSize: 34,
            fontWeight: 700,
            color: COLOURS.black,
            textAlign: "center",
            marginTop: height * 0.03,
        },
        textWrapper: {
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
        },
        bottomImage: {
            width: biggerSize * 0.25,
            height: biggerSize * 0.25,
            position: "absolute",
            bottom: -insets.bottom,
            left: -(insets.left + biggerSize * 0.015),
        },
        image1: {
            width: width * 2,
            height: width / 5,
            aspectRatio: 0.618,
            position: "absolute",
            bottom: -width * 0.04,
            // left: -width * 0.051,
        },
    });
