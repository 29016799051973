import React from "react";
import { Text } from "react-native";
import { MainLayout } from "../../components";
import { useStylesUtils } from "../../hooks";

export const ComingSoonScreen = () => {
    const { styles } = useStylesUtils(getStyles);

    return (
        <MainLayout
            headerProps={{ backButton: true, homeButton: true }}
            viewStyle={styles.viewStyle}>
            <Text style={styles.title}>This page is under construction.</Text>
            <Text style={styles.title}>Please come back later</Text>
        </MainLayout>
    );
};

const getStyles = {
    viewStyle: {
        flex: 1,
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "flex-end",
    },
    title: {
        fontWeight: 700,
        color: "#fff",
        fontSize: 30,
    },
};
