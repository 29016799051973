import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
    return (
        <Svg
            width={650}
            height={397}
            viewBox="0 0 650 397"
            fill="#3370A7"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M91.6777 1.94238C91.6777 1.94238 204.665 0.0710449 317.119 0.0710449C441.318 0.0710449 556.728 1.94238 556.728 1.94238C605.757 5.88404 640.842 38.7659 645.854 76.3291C645.854 76.3291 649.277 111.816 649.277 153.771C649.277 194.23 646.729 319.236 646.729 319.236C641.716 356.799 604.617 392.335 564.591 393.622C545.388 394.9 448.399 395.889 330.225 395.923C211.044 395.958 139.307 397.07 91.6777 393.622C44.0449 390.173 3.80175 343.531 2.54785 306.954C2.54785 306.954 0 187.609 0 142.763C0 105.97 2.58496 73.3746 2.58496 73.3746C5.09228 34.3333 43.2739 5.88404 91.6777 1.94238Z"
                stroke="none"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M556.728 1.943C605.133 2.68482 640.843 38.7662 645.855 76.3294C645.855 76.3294 649.278 111.815 649.278 153.771C649.278 194.229 646.73 319.234 646.73 319.234C641.716 356.797 605.893 392.554 564.592 393.62C553.455 392.554 556.728 1.94299 556.728 1.94299V1.943Z"
                stroke="none"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M92.5493 393.622C44.1445 392.88 8.43505 356.799 3.42236 319.235C3.42236 319.235 0 283.749 0 241.793C0 201.335 2.54785 76.3295 2.54785 76.3295C7.56152 38.7665 23.978 1.94299 84.6855 1.94299C91.1265 3.66894 92.5493 393.622 92.5493 393.622H92.5493Z"
                stroke="none"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48.7043 108.819C64.5418 108.819 77.3807 95.7766 77.3807 79.6876C77.3807 63.5987 64.5418 50.556 48.7043 50.556C32.8667 50.556 20.0278 63.5987 20.0278 79.6876C20.0278 95.7766 32.8667 108.819 48.7043 108.819Z"
                fill="rgba(255,255,255,0.3)"
                stroke="none"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M601.368 347.291C617.205 347.291 630.044 334.248 630.044 318.159C630.044 302.07 617.205 289.027 601.368 289.027C585.53 289.027 572.691 302.07 572.691 318.159C572.691 334.248 585.53 347.291 601.368 347.291Z"
                fill="rgba(255,255,255,0.3)"
                stroke="none"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M599.474 232.214C605.759 232.214 610.854 226.712 610.854 219.924C610.854 213.137 605.759 207.635 599.474 207.635C593.19 207.635 588.095 213.137 588.095 219.924C588.095 226.712 593.19 232.214 599.474 232.214Z"
                fill="rgba(255,255,255,0.3)"
                stroke="none"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M599.474 271.36C605.759 271.36 610.854 266.265 610.854 259.98C610.854 253.696 605.759 248.601 599.474 248.601C593.19 248.601 588.095 253.696 588.095 259.98C588.095 266.265 593.19 271.36 599.474 271.36Z"
                fill="rgba(255,255,255,0.3)"
                stroke="none"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M579.901 251.332C586.437 251.332 591.736 246.237 591.736 239.952C591.736 233.668 586.437 228.573 579.901 228.573C573.365 228.573 568.066 233.668 568.066 239.952C568.066 246.237 573.365 251.332 579.901 251.332Z"
                fill="rgba(255,255,255,0.3)"
                stroke="none"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M619.047 251.332C626.086 251.332 631.792 246.237 631.792 239.952C631.792 233.668 626.086 228.573 619.047 228.573C612.008 228.573 606.302 233.668 606.302 239.952C606.302 246.237 612.008 251.332 619.047 251.332Z"
                fill="rgba(255,255,255,0.3)"
                stroke="none"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M49.2871 139.238V174.406V139.238Z"
                stroke="rgba(255,255,255,0.3)"
                fill="none"
                strokeWidth={20}
                strokeLinecap="round"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M67.3711 156.321H32.2022H67.3711Z"
                stroke="rgba(255,255,255,0.3)"
                fill="none"
                strokeWidth={20}
                strokeLinecap="round"
            />
        </Svg>
    )
}

export default SvgComponent
