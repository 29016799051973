import React, { useState } from "react";
import { ImageBackground, TouchableOpacity, View } from "react-native";
import { useLocation, useNavigate } from "react-router-native";
import { ApproveBtn, BackArrowIcon, RetakeBtnIcon } from "../../../assets/svg";
import { COLOURS, images, navigation } from "../../../constants";
import { useStylesUtils } from "../../../hooks";
import ImageManipulator from "./components/ImageManipulator";
// import AutoHeightImage from "react-native-auto-height-image";
import { useImages } from "../../../hooks/useImagesHook";

export const PreviewScreen = () => {
    const { addImage } = useImages();
    const { state } = useLocation();
    const { uri: imageUri } = state.image;
    const { styles, width, height, imageSizes } = useStylesUtils(getStyles());
    const navigate = useNavigate();
    const [image, setImage] = useState(imageUri);
    const [isEditing, setIsEditing] = useState();

    const onImageEdited = (data) => {
        setIsEditing(false);
        const { uri } = data;
        setImage(uri);
    };

    const navigateToGallery = () => {
        addImage(image);
        navigate(navigation.GALLERY_SCREEN);
    };
    const navigateBack = () => {
        navigate(-1);
    };

    return (
        <>
            {isEditing ? (
                <ImageManipulator
                    photo={{ uri: image }}
                    isVisible={true}
                    onPictureChoosed={(data) => {
                        onImageEdited(data);
                    }}
                    saveOptions={{
                        compress: 1,
                        format: "png",
                        base64: false,
                    }}
                    btnTexts={{
                        done: "Done",
                        crop: "Crop",
                        processing: "Processing",
                    }}
                />
            ) : (
                <ImageBackground
                    resizeMode="cover"
                    style={styles.container}
                    source={images.mainBGPurple}>
                    {/*<AutoHeightImage*/}
                    {/*    width={width * 0.65}*/}
                    {/*    maxHeight={height * 0.75}*/}
                    {/*    source={{ uri: image }}*/}
                    {/*    style={styles.previewImage}*/}
                    {/*/>*/}
                    <View style={styles.controls}>
                        <TouchableOpacity onPress={navigateBack}>
                            <RetakeBtnIcon {...imageSizes.standard} />
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setIsEditing(true)}>
                            <ApproveBtn {...imageSizes.standard} />
                        </TouchableOpacity>
                        <TouchableOpacity onPress={navigateToGallery}>
                            <ApproveBtn {...imageSizes.standard} />
                        </TouchableOpacity>
                    </View>
                    <TouchableOpacity
                        style={styles.backButton}
                        onPress={navigateBack}>
                        <BackArrowIcon {...imageSizes.standard} />
                    </TouchableOpacity>
                </ImageBackground>
            )}
        </>
    );
};

const getStyles =
    () =>
    ({ width, height }) => ({
        container: {
            flex: 1,
            alignItems: "center",
            justifyContent: "space-around",
            backgroundColor: COLOURS.purple3,
            flexDirection: "column",
        },
        previewImage: {
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "black",
            borderRadius: 5,
            borderWidth: 1,
            borderColor: "#ffffff",
        },
        backButton: {
            position: "absolute",
            zIndex: 100,
            top: 10,
            left: 0,
            marginTop: 10,
            marginLeft: 20,
        },
        controls: {
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            width: width * 0.15,
        },
    });
