import React, { useEffect, useRef, useState } from "react";
import { ImageBackground, TextInput, View } from "react-native";
import { images } from "../../../constants";
import { useHaptic, useStylesUtils } from "../../../hooks";

export const PinInput = ({
    pinState: { pin, setPin, isError },
    label,
    inputLength = 4,
    style,
    inputStyle,
}) => {
    const { styles, isWeb } = useStylesUtils(getStyles);
    const { hapticSuccess, hapticImpact } = useHaptic();

    const inputsRef = useRef([]);

    const [nextIdx, setNextIdx] = useState(1);

    const onChange = (e) => {
        const event = e.nativeEvent;
        const value = event.key;
        const isBackspace = value === "Backspace";
        if (pin.length > 0 && isBackspace) {
            setPin((prev) => prev.toString().slice(0, -1));
            hapticImpact(1);
            changeFocus("prev");
            return;
        }
        if (!isBackspace && pin.length < 4) {
            setPin((prev) => prev.toString() + value.toString());
            changeFocus("next");
        }
    };

    const changeFocus = (type) => {
        switch (type) {
            case "next":
                if (nextIdx <= 3) {
                    inputsRef.current[nextIdx].focus();
                    setNextIdx((prev) => prev + 1);
                }
                return true;
            case "prev":
                if (nextIdx > 1) {
                    inputsRef.current[nextIdx - 2].focus();
                    setNextIdx((prev) => prev - 1);
                }
                return true;
        }
    };

    const focusOn = (inputIndex) => {
        if (pin.length > 0) {
            setPin((prev) => prev.toString().slice(0, inputIndex));
            setNextIdx(inputIndex < 3 ? inputIndex + 1 : 4);
            return;
        }
        inputsRef.current[0].focus();
    };

    useEffect(() => {
        if (pin.length > 0) {
            hapticImpact(1);
        }
    }, [pin]);

    const createInputs = (inputLength) => {
        let pinInputs = [];
        for (let i = 0; i < inputLength; i++) {
            pinInputs.push(
                <ImageBackground
                    key={`imgbg-${i}`}
                    source={isError ? images.pinBgError : images.pinBg}
                    imageStyle={styles.inputBg}
                    style={[styles.inputBg, inputStyle]}
                    resizeMode="cover">
                    <TextInput
                        disableFullscreenUI={true}
                        key={`pininput-${i}`}
                        value={pin[i] || ""}
                        style={styles.input}
                        inputMode={"decimal"}
                        keyboardType={"decimal-pad"}
                        maxLength={1}
                        ref={(el) => (inputsRef.current[i] = el)}
                        onPressOut={() => focusOn(i)}
                        onClick={() => (isWeb ? focusOn(i) : "")}
                        secureTextEntry={true}
                        onKeyPress={(e) => onChange(e)}
                    />
                </ImageBackground>
            );
        }
        return pinInputs;
    };

    return (
        <View style={[styles.inputsContainer, style]}>
            {createInputs(inputLength)}
        </View>
    );
};

const getStyles = ({ width }) => ({
    inputsContainer: {
        marginTop: 5,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    input: {
        height: "100%",
        width: "100%",
        textAlign: "center",
    },
    inputBg: {
        width: width * 0.045,
        height: width * 0.05,
        marginRight: width * 0.005,
    },
});
