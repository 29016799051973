export { default as BooksIcon } from "./books";
export { default as MusicIcon } from "./music";
export { default as DanceIcon } from "./dance";
export { default as AbacusIcon } from "./abacus";
export { default as StoriesIcon } from "./stories";
export { default as EnglishIcon } from "./english";
export { default as VideosIcon } from "./videos";
export { default as Videos2Icon } from "./videos2";
export { default as ActivitiesIcon } from "./activities";
export { default as ChapterIcon } from "./chapter";
