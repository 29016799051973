import React from "react";
import {
    QUIZ_QUESTION_STATUS,
    QUIZ_QUESTION_TYPE,
} from "../../../../constants";
import OptionChoiceFactory from "./OptionChoiceFactory";
import FillTheBlanksFactory from "./FillTheBlanksFactory";
import MatchTheOptionsFactory from "./MatchTheOptionsFactory";

const unifiedEmptyDataTemplate = {
    questionText: "",
    questionTextHtml: {
        html: "",
    },
    listOfOptions: [],
    //additional fields for fill the blank
    //additional fields for match the options
};

const unifiedEmptyCalculationTemplate = {
    status: QUIZ_QUESTION_STATUS.NEW,
    mark: 0,
};

export class QuizFactory {
    static prepareQuestionData(question, answers, setAnswers, isFinished) {
        if (!question) {
            return unifiedEmptyDataTemplate;
        }
        switch (question.questionTypeRemapped) {
            case QUIZ_QUESTION_TYPE.SINGLE_CORRECT:
            case QUIZ_QUESTION_TYPE.TRUE_FALSE:
                return OptionChoiceFactory.prepareQuestionData(
                    question,
                    answers,
                    setAnswers,
                    isFinished
                );
            case QUIZ_QUESTION_TYPE.MULTI_CORRECT:
                return OptionChoiceFactory.prepareQuestionData(
                    question,
                    answers,
                    setAnswers,
                    isFinished,
                    true
                );
            case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_REGULAR:
            case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_NUMERIC:
            case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_INTEGER:
                return FillTheBlanksFactory.prepareQuestionData(
                    question,
                    answers,
                    setAnswers,
                    isFinished
                );
            case QUIZ_QUESTION_TYPE.MATCH_THE_OPTIONS:
                return MatchTheOptionsFactory.prepareQuestionData(
                    question,
                    answers,
                    setAnswers,
                    isFinished
                );
            default:
                return unifiedEmptyDataTemplate;
        }
    }

    static calcQuestion(question, answers, isFinished) {
        if (!question) {
            return unifiedEmptyCalculationTemplate;
        }

        switch (question.questionTypeRemapped) {
            case QUIZ_QUESTION_TYPE.SINGLE_CORRECT:
            case QUIZ_QUESTION_TYPE.TRUE_FALSE:
                return OptionChoiceFactory.calcQuestion(
                    question,
                    answers,
                    isFinished
                );
            case QUIZ_QUESTION_TYPE.MULTI_CORRECT:
                return OptionChoiceFactory.calcQuestion(
                    question,
                    answers,
                    isFinished,
                    true
                );
            case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_REGULAR:
            case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_NUMERIC:
            case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_INTEGER:
                return FillTheBlanksFactory.calcQuestion(
                    question,
                    answers,
                    isFinished
                );
            case QUIZ_QUESTION_TYPE.MATCH_THE_OPTIONS:
                return MatchTheOptionsFactory.calcQuestion(
                    question,
                    answers,
                    isFinished
                );
            default:
                return unifiedEmptyCalculationTemplate;
        }
    }
    static getQuestionStatus(question, answers, isFinished) {
        if (!question) {
            return QUIZ_QUESTION_STATUS.NEW;
        }

        switch (question.questionTypeRemapped) {
            case QUIZ_QUESTION_TYPE.SINGLE_CORRECT:
            case QUIZ_QUESTION_TYPE.TRUE_FALSE:
                return OptionChoiceFactory.getQuestionStatus(
                    question,
                    answers,
                    isFinished
                );
            case QUIZ_QUESTION_TYPE.MULTI_CORRECT:
                return OptionChoiceFactory.getQuestionStatus(
                    question,
                    answers,
                    isFinished,
                    true
                );
            case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_REGULAR:
            case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_NUMERIC:
            case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_INTEGER:
                return FillTheBlanksFactory.getQuestionStatus(
                    question,
                    answers,
                    isFinished
                );
            case QUIZ_QUESTION_TYPE.MATCH_THE_OPTIONS:
                return MatchTheOptionsFactory.getQuestionStatus(
                    question,
                    answers,
                    isFinished
                );
            default:
                return QUIZ_QUESTION_STATUS.NEW;
        }
    }
    static getQuestionMark(question, answers, isFinished) {
        if (!question) {
            return 0;
        }

        switch (question.questionTypeRemapped) {
            case QUIZ_QUESTION_TYPE.SINGLE_CORRECT:
            case QUIZ_QUESTION_TYPE.TRUE_FALSE:
                return OptionChoiceFactory.getQuestionMark(
                    question,
                    answers,
                    isFinished
                );
            case QUIZ_QUESTION_TYPE.MULTI_CORRECT:
                return OptionChoiceFactory.getQuestionMark(
                    question,
                    answers,
                    isFinished
                );
            case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_REGULAR:
            case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_NUMERIC:
            case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_INTEGER:
                return FillTheBlanksFactory.getQuestionMark(
                    question,
                    answers,
                    isFinished
                );
            case QUIZ_QUESTION_TYPE.MATCH_THE_OPTIONS:
                return MatchTheOptionsFactory.getQuestionMark(
                    question,
                    answers,
                    isFinished
                );
            default:
                return 0;
        }
    }
    static checkAnswersState(question, answers) {
        if (!question) {
            return question;
        }

        switch (question.questionTypeRemapped) {
            case QUIZ_QUESTION_TYPE.SINGLE_CORRECT:
            case QUIZ_QUESTION_TYPE.TRUE_FALSE:
            case QUIZ_QUESTION_TYPE.MULTI_CORRECT:
                return OptionChoiceFactory.checkAnswersState(question, answers);
            case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_REGULAR:
            case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_NUMERIC:
            case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_INTEGER:
                return FillTheBlanksFactory.checkAnswersState(
                    question,
                    answers
                );
            case QUIZ_QUESTION_TYPE.MATCH_THE_OPTIONS:
                return MatchTheOptionsFactory.checkAnswersState(
                    question,
                    answers
                );
            default:
                return question;
        }
    }
}
