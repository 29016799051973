import { useState } from "react";
import { View } from "react-native";
import { MainLayout } from "../../../components";
import { useStylesUtils } from "../../../hooks";
import { Content } from "./Content";
import { TabsContainer } from "./TabsContainer";

export const AcademicScreen = () => {
    const { styles } = useStylesUtils(getStyles);

    const [activeTab, setActiveTab] = useState(1);

    return (
        <MainLayout
            isSaveArea={false}
            headerProps={{
                backButton: true,
                notificationButton: true,
                searchButton: true,
            }}>
            <View style={styles.container} pointerEvents="box-none">
                <Content activeTab={activeTab} />
                <TabsContainer active={activeTab} setActive={setActiveTab} />
            </View>
        </MainLayout>
    );
};

const getStyles = {
    container: {
        flex: 1,
        marginTop: -60,
        flexDirection: "column-reverse",
    },
};
