import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { mainApi } from "../../api/main.api";
import { startLoading, stopLoading } from "../app/slice";

export const getUserStoriesAndDetailsForChapterThunk = createAsyncThunk(
    "main/userStoriesAndDetailsForChapter",
    async (params, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const res = await mainApi.getUserStoriesAndDetailsForChapter(
                params
            );
            if (!res || res.status !== 200) {
                throw res;
            }
            return res?.data;
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const getBooksAndAssetCountThunk = createAsyncThunk(
    "main/getBooksAndAssetCountThunk",
    async (params, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const res = await mainApi.getBooksAndAssetCount(params);
            if (!res || res.status !== 200) {
                throw res;
            }
            return { data: res?.data.data, params };
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const getBooksForSubtenantThunk = createAsyncThunk(
    "main/getBooksForSubtenantThunk",
    async (params, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const res = await mainApi.getBooksForSubtenantIds(params);
            if (!res || res.status !== 200) {
                throw res;
            }
            return res?.data;
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const getSubjectsForExamThunk = createAsyncThunk(
    "main/getSubjectsForExamThunk",
    async (params, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const res = await mainApi.getSubjectsForExam(params);
            if (!res || res.status !== 200) {
                throw res;
            }
            return { data: res?.data.data, params };
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const getChaptersForExamSubjectSubtenantThunk = createAsyncThunk(
    "main/getChaptersForExamSubjectSubtenantThunk",
    async (params, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const res = await mainApi.getChaptersForExamSubjectSubtenant(
                params
            );
            if (!res || res.status !== 200) {
                throw res;
            }
            return { data: res?.data.data, params };
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const getPlaylistsForThunk = createAsyncThunk(
    "main/getPlaylistsForThunk",
    async (params, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const res = await mainApi.getPlaylistsFor({ params });

            if (!res || res.status !== 200) {
                throw res;
            }
            return { data: res?.data.data, params };
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const getPlaylistsForUserThunk = createAsyncThunk(
    "main/getPlaylistsForUserThunk",
    async ({ params }, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());

            const res = await mainApi.getPlaylistsFor({ params });
            if (!res || res.status !== 200) {
                throw res;
            }

            return res?.data?.data?.playlists;
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const getVideoPlaylistThunk = createAsyncThunk(
    "main/getVideoPlaylistThunk",
    async (params, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const res = await mainApi.getVideoPlaylist(params);
            if (!res || res.status !== 200) {
                throw res;
            }
            return res?.data.data;
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const getChaptersThunk = createAsyncThunk(
    "main/getChaptersThunk",
    async (params, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());

            const data = {
                video: null,
                stories: null,
                books: null,
            };

            const resVideos =
                await mainApi.getTopicsAndVideosForExamChapterSubtenant(params);

            if (resVideos.data?.data?.child?.length > 0) {
                data.video = resVideos.data.data.child;
            }

            // const resBooks = await mainApi.getBooksAndAssetCount(params);
            //
            // console.log('resBooks', resBooks.data)
            //
            // if (resBooks.data?.data?.books?.length > 0) {
            //     data.books = resBooks.data.data.books
            // }
            //

            const interactiveStoriesParams = {
                examIds: params.examId,
                examNodeIds: params.chapterId,
                subtenantId: params.subtenantId,
                status: "Published",
                storyType: "Interactive",
            };

            const articulateStoriesParams = {
                examIds: params.examId,
                examNodeIds: params.chapterId,
                subtenantId: params.subtenantId,
                status: "Published",
                storyType: "Articulate",
            };

            const resInteractiveStoriesParamsStories =
                await mainApi.getStoriesForExamNodeIds(
                    interactiveStoriesParams
                );

            const resArticulateStoriesParamsStories =
                await mainApi.getStoriesForExamNodeIds(articulateStoriesParams);

            if (
                resInteractiveStoriesParamsStories?.data?.data?.length > 0 ||
                resArticulateStoriesParamsStories?.data?.data?.length > 0
            ) {
                data.stories = [
                    ...(resInteractiveStoriesParamsStories?.data?.data || []),
                    ...(resArticulateStoriesParamsStories?.data?.data || []),
                ];
            }

            return { data, params };
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const getStoryThunk = createAsyncThunk(
    "main/getStoryThunk",
    async (params, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const result = await mainApi.getStory(params);
            return result?.data?.data;
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const getDocumentByDocumentIdThunk = createAsyncThunk(
    "main/getDocumentByDocumentIdThunk",
    async (params, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const res = await mainApi.getDocumentByDocumentId(params);
            if (!res || res.status !== 200) {
                throw res;
            }
            return res?.data?.data;
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);
