import { useMemo } from "react";
import { Dimensions, Platform, StyleSheet } from "react-native";
import { fontWeight, COLOURS } from "../constants";

const { OS, isPad } = Platform;

const isWeb = OS === "web";
const isIOS = OS === "ios";
const isAndroid = OS === "android";
const isMobile = OS !== "web";

export const useStylesUtils = (getStyles, additionalStyles) => {
    const { width, height } = useMemo(
        () => Dimensions.get(isMobile ? "screen" : "window"),
        [Dimensions]
    );

    const sizes = useMemo(() => {
        return {
            content: width > height ? width * 0.05 : height * 0.03,
            quizCard: width > height ? width * 0.03 : height * 0.06,
        };
    }, [width, height]);

    const props = useMemo(
        () => ({
            sizes,
            baseStyles,
            width,
            height,
            OS,
            isWeb,
            isIOS,
            isAndroid,
            isMobile,
            isPad,
            biggerSize: Math.max(height, width),
            smallerSize: Math.min(height, width),
        }),
        [width, height]
    );

    const fixStyles = (object = {}) => {
        const objectReduce = (acc, [key, value]) => {
            switch (true) {
                case value === null || value === undefined:
                    value = {};
                    break;
                case typeof value === "object" && !Array.isArray(value):
                    value = fixStyles(value);
                    break;
                case key === "zIndex" && isAndroid:
                    key = "remove";
                    break;
                case key === "fontSize":
                    value = (value / 1000) * props.biggerSize;
                    break;
                case key === "fontWeight":
                    const newWeight = fontWeight[`${value}`];
                    key = "fontFamily";
                    value = newWeight ? newWeight : fontWeight.default;
                    break;
                case key === "lineHeight":
                    value = (value / 300) * props.biggerSize;
                    break;
                case key === "borderWidth" || key === "borderRadius":
                    value = (value / 1000) * props.biggerSize;
                    break;
                case key === "verticalAlign" && isWeb:
                    key = "textAlignVertical";
                    break;
            }
            if (!key !== "remove") {
                acc[key] = value;
            }

            return acc;
        };

        if (typeof object !== "object" || Array.isArray(object)) {
            return object;
        }

        return Object.entries(object).reduce(objectReduce, {});
    };

    const styles = useMemo(() => {
        if (!getStyles) {
            return fixStyles(baseStyles);
        }

        let takeStyles =
            typeof getStyles === "function" ? getStyles(props) : getStyles;

        const fixedStyles = fixStyles({
            ...baseStyles,
            ...takeStyles,
            ...additionalStyles,
        });

        return StyleSheet.create(fixedStyles);
    }, [width, height, getStyles, additionalStyles, props]);

    const imageSizes = useMemo(() => {
        return {
            standard: {
                width: props.biggerSize / 14.75,
                height: props.biggerSize / 14.75,
            },
            type1: {
                width: props.biggerSize / 20,
                height: props.biggerSize / 20,
            },
        };
    }, [width, props]);

    return {
        styles,
        imageSizes,
        ...props,
    };
};

const baseStyles = {
    boldText: {
        fontWeight: 700,
    },
    contentCenter: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    textShadow: {
        textShadowOffset: { height: 2, width: 2 },
        textShadowRadius: 3,
        textShadowColor: COLOURS.black,
    },
};
