import React, { useMemo } from "react";
import { Text } from "react-native";
import { RoundedButton } from "../../../components";
import { useStylesUtils } from "../../../hooks";
import { COLOURS } from "../../../constants";

export const CardView = ({ title, onPress, Icon }) => {
    const { width, height, styles } = useStylesUtils(getStyles);

    const imageStyles = useMemo(() => {
        const fixWidth = width / 4.5;
        const fixHeight = (height / 2) * 1.0684;

        const lastHeight = fixWidth < fixHeight ? fixWidth : fixHeight;

        return {
            width: fixWidth,
            height: lastHeight,
        };
    }, [width, height]);

    return (
        <RoundedButton
            onPress={onPress}
            style={{ ...imageStyles }}
            imageStyle={styles.imageStyle}
            viewStyle={[styles.viewStyle, { padding: imageStyles.width * 0.1 }]}
            pressImageStyle={styles.pressImageStyle}>
            {Icon && (
                <Icon
                    width={imageStyles.width * 0.7}
                    height={imageStyles.height * 0.6}
                />
            )}
            <Text style={styles.text}>{title}</Text>
        </RoundedButton>
    );
};

const getStyles = {
    imageStyle: {
        stroke: COLOURS.blue1,
        fill: COLOURS.lightBlue4,
    },
    viewStyle: {
        alignItems: "center",
        flex: 1,
        justifyContent: "flex-end",
    },
    pressImageStyle: {
        stroke: COLOURS.yellow1,
    },
    text: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        fontSize: 20,
        textAlign: "center",
    },
};
