import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={85}
            height={78}
            viewBox="0 0 85 78"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M76.184 16.572c11.998 17.433 10.57 41.852-6.863 53.85-17.433 11.997-50.827 8.458-62.825-8.975C-5.502 44.013 2.324 20.05 19.758 8.053 37.19-3.945 64.186-.862 76.184 16.572z"
                fill="#FEC195"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M60.55 17.688H56.68v2.782a3.71 3.71 0 01-3.702 3.702h-17.57a3.71 3.71 0 01-3.703-3.702v-2.782h-3.672a2.732 2.732 0 00-2.73 2.729v16.57c8.54.052 15.475 7.01 15.475 15.568 0 2.138-.445 4.24-1.3 6.197l2.946 2.53H60.55a2.726 2.726 0 002.73-2.735v-38.13a2.736 2.736 0 00-2.73-2.73m-3.607 34.645c-.27.27-.65.44-1.067.44h-9.699c-.837 0-1.51-.674-1.51-1.512 0-.421.169-.796.438-1.072.276-.269.65-.439 1.072-.439h9.7a1.511 1.511 0 011.066 2.583m0-9.465c-.27.27-.65.44-1.067.44H42.634a1.507 1.507 0 01-1.511-1.512c0-.422.17-.796.439-1.072.275-.269.656-.439 1.072-.439h13.242a1.511 1.511 0 011.067 2.583zm0-9.465c-.27.27-.65.44-1.067.44h-23.17a1.508 1.508 0 01-1.511-1.512 1.51 1.51 0 011.51-1.511h23.171c.832 0 1.512.68 1.512 1.511 0 .422-.17.797-.445 1.072"
                fill="#B25A1A"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52.977 14.244h-2.91c-.147-3.128-2.707-5.623-5.876-5.623-3.163 0-5.728 2.495-5.874 5.623h-2.905a1.24 1.24 0 00-1.236 1.236v4.99c0 .685.556 1.236 1.236 1.236h17.565c.68 0 1.236-.55 1.236-1.236v-4.99a1.24 1.24 0 00-1.236-1.236zm-8.785 2.524a2.25 2.25 0 11.001-4.5 2.25 2.25 0 01-.002 4.5zM35.76 59.443a12.532 12.532 0 002.069-6.89c0-6.96-5.662-12.621-12.621-12.621-6.96 0-12.622 5.661-12.622 12.621 0 6.96 5.663 12.621 12.622 12.621 2.545 0 4.905-.77 6.89-2.07l6.696 7.795a3.376 3.376 0 104.76-4.76l-7.794-6.696zm-20.131-6.89c0-5.282 4.298-9.579 9.579-9.579 5.282 0 9.578 4.297 9.578 9.58 0 5.281-4.297 9.578-9.578 9.578s-9.58-4.297-9.58-9.579z"
                fill="#B25A1A"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.626 51.117l-5.81 5.809a1.69 1.69 0 01-2.395 0l-2.887-2.886a1.695 1.695 0 012.396-2.397l1.689 1.69 4.61-4.612a1.694 1.694 0 112.397 2.396"
                fill="#B25A1A"
            />
        </Svg>
    );
}

export default SvgComponent;
