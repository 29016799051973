import { PlaylistTitleContent } from "../components/PlaylistTitleContent";
import { navigation } from "../constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-native";
import { usePopups } from "./usePopups";
import { getVideoPlaylistThunk } from "../store/main/thunks";
import React, { useCallback } from "react";
import { useAuth } from "./useAuth";
import { capitalizeStr } from "../utils/text.utils";

export const useRead = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { oopsPopUp } = usePopups();
    const { checkRejectResponse } = useAuth();

    const getVideosFor = useCallback(
        async (playlistId, playlistTitle, playlistImage) => {
            const result = await dispatch(getVideoPlaylistThunk(playlistId));

            if (getVideoPlaylistThunk.fulfilled.match(result)) {
                const state = {
                    titleContent: (
                        <PlaylistTitleContent
                            title={capitalizeStr(playlistTitle)}
                            image={playlistImage}
                        />
                    ),
                };

                navigate(navigation.VIDEOS_SCREEN, { state });

                return Promise.resolve(result);
            }
            if (getVideoPlaylistThunk.rejected.match(result)) {
                await checkRejectResponse(result);
                oopsPopUp();
            }
        },
        []
    );

    return {
        getVideosFor,
    };
};
