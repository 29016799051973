import * as React from "react";
import Svg, { G, Path, Defs } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent(props) {
    return (
        <Svg
            width={107}
            height={101}
            viewBox="0 0 107 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <G filter="url(#filter0_d_0_133228)">
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M88.21 22.499c12.218 17.754 10.764 42.62-6.99 54.84-17.753 12.217-51.76 8.613-63.98-9.14-12.217-17.754-4.248-42.156 13.506-54.375C48.5 1.606 75.992 4.745 88.21 22.5"
                    fill="#5F0FAA"
                />
                <Path
                    d="M88.21 22.499c12.218 17.754 10.764 42.62-6.99 54.84-17.753 12.217-51.76 8.613-63.98-9.14-12.217-17.754-4.248-42.156 13.506-54.375C48.5 1.606 75.992 4.745 88.21 22.5"
                    stroke="#EAD5FD"
                    strokeWidth={5}
                />
            </G>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.782 49.184c9.745 5.683 19.599 12.186 28.63 16.83 2.7 1.39 6.36-1.224 6.575-4.416.649-10.722.649-21.474 0-32.197-.216-3.19-3.875-5.805-6.575-4.416-9.031 4.644-18.885 11.148-28.63 16.83-2.932 1.72-2.932 5.65 0 7.37"
                fill="#fff"
            />
            <Defs></Defs>
        </Svg>
    );
}

export default SvgComponent;
