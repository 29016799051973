import { Image } from "../../Image";

export const CardsImage = ({ source, resizeMode = "contain", ...rest }) => {
    if (typeof source === "string") {
        const getFileName = source
            ?.split("/")
            .pop()
            .split("#")[0]
            .split("?")[0];
        const fileId = getFileName ? getFileName.split(".")[0] : null;

        return (
            <Image
                cacheKey={fileId}
                source={{ uri: source }}
                resizeMode={resizeMode}
                {...rest}
            />
        );
    } else if (source) {
        return <Image source={source} resizeMode={resizeMode} {...rest} />;
    }

    return <></>;
};
