import * as React from "react";
import Svg, { G, Path, Defs } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const PrevNavButtonIcon = (props) => {
    return (
        <Svg
            width={107}
            height={100}
            viewBox="0 0 107 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <G filter="url(#filter0_d_3037_88323)">
                <Path
                    d="M87.686 21.682c12.218 17.754 10.765 42.62-6.99 54.84-17.753 12.219-51.76 8.614-63.98-9.14-12.218-17.754-4.248-42.156 13.506-54.374C47.976.79 75.467 3.928 87.686 21.683"
                    fill="#330A75"
                />
                <Path
                    d="M87.686 21.682c12.218 17.754 10.765 42.62-6.99 54.84-17.753 12.219-51.76 8.614-63.98-9.14-12.218-17.754-4.248-42.156 13.506-54.374C47.976.79 75.467 3.928 87.686 21.683"
                    stroke="#fff"
                    strokeWidth={5}
                />
            </G>
            <Path
                d="M33.259 48.368c9.745 5.682 19.6 12.186 28.63 16.83 2.698 1.389 6.358-1.224 6.57-4.411.65-10.724.65-21.477 0-32.2-.215-3.191-3.875-5.805-6.574-4.416-9.026 4.642-18.882 11.146-28.626 16.828a4.185 4.185 0 000 7.369z"
                fill="#fff"
            />
            <Defs></Defs>
        </Svg>
    );
};

export const NextNavButtonIcon = (props) => {
    return (
        <Svg
            width={107}
            height={100}
            viewBox="0 0 107 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <G filter="url(#filter0_d_3037_88327)">
                <Path
                    d="M19.088 21.682C6.87 39.436 8.323 64.302 26.077 76.522c17.754 12.219 51.76 8.614 63.98-9.14 12.219-17.754 4.249-42.156-13.505-54.374C58.798.79 31.307 3.928 19.088 21.683"
                    fill="#330A75"
                />
                <Path
                    d="M19.088 21.682C6.87 39.436 8.323 64.302 26.077 76.522c17.754 12.219 51.76 8.614 63.98-9.14 12.219-17.754 4.249-42.156-13.505-54.374C58.798.79 31.307 3.928 19.088 21.683"
                    stroke="#fff"
                    strokeWidth={5}
                />
            </G>
            <Path
                d="M73.515 48.368c-9.745 5.682-19.6 12.186-28.63 16.83-2.698 1.389-6.358-1.224-6.57-4.411a266.346 266.346 0 010-32.2c.215-3.191 3.875-5.805 6.574-4.416 9.026 4.642 18.882 11.146 28.626 16.828a4.186 4.186 0 010 7.369z"
                fill="#fff"
            />
            <Defs></Defs>
        </Svg>
    );
};
