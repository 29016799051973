import { createSlice } from "@reduxjs/toolkit";
import { parseListsUtils } from "../../utils/parse-lists.utils";
import {
    getBooksAndAssetCountThunk,
    getChaptersForExamSubjectSubtenantThunk,
    getChaptersThunk,
    getDocumentByDocumentIdThunk,
    getPlaylistsForThunk,
    getPlaylistsForUserThunk,
    getSubjectsForExamThunk,
    getUserStoriesAndDetailsForChapterThunk,
    getVideoPlaylistThunk,
} from "./thunks";

const initialState = {
    userStories: null,
    categoriesList: { data: null, params: null },
    booksList: { data: null, params: null },
    chaptersList: { data: null, params: null },
    subjectsList: { data: null, params: null },
    videoPlaylist: { data: null, params: null },
    booksAndAssetCount: null,
    subjectsForExam: null,
    playlistsFor: null,
    playlistsForUser: null,
    userImages: [],
    userDocuments: [],
};

const slice = createSlice({
    name: "main",
    initialState,
    reducers: {
        setVideoPlayList: (state, { payload }) => {
            state.videoPlaylist.data = {
                ...payload,
                videos: parseListsUtils(payload?.videos),
            };
        },
        setUserImages: (state, { payload }) => {
            state.userImages = [...payload];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                getUserStoriesAndDetailsForChapterThunk.fulfilled,
                (state, { payload }) => {
                    state.userStories = payload;
                }
            )
            .addCase(
                getBooksAndAssetCountThunk.fulfilled,
                (state, { payload }) => {
                    state.booksList = payload;
                }
            )
            .addCase(
                getSubjectsForExamThunk.fulfilled,
                (state, { payload }) => {
                    state.categoriesList = payload;
                }
            )
            .addCase(getPlaylistsForThunk.fulfilled, (state, { payload }) => {
                state.playlistsFor = payload;
            })
            .addCase(
                getPlaylistsForUserThunk.fulfilled,
                (state, { payload }) => {
                    state.playlistsForUser = payload.filter(
                        (playlist) => playlist.status === "Active"
                    );
                }
            )
            .addCase(
                getChaptersForExamSubjectSubtenantThunk.fulfilled,
                (state, { payload }) => {
                    state.subjectsList = payload;
                }
            )
            .addCase(getVideoPlaylistThunk.fulfilled, (state, { payload }) => {
                const data = {
                    data: {
                        ...payload,
                        videos: parseListsUtils(payload?.videos),
                    },
                };

                state.videoPlaylist = data;
            })
            .addCase(getChaptersThunk.fulfilled, (state, { payload }) => {
                state.chaptersList = payload;
            })
            .addCase(
                getDocumentByDocumentIdThunk.fulfilled,
                (state, { payload }) => {
                    state.userDocuments = payload;
                }
            );
    },
});

export const { setVideoPlayList, setUserImages, removeUserImage } =
    slice.actions;
export default slice.reducer;
