import * as React from "react"
import Svg, { Path, Mask, G } from "react-native-svg"

function SvgComponent(props) {
    return (
        <Svg
            width={389}
            height={524}
            viewBox="0 0 389 524"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M373.46 454.783C373.46 454.783 357.341 479.028 375.733 504.526C375.733 504.526 215.033 532.559 41.9145 512.481C41.9145 512.481 12.2207 505.128 13.6606 478.493C13.6606 478.493 13.6916 460.319 34.6688 451.491C55.646 442.661 373.46 454.783 373.46 454.783Z"
                fill="#A0DDFF"
            />
            <Mask
                id="mask0_0_13177"
                style={{
                    maskType: "luminance"
                }}
                maskUnits="userSpaceOnUse"
                x={-224}
                y={-150}
                width={779}
                height={520}
            >
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M-223.286 -149.362H554.739V369.835H-223.286V-149.362Z"
                    fill="white"
                />
            </Mask>
            <G mask="url(#mask0_0_13177)">
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M-223.286 -149.362H554.739V369.835H-223.286V-149.362Z"
                    stroke="#FFF9D5"
                />
            </G>
            <Mask
                id="mask1_0_13177"
                style={{
                    maskType: "luminance"
                }}
                maskUnits="userSpaceOnUse"
                x={43}
                y={11}
                width={334}
                height={448}
            >
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M368.162 446.365C263.069 461.614 156.346 462.932 50.97 450.315C50.6829 450.281 50.3977 450.247 50.1097 450.212C50.09 449.906 50.0732 449.602 50.0545 449.297C41.4617 306.001 41.6235 165.288 50.5398 21.8984C50.5529 21.6646 50.5698 21.4308 50.5838 21.197C50.8045 21.1072 51.0262 21.0175 51.2469 20.9286C51.4592 20.8482 51.6182 20.7762 51.8295 20.6957C52.0166 20.62 52.2251 20.5667 52.4093 20.4891L52.5412 20.4732C157.202 7.60272 263.18 9.00461 367.555 24.6798C367.85 24.7237 368.142 24.7677 368.436 24.8116C368.461 25.1128 368.483 25.413 368.506 25.7141C379.329 167.057 379.528 304.149 369.114 445.335C369.089 445.635 369.07 445.936 369.047 446.236C368.754 446.28 368.456 446.322 368.162 446.365Z"
                    fill="white"
                />
            </Mask>
            <G mask="url(#mask1_0_13177)">
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M91.4178 333.766C91.4178 333.766 149.047 326.299 206.403 326.299C269.751 326.299 333.422 333.766 333.422 333.766C358.429 337.336 376.324 361.346 378.881 395.367C378.881 395.367 381.555 433.279 381.555 471.28C381.555 507.923 379.327 626.914 379.327 626.914C376.77 660.936 357.848 687.35 337.433 688.516C327.638 689.673 273.362 692.218 213.089 692.249C152.301 692.281 115.711 691.64 91.4178 688.516C67.1241 685.392 46.598 661.288 45.9583 628.159C45.9583 628.159 43.73 499.453 43.73 458.835C43.73 425.511 45.5126 395.989 45.5126 395.989C46.7915 360.629 66.7301 337.336 91.4178 333.766V333.766Z"
                    fill="#F7E2B4"
                />
            </G>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M51.3267 11.2255C156.325 -3.41528 262.849 -3.4134 367.847 11.2293C371.278 11.7081 374.494 13.5205 376.771 16.1102C376.815 16.1167 376.859 16.1232 376.902 16.1289C379.204 18.7512 380.872 22.089 381.129 25.6101C391.397 166.894 391.397 304.278 381.129 445.562C380.873 449.058 379.213 452.315 376.887 454.913C376.851 454.918 376.816 454.923 376.781 454.928C376.778 454.972 376.774 455.016 376.771 455.061C374.501 457.572 371.277 459.464 367.847 459.942C262.849 474.586 156.324 474.587 51.3267 459.946C47.8702 459.464 44.6726 457.576 42.2617 454.913C42.2654 454.957 42.2701 455.002 42.2719 455.046C39.9442 452.447 38.2767 449.084 38.0167 445.562C27.749 304.277 27.749 166.894 38.0167 25.6101C38.2739 22.089 39.9414 18.7512 42.2719 16.1251C44.6764 13.5168 47.8711 11.7081 51.3267 11.2255M352.756 22.1358C257.676 10.844 161.47 10.844 66.3893 22.1358C57.6993 23.1804 50.1698 31.3121 49.6106 40.1461C41.491 171.299 41.491 299.872 49.6106 431.025C50.1698 439.859 57.7002 447.992 66.3893 449.035C161.469 460.327 257.676 460.327 352.756 449.035C361.448 447.992 368.978 439.859 369.535 431.025C377.656 299.872 377.656 171.299 369.535 40.1461C368.978 31.313 361.448 23.1804 352.756 22.1358Z"
                fill="#005A8B"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.1471 26.7196C48.865 26.6523 41.2374 30.3277 33.8379 35.6771C27.4158 40.4542 21.7596 46.3507 19.7555 50.9595C16.5093 190.448 18.3863 330.092 22.7743 469.644C18.5958 471.905 14.5192 474.014 10.5651 475.983C-0.150607 333.048 -2.70375 193.23 5.73189 51.0352C5.86656 50.4255 6.00217 49.8148 6.13684 49.205C8.98457 41.6934 16.9536 32.6816 25.4509 25.9387C34.4299 18.7843 44.3077 13.9474 51.4555 13.9502C52.3374 18.2036 53.2343 22.4607 54.1471 26.7196"
                fill="#005A8B"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M171.492 523.724C128.935 523.011 86.4164 520.025 44.1194 514.727C38.047 514.212 32.2561 511.828 26.9441 508.209C21.7948 504.63 17.13 499.751 13.7669 494.189C10.5339 488.655 8.74015 482.703 8.61389 476.981C8.48109 471.126 10.0177 465.47 13.0356 460.208C15.3372 456.336 18.3486 452.92 22.0501 450.007C25.5609 447.133 29.6778 444.878 34.0031 443.62C35.4537 443.117 37.8487 442.753 40.2672 442.361C43.028 441.921 46.0188 441.756 47.6722 441.669C47.892 446.376 48.1511 451.091 48.4438 455.815C47.3664 455.836 43.3974 455.914 41.0042 456.249C38.9542 456.404 37.2455 456.579 36.6414 456.733C34.0087 457.551 31.6127 458.776 29.3186 460.556C27.1602 462.22 25.3674 464.242 23.9131 466.753C22.1409 470.115 21.215 473.805 21.2898 477.538C21.3618 481.268 22.4439 485.051 24.3367 488.462C26.3905 491.989 29.2513 494.906 32.4366 497.001C35.5977 499.089 39.2244 500.511 42.9316 500.715C43.0093 500.722 43.0887 500.729 43.1664 500.737C55.7132 502.878 158.068 508.234 171.384 507.273M171.383 507.273C238.893 507.532 308.166 502.829 375.146 495.839C378.461 495.504 381.054 497.956 380.84 501.31C380.617 504.768 377.684 507.955 374.386 508.447C307.115 518.881 239.233 524.205 171.492 523.724M16.2243 416.702C16.3578 416.689 16.4978 416.678 16.6346 416.666C16.2886 418.282 15.9591 419.894 15.6346 421.504C15.8207 419.906 16.0184 418.305 16.2243 416.702Z"
                fill="#005A8B"
            />
            <Path
                d="M40.125 480.81C141.207 490.284 235.045 493.769 350.369 480.81C355.592 480.223 360.763 479.364 365.926 478.594"
                stroke="#005A8B"
                strokeWidth={10}
                strokeLinecap="round"
            />
        </Svg>
    )
}

export default SvgComponent
