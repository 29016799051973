import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { startLoading, stopLoading } from "../app/slice";
import { testsApi } from "../../api/tests.api";

export const getStudentTestWithParamsThunk = createAsyncThunk(
    "tests/getStudentTestWithParamsThunk",
    async ({ addToExist, loader, ...rest }, { dispatch, rejectWithValue }) => {
        try {
            if (loader) {
                await dispatch(startLoading());
            }
            //SCHEDULED,UPCOMING,ONGOING,COMPLETED,MISSED
            const res = await testsApi.getStudentTestWithParams(rest);
            return { data: res?.data, addToExist, params: rest };
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const getStudentTestInstructionThunk = createAsyncThunk(
    "tests/getStudentTestInstructionThunk",
    async ({ scheduleId, testId }, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());

            const instruction = await testsApi.getStudentTestInstruction({
                testId,
            });

            const instructionsRes = instruction?.data?.data;

            return {
                instruction: instructionsRes?.testInstructions,
                syllabus: instructionsRes?.testSyllabus[0],
                totalMarks: instructionsRes?.totalMarks,
                totalQuestions: instructionsRes?.totalQuestions,
            };
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const getTestPreInfoThunk = createAsyncThunk(
    "tests/getTestPreInfoThunk",
    async ({ studentTestId, testId }, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());

            const instruction = await testsApi.getStudentTestInstruction({
                studentTestId,
            });
            const syllabus = await testsApi.getTestSyllabus({ testId });
            return { instruction: instruction?.data, syllabus: syllabus?.data };
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const getStudentTestDetailsThunk = createAsyncThunk(
    "tests/getStudentTestDetailsThunk",
    async (params, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());

            const details = await testsApi.getStudentTestDetails(params);
            return { details: details?.data };
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const studentTestResultReviewThunk = createAsyncThunk(
    "tests/studentTestResultReviewThunk",
    async (params, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const score = await testsApi.getStudentTestScore(params);
            const details = await testsApi.getStudentTestDetailsPaper(params);

            return {
                testResult: score?.data,
                testDetails: details?.data,
            };
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const markStudentTestQuestionAsVisitedThunk = createAsyncThunk(
    "tests/markStudentTestQuestionAsVisitedThunk",
    async ({ loader = true, ...rest }, { dispatch, rejectWithValue }) => {
        try {
            if (loader) {
                await dispatch(startLoading());
            }

            const res = await testsApi.markStudentTestQuestionAsVisited(rest);

            return res?.data;
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const getStudentSpentTimeRedisThunk = createAsyncThunk(
    "tests/getStudentSpentTimeRedisThunk",
    async (params, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());

            const res = await testsApi.getStudentSpentTimeRedis(params);

            return res?.data;
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const updateStudentTestSpentTimeThunk = createAsyncThunk(
    "tests/updateStudentTestSpentTimeThunk",
    async (params, { dispatch, rejectWithValue }) => {
        try {
            // await dispatch(startLoading());
            const res = await testsApi.updateStudentTestSpentTime(params);

            return res?.data;
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            // await dispatch(stopLoading());
        }
    }
);

export const studentTestFinishThunk = createAsyncThunk(
    "tests/studentTestFinishThunk",
    async (params, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const res = await testsApi.studentTestFinish(params);
            const res2 = await testsApi.getStudentTestScore(params);

            return { finish: res?.data, score: res2?.data };
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);
