import React, { useEffect, useState } from "react";
import { Pressable, ScrollView, Text } from "react-native";
import { CardsImage } from "../../../components/carrouselCards/components/CardsImage";

export const Playlist = ({
    styles,
    parseList,
    playerRef,
    setActiveVideo,
    selectedItemIndex,
    isFullscreen,
}) => {
    const [ref, setRef] = useState(null);
    const [dataSourceCords, setDataSourceCords] = useState([]);

    useEffect(() => {
        if (ref && dataSourceCords.length > selectedItemIndex) {
            ref.scrollTo({
                yx: 0,
                x: dataSourceCords[selectedItemIndex - 1],
                animated: true,
            });
        }
    }, [ref, dataSourceCords, selectedItemIndex, isFullscreen]);

    const goToVideo = async (video) => {
        const { url, index, image } = video;
        await playerRef.current.setPositionAsync(0);
        setActiveVideo({
            url: url,
            index: index,
            image: image,
        });
    };

    return (
        <ScrollView
            ref={(ref) => setRef(ref)}
            style={{ width: "100%" }}
            horizontal={true}
            showsHorizontalScrollIndicator={true}
            contentContainerStyle={styles.list}>
            {parseList.map((video, index) => (
                <Pressable
                    key={video?.id}
                    style={styles.playlistContainer}
                    onPress={() => goToVideo(video, index)}
                    onLayout={(event) => {
                        dataSourceCords[index] = event.nativeEvent.layout.x;
                        setDataSourceCords(dataSourceCords);
                    }}>
                    <CardsImage
                        source={video?.image}
                        style={[styles.playlistImage(video?.isSelected)]}
                    />
                    <Text
                        numberOfLines={1}
                        ellipsizeMode="tail"
                        style={styles.title}>
                        {video?.title}
                    </Text>
                </Pressable>
            ))}
        </ScrollView>
    );
};
