import {useDispatch} from "react-redux";
import {ProfileModalContent} from "../components/modal/Profile";
import {openModal} from "../store/app/slice";

export const useModal = () => {
    const dispatch = useDispatch()

    const openProfileModal = () => {
        dispatch(openModal(<ProfileModalContent/>))
    }

    return {
        openProfileModal
    }
}
