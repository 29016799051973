import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={29}
            height={27}
            viewBox="0 0 29 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.982 26.154a627.073 627.073 0 00-8.748-7.935c-1.457-.024-2.914-.05-4.37-.081-.437-.01-.801-.306-.81-.662-.072-2.645-.072-5.286 0-7.93.009-.356.373-.653.81-.662 1.456-.03 2.913-.058 4.37-.081A626.02 626.02 0 0013.98.868c.492-.455 1.349-.174 1.338.437a669.752 669.752 0 000 24.411c.01.612-.846.893-1.337.438M16.935 16.436c-.502-.426-.503-1.114-.002-1.54.902-.763.902-2.007 0-2.771-.501-.425-.5-1.114.002-1.54.25-.213.579-.319.907-.319.328 0 .656.107.907.32 1.908 1.616 1.908 4.233 0 5.85-.502.426-1.313.426-1.814 0z"
                fill="#FFD9D0"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.115 19.942c-.504.43-1.305.441-1.795.022-.49-.42-.484-1.108.018-1.535 1.558-1.326 2.437-3.07 2.436-4.918.001-1.847-.879-3.592-2.436-4.918-.502-.427-.508-1.116-.018-1.535.245-.21.568-.312.892-.308.325.004.651.115.903.33 2.05 1.75 3.232 4.028 3.23 6.431.002 2.403-1.18 4.68-3.23 6.431"
                fill="#FFD9D0"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.324 23.336c-.511.437-1.295.469-1.762.064-.466-.404-.441-1.087.066-1.52 2.791-2.393 4.308-5.401 4.297-8.37.01-2.967-1.506-5.976-4.297-8.368-.508-.433-.532-1.116-.066-1.52.234-.202.546-.296.864-.284.319.012.643.13.898.348C26.54 6.453 28.51 9.915 28.5 13.51c.01 3.596-1.96 7.057-5.176 9.825"
                fill="#FFD9D0"
            />
        </Svg>
    );
}

export default SvgComponent;
