import { View, FlatList, ActivityIndicator } from "react-native";
import { useHaptic, useStylesUtils } from "../hooks";
import { CardViewComponent } from "./carrouselCards";
import { forwardRef } from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import { getScreenColors } from "../store/app/selectors";

export const PaginationCarousel = forwardRef(
    (
        {
            data,
            CardView = CardViewComponent,
            type: layType = null,
            onFetch,
            showLoader,
            onPressCard = () => {},
        },
        ref
    ) => {
        const insets = useSafeAreaInsets();
        const { styles, width, b } = useStylesUtils(getStyles(insets));
        const { hapticImpact } = useHaptic();
        const { fill } = useSelector(getScreenColors);

        const fetchMoreData = () => {
            onFetch();
        };

        const renderFooter = () => {
            if (showLoader) {
                return (
                    <View
                        style={{
                            ...styles.container,
                            width: width * 0.2,
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                        }}>
                        <ActivityIndicator size="large" color={"#ffffff"} />
                    </View>
                );
            }
            return <></>;
        };
        return (
            <View style={styles.container}>
                <View>
                    <FlatList
                        showsHorizontalScrollIndicator={true}
                        showsVertivalScrollIndicator={true}
                        horizontal={true}
                        contentContainerStyle={{ flexGrow: 1 }}
                        data={data}
                        ref={ref}
                        renderItem={(data) => {
                            const { id, onPress, type, key, ...rest } =
                                data.item;
                            return (
                                <CardView
                                    key={key}
                                    {...rest}
                                    fill={fill}
                                    type={
                                        type ? type : layType ? layType : null
                                    }
                                    onPress={() => {
                                        hapticImpact(1);
                                        onPress
                                            ? onPress(data.item)
                                            : onPressCard(data.item);
                                    }}
                                />
                            );
                        }}
                        ListFooterComponent={renderFooter}
                        onEndReachedThreshold={0.2}
                        onEndReached={fetchMoreData}
                        style={styles.scrollView}
                    />
                </View>
            </View>
        );
    }
);

const getStyles =
    (insets) =>
    ({ width, isMobile, isWeb }) => ({
        container: {
            flex: 1,
            width: "100%",
            height: "100%",
            alignItems: isMobile ? "center" : "stretch",
            justifyContent: "center",
        },
        scrollView: {
            // alignItems: "center",
            paddingLeft: "1.5%",
            paddingBottom: 10,
            margin: isWeb ? 0 : "auto",
        },
        indicatorContainer: {
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: width * 0.0624,
            justifyContent: "flex-end",
            marginBottom: -insets?.bottom,
            left: Number(-insets?.left) || 0,
        },
        loader: {
            width: 20,
            height: 20,
            backgroundColor: "red",
        },
    });
