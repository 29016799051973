import Heap from "@heap/react-native-heap";
import React, { useEffect, useMemo, useContext } from "react";
import { Image, ScrollView, Text, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-native";
import { MainButton, MainLayout } from "../../../components";
import { images, navigation, COLOURS } from "../../../constants";
import {
    useCreateHook,
    useHaptic,
    useLearnHook,
    usePermissions,
    usePopups,
    useStylesUtils,
} from "../../../hooks";
import { getUserInfo } from "../../../store/auth/selectors";
import { useAuth } from "../../../hooks/useAuth";
import { LeftBlockContent } from "./LeftBlockContent";
import GlobalAudioContext from "../../../providers/GlobalAudioContext";

export const HomeScreen = () => {
    const { playBgSound, stopBgSound } = useContext(GlobalAudioContext);
    const { getStorysName } = useCreateHook();
    const navigate = useNavigate();
    const { hapticImpact } = useHaptic();
    const insets = useSafeAreaInsets();
    const { biggerSize: windowWidth, styles } = useStylesUtils(
        getStyles(insets)
    );
    const { getPlaylistsForUser } = useLearnHook();
    const { checkInitialPermissions, canLoginInApp } = usePermissions();
    const userInfo = useSelector(getUserInfo);
    const { loginCMSGradeError } = usePopups();
    const { onLogOut } = useAuth();
    const { getSubjectsForExam } = useLearnHook();

    useEffect(() => {
        if (canLoginInApp && process.env.NODE_ENV !== "development") {
            loginCMSGradeError(async () => {
                await onLogOut(false);
            });
        } else if (userInfo && process.env.NODE_ENV !== "development") {
            const { admissionNumber, lastName, firstName, email, phone } =
                userInfo;
            Heap.identify(admissionNumber);
            Heap.addUserProperties({
                Name: `${firstName} ${lastName}`,
                Email: email,
                Phone: phone,
            });
            getPlaylistsForUser();
        } else {
            getPlaylistsForUser();
        }

        // request to get access to the camera and microphone
        // checkInitialPermissions();
    }, []);

    useEffect(() => {
        playBgSound();

        return () => {
            stopBgSound();
        };
    }, []);

    const tabs = [
        {
            title: "Diary",
            bgIcon: images.purpleBtnBG,
            bgColor: COLOURS.purple22,
            icon: images.btnImage4,
            textShadowColor: COLOURS.purple12,
            onPress: () => {
                hapticImpact(1);
                navigate(navigation.DIARY);
            },
            id: 0,
        },
        // {
        //     title: "Self \nLearn",
        //     icon: images.btnImage1,
        //     bgColor: COLOURS.green8,
        //     bgIcon: images.blueBtnTestBG1,
        //     textShadowColor: COLOURS.green6,
        //     onPress: () => {
        //         hapticImpact(1);
        //         // navigate(navigation.LETS_LEARN);
        //         navigate(navigation.COMING_SOON_SCREEN);
        //     },
        //     id: 1,
        // },
        // {
        //     title: "Let's \nRead",
        //     icon: images.btnImage1,
        //     bgColor: COLOURS.mainGreen,
        //     bgIcon: images.greenBtnBG,
        //     textShadowColor: COLOURS.green7,
        //     onPress: () => {
        //         hapticImpact(1);
        //         navigate(navigation.LETS_READ);
        //     },
        //     id: 2,
        // },
        // {
        //     title: "Let's \nCreate",
        //     bgIcon: mainButtonsBG[4],
        //     bgColor: COLOURS.mainRed,
        //     icon: images.btnImage2,
        //     textShadowColor: COLOURS.red6,
        //     onPress: () => {
        //         hapticImpact(1);
        //         getStorysName({
        //             params: [4390, 4391],
        //             location: navigation.LETS_CREATE,
        //         });
        //     },
        //     id: 3,
        // },
        {
            title: "Let's \nLearn",
            bgIcon: images.blueBtnBG,
            bgColor: COLOURS.mainBlue,
            icon: images.btnImage3,
            textShadowColor: COLOURS.dark_purple,
            onPress: () => {
                hapticImpact(1);
                getSubjectsForExam();
                // navigate(navigation.LETS_LEARN);
            },
            id: 4,
        },
        {
            title: "Let's \nExplore",
            bgIcon: images.redBtnBG,
            bgColor: COLOURS.mainRed,
            icon: images.btnImage2,
            textShadowColor: COLOURS.red6,
            onPress: () => {
                hapticImpact(1);

                getStorysName({
                    params: [4390, 4391],
                    location: navigation.LETS_EXPLORE,
                });
            },
            id: 5,
        },
        // {
        //     title: "Quizz",
        //     bgIcon: images.redBtnBG,
        //     bgColor: COLOURS.mainRed,
        //     icon: images.btnImage2,
        //     textShadowColor: COLOURS.red6,
        //     onPress: () => {
        //         hapticImpact(1);
        //         navigate(navigation.QUIZZ_SCREEN);
        //     },
        //     id: 6,
        // },
    ];

    const { width, height, padding } = useMemo(() => {
        const cardSize = windowWidth / 4;
        return {
            width: cardSize,
            height: cardSize * 1.0684,
            padding: cardSize * 0.12,
        };
    }, [windowWidth]);

    return (
        <MainLayout
            headerProps={{
                /*removed from current scope*/
                notificationButton: false,
                profileButton: true,
                /*removed from current scope*/
                searchButton: false,
                leftBlockContent: <LeftBlockContent />,
            }}>
            <View style={{ flex: 1, width: "100%", justifyContent: "center" }}>
                <View>
                    <ScrollView
                        horizontal={true}
                        contentContainerStyle={{ margin: "auto" }}>
                        <View style={styles.container}>
                            {tabs.map((tab, index, all) => (
                                <View
                                    key={tab.id}
                                    style={{
                                        marginRight:
                                            all.length - 1 === index ? 0 : 20,
                                    }}>
                                    <MainButton
                                        key={tab.id}
                                        onPress={tab.onPress}
                                        style={{
                                            width,
                                            height,
                                            padding,
                                        }}
                                        source={tab.bgIcon}>
                                        <View style={styles.imageWrapper}>
                                            <Image
                                                style={{
                                                    width: width * 0.6,
                                                    height: height * 0.45,
                                                }}
                                                source={tab.icon}
                                                resizeMode="contain"
                                            />
                                        </View>
                                        <View style={styles.textWrapper}>
                                            <Text
                                                style={[
                                                    styles.text,
                                                    {
                                                        textShadowColor:
                                                            tab.textShadowColor,
                                                    },
                                                ]}>
                                                {tab.title}
                                            </Text>
                                        </View>
                                    </MainButton>
                                </View>
                            ))}
                        </View>
                    </ScrollView>
                </View>
            </View>
        </MainLayout>
    );
};

const getStyles =
    (insets) =>
    ({ width, OS }) => ({
        container: {
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100%",
            paddingHorizontal: insets.left || insets.right ? 0 : width * 0.05,
            minWidth: "100%",
        },
        textWrapper: {
            width: "100%",
            flex: 2,
            justifyContent: "center",
            alignItems: "center",
        },
        text: {
            paddingTop: width * (OS === "web" ? 0.009 : 0),
            fontSize: 40,
            lineHeight: 12,
            textAlign: "center",
            fontWeight: 700,
            color: COLOURS.white,
            textShadowOffset: { height: 2, width: 2 },
            textShadowRadius: 3,
        },
        imageWrapper: {
            width: "100%",
            flex: 3,
            justifyContent: "flex-start",
            flexDirection: "column-reverse",
            alignItems: "center",
        },
        image: {
            width: "100%",
            maxWidth: "80%",
            height: "100%",
            maxHeight: "80%",
        },
    });
