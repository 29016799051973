import { arrAreEqual, findAnswer } from "../utils/QuizUtils";
import {
    QUIZ_OPTION_STATUS,
    QUIZ_QUESTION_STATUS,
    QUIZ_QUESTION_TYPE,
} from "../../../../constants";
import _ from "lodash";

class OptionChoiceFactory {
    static prepareQuestionData(
        question,
        answers,
        setAnswers,
        isFinished,
        multipleChoice = false
    ) {
        return {
            questionText: question.question_text,
            questionTextHtml: {
                html: question.question_text ? question.question_text : "",
            },
            listOfOptions: this.prepareListOfOptions(
                question,
                answers,
                setAnswers,
                isFinished,
                multipleChoice
            ),
        };
    }

    static prepareListOfOptions(
        question,
        answers,
        setAnswers,
        isFinished,
        multipleChoice = false
    ) {
        const correctOptionIndexArr = question.correct_answer.map(
            (el) => Number(el) - 1
        );
        const correctAnswersArr = question.answer_options.filter((el, index) =>
            correctOptionIndexArr.includes(index)
        );

        return question.answer_options.map((opt, index) => {
            const optId = `${question.question_id}_${index}`;
            return {
                id: optId,
                text: opt,
                image: undefined,
                status: this.getOptionStatus(
                    answers,
                    question.question_id,
                    optId,
                    correctOptionIndexArr.includes(index),
                    correctAnswersArr,
                    isFinished
                ),
                isSelected: this.optionsIsSelected(
                    answers,
                    question.question_id,
                    optId,
                    correctOptionIndexArr.includes(index),
                    correctAnswersArr,
                    isFinished
                ),
                isTrue: index + 1 === Number(question.correct_answer),
                onOptionPick: this.getOnOptionPickHandler(
                    question,
                    answers,
                    setAnswers,
                    isFinished,
                    multipleChoice
                ),
            };
        });
    }

    static getOptionStatus(
        answers,
        question_id,
        optionId,
        isCorrectOption,
        correctAnswersArr,
        quizIsFinished = false
    ) {
        const answer = findAnswer(answers, question_id);
        if (!quizIsFinished) {
            if (
                answer &&
                answer.options.find((el) => el.optionId === optionId)
            ) {
                return QUIZ_OPTION_STATUS.CHECKED;
            }
            return QUIZ_OPTION_STATUS.NONE;
        }

        if (quizIsFinished) {
            if (isCorrectOption) {
                return QUIZ_OPTION_STATUS.SUCCESS;
            }

            if (!answer || !answer.options || !answer.options.length) {
                return QUIZ_OPTION_STATUS.NONE;
            }
            const option = answer.options.find(
                (el) => el.optionId === optionId
            );
            if (option) {
                return correctAnswersArr.includes(option.value)
                    ? QUIZ_OPTION_STATUS.SUCCESS
                    : QUIZ_OPTION_STATUS.ERROR;
            }
        }
        return QUIZ_OPTION_STATUS.NONE;
    }
    static optionsIsSelected(
        answers,
        question_id,
        optionId,
        isCorrectOption,
        correctAnswer,
        isFinished
    ) {
        if (isFinished && isCorrectOption) {
            return true;
        }
        const answer = findAnswer(answers, question_id);
        if (!answer) {
            return false;
        }
        return answer.options.find((el) => el.optionId === optionId);
    }
    static getOnOptionPickHandler(
        question,
        answers,
        setAnswers,
        isFinished,
        multipleChoice = false
    ) {
        if (isFinished) {
            return () => {};
        }
        return (optionId, value) => {
            setAnswers((prevState) => {
                const answer = findAnswer(prevState, question.question_id);
                if (!answer) {
                    prevState.push({
                        question_id: question.question_id,
                        options: [{ optionId, value }],
                    });
                    return this.checkAnswersState(question, [...prevState]);
                }

                if (!multipleChoice) {
                    answer.options = [{ optionId, value }];
                    return this.checkAnswersState(question, [...prevState]);
                }

                if (answer.options.find((el) => el.optionId === optionId)) {
                    answer.options = answer.options.filter(
                        (el) => el.optionId !== optionId
                    );
                    return this.checkAnswersState(question, [...prevState]);
                }

                answer.options.push({ optionId, value });
                return this.checkAnswersState(question, [...prevState]);
            });
        };
    }

    static calcQuestion(question, answers, isFinished) {
        const multiChoice =
            question.questionTypeRemapped === QUIZ_QUESTION_TYPE.MULTI_CORRECT;
        return {
            status: this.getQuestionStatus(question, answers, isFinished),
            mark: this.getQuestionMark(
                question,
                answers,
                isFinished,
                multiChoice
            ),
        };
    }

    static getQuestionStatus(question, answers, isFinished) {
        if (!question || !answers) {
            return isFinished
                ? QUIZ_QUESTION_STATUS.ERROR
                : QUIZ_QUESTION_STATUS.NEW;
        }
        const answer = findAnswer(answers, question.question_id);
        if (isFinished) {
            if (!answer) {
                return QUIZ_QUESTION_STATUS.ERROR;
            }
            return this.questionStatusByAnswer(question, answer, isFinished);
        }

        if (!answer) {
            return QUIZ_QUESTION_STATUS.NEW;
        }
        if (answer.options.length > 0) {
            return QUIZ_QUESTION_STATUS.VISITED;
        }
        return QUIZ_QUESTION_STATUS.NEW;
    }

    static questionStatusByAnswer(question, answer, isFinished) {
        const correctOptionIndexArr = question.correct_answer.map(
            (el) => Number(el) - 1
        );
        const correctAnswersArr = question.answer_options.filter((el, index) =>
            correctOptionIndexArr.includes(index)
        );

        const answerOptions = !(answer.options || answer.options.length)
            ? []
            : answer.options.map((el) => el.value);
        //ALL MATCHED
        if (arrAreEqual(correctAnswersArr, answerOptions)) {
            return QUIZ_QUESTION_STATUS.SUCCESS;
        }
        const optionIntersection = [...correctAnswersArr].filter((x) =>
            answerOptions.includes(x)
        );

        //PARTIAL MATCH
        if (optionIntersection.length) {
            return QUIZ_QUESTION_STATUS.SEMI_ERROR;
        }

        //ZERO MATCH
        return QUIZ_QUESTION_STATUS.ERROR;
    }

    static getQuestionMark(
        question,
        answers,
        isFinished,
        multipleChoice = false
    ) {
        return 0;
    }

    static checkAnswersState(question, state = []) {
        if (!question || !state) {
            return state;
        }
        const answer = findAnswer(state, question?.question_id);

        if (!question || !state?.length || !answer?.options?.length) {
            return state;
        }

        const { answer_options, correct_answer } = question;

        const optionsResponse = answer.options.map(({ value }) => {
            const fIndex = answer_options.findIndex((item) => {
                return item === value;
            });

            return fIndex >= 0 ? (Number(fIndex) + 1).toString() : "";
        });

        answer.correct = _.isEqual(optionsResponse, correct_answer);
        answer.response = optionsResponse.join(",");

        return state;
    }
}

export default OptionChoiceFactory;
