import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { mainApi } from "../../api/main.api";
import { startLoading, stopLoading } from "../app/slice";

export const getStudentHomeworksThunk = createAsyncThunk(
    "diary/studentHomeworks",
    async (params, { dispatch, rejectWithValue }) => {
        try {
            if (!params.addToExist) {
                await dispatch(startLoading());
            }
            const res = await mainApi.getHomeworks(params);
            if (!res || res.status !== 200) {
                throw res;
            }
            return { data: res?.data, params };
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);
export const getStudentHomeworksSubjectsThunk = createAsyncThunk(
    "diary/StudentHomeworksSubjectsThunk",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const res = await mainApi.getHomeworkSubjects();
            if (!res || res.status !== 200) {
                throw res;
            }
            return res?.data?.data;
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const getVideoByVideoIdThunk = createAsyncThunk(
    "diary/getVideoByVideoId",
    async (props, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const res = await mainApi.getVideoByVideoId(props);
            if (!res || res.status !== 200) {
                throw res;
            }

            return res?.data;
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const setHomeworkStatusToCompleteThunk = createAsyncThunk(
    "diary/studentHomeworkComplete",
    async (params, { dispatch, rejectWithValue }) => {
        try {
            const res = await mainApi.setHomeworkStatusAsComplited(params);
            if (!res || res.status !== 200) {
                throw res;
            }
            return { data: res?.data, params };
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
        }
    }
);

export const setHomeworkVideoProgressThunk = createAsyncThunk(
    "diary/studentHomeworkComplete",
    async (params, { dispatch, rejectWithValue }) => {
        try {
            const res = await mainApi.setHomeworkVideoProgress(params);
            if (!res || res.status !== 200) {
                throw res;
            }
            return res?.data;
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
        }
    }
);
