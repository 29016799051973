import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={42}
            height={42}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40.864 34.223c-2.261-3.462-5.335-6.64-9.247-9.287 1.641-2.538 2.57-5.119 2.693-8.358C33.04 4.924 25.867.637 16.906.637 7.946.637.674 7.927.656 16.887c-.02 9.005 7.247 16.346 16.25 16.252 2.977-.032 6.074-.305 8.476-1.709 3.013 4.05 5.79 6.977 9.01 8.813a3.78 3.78 0 002.681 1.11c.97 0 1.94-.37 2.68-1.11 1.481-1.48 2.591-4.54 1.111-6.02m-32.11-17.61c0-4.588 3.784-7.641 8.322-8.322 3.814-.571 7.863 2.127 8.322 8.322 1.36 4.383-3.734 8.323-8.322 8.323-4.59 0-8.322-3.734-8.322-8.323"
                fill="#70ACE3"
            />
        </Svg>
    );
}

export default SvgComponent;
