import { ProfileBoyIcon, ProfileGirlIcon, ProfileIcon } from "../assets/svg";
import { newcurrentAllowedGradeIDs } from "../constants";

const getGenderIcon = (gender) => {
    switch (gender) {
        case "male":
            return ProfileBoyIcon;
        case "female":
            return ProfileGirlIcon;
        default:
            return ProfileIcon;
    }
};

export const transformAdmissionNumberResponse = (response) => {
    let data = response?.payload?.data;
    if (data) {
        const activeAdmissionNumbers = data?.reduce((total, student) => {
            const {
                grade,
                active,
                admissionNumber,
                uid,
                gender,
                firstName,
                lastName,
            } = student || "";
            const studentGrade = grade?.gradeId;
            if (
                newcurrentAllowedGradeIDs.includes(studentGrade) &&
                active &&
                admissionNumber
            ) {
                return [
                    ...total,
                    {
                        id: (total?.[total?.length - 1]?.id || 0) + 1,
                        uid: uid,
                        Icon: getGenderIcon(gender),
                        name: `${firstName} ${lastName}`,
                        grade: grade?.name,
                    },
                ];
            } else {
                return total;
            }
        }, []);
        return activeAdmissionNumbers;
    } else {
        return [];
    }
};

export const maskPhone = (phone, start = 2, end = 2) => {
    let result = phone.slice(0, start);
    result += "X".repeat(phone.length - start - end);
    result += phone.slice(phone.length - end);
    return result;
};
