import React from "react";
import { Text, View } from "react-native";
import { DashedLine } from "../../../components";

export const LeftBlock = ({ styles }) => {
    return (
        <View style={styles.leftBlock}>
            <View style={styles.tableColumn}>
                <View style={[styles.tableTitle, styles.tableTitleLight]}>
                    <Text
                        style={[
                            styles.tableTitleText,
                            styles.tableTitleTextLight,
                        ]}>
                        Periods
                    </Text>
                </View>
                <View style={styles.tableRow()}>
                    <Text style={styles.tableRowTitle}>Period 1</Text>
                    <Text style={styles.tableRowSubTitle}>
                        10:30 am to 11:30 am
                    </Text>
                </View>
                <DashedLine vertical={false} viewStyle={styles.tableRowLine} />
                <View style={styles.tableRow()}>
                    <Text style={styles.tableRowTitle}>Period 2</Text>
                    <Text style={styles.tableRowSubTitle}>
                        10:30 am to 11:30 am
                    </Text>
                </View>
                <DashedLine vertical={false} viewStyle={styles.tableRowLine} />
                <View style={styles.tableRow()}>
                    <Text style={styles.tableRowSubTitle}>
                        10:30 am to 11:30 am
                    </Text>
                </View>
                <DashedLine vertical={false} viewStyle={styles.tableRowLine} />
                <View style={styles.tableRow()}>
                    <Text style={styles.tableRowTitle}>Period 3</Text>
                    <Text style={styles.tableRowSubTitle}>
                        10:30 am to 11:30 am
                    </Text>
                </View>
                <DashedLine vertical={false} viewStyle={styles.tableRowLine} />
                <View style={styles.tableRow()}>
                    <Text style={styles.tableRowTitle}>Period 4</Text>
                    <Text style={styles.tableRowSubTitle}>
                        10:30 am to 11:30 am
                    </Text>
                </View>
                <DashedLine vertical={false} viewStyle={styles.tableRowLine} />
                <View style={styles.tableRow()}>
                    <Text style={styles.tableRowSubTitle}>
                        10:30 am to 11:30 am
                    </Text>
                </View>
                <DashedLine vertical={false} viewStyle={styles.tableRowLine} />
                <View style={styles.tableRow()}>
                    <Text style={styles.tableRowTitle}>Period 5</Text>
                    <Text style={styles.tableRowSubTitle}>
                        10:30 am to 11:30 am
                    </Text>
                </View>
            </View>
        </View>
    );
};
