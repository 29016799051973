import MathJax from "react-native-mathjax";
import {
    mmlOptions,
    transformTagsToBasic,
    COLOURS,
    customHTMLElementModelsReplaceStandardTags,
    connectFonts,
} from "../constants";
import React, { useState } from "react";
import { useStylesUtils } from "../hooks";
import RenderHtml from "react-native-render-html";
import { View, Text } from "react-native";

export const MathJaxView = ({
    html,
    textColor = COLOURS.black,
    isTextCentered = false,
    textStyles = {},
}) => {
    const { styles, width, isWeb } = useStylesUtils(
        getStyles(textColor, isTextCentered),
        { textStyles }
    );

    const [coords, setCoords] = useState({ w: 0, h: 0 });

    const renderMathJax = transformTagsToBasic.find((tag) =>
        html.toString().includes("<" + tag)
    );

    const setLayoutCoords = (event) => {
        const layout = event.nativeEvent.layout;
        setCoords({ w: layout.width });
    };

    const isHtml = /<\/?[a-z][\s\S]*>/i.test(html);

    return (
        <View
            onLayout={setLayoutCoords}
            style={{
                width: "100%",
                alignItems: isTextCentered ? "center" : "flex-start",
            }}>
            {!renderMathJax ? (
                <>
                    {!isHtml ? (
                        <Text style={[styles.text, styles.textStyles]}>
                            {html}
                        </Text>
                    ) : (
                        <RenderHtml
                            source={{
                                html: `${html}`,
                            }}
                            customHTMLElementModels={customHTMLElementModelsReplaceStandardTags(
                                {
                                    styles: {
                                        ...styles.text,
                                        ...styles.textStyles,
                                    },
                                }
                            )}
                            enableCSSInlineProcessing={false}
                            enableExperimentalMarginCollapsing={true}
                            contentWidth={coords.w}
                            systemFonts={["Quicksand"]}
                        />
                    )}
                </>
            ) : isWeb ? (
                // <RenderHtml
                //     originWhitelist={["*"]}
                //     source={{
                //         html: `${html}`,
                //     }}
                //     contentWidth={coords.w}
                //     enableCSSInlineProcessing={false}
                //     enableExperimentalMarginCollapsing={true}
                //     systemFonts={["Quicksand"]}
                // />
                <div
                    style={{
                        fontFamily: "Quicksand_400Regular",
                        fontSize: 14,
                        fontWeight: "700",
                        ...styles.text,
                        ...styles.textStyles,
                    }}
                    dangerouslySetInnerHTML={{ __html: `${html}` }}
                />
            ) : (
                <MathJax
                    mathJaxOptions={mmlOptions({
                        styles: {
                            p: {
                                height: "100%",
                            },
                            "p, span, div, mn": {
                                display: "block",
                                "font-family": "Quicksand, sans-serif",
                                "font-weight": "700",
                                "font-size": `${styles.text.fontSize * 1.2}px`,
                                color: textColor,
                                "text-align": isTextCentered
                                    ? "center"
                                    : "left",
                                "max-width": coords.w,
                            },
                        },
                    })}
                    html={`${connectFonts} ${html}`}
                    style={{
                        flex: 1,
                        width: "100%",
                        maxWidth: width / 2,
                        backgroundColor: COLOURS.transparent,
                        justifyContent: "center",
                    }}
                />
            )}
        </View>
    );
};

const getStyles =
    (textColor, isTextCentered) =>
    ({ width }) => ({
        text: {
            fontSize: 16,
            fontWeight: 700,
            color: textColor,
            textAlign: isTextCentered ? "center" : "left",
        },
    });
