import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { authApi } from "../../api/auth.api";
import { startLoading, stopLoading } from "../app/slice";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const loginThunk = createAsyncThunk(
    "auth/login",
    async ({ admission_number, password }, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const res = await authApi.login({ admission_number, password });
            if (res?.status !== 200) {
                throw Error(res);
            }
            return res?.data;
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await AsyncStorage.clear();
            await dispatch(stopLoading());
        }
    }
);

export const getUserDtoThunk = createAsyncThunk(
    "auth/getUserDto",
    async (params, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const res = await authApi.getUserDto();
            return res?.data[0];
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const verifyUidThunk = createAsyncThunk(
    "auth/verifyUidThunk",
    async ({ admission_number }, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const res = await authApi.verifyUid({ admission_number });
            return { data: res?.data, uid: admission_number };
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await AsyncStorage.clear();
            await dispatch(stopLoading());
        }
    }
);

export const loginWithOtpThunk = createAsyncThunk(
    "auth/loginWithOtpThunk",
    async ({ admission_number, otp }, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const res = await authApi.loginWithOtp({ admission_number, otp });
            return res?.data;
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const generateOTPThunk = createAsyncThunk(
    "auth/generateOTP",
    async ({ body }, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            await authApi.generateOTP(body);
            return {};
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const getUidByPhoneThunk = createAsyncThunk(
    "auth/getUidByPhoneWithoutToken",
    async (body, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const res = await authApi.getUidByPhoneWithoutToken(body);
            return { data: res.data, phone: body.phone, isd: body.isd };
        } catch (err) {
            console.error("getUidByPhoneThunk error", err);
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const setNewPasscodeThunk = createAsyncThunk(
    "auth/setNewPasscodeThunk",
    async ({ password }, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const res = await authApi.setNewPasscode({ password });
            return res.data;
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const logoutThunk = createAsyncThunk(
    "auth/logoutThunk",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const res = await authApi.logout();
            return res.data;
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);

export const getMappingThunk = createAsyncThunk(
    "auth/getMapping",
    async (params, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(startLoading());
            const res = await authApi.getMapping(params);
            return res.data;
        } catch (err) {
            if (axios.isAxiosError(err)) {
                return rejectWithValue(err.response?.data);
            }
            throw err;
        } finally {
            await dispatch(stopLoading());
        }
    }
);
