import clickSound from "../assets/sounds/clickSound.wav";
import trainSound from "../assets/sounds/toy_train.mp3";
import backgroundSound from "../assets/sounds/summer_loop.mp3";
import pop_up from "../assets/sounds/pop_up.mp3";

export const SOUNDS = Object.freeze({
    clickSound,
    backgroundSound,
    trainSound,
    pop_up,
});
