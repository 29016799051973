import { ImageBackground, View, Text, TouchableOpacity } from "react-native";
import Animated, {
    Layout,
    SlideOutUp,
    SlideInUp,
} from "react-native-reanimated";

import { useStylesUtils } from "../../../hooks";
import { COLOURS, notificationsImages } from "../../../constants";
import { BellIcon, CloseIcon } from "../../../assets/svg";
import { NotificationCard } from "./NotificationCard";
import { CustomScrollView } from "../../CustomScrollView";
import { useSelector } from "react-redux";
import { getNotificationState } from "../../../store/app/selectors";
import { useEffect, useState } from "react";
import { useNotification } from "../../../hooks/useNotification";
import Swipeable from "react-native-gesture-handler/Swipeable";
import { useLocation } from "react-router-native";

export const NotificationsLayout = () => {
    const { pathname } = useLocation();
    const { styles, imageSizes } = useStylesUtils(getStyles);
    const { closeNotificationLayout } = useNotification();

    const { open } = useSelector(getNotificationState);

    const [show, setShow] = useState(open);

    const renderLeftActions = (progress, dragX) => {
        const trans = dragX.interpolate({
            inputRange: [0, 50, 100, 101],
            outputRange: [-20, 0, 0, 1],
        });
    };
    useEffect(() => {
        setShow(open);
    }, [open]);

    useEffect(() => {
        if (show) {
            closeNotificationLayout();
        }
    }, [pathname]);

    return show ? (
        <Animated.View
            style={styles.container}
            layout={Layout.duration(200).delay(200)}
            entering={SlideInUp}
            exiting={SlideOutUp}>
            <ImageBackground
                source={notificationsImages.BG_GREY}
                style={styles.background}
                resizeMode="stretch">
                <View style={styles.title}>
                    <BellIcon {...imageSizes.type1} />
                    <Text style={styles.lable}>Notification</Text>
                </View>
                <View style={styles.content}>
                    <CustomScrollView
                        showIndicator={true}
                        colors={{
                            indicatorBG: "#ffffff",
                            indicator: COLOURS.blue3,
                        }}>
                        <Swipeable renderLeftActions={this.renderLeftActions}>
                            <NotificationCard />
                        </Swipeable>
                    </CustomScrollView>
                </View>
                <TouchableOpacity
                    style={styles.title}
                    onPress={() => closeNotificationLayout()}>
                    <CloseIcon {...imageSizes.type1} />
                    <Text style={styles.lable}>Close</Text>
                </TouchableOpacity>
            </ImageBackground>
        </Animated.View>
    ) : null;
};

const getStyles = ({ width, height }) => ({
    container: {
        position: "absolute",
        width: width,
        height: height,
        left: 0,
        top: 0,
        zIndex: 500,
    },
    background: {
        width: width,
        height: height * 0.9,
        paddingVertical: height * 0.05,
        justifyItems: "center",
        alignItems: "center",
        paddingTop: height * 0.05,
        justifyContent: "space-between",
    },
    title: {
        flexDirection: "row",
        alignItems: "center",
    },
    lable: {
        marginLeft: width * 0.01,
        fontSize: 30,
        textAlign: "center",
        fontWeight: 700,
        color: "#fff",
        textShadowColor: "#000",
        textShadowOffset: { height: 0, width: 0 },
        textShadowRadius: 3,
        verticalAlign: "middle",
    },
    content: {
        width: width * 0.73,
        justifyContent: "center",
        alignItems: "center",
        height: "68%",
    },
});
