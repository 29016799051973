import Constants from "expo-constants";

export const uidStorage = "uid";
export const xTenant = "srichaitanya";
export const xProduct = "18";
export const xAppName = "infinityMetaJr";
export const authAccept = "application/json";
export const authProductId = "600";
export const authTenantId = "2";
export const authContentType = "application/json";
export const xAppVersion = Constants.manifest.version;

export const projectType = "PreProd"; // 'PreProd', 'Staging', 'Prod', 'Dev'

export const APP_KEY = "AB263AFA6BCFA";
export const APP_SECRET = "00nqBP1LsQpZMMnMApSjfjd2DVRkEnlp";

export const MAX_REFRESH_TRIES = 10;
export const REFRESH_LOADER_TIMEOUT = 3;

//const TESTS_BASE_URL = "https://k8stgslms.ilteach.com";    ##TEST
//const SELFLERN_BASE_URL = "https://selflearnv2api.devinfinitylearn.in";

const apiConstants = {
    // ["Dev"]: {
    //     UAM_BASE_URL: "https://devapi.devinfinitylearn.in/api",
    //     CMS_BASE_URL: "https://cmsdevapi.devinfinitylearn.in",
    //     SLMS_BASE_URL: "https://devlms.ilteach.com",
    //     TESTS_BASE_URL : "https://k8stgslms.ilteach.com",
    //     SELFLERN_BASE_URL,
    // },
    ["PreProd"]: {
        UAM_BASE_URL: "https://api.devinfinitylearn.in/api",
        AUTH_BASE_URL: "https://gatewayapi.devinfinitylearn.in/api",
        OTP_BASE_URL: "https://otp.devinfinitylearn.in/api",
        CMS_BASE_URL: "https://cmspreprodapi.devinfinitylearn.in",
        SLMS_BASE_URL: "https://k8stgslms.ilteach.com",
        TESTS_BASE_URL: "https://k8stgslms.ilteach.com",
        SELFLERN_BASE_URL: "https://selflearnv2api.devinfinitylearn.in",
    },
    // ["Staging"]: {
    //     UAM_BASE_URL: "https://stagingapi.devinfinitylearn.in/api",
    //     CMS_BASE_URL: "https://cmsapi.devinfinitylearn.in",
    //     SLMS_BASE_URL: "https://testlms.ilteach.com",
    //     TESTS_BASE_URL : "https://k8stgslms.ilteach.com",
    //     SELFLERN_BASE_URL,
    // },
    ["Prod"]: {
        UAM_BASE_URL: "https://api.infinitylearn.com/api",
        AUTH_BASE_URL: "https://gatewayapimeta.infinitylearn.com/api",
        OTP_BASE_URL: "https://otpmeta.infinitylearn.com/api",
        CMS_BASE_URL: "https://communityapi.infinitylearn.com",
        SLMS_BASE_URL: "https://prodslms.ilteacher.com",
        TESTS_BASE_URL: "https://prodslms.ilteacher.com",
        SELFLERN_BASE_URL: "https://selflearnv2api.infinitylearn.com",
    },
};

export const currentApiConstants = apiConstants[projectType];
