import { Pressable, Text, View } from "react-native";
import { ProfileIcon } from "../../../assets/svg";
import { COLOURS } from "../../../constants";
import { useStylesUtils } from "../../../hooks/useStylesUtils";

export const TeamMembersList = () => {
    const { styles, width } = useStylesUtils(getStyles);

    const list = [
        { id: 1, name: "Bunty Sharma", Icon: ProfileIcon },
        {
            id: 2,
            name: "Ranchordas Chachad",
            Icon: ProfileIcon,
        },
        { id: 3, name: "Chanakya Sharma", Icon: ProfileIcon },
        { id: 4, name: "Bunty Sharma", Icon: ProfileIcon },
    ];

    return (
        <View>
            {list.map(({ id, Icon, name }, index, all) => (
                <Pressable
                    key={"members" + id}
                    style={[
                        styles.memberView,
                        { marginBottom: all.length - 1 === index ? 0 : 10 },
                    ]}>
                    <Icon width={width * 0.08} height={width * 0.08} />
                    <Text
                        style={styles.memberText}
                        numberOfLines={1}
                        ellipsizeMode="tail">
                        {name}
                    </Text>
                </Pressable>
            ))}
        </View>
    );
};

const getStyles = {
    memberView: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: 10,
        paddingHorizontal: 20,
        backgroundColor: COLOURS.purple16,
        marginBottom: 10,
        borderRadius: 25,
    },
    memberText: {
        marginLeft: 20,
        color: COLOURS.dark_purple,
        fontSize: 30,
        fontWeight: 700,
    },
};
