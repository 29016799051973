import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
    return (
        <Svg
            width={525}
            height={398}
            viewBox="0 0 525 398"
            fill="#3370A7"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M491.619 386.964C348.774 400.701 176.031 400.701 33.1841 386.964C16.385 385.296 2.34797 370.17 1.93087 353.453C-0.596749 250.256 -0.596749 147.011 1.93087 43.8144C2.34797 27.0979 16.385 11.9705 33.1841 10.3038C176.031 -3.4346 348.773 -3.4346 491.619 10.3038C508.418 11.9705 522.455 27.0979 522.873 43.8144C525.4 147.011 525.4 250.255 522.873 353.453C522.456 370.169 508.418 385.296 491.619 386.964Z"
                stroke="none"
            />
            <Path
                d="M494.129 170.5V225.969"
                fill="none"
                stroke="rgba(255,255,255,0.3)"
                strokeWidth={30}
                strokeLinecap="round"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.5 206C24.6421 206 28 202.642 28 198.5C28 194.358 24.6421 191 20.5 191C16.3579 191 13 194.358 13 198.5C13 202.642 16.3579 206 20.5 206Z"
                fill="rgba(255,255,255,0.3)"
                stroke="none"
            />
        </Svg>
    )
}

export default SvgComponent
