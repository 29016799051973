import React, { useCallback, useEffect, useState } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { useNavigate } from "react-router-native";
import { AuthLayout } from "../../../components";
import { COLOURS, images, navigation } from "../../../constants";
import { useStylesUtils } from "../../../hooks";
import { useAuth } from "../../../hooks/useAuth";
import { Button, Input } from "../components";
import { TitleContent } from "../TitleContent";

export const EnterAdmissionNumber = () => {
    const { styles, biggerSize } = useStylesUtils(getStyles);

    const navigate = useNavigate();
    const { verifyUid, checkToAuthorized } = useAuth();

    const [uid, setUidValue] = useState("");
    const [uidError, setUidError] = useState(false);

    useEffect(() => {
        checkToAuthorized();
    }, []);

    useEffect(() => {
        setUidError(false);
    }, [uid]);

    const onSubmit = async () => {
        if (uidError) {
            return;
        }
        try {
            await verifyUid(uid.trim()?.toLocaleUpperCase());
        } catch (e) {
            setUidError(true);
        }
    };

    const onForgot = useCallback(
        () =>
            navigate(navigation.ENTER_REGISTERED_PHONE, {
                state: { getAdmissionNumbers: true },
            }),
        []
    );

    return (
        <AuthLayout
            headerProps={{ backButton: true, titleContent: <TitleContent /> }}
            showLogo={true}>
            <Text style={styles.label}>
                Please enter your Admission Number to proceed
            </Text>
            <View style={{ alignItems: "flex-start" }}>
                <View style={styles.formContainer}>
                    <Input
                        label={"User ID"}
                        style={styles.input}
                        inputState={{
                            value: uid,
                            setValue: setUidValue,
                            isError: uidError,
                        }}
                    />
                    <Button
                        disabled={uidError || !uid}
                        label={"Submit"}
                        onPress={onSubmit}
                        style={{ marginLeft: biggerSize * 0.005 }}
                    />
                </View>
                {uidError && (
                    <View
                        style={[
                            styles.flexRow,
                            { marginLeft: biggerSize * 0.02 },
                        ]}>
                        <Image
                            style={styles.errorCodeIcon}
                            source={images.importantIcon}
                        />
                        <Text style={styles.errorCodeText}>
                            Incorrect Admission Number
                        </Text>
                    </View>
                )}
                <TouchableOpacity
                    style={[styles.flexRow, { marginLeft: biggerSize * 0.005 }]}
                    onPress={onForgot}>
                    <Text style={[styles.forgotCodeText]}>
                        Don’t know your Admission Number?
                    </Text>
                </TouchableOpacity>
            </View>
        </AuthLayout>
    );
};

const getStyles = ({ biggerSize }) => ({
    container: {
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
    },
    flexRow: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    label: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        marginBottom: 10,
        fontSize: 24,
    },
    formContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: biggerSize * 0.01,
    },
    forgotCodeText: {
        color: COLOURS.dark_purple,
        fontWeight: 700,
        fontSize: 12,
    },
    errorCodeText: {
        color: COLOURS.errorRed,
        fontWeight: 700,
        fontSize: 12,
        marginBottom: biggerSize * 0.01,
    },
    errorCodeIcon: {
        resizeMode: "contain",
        height: biggerSize * 0.015,
        width: biggerSize * 0.015,
        marginBottom: biggerSize * 0.01,
        marginRight: biggerSize * 0.005,
    },
});
