import * as React from "react";
import Svg, { Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const CheckBoxCheckedIcon = (props) => {
    return (
        <Svg
            width={103}
            height={107}
            viewBox="0 0 103 107"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.518 29.38s7.876-1.02 15.716-1.02c8.658 0 17.36 1.02 17.36 1.02 3.418.489 5.864 3.77 6.213 8.42 0 0 .366 5.182.366 10.376 0 5.008-.305 21.271-.305 21.271-.35 4.65-2.935 8.26-5.726 8.42-1.338.158-8.757.506-16.995.51-8.308.004-13.309-.083-16.63-.51-3.32-.427-6.125-3.722-6.212-8.25 0 0-.305-17.59-.305-23.142 0-4.555.244-8.59.244-8.59.174-4.832 2.9-8.016 6.274-8.504z"
                fill="#EDF"
            />
            <Path
                d="M73.807 37.8l1.995-.14v-.01c-.389-5.166-3.218-9.577-7.925-10.25l-.025-.003-.025-.002-.233 1.986.233-1.987h0-.008l-.02-.003-.08-.009-.298-.033a182.84 182.84 0 00-5.104-.472c-3.287-.258-7.683-.517-12.083-.517-3.999 0-7.99.26-10.97.518a150.904 150.904 0 00-4.02.401l-.02-.021-.992.143c-4.572.662-7.778 4.875-7.986 10.388l-.002.027-.008.14-.028.53a160.94 160.94 0 00-.208 7.99c0 2.793.076 8.595.152 13.682a4494.372 4494.372 0 00.14 8.727l.01.568.002.148V69.652l1.995-.034-1.994.034v.004c.102 5.27 3.427 9.612 7.958 10.194h0c3.474.447 8.615.531 16.885.527 8.135-.004 15.571-.342 17.17-.517 2.21-.151 4.023-1.609 5.248-3.382 1.263-1.829 2.098-4.244 2.296-6.88l.005-.057v-.056l-1.999-.038 2 .038h0v-.047l.003-.137.01-.526a4505.718 4505.718 0 00.14-8.053c.075-4.686.152-10.022.152-12.546 0-2.64-.093-5.267-.185-7.23a179.82 179.82 0 00-.17-3.044l-.011-.178-.004-.047v-.016h0v-.001l-1.996.14z"
                stroke="#5F0FAA"
                strokeWidth={4}
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32.773 54.55c2.903 2.01 4.421 2.374 6.923 6.588s2.94 7.081 3.598 7.608c.658.526 3.95-.132 5.662-1.054 1.712-.921 1.776-1.43 3.375-5.168 2.216-5.182 5.815-11.544 8.58-15.232 2.766-3.687 8.588-8.537 8.851-9.458.263-.922-2.502-1.35-5.136-.56-2.633.79-8.181 6.892-11.531 11.693-3.721 5.334-5.044 9.837-5.702 9.705-.659-.131-1.946-2.816-3.789-4.792-1.844-1.975-3.786-3.313-6.025-2.918-2.238.395-6.145 2.661-4.806 3.588"
                fill="#5F0FAA"
            />
            <Path
                d="M32.773 54.55c2.903 2.01 4.421 2.374 6.923 6.588s2.94 7.081 3.598 7.608c.658.526 3.95-.132 5.662-1.054 1.712-.921 1.776-1.43 3.375-5.168 2.216-5.182 5.815-11.544 8.58-15.232 2.766-3.687 8.588-8.537 8.851-9.458.263-.922-2.502-1.35-5.136-.56-2.633.79-8.181 6.892-11.531 11.693-3.721 5.334-5.044 9.837-5.702 9.705-.659-.131-1.946-2.816-3.789-4.792-1.844-1.975-3.786-3.313-6.025-2.918-2.238.395-6.145 2.661-4.806 3.588"
                stroke="#5F0FAA"
                strokeWidth={2}
            />
        </Svg>
    );
};
export const CheckBoxUnCheckedIcon = (props) => {
    return (
        <Svg
            width={103}
            height={107}
            viewBox="0 0 103 107"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.518 29.38s7.877-1.02 15.716-1.02c8.658 0 17.36 1.02 17.36 1.02 3.418.489 5.864 3.77 6.214 8.42 0 0 .365 5.182.365 10.376 0 5.008-.304 21.271-.304 21.271-.35 4.65-2.936 8.26-5.726 8.42-1.34.158-8.757.506-16.995.51-8.308.004-13.31-.083-16.63-.51-3.32-.427-6.126-3.722-6.213-8.25 0 0-.305-17.59-.305-23.142 0-4.555.244-8.59.244-8.59.175-4.832 2.9-8.016 6.274-8.504z"
                fill="#EDF"
            />
            <Path
                d="M73.808 37.8l1.995-.14v-.01c-.39-5.166-3.218-9.577-7.926-10.25l-.025-.003-.025-.002-.233 1.986.233-1.987h0-.008l-.02-.003-.079-.009-.299-.033a182.725 182.725 0 00-5.104-.472c-3.286-.258-7.683-.517-12.083-.517-3.998 0-7.99.26-10.97.518a150.851 150.851 0 00-4.019.401l-.021-.021-.992.143c-4.572.662-7.777 4.875-7.986 10.388l-.001.027-.008.14a157.943 157.943 0 00-.113 2.439c-.062 1.595-.123 3.77-.123 6.08 0 2.794.076 8.596.152 13.683a4654.492 4654.492 0 00.14 8.727l.009.568.003.148V69.652l1.994-.034-1.994.034v.004c.102 5.27 3.427 9.612 7.958 10.194h0c3.474.447 8.615.531 16.886.527 8.134-.004 15.57-.342 17.17-.517 2.209-.151 4.022-1.609 5.247-3.382 1.264-1.829 2.099-4.244 2.297-6.88l.004-.057.001-.056-2-.038 2 .038h0V69.471l.001-.034.003-.137.01-.526a4505.718 4505.718 0 00.139-8.053c.076-4.686.152-10.022.152-12.546 0-2.64-.093-5.267-.185-7.23a179.82 179.82 0 00-.169-3.044l-.012-.178-.003-.047v-.013l-.001-.003h0v-.001l-1.995.14z"
                stroke="#DEBEFF"
                strokeWidth={4}
            />
        </Svg>
    );
};
