import { View, Text } from "react-native";
import { useStylesUtils } from "../../../../hooks";
import moment from "moment/moment";
import { COLOURS } from "../../../../constants";

export const CategorizedText = ({ lable, text, isDate, containerStyle }) => {
    const { styles, width } = useStylesUtils(getStyles);
    return (
        <View style={{ ...styles.container, ...containerStyle }}>
            <Text style={styles.lable}>{lable}:</Text>
            {isDate ? (
                <Text style={styles.text}>
                    {moment(text).format("DD-MM-YYYY")}
                </Text>
            ) : (
                <Text style={styles.text}>{text}</Text>
            )}
        </View>
    );
};

const getStyles = ({ height, width }) => ({
    container: {
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "row",
    },
    lable: {
        fontSize: 18,
        fontWeight: 500,
        marginRight: width * 0.005,
        lineHeight: 8,
        color: COLOURS.blue4,
    },
    text: {
        fontWeight: 700,
        fontSize: 18,
        lineHeight: 8,
        color: COLOURS.blue4,
    },
});
