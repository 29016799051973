import { remapMatchQuestion } from "../utils/QuizParser";
import { findAnswer, getUniqColor } from "../utils/QuizUtils";
import { QUIZ_QUESTION_STATUS } from "../../../../constants";
import _ from "lodash";

class MatchTheOptionsFactory {
    static initialAnswer = {
        currentPickedOption: {},
        options: [],
    };
    static types = ["a", "b"];

    ///////////////////////
    static calcQuestion(question, answers, isFinished) {
        return {
            status: this.getQuestionStatus(question, answers, isFinished),
            mark: this.getQuestionMark(question, answers, isFinished),
        };
    }

    static getQuestionMark(question, answers, isFinished) {
        return 0;
    }

    static getQuestionStatus(question, answers, isFinished) {
        if (!question || !answers) {
            return isFinished
                ? QUIZ_QUESTION_STATUS.ERROR
                : QUIZ_QUESTION_STATUS.NEW;
        }

        const answer = findAnswer(answers, question.question_id);

        if (isFinished) {
            const [a, b] = question.answer_options.map(remapMatchQuestion);

            if (answer?.options.length) {
                if (
                    answer?.options.length === b.length &&
                    answer?.options.every((item) => item.isCorrect)
                ) {
                    return QUIZ_QUESTION_STATUS.SUCCESS;
                } else if (answer?.options.some((item) => item.isCorrect)) {
                    return QUIZ_QUESTION_STATUS.SEMI_ERROR;
                }
            }
            return QUIZ_QUESTION_STATUS.ERROR;
        }

        if (answer?.options.length) {
            return QUIZ_QUESTION_STATUS.VISITED;
        }

        return QUIZ_QUESTION_STATUS.NEW;
    }

    static prepareQuestionData(question, answers, setAnswers, isFinished) {
        const answer = findAnswer(answers, question.question_id) || {};

        return {
            ...answer,
            list: this.prepareListData(
                question,
                answer,
                setAnswers,
                isFinished
            ),
        };
    }

    static prepareListData(question, answer, setAnswers, isFinished) {
        const [a, b] = this.prepareListOfOptions(
            question,
            answer,
            setAnswers,
            isFinished
        );

        return {
            a,
            b,
        };
    }

    static prepareListOfOptions(question, answer, setAnswers, isFinished) {
        const { answer_options, correct_answer, question_id } = question;

        return answer_options.map((item, listIndex) => {
            const type = this.types[listIndex];

            return remapMatchQuestion(item).map((item, index) => {
                const correctIndex = this.getCurrentCorrectIndex(
                    correct_answer,
                    type,
                    index
                );

                const id = this.createId(
                    question_id,
                    type,
                    index,
                    correctIndex
                );

                const findInChooses = answer?.options?.find(
                    (choose) => choose[type] === index
                );

                const createNewItem = {
                    id,
                    index,
                    correctIndex,
                    text: item,
                    type,
                    color: this.getOptionColor(
                        answer,
                        type,
                        index,
                        findInChooses
                    ),
                    isCorrect: this.checkIsCorrect(
                        isFinished,
                        findInChooses,
                        type
                    ),
                };

                const onOptionPick = this.getOnOptionPickHandler(
                    createNewItem,
                    question,
                    setAnswers,
                    isFinished
                );

                createNewItem.onOptionPick = onOptionPick;

                return createNewItem;
            });
        });
    }

    static getOptionColor(answer, type, id, findInChooses) {
        if (answer?.currentPickedOption?.[type] === id) {
            return answer.currentPickedOption.color;
        } else if (findInChooses) {
            return findInChooses.color;
        }

        return undefined;
    }

    static getOnOptionPickHandler(item, question, setAnswers, isFinished) {
        if (isFinished) {
            return () => {};
        }

        return () => {
            setAnswers((prevState) => {
                const answer = findAnswer(prevState, question.question_id);

                if (!answer) {
                    prevState.push({
                        question_id: question.question_id,
                        currentPickedOption: {
                            [item.type]: item.index,
                            color: getUniqColor([]),
                        },
                        options: [],
                    });
                    return this.checkAnswersState(question, [...prevState]);
                }

                let { currentPickedOption = {}, options = [] } = answer;

                currentPickedOption = {
                    ...currentPickedOption,
                    [item.type]: item.index,
                    color: currentPickedOption?.color || getUniqColor(options),
                };

                const { a = null, b = null } = currentPickedOption;

                const find = options.findIndex(
                    ({ a: prevA, b: prevB }) => a === prevA || b === prevB
                );

                if (find >= 0) {
                    options = options.filter((value, i) => find !== i);
                }

                if (a !== null && b !== null) {
                    options = [...options, { ...currentPickedOption }];
                    currentPickedOption = null;
                }

                prevState = prevState.map((item) =>
                    answer.question_id === item.question_id
                        ? { ...answer, currentPickedOption, options }
                        : item
                );

                return this.checkAnswersState(question, [...prevState]);
            });
        };
    }

    static getCurrentCorrectIndex(correctAnswers, type, index) {
        if (this.isTypeB(type)) {
            return Number(correctAnswers[index]) - 1;
        }

        return correctAnswers.findIndex(
            (answerIndex) => Number(answerIndex) - 1 === index
        );
    }

    static checkIsCorrect(isFinished, find, type) {
        if (this.isTypeA(type) || !isFinished) {
            return undefined;
        }

        return !!find?.isCorrect;
    }

    static checkAnswersState(question, state = []) {
        const answer = findAnswer(state, question?.question_id);

        if (!question || !state?.length || !answer?.options?.length) {
            return state;
        }

        const { answer_options, correct_answer } = question;

        const optionsResponse = correct_answer.map((value, i) => {
            const find = answer.options.find(({ a, b }) => a === i);

            return find ? `${find.b + 1}` : "";
        });

        //
        answer.correct = _.isEqual(optionsResponse, correct_answer);
        answer.response = optionsResponse.join("~`");

        return state;
    }

    static isTypeA(type) {
        const [a] = this.types;
        return type === a;
    }

    static isTypeB(type) {
        const [a, b] = this.types;
        return type === b;
    }

    static getOppositeType(type) {
        const [a, b] = this.types;
        return this.isTypeA(type) ? b : a;
    }

    static createId(...rest) {
        return `${rest.join("~`")}`;
    }

    static parseQuestionAnswer(question) {}
}

export default MatchTheOptionsFactory;
