import { View, Text, Pressable, ActivityIndicator } from "react-native";
import { useStylesUtils } from "../../hooks";
import { COLOURS } from "../../constants";
import { CardsImage } from "./components/CardsImage";
import { FileIcon } from "../../assets/svg";
import { fillColorUtils } from "../../utils/fillColor.utils";

export const FileCard = ({
    title = "cardTitle",
    onPress,
    image,
    isActive,
    colour = "transparent",
}) => {
    const { styles, biggerSize } = useStylesUtils(getStyles);
    return (
        <View style={[styles.container]}>
            <Pressable style={styles.bookContainer} onPress={onPress}>
                <FileIcon
                    width={biggerSize * 0.23}
                    height={biggerSize * 0.24}
                    fill={fillColorUtils(isActive, colour)}
                />
                <View style={styles.activityIndicator}>
                    <ActivityIndicator size="large" color={COLOURS.orange} />
                </View>
                <CardsImage
                    source={image}
                    style={styles.cardImage}
                    resizeMode={"stretch"}
                />
            </Pressable>
            <Text style={styles.title} numberOfLines={1} ellipsizeMode="tail">
                {title}
            </Text>
        </View>
    );
};

const getStyles = ({ biggerSize }) => ({
    container: {
        marginRight: biggerSize * 0.02,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    bookContainer: {
        marginBottom: 15,
        overflow: "hidden",
    },
    cardImage: {
        position: "absolute",
        top: biggerSize * 0.01,
        left: biggerSize * 0.03,
        width: biggerSize * 0.17,
        height: biggerSize * 0.223,
        zIndex: -10,
        borderRadius: biggerSize * 0.02,
    },
    activityIndicator: {
        position: "absolute",
        top: biggerSize * 0.01,
        left: biggerSize * 0.03,
        width: biggerSize * 0.17,
        height: biggerSize * 0.223,
        zIndex: -15,
        borderRadius: biggerSize * 0.02,
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: "center",
    },
    title: {
        fontSize: 20,
        textAlign: "center",
        fontWeight: 700,
        color: COLOURS.dark_purple,
        maxWidth: biggerSize * 0.21,
    },
});
