import React, { useEffect } from "react";
import { MainLayout } from "../../../components";
import { ContentContainer } from "./ContentContainer";
import { resetTestState } from "../../../store/tests/slice";
import { useDispatch } from "react-redux";
import { FilterContainer } from "./filterContainer";
import { testTabs } from "../../../components/tabs/testTabs";

export const TestScreen = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetTestState());
            // dispatch(resetTestFilter());
        };
    }, []);

    return (
        <MainLayout
            viewStyle={{ zIndex: 15 }}
            tabs={testTabs()}
            headerProps={{
                backButton: true,
                homeButton: true,
                showBgCloud: true,
            }}>
            <FilterContainer />
            <ContentContainer />
        </MainLayout>
    );
};
