import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-native";
import { CarrouselComponent, MainLayout } from "../../../components";
import {
    letsCreateIcons,
    navigation,
    letsLearnIcons,
} from "../../../constants";
import { usePermissions, useRead, useLearnHook } from "../../../hooks";
import { useCreateHook } from "../../../hooks/useCreateHook";
import { getPlaylistsForUserRedux } from "../../../store/main/selectors";
import { CardView } from "./CardView";
import { TitleContent } from "./TitleContent";
import { getDeepCopy, parseListsUtils } from "../../../utils/parse-lists.utils";
import { letsReedCategoriesNames, letsReedGetIcon } from "../LetsRead/optons";

export const LetsExploreScreen = () => {
    const { state } = useLocation();
    const { getStory, getStorysName } = useCreateHook();
    const playListData = useSelector(getPlaylistsForUserRedux);
    const { canSeeTracingBoard } = usePermissions();
    const { getVideosFor } = useRead();
    const { getVideoPlaylist } = useLearnHook();

    const getStoryName = (storyId) => {
        const name = state.find((story) => story?.storyId === storyId);
        return name?.title;
    };

    const isArtVisible = () => {
        const isArtCraft = playListData?.find(
            (playlist) => playlist.title === "Art and Craft"
        );
        return !!isArtCraft;
    };

    const createList = [
        {
            id: 4390,
            title: getStoryName(4390),
            image: letsCreateIcons.DrawingImage,
            titleContent: <TitleContent />,
            isVisible: true,
            onPress: () => getStory({ params: { storyId: 4390 } }),
        },
        {
            id: 4391,
            title: getStoryName(4391),
            image: letsCreateIcons.ColoringImage,
            titleContent: <TitleContent />,
            isVisible: true,
            onPress: () => getStory({ params: { storyId: 4391 } }),
        },
        {
            id: 1,
            title: "Tracing\nBoard",
            image: letsCreateIcons.TracingImage,
            titleContent: <TitleContent />,
            isVisible: canSeeTracingBoard,
            onPress: () =>
                getStorysName({
                    params: [4334, 4392, 4393, 4394],
                    location: navigation.TRACING_BOARD_SCREEN,
                }),
        },
        {
            id: 2,
            title: "Art &\nCraft",
            image: letsCreateIcons.ArtCraft,
            titleContent: <TitleContent />,
            isVisible: isArtVisible(),
            onPress: () =>
                getVideosFor(47, "Art & Craft", letsCreateIcons.ArtCraft),
        },
    ].filter((el) => el.isVisible);

    const letsLearnCategories = [
        {
            id: 22,
            title: "Music",
            onPress: getVideoPlaylist,
            image: letsLearnIcons.Music,
        },
        {
            id: 23,
            title: "Dance",
            onPress: getVideoPlaylist,
            image: letsLearnIcons.Dance,
        },
        {
            id: 24,
            title: "Abacus",
            onPress: getVideoPlaylist,
            image: letsLearnIcons.Abacus,
        },
    ];

    const parseList = useMemo(() => {
        const apiResultCopy = parseListsUtils(getDeepCopy(playListData));

        const letsReadListParse = letsReedCategoriesNames.reduce(
            (acc, value) => {
                const find = apiResultCopy?.find(({ title }) =>
                    title.toLowerCase().includes(value)
                );

                if (find) {
                    acc.push({
                        ...find,
                        title: value,
                        image: letsReedGetIcon(value),
                        onPress: () =>
                            getVideosFor(
                                find?.id,
                                value,
                                letsReedGetIcon(value)
                            ),
                    });
                }

                return acc;
            },
            []
        );

        const letsLearnCategoriesParse = letsLearnCategories.reduce(
            (acc, value) => {
                const find = apiResultCopy?.find((item) =>
                    item.title
                        .toLocaleLowerCase()
                        .split(" ")
                        .includes(value.title.toLocaleLowerCase())
                );

                if (find) {
                    value.params = { playlistId: find.id };
                    acc.push(value);
                }

                return acc;
            },
            []
        );

        return [
            ...createList,
            ...letsReadListParse,
            ...letsLearnCategoriesParse,
        ].map((item) => ({ ...item, key: item?.id + item.title }));
    }, [playListData]);

    return (
        <MainLayout
            headerProps={{
                backButton: true,
                homeButton: true,
                titleContent: <TitleContent />,
            }}>
            <CarrouselComponent data={parseList} CardView={CardView} />
        </MainLayout>
    );
};
