import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={94}
            height={88}
            viewBox="0 0 94 88"
            fill="#fff"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.72.97C64.165.97 94 14.94 94 39.385 94 63.828 71.68 87.82 47.238 87.82 22.793 87.819.472 63.828.472 39.384.472 14.941 15.277.971 39.721.971z"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.024 6.063c21.291 0 47.28 12.168 47.28 33.46S66.863 81.712 45.57 81.712c-21.291 0-40.734-20.897-40.734-42.19 0-21.29 12.896-33.46 34.188-33.46z"
                fill="#073763"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.458 42.126a209.926 209.926 0 00-.012 1.661c-.007 3.438 1.73 6.594 4.352 8.946 2.312 2.067 5.264 3.495 8.46 3.8a1329.653 1329.653 0 00.065 5.724 193.622 193.622 0 01-6.288-.143c-.692-.026-1.203.495-1.134 1.169.069.667.675 1.248 1.346 1.279 4.9.222 9.747.222 14.647 0 .664-.03 1.272-.612 1.34-1.28.07-.672-.443-1.194-1.129-1.167-2.102.081-4.198.13-6.293.143.025-1.9.047-3.805.064-5.713v-.011c3.203-.306 6.154-1.734 8.467-3.801 2.617-2.352 4.359-5.508 4.351-8.946 0-.252 0-.504-.002-.756l-.004-.424c-.01-1.14-.037-2.28-.076-3.42-.024-.663-.657-1.22-1.405-1.233-.742-.015-1.33.524-1.31 1.194a161.444 161.444 0 01.068 4.638c.004 2.8-1.364 5.353-3.456 7.245-2.102 1.884-4.902 3.09-7.939 3.09-3.042 0-5.84-1.206-7.939-3.09-2.098-1.892-3.459-4.446-3.455-7.245 0-.254 0-.508.002-.762l.007-.867c.01-1.003.03-2.006.059-3.009.018-.67-.569-1.209-1.317-1.194-.742.014-1.374.57-1.398 1.234-.035.979-.059 1.958-.071 2.938m14.112-19.08c-4.05.005-7.799 3.604-8.108 7.778a226.224 226.224 0 00-.321 7.704 224.068 224.068 0 00-.064 4.566c0 .23-.002.46-.001.692.012 4.19 3.92 7.697 8.494 7.704 4.567-.006 8.483-3.514 8.495-7.704l-.001-.693a221.215 221.215 0 00-.385-12.269c-.31-4.174-4.064-7.773-8.109-7.778"
                fill="#fff"
            />
        </Svg>
    );
}

export default SvgComponent;
