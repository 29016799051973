import { BlurView } from "expo-blur";
import React from "react";
import {
    Platform,
    StyleSheet,
    TouchableNativeFeedback,
    TouchableOpacity,
    TouchableWithoutFeedback,
    View,
} from "react-native";
import { useStylesUtils } from "../../hooks";

const { OS } = Platform;

export const OverLayout = ({ children, ...rest }) => {
    const { styles } = useStylesUtils(getStyles);

    const TouchableEffect = ({ children, style, ...props }) => {
        if (OS === "android") {
            return (
                <TouchableNativeFeedback {...props}>
                    <View style={style}>{children}</View>
                </TouchableNativeFeedback>
            );
        }

        if (OS === "web") {
            return (
                <TouchableWithoutFeedback {...props} style={{ flex: 1 }}>
                    <View
                        style={[
                            style,
                            {
                                backdropFilter: "blur(5px)",
                            },
                        ]}>
                        <TouchableWithoutFeedback onPress={() => {}}>
                            {children}
                        </TouchableWithoutFeedback>
                    </View>
                </TouchableWithoutFeedback>
            );
        }

        return (
            <BlurView intensity={50} style={{ flex: 1 }}>
                <TouchableOpacity {...props} style={style}>
                    {children}
                </TouchableOpacity>
            </BlurView>
        );
    };

    return (
        <View style={[styles.container, StyleSheet.absoluteFill]}>
            <TouchableEffect activeOpacity={1} {...rest}>
                {children}
            </TouchableEffect>
        </View>
    );
};

const getStyles = ({ isIOS }) => ({
    container: {
        position: "absolute",
        backgroundColor: isIOS ? "" : "rgba(255,255,255, 0.8)",
        flex: 1,
        justifyContent: "center",
        flexDirection: "row",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
});
