import { Platform } from "react-native";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-native";
import { navigation } from "../constants";
import { getUserInfoForQueries, getMappingInfo } from "../store/auth/selectors";
import {
    getBooksAndAssetCountThunk,
    getBooksForSubtenantThunk,
    getChaptersForExamSubjectSubtenantThunk,
    getChaptersThunk,
    getPlaylistsForThunk,
    getPlaylistsForUserThunk,
    getStoryThunk,
    getSubjectsForExamThunk,
    getUserStoriesAndDetailsForChapterThunk,
    getVideoPlaylistThunk,
} from "../store/main/thunks";
import { usePopups } from "./usePopups";
import { useAuth } from "./useAuth";
import { cookiesSeter } from "../utils/cookiesUtil";

export const useLearnHook = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { getMainListError, getListError } = usePopups();
    const { checkRejectResponse } = useAuth();

    const { subtenantId, tenantId } = useSelector(getUserInfoForQueries);
    const { cmsGradeId, cmsExamId } = useSelector(getMappingInfo);

    const getBooksAndAssetCount = useCallback(
        async (data = {}) => {
            const params = {
                subtenantId,
                examId: cmsExamId,
                subjectId: 1,
                ...data,
            };
            const result = await dispatch(getBooksAndAssetCountThunk(params));

            if (getBooksAndAssetCountThunk.rejected.match(result)) {
                await checkRejectResponse(result);
                getMainListError({ result });
            }
        },
        [subtenantId, cmsExamId]
    );

    const getStories = useCallback(
        async (data) => {
            const params = {
                chapterId: 115,
                examId: cmsExamId,
                gradeId: cmsGradeId,
                ...(data.params ?? {}),
            };

            const result = await dispatch(
                getUserStoriesAndDetailsForChapterThunk(params)
            );

            if (
                getUserStoriesAndDetailsForChapterThunk.rejected.match(result)
            ) {
                await checkRejectResponse(result);
                getMainListError({ result });
            }
        },
        [cmsExamId, cmsGradeId]
    );

    const getBooksForTenantId = useCallback(async (data) => {
        const params = {
            subtenantIds: ["406"],
            ...(data.params ?? {}),
        };

        const result = await dispatch(getBooksForSubtenantThunk(params));

        if (getBooksForSubtenantThunk.rejected.match(result)) {
            await checkRejectResponse(result);
            getMainListError({ result });
        }
    }, []);

    const getSubjectsForExam = useCallback(
        async (data = {}) => {
            const params = {
                subtenantId,
                examId: cmsExamId,
                ...(data.params ?? {}),
            };

            const result = await dispatch(getSubjectsForExamThunk(params));

            if (getSubjectsForExamThunk.fulfilled.match(result)) {
                const list =
                    result?.payload?.data?.childNodes?.filter(
                        (subj) => subj?.examSubjectStatusName === "Active"
                    ) ?? null;
                const subjectId = list?.[0]?.nodeId;
                if (subjectId) {
                    await dispatch(
                        getBooksAndAssetCountThunk({ ...params, subjectId })
                    );
                }

                if (list.length > 0) {
                    navigate(navigation.BOOKS_SCREEN);
                } else {
                    getListError({
                        title: "We dont have any available books for you",
                    });
                }

                return Promise.resolve(result);
            }

            if (getSubjectsForExamThunk.rejected.match(result)) {
                await checkRejectResponse(result);
                getMainListError({ result });
            }
        },
        [subtenantId, cmsExamId]
    );

    const getSubjects = useCallback(
        async (data = {}) => {
            const { title = "", ...rest } = data;
            const params = {
                subtenantId,
                examId: cmsExamId,
                ...rest,
            };

            const result = await dispatch(
                getChaptersForExamSubjectSubtenantThunk(params)
            );

            if (
                getChaptersForExamSubjectSubtenantThunk.fulfilled.match(result)
            ) {
                navigate(navigation.SUBJECTS_SCREEN, { state: { title } });
                return Promise.resolve(result);
            }

            if (
                getChaptersForExamSubjectSubtenantThunk.rejected.match(result)
            ) {
                await checkRejectResponse(result);
                getMainListError({ result });
            }
        },
        [subtenantId, cmsExamId]
    );

    const getPlaylistsFor = useCallback(
        async ({ params = {} }) => {
            const body = {
                examId: cmsExamId,
                subtenantId,
                status: "active",
                // examNodeIds: '35845',
                storyType: "Articulate",
                ...params,
            };

            const result = await dispatch(getPlaylistsForThunk(body));

            if (getPlaylistsForThunk.fulfilled.match(result)) {
                const data = result.payload.data;
                if (data) {
                    const findMusic = data.find((item) =>
                        item.title
                            .toLocaleLowerCase()
                            .split(" ")
                            .includes("music")
                    );

                    if (findMusic) {
                        await getVideoPlaylist({
                            params: { playlistId: findMusic.playlistId },
                        });
                    } else {
                        notFoundPlayList();
                    }
                } else {
                    notFoundPlayList();
                }

                return Promise.resolve(result);
            }

            if (getPlaylistsForThunk.rejected.match(result)) {
                await checkRejectResponse(result);
                notFoundPlayList();
            }
        },
        [subtenantId, cmsExamId]
    );

    const getPlaylistsForUser = useCallback(async () => {
        const params = {
            pageNumber: 0,
            pageSize: 50,
            sortOrder: "desc",
            tenantIds: tenantId,
            gradeIds: cmsGradeId,
            playlistTypeId: 1,
        };

        const result = await dispatch(getPlaylistsForUserThunk({ params }));

        if (getPlaylistsForUserThunk.rejected.match(result)) {
            await checkRejectResponse(result);
        }
    }, [tenantId, cmsExamId]);

    const getVideoPlaylist = useCallback(async ({ params = {} }) => {
        const result = await dispatch(getVideoPlaylistThunk(params.playlistId));

        if (getVideoPlaylistThunk.fulfilled.match(result)) {
            navigate(navigation.PLAYLIST);
        }

        if (getVideoPlaylistThunk.rejected.match(result)) {
            await checkRejectResponse(result);
            notFoundPlayList();
        }
    }, []);

    const getChapter = useCallback(
        async ({ params = {}, chapterName = "" }) => {
            const body = {
                examId: cmsExamId,
                subtenantId,
                ...params,
            };

            const result = await dispatch(getChaptersThunk(body));

            if (getChaptersThunk.fulfilled.match(result)) {
                navigate(navigation.CHAPTERS_SCREEN, {
                    state: { title: chapterName },
                });
            }

            if (getChaptersThunk.rejected.match(result)) {
                await checkRejectResponse(result);
            }
        },
        [subtenantId, cmsExamId]
    );

    const getStory = useCallback(
        async ({ params = {} }) => {
            const result = await dispatch(
                getStoryThunk({
                    ...params,
                    tenantId,
                })
            );

            if (getStoryThunk.fulfilled.match(result)) {
                if (result != null) {
                    const url = new URL(result.payload?.storyURL);
                    const hostname = url.host;
                    const regex = /^[^.]+\./;
                    const editedHostname = hostname.replace(regex, "");
                    // console.log("hostname", hostname);
                    // console.log("editedHostname", editedHostname);

                    const state = {
                        policy: result.payload.policy,
                        keyPairId: result.payload.keyPairId,
                        signature: result.payload.signature,
                        uri: result.payload.storyURL,
                        hostname: editedHostname,
                    };

                    if (Platform.OS === "web") {
                        cookiesSeter(
                            state.policy,
                            state.keyPairId,
                            state.signature
                        );
                        setTimeout(() => {
                            //window.open(state.uri, "_blank");
                            navigate(navigation.WEB_STORY_SCREEN, {
                                state,
                            });
                        }, 1000);
                        // navigate(navigation.WEB_STORY_SCREEN, {
                        //     state,
                        // });
                    } else {
                        navigate(navigation.STORY_SCREEN, { state });
                    }
                }
            }
            if (getStoryThunk.rejected.match(result)) {
                await checkRejectResponse(result);
            }
        },
        [tenantId]
    );

    const notFoundPlayList = () =>
        getListError({
            title: "Not found",
            content: "We don't found an available music playlist for you.",
        });

    return {
        getBooksAndAssetCount,
        getStories,
        getBooksForTenantId,
        getSubjectsForExam,
        getPlaylistsFor,
        getSubjects,
        getVideoPlaylist,
        getChapter,
        getStory,
        getPlaylistsForUser,
    };
};
