import React, { useCallback, useContext } from "react";
import { Image, TouchableOpacity, View } from "react-native";
import { useNavigate } from "react-router-native";
import { BackArrowIcon, HomeIcon, SearchIcon } from "../../../assets/svg";
import { COLOURS, navigation } from "../../../constants";
import { useHaptic, usePopups, useStylesUtils } from "../../../hooks";
import { useModal } from "../../../hooks/useModal";
import { useNotification } from "../../../hooks/useNotification";
import { HeaderBG } from "./HeaderBG";
import { HeaderTitle } from "./HeaderTitle";
import { ProfileIcon } from "../../ProfileIcon";
import GlobalAudioContext from "../../../providers/GlobalAudioContext";

export const HeaderComponent = ({
    hide = false,
    backButton = false,
    notificationButton = false,
    profileButton = false,
    searchButton = false,
    homeButton = false,
    showBgCloud = true,
    shortcutBtns,
    titleContent,
    tabsContent,
    rightBlockContent = null,
    leftBlockContent = null,
    style = {},
}) => {
    const { styles, imageSizes, biggerSize } = useStylesUtils(getStyles);
    const { playClickSound } = useContext(GlobalAudioContext);

    const navigate = useNavigate();
    const { openProfileModal } = useModal();
    const { openNotificationLayout } = useNotification();
    const { loginError } = usePopups();
    const { hapticImpact } = useHaptic();

    const pressBack = useCallback(() => {
        hapticImpact(1);
        playClickSound();

        typeof backButton === "function"
            ? backButton({ goBack: (config) => navigate(-1, config) })
            : navigate(-1);
    }, [backButton]);
    const pressProfile = useCallback(() => {
        playClickSound();
        hapticImpact(1);
        openProfileModal();
    }, []);
    const pressNotification = useCallback(() => {
        playClickSound();
        hapticImpact(1);
        // openNotificationLayout();
        navigate(navigation.COMING_SOON_SCREEN);
    }, []);
    const pressSearch = useCallback(() => {
        playClickSound();
        hapticImpact(1);
        // navigate(navigation.SEARCH_SCREEN);
        navigate(navigation.COMING_SOON_SCREEN);
    }, []);
    const pressHome = useCallback(() => {
        playClickSound();
        hapticImpact(1);
        navigate(navigation.HOME);
    }, []);

    if (hide) {
        return <></>;
    }

    return (
        <HeaderBG showBgCloud={showBgCloud} style={style}>
            {!titleContent && shortcutBtns && (
                <View style={styles.shortcutContainer}>{shortcutBtns}</View>
            )}
            <View
                style={[
                    styles.leftContainer,
                    { minWidth: imageSizes.standard.width * 1.5 },
                ]}>
                {backButton && (
                    <TouchableOpacity
                        style={styles.backButton}
                        onPress={pressBack}>
                        <BackArrowIcon
                            {...imageSizes.standard}
                            style={{ marginRight: 10, marginLeft: 10 }}
                        />
                    </TouchableOpacity>
                )}
                {profileButton && (
                    <TouchableOpacity
                        style={styles.button}
                        onPress={pressProfile}>
                        <ProfileIcon />
                    </TouchableOpacity>
                )}
                {leftBlockContent}
            </View>
            {!shortcutBtns && <HeaderTitle>{titleContent}</HeaderTitle>}
            {tabsContent && (
                <HeaderTitle>
                    <View
                        style={styles.buttonContainer}
                        pointerEvents="box-none">
                        {tabsContent}
                    </View>
                </HeaderTitle>
            )}
            <View
                style={[
                    styles.rightContainer,
                    { minWidth: imageSizes.standard.width * 1.5 },
                ]}>
                {notificationButton && (
                    <TouchableOpacity
                        style={styles.button}
                        onPress={pressNotification}>
                        <Image
                            source={require("../../../assets/images/header/bell.png")}
                            style={{
                                ...imageSizes.standard,
                                resizeMode: "contain",
                            }}
                        />
                    </TouchableOpacity>
                )}
                {searchButton && (
                    <TouchableOpacity
                        style={styles.button}
                        onPress={pressSearch}>
                        <SearchIcon {...imageSizes.standard} />
                    </TouchableOpacity>
                )}
                {homeButton && (
                    <TouchableOpacity style={styles.button} onPress={pressHome}>
                        <HomeIcon {...imageSizes.standard} />
                    </TouchableOpacity>
                )}
                {rightBlockContent}
            </View>
        </HeaderBG>
    );
};

const getStyles = ({ height, baseStyles }) => ({
    leftContainer: {
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: 10,
    },
    rightContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        paddingLeft: 10,
        paddingRight: 10,
    },
    title: {
        marginTop: 16,
        paddingVertical: 8,
        borderWidth: 4,
        borderColor: COLOURS.blackLight,
        borderRadius: 6,
        backgroundColor: COLOURS.lightBlue2,
        color: COLOURS.blackLight,
        textAlign: "center",
        fontSize: 30,
    },
    button: {
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
    },
    backButton: {
        flexDirection: "row",
    },
    profileButton: {
        backgroundColor: COLOURS.lightBlue3,
        borderRadius: 999,
    },
    shortcutContainer: {
        zIndex: -1,
        position: "absolute",
        width: "100%",
        height: height * 0.2,
        left: 100,
        flexDirection: "row",
    },
    buttonContainer: {
        top: 20,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-end",
        backgroundColor: "red",
    },
});
