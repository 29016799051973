import React from "react";
import { View } from "react-native";
import { Button } from "../index";
import { useStylesUtils } from "../../hooks";

export const LayoutTabs = ({ list = [] }) => {
    const { isPad } = useStylesUtils();

    return (
        <View
            style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-end",
                bottom: "-35%",
            }}>
            {list.map(({ id, style = {}, ...rest }) => (
                <Button
                    key={"tab" + id}
                    type={"tab"}
                    style={{
                        transform: isPad ? [{ scale: 0.9 }] : [],
                        ...style,
                    }}
                    {...rest}
                />
            ))}
        </View>
    );
};
