import { View, TouchableOpacity, ScrollView, Text } from "react-native";
import { DashedLine, MainLayout } from "../../../components";
import { TitleContent } from "./TitleContent";
import { useStylesUtils } from "../../../hooks";
import { CategorizedText } from "./components/CategorizedText";
import React, { useCallback, useState } from "react";
import { COLOURS } from "../../../constants";
import { PhotoEditIcon } from "../../../assets/svg";
import { ParentsDetails } from "./tabs/ParentsDetails";
import { AddressDetails } from "./tabs/AddressDetails";
import { DeviceSettings } from "./tabs/DeviceSettings";
import { UserSettings } from "./tabs/UserSettings";

const demoUser = {
    name: "student name",
    birthdate: new Date(Date.now()),
    bloodGroup: "AB +ve",
    class: "II",
    section: "A",
    orientation: new Date(Date.now()),
    batch: "AB +ve",
    image: null,
};
const tabs = [
    {
        title: "Parents details",
        id: "parents_details",
    },
    {
        title: "Address details",
        id: "address_details",
    },

    {
        title: "Device settings",
        id: "device_settings",
    },

    {
        title: "User Settings",
        id: "user_settings",
    },
];
export const ProfileScreen = () => {
    const { styles, width, imageSizes, height } = useStylesUtils(getStyles);

    const [currentTab, setCurrentTab] = useState("parents_details");

    const onTabSelected = (tabId) => {
        setCurrentTab(tabId);
    };

    const getCurrentTab = useCallback(() => {
        switch (currentTab) {
            case "parents_details":
                return <ParentsDetails />;
            case "address_details":
                return <AddressDetails />;
            case "device_settings":
                return <DeviceSettings />;
            case "user_settings":
                return <UserSettings />;
        }
    }, [currentTab]);

    return (
        <MainLayout
            headerProps={{
                showBgCloud: true,
                backButton: true,
                searchButton: true,
                titleContent: <TitleContent />,
            }}>
            <ScrollView
                showsHorizontalScrollIndicator={true}
                showsVertivalScrollIndicator={true}>
                <View style={styles.contentContainer}>
                    <View style={styles.profileInfo}>
                        <TouchableOpacity>
                            {demoUser.image ? (
                                <>
                                    <View></View>
                                </>
                            ) : (
                                <PhotoEditIcon {...imageSizes.standard} />
                            )}
                        </TouchableOpacity>
                        <View>
                            <CategorizedText
                                lable={"Name"}
                                text={demoUser.name}
                            />
                            <CategorizedText
                                isDate={true}
                                lable={"Date of Birth"}
                                text={demoUser.birthdate}
                            />
                            <CategorizedText
                                lable={"Blood Group"}
                                text={demoUser.bloodGroup}
                            />
                        </View>
                        <View>
                            <View style={{ flexDirection: "row" }}>
                                <CategorizedText
                                    lable={"Class"}
                                    text={demoUser.batch}
                                    containerStyle={{
                                        marginRight: width * 0.035,
                                    }}
                                />
                                <CategorizedText
                                    lable={"Section"}
                                    text={demoUser.batch}
                                />
                            </View>
                            <CategorizedText
                                isDate={true}
                                lable={"Date of Birth"}
                                text={demoUser.orientation}
                            />
                            <CategorizedText
                                lable={"Batch"}
                                text={demoUser.batch}
                            />
                        </View>
                    </View>
                    <DashedLine
                        vertical={false}
                        viewStyle={{
                            width: "100%",
                            marginBottom: height * 0.05,
                        }}
                        color={COLOURS.orange2}
                    />
                    <View style={styles.tabContainer}>
                        {getCurrentTab()}
                        <View>
                            {tabs.map(({ title, id }) => (
                                <TouchableOpacity
                                    key={id}
                                    onPress={() => onTabSelected(id)}>
                                    <View
                                        style={
                                            currentTab === id
                                                ? styles.selectedCategoryBtn
                                                : styles.categoryBtn
                                        }>
                                        <Text style={styles.tabTitle}>
                                            {title}
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            ))}
                        </View>
                    </View>
                </View>
            </ScrollView>
        </MainLayout>
    );
};

const getStyles = ({ height, width }) => ({
    contentContainer: {
        flex: 1,
        height: "100%",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    profileInfo: {
        width: width * 0.7,
        height: height * 0.21,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
    },
    categoryBtn: {
        height: width * 0.05,
        width: width * 0.17,
        borderRadius: 10,
        borderWidth: 3,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#ffffff",
        marginBottom: height * 0.015,
        borderColor: COLOURS.pink5,
    },
    selectedCategoryBtn: {
        height: width * 0.05,
        width: width * 0.2,
        zIndex: 100,
        marginRight: -width * 0.00715,
        borderRightWidth: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRadius: 10,
        borderWidth: 3,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#ffffff",
        marginBottom: height * 0.015,
        borderColor: COLOURS.red4,
    },
    tabTitle: {
        color: COLOURS.blue4,
        fontWeight: 700,
    },
    tabContainer: {
        width: width * 0.8,
        flexDirection: "row-reverse",
        justifyContent: "space-around",
        alignItems: "center",
    },
    tab: {
        width: width * 0.6,
        height: height * 0.7,
        borderRadius: 25,
        borderWidth: 3,
        borderColor: "#7E1121",
        backgroundColor: "#FFFFFF",
    },
});
