import {
    applyMiddleware,
    combineReducers,
    createStore,
} from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

import app from "./app/slice";
import auth from "./auth/slice";
import diary from "./diary/slice";
import main from "./main/slice";
import tests from "./tests/slice";

import {
    appPersistConfig,
    dairyPersistConfig,
    mainPersistConfig,
    rootPersistConfig,
} from "./configs";

const reducers = combineReducers({
    auth,
    diary: persistReducer(dairyPersistConfig, diary),
    app: persistReducer(appPersistConfig, app),
    main: persistReducer(mainPersistConfig, main),
    tests,
});

const rootReducer = (state, action) => {
    if (
        action.type === "auth/logoutThunk/fulfilled" ||
        action.type === "auth/logoutThunk/rejected"
    ) {
        return reducers(undefined, action);
    }

    return reducers(state, action);
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
export const store = createStore(persistedReducer, {}, applyMiddleware(thunk));
export const persisStore = persistStore(store);
