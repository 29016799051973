import React, { useEffect, useRef, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import { WebView } from "react-native-webview";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-native";
import { MAX_REFRESH_TRIES, REFRESH_LOADER_TIMEOUT } from "../../api/constants";
import { BackArrowIcon } from "../../assets/svg";
import { useHaptic, useStylesUtils, usePopups } from "../../hooks";
import { startLoading, stopLoading } from "../../store/app/slice";

export const StoryScreen = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { styles, imageSizes } = useStylesUtils(getStyles);
    const { hapticImpact } = useHaptic();
    const { completeHomeWork } = usePopups();

    const [injected, setInjected] = useState(false);
    const [refreshes, setRefreshes] = useState(0);

    const [timerSeconds, setTimerSeconds] = useState(REFRESH_LOADER_TIMEOUT);
    const [timerActive, setTimerActive] = useState(true);

    useEffect(() => {
        dispatch((timerActive ? startLoading : stopLoading)());
    }, [timerActive]);

    useEffect(() => {
        let interval = null;
        if (timerActive) {
            interval = setInterval(() => {
                setTimerSeconds((seconds) => seconds - 1);
            }, 1000);
            if (!timerActive || timerSeconds <= 0) {
                clearInterval(interval);
                setTimerActive(false);
            }
        }
        return () => clearInterval(interval);
    }, [timerActive, timerSeconds]);

    const webViewRef = useRef(null);
    const navigate = useNavigate();

    const onLoadEnd = async () => {
        if (injected) return;
        injectJS(setCookieJs(state));
        setInjected(true);
    };
    const onLoad = async (state) => {
        if (injected) return;
        injectJS(setCookieJs(state));
        injectJS(navigateJs(state.uri));
    };
    const onMessage = (event, data) => {
        console.log(event, { event: data?.nativeEvent });
    };
    const onHttpError = () => {
        if (refreshes > MAX_REFRESH_TRIES) return;
        injectJS(navigateJs(state.uri));
        setRefreshes((prev) => prev + 1);
    };

    const injectJS = (jsCode) => {
        webViewRef.current.injectJavaScript(jsCode);
    };
    const setCookieJs = (state) => {
        const { policy, keyPairId, signature } = state;
        return `
      document.cookie = 'CloudFront-Policy=${policy}; path=/';
      document.cookie = 'CloudFront-Key-Pair-Id=${keyPairId}; path=/';
      document.cookie = 'CloudFront-Signature=${signature}; path=/';
    `;
    };

    const navigateJs = (uri) => `window.location.href = '${uri}';`;

    const navigateBack = () => {
        const navBack = () => {
            navigate(-1);
            hapticImpact(1);
        };

        if (state.showCompletePopup) {
            completeHomeWork(navBack, "STORY");
        } else {
            navBack();
        }
    };

    return state ? (
        <View style={styles.container}>
            <WebView
                ref={webViewRef}
                source={{
                    uri: state.uri,
                }}
                onLoadEnd={onLoadEnd}
                onLoad={onLoad}
                onMessage={(data) => onMessage("onMessage", data)}
                onHttpError={onHttpError}
                onError={(data) => onMessage("onError", data)}
            />
            <TouchableOpacity style={styles.backButton} onPress={navigateBack}>
                <BackArrowIcon {...imageSizes.standard} />
            </TouchableOpacity>
        </View>
    ) : null;
};

const getStyles = {
    container: {
        flex: 1,
    },
    backButton: {
        position: "absolute",
        zIndex: 100,
        top: 10,
        left: 0,
        marginTop: 10,
        marginLeft: 20,
    },
};
