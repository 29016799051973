import { createSlice } from "@reduxjs/toolkit";
import { homeWorkStatusesStrings } from "../../constants/home-work";
import {
    getStudentHomeworksSubjectsThunk,
    getStudentHomeworksThunk,
} from "./thunks";
import { parseListsUtils } from "../../utils/parse-lists.utils";

export const diaryInitialState = {
    studentHomeworks: {
        subjects: [],
        homeworks: [],
        totalRecords: 0,
        pageSize: 5,
        pageNumber: 1,
        filter: {
            statuses: homeWorkStatusesStrings,
            subjectIds: [],
            startDate: new Date(),
            endDate: new Date(),
            initialState: {
                statuses: homeWorkStatusesStrings,
                subjectIds: [],
                startDate: new Date(),
                endDate: new Date(),
            },
        },
    },
};

const slice = createSlice({
    name: "diary",
    initialState: diaryInitialState,
    reducers: {
        setFilterData: (state, { payload }) => {
            state.studentHomeworks.filter = {
                ...state.studentHomeworks.filter,
                ...payload,
            };
        },
        resetFilter: (state) => {
            const { initialState } = state.studentHomeworks.filter;

            state.studentHomeworks.filter = {
                ...diaryInitialState.studentHomeworks.filter,
                initialState,
            };

            state.studentHomeworks.filter.subjectIds =
                state.studentHomeworks.subjects.map(({ id }) => id);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                getStudentHomeworksThunk.fulfilled,
                (state, { payload }) => {
                    state.studentHomeworks = {
                        ...state.studentHomeworks,
                        homeworks: payload.params.addToExist
                            ? [
                                  ...state.studentHomeworks.homeworks,
                                  ...(payload.data.homeworks || []),
                              ]
                            : payload.data.homeworks || [],
                        totalRecords: payload.data.totalRecords,
                        pageNumber: payload.params.addToExist
                            ? payload.params.pageNumber
                            : 1,
                    };
                }
            )
            .addCase(
                getStudentHomeworksSubjectsThunk.fulfilled,
                (state, { payload }) => {
                    state.studentHomeworks.subjects = parseListsUtils(
                        payload.childNodes
                    );

                    if (
                        state.studentHomeworks.filter?.subjectIds?.length <= 0
                    ) {
                        state.studentHomeworks.filter.subjectIds =
                            state.studentHomeworks.subjects.map(({ id }) => id);
                    }

                    const { initialState, ...rest } =
                        state.studentHomeworks.filter;
                    state.studentHomeworks.filter.initialState = rest;
                }
            );
    },
});

export const { setFilterData, resetFilter } = slice.actions;
export default slice.reducer;
