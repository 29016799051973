import * as React from "react";
import Svg, { G, Path, Defs } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const SvgComponent = (props) => {
    return (
        <Svg
            width={126}
            height={116}
            viewBox="0 0 126 116"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <G filter="url(#filter0_d_0_327965)">
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M98.736 19.41c-3.772-1.718-17.821-6.476-32.037 3.857C58.666 8.205 43.689 6.98 38.939 7.184c-11.337.484-23.867 8.233-27.56 22.731-7.056 27.697 14.311 54.32 38.474 72.701 26.638-5.83 60.368-22.933 65.188-51.105 2.522-14.747-5.978-27.396-16.305-32.1"
                    fill="#FF883B"
                />
                <Path
                    d="M98.736 19.41c-3.772-1.718-17.821-6.476-32.037 3.857C58.666 8.205 43.689 6.98 38.939 7.184c-11.337.484-23.867 8.233-27.56 22.731-7.056 27.697 14.311 54.32 38.474 72.701 26.638-5.83 60.368-22.933 65.188-51.105 2.522-14.747-5.978-27.396-16.305-32.1"
                    stroke="#fff"
                    strokeWidth={8}
                />
            </G>
            <Defs></Defs>
        </Svg>
    );
};

export default SvgComponent;
