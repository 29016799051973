import { TouchableOpacity, View, Text } from "react-native";
import { useStylesUtils } from "../../../../hooks";
import { COLOURS } from "../../../../constants";

export const SelectBtn = ({ lable = "oo", isSelected, onPress }) => {
    const { styles, width, imageSizes, height } = useStylesUtils(
        getStyles(isSelected)
    );
    return (
        <TouchableOpacity onPress={onPress}>
            <View style={styles.btn}>
                <Text style={styles.lable}>{lable}</Text>
                <View style={styles.dot} />
            </View>
        </TouchableOpacity>
    );
};

const getStyles =
    (isSelected) =>
    ({ height, width }) => ({
        btn: {
            width: width * 0.06,
            height: width * 0.03,
            borderRadius: 25,
            backgroundColor: "#FFF0F0",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
        },
        lable: {
            fontWeight: 700,
            color: isSelected ? COLOURS.green2 : COLOURS.red4,
        },
        dot: {
            height: width * 0.023,
            width: width * 0.024,
            borderRadius: width,
            borderWidth: 3,
            backgroundColor: isSelected ? COLOURS.green2 : COLOURS.orange2,
            borderColor: "#ffffff",
        },
    });
