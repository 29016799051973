import * as React from "react"
import Svg, { Path, Mask, G } from "react-native-svg"

function SvgComponent(props) {
    return (
        <Svg
            width={130}
            height={120}
            viewBox="0 0 130 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.712 0c33.604 0 74.622 19.205 74.622 52.81 0 33.603-30.685 66.585-64.29 66.585-33.603 0-64.29-32.981-64.29-66.585C.755 19.205 21.109 0 54.713 0z"
                fill="#fff"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M53.755 7c29.27 0 65 16.729 65 46s-26.73 58-56 58-56-28.729-56-58 17.73-46 47-46z"
                fill="#073763"
            />
            <Mask
                id="a"
                style={{
                    maskType: "luminance"
                }}
                maskUnits="userSpaceOnUse"
                x={41}
                y={32}
                width={44}
                height={55}
            >
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M41.19 32.432h43.128v54.53H41.19v-54.53z"
                    fill="#fff"
                />
            </Mask>
            <G mask="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="#fff">
                <Path d="M70.593 81.091c-1.244 3.442-4.264 5.87-7.792 5.87-3.527 0-6.547-2.428-7.791-5.87 3.647-2.142 9.956-.302 15.583 0M41.336 74.966c.217-1.55.932-4.233 3.259-6.788 3.053-3.352.282-8.424.392-12.757.059-2.317 1.282-14.157 13-17.762 0 0-.121-5.194 4.767-5.227 4.888.033 4.767 5.227 4.767 5.227 11.718 3.605 12.106 15.445 12.165 17.762.11 4.333-1.826 9.405 1.227 12.757 2.327 2.555 3.042 5.238 3.259 6.788.08.514.122 1.09.145 1.756a1.634 1.634 0 01-1.632 1.689s-10.228-.136-17.457-1.573C57.999 75.4 42.823 78.41 42.823 78.41a1.633 1.633 0 01-1.632-1.69c.023-.666.066-1.241.145-1.755" />
            </G>
        </Svg>
    )
}

export default SvgComponent
