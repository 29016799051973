import { useEffect, useMemo } from "react";
import { Image, View, Text } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button, MainLayout } from "../../components";
import { oopsImagesOptions } from "../../constants";
import { useStylesUtils } from "../../hooks";
import { setLayoutType } from "../../store/app/slice";
import { COLOURS } from "../../constants";

// type = 'file' | 'tv' | 'story'

export const OopsScreen = () => {
    const { styles, width, height } = useStylesUtils(getStyles);

    const { state } = useLocation();
    const dispatch = useDispatch();

    const type = useSelector((state) => state.app.layoutType);

    const { img, aspectRatio, aspectRatioFactor } = useMemo(
        () => oopsImagesOptions[state?.type || "tv"],
        [state]
    );

    const imageStyle = useMemo(() => {
        const size = width - height > 0 ? width : height;

        const fixWidth = size * aspectRatioFactor;
        const fixHeight = fixWidth / aspectRatio;

        return {
            width: fixWidth,
            height: fixHeight,
        };
    }, [width, height, aspectRatio, aspectRatioFactor]);

    useEffect(() => {
        if (type !== "white") {
            dispatch(setLayoutType("white"));
        }
    }, []);

    return (
        <MainLayout
            headerProps={{ backButton: true, homeButton: true }}
            viewStyle={{
                flex: 1,
                maxWidth: width * 0.8,
                alignItems: "flex-start",
            }}>
            <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
                <Image
                    source={img}
                    style={{
                        height: imageStyle.height,
                        width: imageStyle.width,
                    }}
                />
                <View style={{ marginLeft: 20 }}>
                    <Text style={styles.title1}>Oops!</Text>
                    <Text style={styles.title2}>
                        We can’t seem to find the page you are looking for.
                    </Text>
                    <Text style={styles.title2}>Click below to try again.</Text>
                    <Button style={{ marginTop: 20 }} label={"Try again"} />
                </View>
            </View>
        </MainLayout>
    );
};

const getStyles = {
    title1: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        fontSize: 40,
        marginBottom: 20,
    },
    title2: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        fontSize: 14,
    },
};
