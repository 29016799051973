import { useMemo } from "react";
import { Text, View } from "react-native";
import { COLOURS } from "../../../constants";
import { useStylesUtils } from "../../../hooks";

export const AcademicTable = ({ data = null }) => {
    const { styles, width } = useStylesUtils(getStyles);

    const addonsStyles = {
        ["important"]: styles.important,
    };

    const getAddonsStyles = (prop) => addonsStyles[prop] || {};

    const getColumns = useMemo(() => {
        if (data.columns.length <= 0) {
            return <></>;
        }

        return data.columns?.map(({ id, title, property, Icon }, i, all) => {
            const isFirst = i === 0;
            const isLast = all.length - 1 === i;
            const rows =
                data.rows?.map((item) => ({
                    id: item.id,
                    data: item[property],
                })) || [];

            return (
                <View
                    key={"tableColumn" + id}
                    style={[
                        styles.column,
                        isFirst ? styles.columnFirst : {},
                        isLast ? styles.columnLast : {},
                    ]}>
                    <View style={[styles.row, styles.rowTitle]}>
                        {Icon ? (
                            <Icon
                                width={width * 0.02}
                                height={width * 0.02}
                                style={{ marginRight: 10 }}
                            />
                        ) : null}
                        <Text style={styles.mainText}>{title}</Text>
                    </View>
                    {rows.map(({ id, data }) => {
                        const RowIcon =
                            typeof data === "object"
                                ? data.Icon
                                    ? data.Icon
                                    : null
                                : null;

                        return (
                            <View key={"tableRow" + id} style={[styles.row]}>
                                {RowIcon && (
                                    <RowIcon
                                        width={width * 0.02}
                                        height={width * 0.02}
                                        style={{ marginRight: 10 }}
                                    />
                                )}
                                <View>
                                    <Text
                                        numberOfLines={1}
                                        ellipsizeMode="tail"
                                        style={[
                                            styles.mainText,
                                            getAddonsStyles(data.type),
                                        ]}>
                                        {typeof data === "object"
                                            ? data.title
                                            : data}
                                    </Text>
                                    {data?.subTitle && (
                                        <Text
                                            numberOfLines={1}
                                            ellipsizeMode="tail"
                                            style={[
                                                styles.mainText,
                                                styles.subTitle,
                                                getAddonsStyles(data.type),
                                            ]}>
                                            {data.subTitle}
                                        </Text>
                                    )}
                                </View>
                            </View>
                        );
                    })}
                </View>
            );
        });
    }, [data]);

    return data ? <View style={styles.container}>{getColumns}</View> : null;
};

const getStyles = ({ width }) => ({
    container: { flexDirection: "row" },
    column: {
        flex: 1,
    },
    columnFirst: {
        flex: 0,
        width: "25%",
    },
    columnLast: {
        flex: 0,
        width: "25%",
    },
    rowTitle: {
        backgroundColor: COLOURS.pink4,
    },
    row: {
        height: width * 0.05,
        backgroundColor: "#fff",
        marginHorizontal: 10,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10,
        marginBottom: 10,
    },
    mainText: {
        color: COLOURS.blue4,
        fontSize: 16,
        fontWeight: 700,
        textAlign: "center",
    },
    subTitle: {
        fontWeight: 700,
        fontSize: 10,
    },
    important: {
        color: COLOURS.red3,
    },
});
