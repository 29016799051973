import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-native";
import { CarrouselComponent, MainLayout } from "../../../components";
import { letsCreateIcons, navigation } from "../../../constants";
import { usePermissions, useRead } from "../../../hooks";
import { useCreateHook } from "../../../hooks/useCreateHook";
import { getPlaylistsForUserRedux } from "../../../store/main/selectors";
import { CardView } from "./CardView";
import { TitleContent } from "./TitleContent";

export const LetsCreateScreen = () => {
    const { state } = useLocation();
    const { getStory, getStorysName } = useCreateHook();
    const playListData = useSelector(getPlaylistsForUserRedux);
    const { canSeeTracingBoard } = usePermissions();
    const { getVideosFor } = useRead();
    const getStoryName = (storyId) => {
        const name = state.find((story) => story?.storyId === storyId);
        return name?.title;
    };

    const isArtVisible = () => {
        const isArtCraft = playListData.find(
            (playlist) => playlist.title === "Art and Craft"
        );
        return !!isArtCraft;
    };

    const createList = [
        {
            id: 4390,
            title: getStoryName(4390),
            image: letsCreateIcons.DrawingImage,
            titleContent: <TitleContent />,
            isVisible: true,
            onPress: () => getStory({ params: { storyId: 4390 } }),
        },
        {
            id: 4391,
            title: getStoryName(4391),
            image: letsCreateIcons.ColoringImage,
            titleContent: <TitleContent />,
            isVisible: true,
            onPress: () => getStory({ params: { storyId: 4391 } }),
        },
        {
            id: 1,
            title: "Tracing\nBoard",
            image: letsCreateIcons.TracingImage,
            titleContent: <TitleContent />,
            isVisible: canSeeTracingBoard,
            onPress: () =>
                getStorysName({
                    params: [4334, 4392, 4393, 4394],
                    location: navigation.TRACING_BOARD_SCREEN,
                }),
        },
        {
            id: 2,
            title: "Art &\nCraft",
            image: letsCreateIcons.ArtCraft,
            titleContent: <TitleContent />,
            isVisible: isArtVisible(),
            onPress: () =>
                getVideosFor(47, "Art & Craft", letsCreateIcons.ArtCraft),
        },
    ];

    return (
        <MainLayout
            headerProps={{
                backButton: true,
                homeButton: true,
                titleContent: <TitleContent />,
            }}>
            <CarrouselComponent
                data={createList.filter((el) => el.isVisible)}
                CardView={CardView}
            />
        </MainLayout>
    );
};
