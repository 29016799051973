import { Audio } from "expo-av";
import { Camera } from "expo-camera";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPermissions } from "../store/app/selectors";
import {
    setAudioRecordingStatus,
    setCameraAccessStatus,
} from "../store/app/slice";
import { getUserInfoForQueries, getMappingInfo } from "../store/auth/selectors";
import { validateGrade } from "../utils/validators";

export const usePermissions = () => {
    const dispatch = useDispatch();

    const { isAccessToCamera, isAccessToMicrophone } =
        useSelector(getPermissions);

    const { gradeId, examIdRaw } = useSelector(getUserInfoForQueries);

    const { cmsGradeId } = useSelector(getMappingInfo);

    const checkInitialPermissions = async () => {
        await requestForCameraAccess();
        await requestForMicrophoneAccess();
    };

    const requestForCameraAccess = async () => {
        const cameraStatus = await Camera.requestCameraPermissionsAsync();
        await dispatch(
            setCameraAccessStatus(cameraStatus.status === "granted")
        );
    };

    const requestForMicrophoneAccess = async () => {
        const audioStatus = await Audio.requestPermissionsAsync();
        await dispatch(
            setAudioRecordingStatus(audioStatus.status === "granted")
        );
    };

    const props = useMemo(() => {
        return {
            canSeeLetsCreate: !(cmsGradeId > 3 && cmsGradeId <= 5),
            canSeeTracingBoard: !(cmsGradeId >= 3 && cmsGradeId <= 5),
            canLoginInApp: !validateGrade(gradeId),
        };
    }, [cmsGradeId, examIdRaw, gradeId]);

    return {
        ...props,
        checkInitialPermissions,
        requestForMicrophoneAccess,
        isAccessToCamera,
        isAccessToMicrophone,
    };
};
