import React, { useCallback, useEffect, useState } from "react";
import Animated, {
    FadeOutDown,
    FadeInUp,
    Layout,
} from "react-native-reanimated";
import { Platform, Text, View, TouchableOpacity } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import {
    CloseIcon,
    ErrorTypeIcon,
    ImportantIcon,
    InfoIcon,
    SuccessTypeIcon,
} from "../../../assets/svg";
import { COLOURS, popupTypes } from "../../../constants";
import { useStylesUtils } from "../../../hooks";
import { getPopupState } from "../../../store/app/selectors";
import { closePopup } from "../../../store/app/slice";
import { OverLayout } from "../OverLayout";
import { RenderButtons } from "./RenderButtons";
import { useLocation } from "react-router-native";
import { CustomScrollView } from "../../CustomScrollView";

const { OS } = Platform;

export const PopupLayout = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const {
        open,
        type,
        title,
        subTitle,
        content,
        cancelButton,
        confirmButton,
        showCloseBtn = false,
        closeWithConfirm = false,
        styles: popupStyles,
    } = useSelector(getPopupState);

    const { styles, width, height } = useStylesUtils(getStyles, popupStyles);

    const [show, setShow] = useState(open);

    useEffect(() => {
        setShow(open);
    }, [open]);

    const close = useCallback(() => {
        if (
            closeWithConfirm &&
            cancelButton &&
            typeof cancelButton === "object" &&
            typeof cancelButton.onPress === "function"
        ) {
            cancelButton.onPress();
        } else if (
            closeWithConfirm &&
            confirmButton &&
            typeof confirmButton === "object" &&
            typeof confirmButton.onPress === "function"
        ) {
            confirmButton.onPress();
        }
        setShow(false);
        dispatch(closePopup());
    }, [closeWithConfirm, cancelButton, confirmButton]);

    useEffect(() => {
        if (show) {
            close();
        }
    }, [pathname]);

    return show ? (
        <OverLayout style={styles.overlayStyle} onPress={close}>
            <Animated.View
                onStartShouldSetResponder={() => true}
                style={[styles.dialogStyle, styles.dialog]}
                layout={Layout.duration(200).delay(200)}
                entering={FadeInUp}
                exiting={FadeOutDown}>
                {type !== popupTypes.NONE && (
                    <View
                        style={{
                            alignItems: "center",
                            marginBottom: 10,
                        }}>
                        {type === popupTypes.SUCCESS && (
                            <SuccessTypeIcon
                                width={height * 0.15}
                                height={height * 0.15}
                            />
                        )}
                        {type === popupTypes.ERROR && (
                            <ErrorTypeIcon
                                width={height * 0.15}
                                height={height * 0.15}
                            />
                        )}
                        {type === popupTypes.IMPORTANT && (
                            <ImportantIcon
                                width={height * 0.15}
                                height={height * 0.15}
                            />
                        )}
                        {type === popupTypes.INFO && (
                            <InfoIcon
                                width={height * 0.15}
                                height={height * 0.15}
                                fill={COLOURS.green1}
                            />
                        )}
                    </View>
                )}
                {!!title && (
                    <Text style={[styles.titleStyle, styles.title]}>
                        {title}
                    </Text>
                )}
                {!!subTitle && (
                    <Text style={[styles.subTitleStyle, styles.subTitle]}>
                        {subTitle}
                    </Text>
                )}
                {!!content && (
                    <View
                        style={[
                            {
                                overflow: "hidden",
                                flexShrink: 1,
                            },
                            styles.content,
                        ]}>
                        <CustomScrollView
                            showIndicator={true}
                            style={[styles.contentStyle, styles.contentScroll]}>
                            <View onStartShouldSetResponder={() => true}>
                                {typeof content === "string" ? (
                                    <Text style={styles.contentText}>
                                        {content}
                                    </Text>
                                ) : (
                                    content
                                )}
                            </View>
                        </CustomScrollView>
                    </View>
                )}
                {(!!cancelButton || !!confirmButton) && (
                    <View style={[styles.buttonsStyle, styles.buttons]}>
                        <RenderButtons
                            close={close}
                            cancelButton={cancelButton}
                            confirmButton={confirmButton}
                        />
                    </View>
                )}
                {!!showCloseBtn && (
                    <TouchableOpacity
                        style={[
                            { position: "absolute", right: 0, top: 0 },
                            styles.closeBtn,
                        ]}
                        onPress={close}>
                        <CloseIcon
                            width={width * 0.05}
                            height={width * 0.05}
                            type={1}
                        />
                    </TouchableOpacity>
                )}
            </Animated.View>
        </OverLayout>
    ) : null;
};

const getStyles = ({ width }) => ({
    overlayStyle: {
        flex: 1,
        justifyContent: "center",
        backgroundColor:
            OS === "ios" || OS === "web" ? "" : "rgba(255,255,255,0.8)",
        alignItems: "center",
    },
    dialogStyle: {
        flexShrink: 1,
        backgroundColor: "#fff",
        borderColor: "#280A62",
        borderWidth: 5,
        width: "90%",
        maxHeight: "80%",
        borderRadius: 25,
        shadowColor: "#000",
        paddingHorizontal: width * 0.02,
        paddingTop: width * 0.01,
        paddingBottom: width * 0.02,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
        elevation: 4,
        zIndex: 10,
        overflow: "hidden",
    },
    titleStyle: {
        textAlign: "center",
        color: COLOURS.dark_purple,
        fontSize: 26,
        fontWeight: 700,
        marginBottom: 10,
    },
    subTitleStyle: {
        textAlign: "center",
        fontSize: 20,
        fontWeight: 600,
        color: COLOURS.dark_purple,
        marginBottom: 10,
    },
    contentStyle: {
        width: "100%",
        flexShrink: 1,
    },
    buttonsStyle:
        OS === "ios"
            ? {
                  paddingTop: 10,
              }
            : {
                  width: "100%",
                  paddingLeft: 24,
                  paddingRight: 8,
                  paddingTop: 8,
                  paddingBottom: 8,
              },
    contentText: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: 600,
        color: COLOURS.dark_purple,
    },
    absolute: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
});
