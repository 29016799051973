import React, { useState } from "react";
import { View } from "react-native";
import { MainLayout } from "../../../components";
import { TimeLineContainer } from "../../../components/screens/TimeLineContainer";
import { images } from "../../../constants";
import { useStylesUtils } from "../../../hooks";
import { ContentContainer } from "./ContentContainer";
import { homeWorkTabs } from "../../../components/tabs/homeWorkTabs";

export const ClassActivityScreen = () => {
    const { styles } = useStylesUtils(getStyles);

    const [editMode, setEditMode] = useState(false);
    const [test, setTest] = useState(true);

    const toggleEditMode = () => setEditMode((prev) => !prev);

    const list = test
        ? [
              {
                  id: 1,
                  name: "Maths",
                  content:
                      "1. Lorem ipsum dolor sit amet, consectetur adipiscing\n" +
                      "2. But I must explain to you how all this mistaken idea\n" +
                      "3. At vero eos et accusamus et iusto odio dignissimos" +
                      "2. But I must explain to you how all this mistaken idea\n" +
                      "3. At vero eos et accusamus et iusto odio dignissimos" +
                      "2. But I must explain to you how all this mistaken idea\n" +
                      "3. At vero eos et accusamus et iusto odio dignissimos",
                  image: images.vedicMathIcon,
              },
              {
                  id: 2,
                  name: "Science",
                  content:
                      "Risus at ultrices mi tempus imperdiet. Et pharetra pharetra massa massa ultricies. Magnis dis parturient montes nascetur ridiculus mus. Fermentum et sollicitudin ac orci. Faucibus pulvinar elementum integer enim neque volutpat. Egestas congue quisque egestas diam in arcu cursus. Et malesuada fames ac turpis egestas sed tempus urna. Dignissim sodales ut eu sem. Ut porttitor leo a diam sollicitudin tempor id eu nisl. Quisque sagittis purus sit amet. Nunc congue nisi vitae suscipit tellus mauris a diam. Sit amet tellus cras adipiscing enim eu. Morbi tristique senectus et netus. Nascetur ridiculus mus mauris vitae. Amet luctus venenatis lectus magna fringilla urna. Vulputate eu scelerisque felis imperdiet proin fermentum leo. Pulvinar sapien et ligula ullamcorper malesuada proin. Duis ultricies lacus sed turpis.",
              },
              {
                  id: 3,
                  name: "EVS",
                  content:
                      "Risus at ultrices mi tempus imperdiet. Et pharetra pharetra massa massa ultricies. Magnis dis parturient montes nascetur ridiculus mus. Fermentum et sollicitudin ac orci. Faucibus pulvinar elementum integer enim neque volutpat. Egestas congue quisque egestas diam in arcu cursus. Et malesuada fames ac turpis egestas sed tempus urna. Dignissim sodales ut eu sem. Ut porttitor leo a diam sollicitudin tempor id eu nisl. Quisque sagittis purus sit amet. Nunc congue nisi vitae suscipit tellus mauris a diam. Sit amet tellus cras adipiscing enim eu. Morbi tristique senectus et netus. Nascetur ridiculus mus mauris vitae. Amet luctus venenatis lectus magna fringilla urna. Vulputate eu scelerisque felis imperdiet proin fermentum leo. Pulvinar sapien et ligula ullamcorper malesuada proin. Duis ultricies lacus sed turpis.",
              },
          ]
        : [];

    return (
        <MainLayout
            viewStyle={{ zIndex: 15 }}
            tabs={homeWorkTabs()}
            headerProps={{
                backButton: true,
                homeButton: true,
                showBgCloud: true,
                titleContent: <></>,
            }}>
            <View style={styles.content} pointerEvents="box-none">
                <TimeLineContainer
                    penProps={{
                        showPenButton: true,
                        onPress: toggleEditMode,
                        value: editMode,
                    }}
                    toggleTest={() => setTest((prev) => !prev)}
                />
                <ContentContainer
                    list={list}
                    editMode={editMode}
                    toggleEditMode={toggleEditMode}
                />
            </View>
        </MainLayout>
    );
};

const getStyles = () => ({
    content: {
        zIndex: 3,
        width: "100%",
    },
});
