import React, { useMemo } from "react";
import { Text } from "react-native";
import { useSelector } from "react-redux";
import { MusicIcon } from "../../assets/svg";
import {
    CardViewComponent,
    CarrouselComponent,
    MainLayout,
} from "../../components";
import { COLOURS } from "../../constants";
import { useStylesUtils } from "../../hooks";
import { getPlaylistsForRedux } from "../../store/main/selectors";
import { parseListsUtils } from "../../utils/parse-lists.utils";

export const MusicScreen = () => {
    const { styles, width, height } = useStylesUtils(getStyles);

    const playList = useSelector(getPlaylistsForRedux);

    const parseList = useMemo(
        () => parseListsUtils(playList?.data),
        [playList]
    );

    return (
        <MainLayout
            headerProps={{
                backButton: true,
                homeButton: true,
                titleContent: (
                    <>
                        <MusicIcon
                            height={height * 0.25}
                            width={width * 0.14}
                        />
                        <Text style={styles.titleText}>Music</Text>
                    </>
                ),
            }}>
            <CarrouselComponent
                data={parseList}
                CardView={CardViewComponent}
                type={"video"}
            />
        </MainLayout>
    );
};

const getStyles = {
    titleText: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        fontSize: 40,
        textAlign: "center",
        marginTop: 20,
    },
};
