import React from "react";
import { Text } from "react-native";
import { MainLayout } from "../../components";
import { Heart2Icon } from "../../assets/svg";
import { useStylesUtils } from "../../hooks";
import { COLOURS } from "../../constants";

export const FavoriteScreen = () => {
    const { styles, width } = useStylesUtils({
        titleText: {
            fontSize: 44,
            fontWeight: 700,
            color: COLOURS.white,
            marginRight: 10,
            textShadowOffset: { height: 2, width: 2 },
            textShadowRadius: 3,
            textShadowColor: COLOURS.brown1,
        },
    });

    return (
        <MainLayout
            headerProps={{
                backButton: true,
                homeButton: true,
                notificationButton: true,
                titleContent: (
                    <>
                        <Text style={styles.titleText}>Favorite</Text>
                        <Heart2Icon width={width * 0.06} />
                    </>
                ),
            }}>
            <Text>Favorite screen</Text>
        </MainLayout>
    );
};
