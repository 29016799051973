import React, { useMemo } from "react";
import { Text } from "react-native";
import { RoundedButton } from "../../../components";
import { useStylesUtils } from "../../../hooks";
import { COLOURS } from "../../../constants";
import { CardsImage } from "../../../components/carrouselCards/components/CardsImage";

export const CardView = ({ title, onPress, image }) => {
    const { styles, width, height } = useStylesUtils(getStyles);

    const imageStyles = useMemo(() => {
        const fixWidth = width / 5;
        const fixHeight = (height / 2) * 1.0684;

        const lastHeight = fixWidth < fixHeight ? fixWidth : fixHeight;

        return {
            width: fixWidth,
            height: lastHeight,
        };
    }, [width, height]);

    const capitalize = (text) => {
        const words = text.split(" ");
        let capitalizedStr = "";
        for (let i = 0; i < words.length; i++) {
            capitalizedStr +=
                words[i].charAt(0).toUpperCase() + words[i].slice(1) + " ";
        }
        return capitalizedStr;
    };

    return (
        <RoundedButton
            onPress={onPress}
            style={{ ...imageStyles }}
            imageStyle={styles.imageStyle}
            viewStyle={[styles.viewStyle, { padding: imageStyles.width * 0.1 }]}
            pressImageStyle={styles.pressImageStyle}>
            <CardsImage source={image} style={styles.cardImage} />
            <Text style={styles.text}>{capitalize(title)}</Text>
        </RoundedButton>
    );
};

const getStyles = ({ width }) => ({
    imageStyle: {
        stroke: COLOURS.green2,
        fill: COLOURS.lightBlue4,
    },
    viewStyle: {
        alignItems: "center",
        flex: 1,
        justifyContent: "flex-end",
    },
    pressImageStyle: {
        stroke: COLOURS.lightOrange,
    },
    text: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        fontSize: 20,
        textAlign: "center",
    },
    cardImage: {
        width: width * 0.12,
        height: width * 0.12,
        position: "absolute",
        top: width * 0.022,
    },
});
