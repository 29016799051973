import * as React from "react";
import Svg, { G, Path, Defs } from "react-native-svg";

/* SVGR has dropped some elements not supported by react-native-svg: filter */
function SvgComponent(props) {
    return (
        <Svg
            width={162}
            height={151}
            viewBox="0 0 162 146"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <G filter="url(#filter0_d_4887_1310)">
                <Path
                    d="M68.776.57c39.919 0 88.646 22.815 88.646 62.734s-36.453 79.1-76.371 79.1c-39.918 0-76.375-39.181-76.375-79.101S28.854.57 68.776.57z"
                    fill="#fff"
                />
                <Path
                    d="M67.636 8.886c34.772 0 77.215 19.873 77.215 54.645 0 34.772-31.752 68.9-66.523 68.9S11.804 98.302 11.804 63.53 32.864 8.886 67.636 8.886z"
                    fill="#0A3056"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M75.926 98.815c2.734 1.938 6.534-.001 6.534-3.335V45.836c0-3.333-3.8-5.272-6.534-3.334L53.532 58.364H41.135c-2.282 0-4.133 1.835-4.133 4.098v16.393c0 2.263 1.85 4.098 4.133 4.098h12.397l22.394 15.862zm30.061-53.361a4.133 4.133 0 015.773.914 41.329 41.329 0 010 48.58 4.134 4.134 0 01-6.687-4.857 33.058 33.058 0 000-38.865 4.132 4.132 0 01.914-5.772zM92.614 55.17a4.133 4.133 0 015.772.914 24.797 24.797 0 010 29.149 4.133 4.133 0 01-6.686-4.858 16.53 16.53 0 000-19.432 4.133 4.133 0 01.914-5.773z"
                    fill="#fff"
                />
            </G>
            <Defs></Defs>
        </Svg>
    );
}
export default SvgComponent;
