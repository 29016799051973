import moment from "moment";

export const getTestTiming = (testDuration, start, end) => {
    let line = "";

    if (!!testDuration) {
        line += `${testDuration} ${testDuration > 1 ? "Mins" : "Min"}`;
    }

    if (line && (start || end)) {
        line += " | ";
    }

    if (start && end) {
        line += `${moment(start).format("LT")} - ${moment(end).format("LT")}`;
    } else if (start) {
        line += moment(start).format("LT");
    } else if (end) {
        line += moment(end).format("LT");
    }

    return line;
};

export const getCurrentFooterButtonName = (testCompleted, status) => {
    switch (true) {
        case testCompleted:
            return "View Report";
        case status === "ONGOING":
        case status === "UPCOMING":
            return "Lets Begin";
        case !testCompleted:
            return "View Report";
    }
};
