import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={126}
            height={117}
            viewBox="0 0 126 117"
            fill="#7E42B7"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M114.07 24.512c18.12 26.33 15.964 63.207-10.365 81.327-26.328 18.119-76.761 12.774-94.88-13.554-18.12-26.33-6.301-62.518 20.027-80.637 26.33-18.12 67.099-13.464 85.218 12.864"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M75.514 87.096c-2.981 8.46-9.04 10.796-13.747 10.782a13.518 13.518 0 01-3.456-.444 11.483 11.483 0 01-6.879-5.473c-1.34-2.37-2.616-6.398-.96-12.15 2.602-9.012 4.358-15.008 5.632-19.379 1.166-3.991 1.877-6.531 2.52-8.633-1.64.819-4.24 2.449-6.077 3.583a2.838 2.838 0 01-3.96-.651l-.03-.044a2.981 2.981 0 01.772-3.976c4-3.147 9.959-6.697 14.835-7.157 3.874-.366 6.7 1.12 8.251 4.342 1.763 3.658.383 9.28-1.072 13.798a6365.7 6365.7 0 00-6.27 19.558c-.787 2.484-.385 5.28 1.464 6.242 1.982 1.033 4.27.58 7.082-1.651a1.243 1.243 0 011.2-.234c.598.225.906.888.69 1.49M70.056 36.317a8.49 8.49 0 01-1.176-16.94 8.588 8.588 0 018.985 7.963 8.335 8.335 0 01-7.809 8.976"
                fill="#fff"
            />
        </Svg>
    );
}

export default SvgComponent;
