import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
    return (
        <Svg
            viewBox="0 0 273 130"
            width={273}
            height={130}
            stroke="#fff"
            strokeOpacity={0.9}
            fill="#2c0866"
            {...props}
        >
            <Path
                fill="none"
                strokeWidth={8}
                strokeLinejoin="round"
                d="M4.5 46.5C6 21.1 17 11.6 51.9 7.7h-.5.6q.6 0 1.8-.1c1.5-.1 3.8-.2 6.6-.4C66 6.9 73.7 6.4 82.3 6c17.1-.8 37.7-1.7 51.1-1.7 17.8-.5 40 .4 57.7 1.4 8.8.5 16.6 1 22.2 1.4 2.7.2 5 .3 6.5.5q1.1 0 1.7.1h.6c21.2 1 32.3 4.9 38.3 12.4 2.9 3.7 4.3 8 5.2 12.2.5 1.9.8 3.9 1.2 5.9v.5c.4 2.1.8 4.1 1.2 6.2.1.3.2.6.2.7.2.6.3 1.5.4 2.8.2 2.7.4 8 .4 18.7V80.7l-1.8 14.8 1.8-14.7V85l-.2.3.1.5c-.5 2.3-1 4.6-1.4 6.8v.3c-.4 2.1.1 1.1-.4 3.1-.9 4.3-3.1 11.7-5.7 15.4-5.4 7.8-15.1 11.8-32.7 12.1-3.4.1-6.4.3-9.7.4-12.5.7-27.6 1.4-74.3 1.7-54.6 0-57.6-.2-70.5-1-5-.2-11.4-.6-22.8-1.1-20.7-.9-32.5-4.7-39-11.9-6.1-6.9-6.7-16-7.1-24.5q-.1-.5-.1-1 0 0 0 0-.1-.3-.1-.8c-.1-.8-.2-1.9-.3-3.5-.3-3.3-.5-8.7-.4-17.3V46.7z"
            />
            <Path
                fillRule="evenodd"
                d="M51.6 11.7s55.2-3.4 81.9-3.4c35.2-1 88.3 3.4 88.3 3.4 42.3 2 38.1 15.5 42.4 34.6 0 0 .8-.6.8 20.8v20c-4.3 19.1-1.9 31.7-36.4 32.4-16.5.7-25.1 1.8-83.9 2.1-75.4 0-52.1-.3-93.1-2.1-41.1-1.8-41.3-15.3-42.4-33.9 0 0-.9-3.9-.8-21.1V46.7c2.2-19.9 1.5-33 43.2-35z"
                stroke="none"
            />
        </Svg>
    )
}

export default SvgComponent
