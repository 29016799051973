import React, { useMemo } from "react";
import { Text, View } from "react-native";
import { RoundedButton } from "../../../components";
import { CardsImage } from "../../../components/carrouselCards/components/CardsImage";
import { useStylesUtils } from "../../../hooks";
import { COLOURS } from "../../../constants";
import { capitalizeStr } from "../../../utils/text.utils";

export const CardView = ({ title, onPress, image }) => {
    const { biggerSize, height, styles } = useStylesUtils(getStyles);

    const imageStyles = useMemo(() => {
        const fixWidth = biggerSize / 4;
        const fixHeight = (height / 2) * 1.0684;

        const lastHeight = fixWidth < fixHeight ? fixWidth : fixHeight;

        return {
            width: fixWidth,
            height: lastHeight,
        };
    }, [biggerSize, height]);

    return (
        <RoundedButton
            onPress={onPress}
            style={{ ...imageStyles }}
            imageStyle={styles.imageStyle}
            viewStyle={[
                styles.viewStyle,
                { padding: imageStyles.width * 0.11 },
            ]}
            pressImageStyle={styles.pressImageStyle}>
            <View style={styles.imageWrapper}>
                <CardsImage source={image} style={styles.cardImage} />
            </View>
            <View style={styles.textWrapper}>
                <Text
                    style={styles.text}
                    numberOfLines={2}
                    ellipsizeMode="tail">
                    {capitalizeStr(title?.toLowerCase())}
                </Text>
            </View>
        </RoundedButton>
    );
};

const getStyles = ({ biggerSize }) => ({
    imageStyle: {
        stroke: COLOURS.red7,
        strokeOpacity: 0.6,
        fill: COLOURS.pink7,
    },
    viewStyle: {
        alignItems: "center",
        flex: 1,
        justifyContent: "flex-end",
    },
    pressImageStyle: {
        stroke: COLOURS.yellow2,
    },
    imageWrapper: {
        flex: 3,
        flexDirection: "column-reverse",
        alignItems: "center",
        width: biggerSize * 0.15,
        height: biggerSize * 0.15,
    },
    cardImage: {
        width: "100%",
        height: "100%",
        resizeMode: "contain",
        maxWidth: "100%",
        maxHeight: "100%",
    },
    textWrapper: {
        flex: 2,
        justifyContent: "center",
        alignItems: "center",
    },
    text: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        fontSize: 30,
        textAlign: "center",
    },
});
