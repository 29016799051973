import * as Haptics from "expo-haptics";
import { Platform } from "react-native";

const { OS } = Platform;
export const useHaptic = () => {
    const haptics = Haptics.notificationAsync;
    const hapticSuccess = () => {
        if (OS === "web") {
            return;
        }
        haptics(Haptics.NotificationFeedbackType.Success);
    };
    const hapticWarning = () => {
        if (OS === "web") {
            return;
        }
        haptics(Haptics.NotificationFeedbackType.Warning);
    };
    const hapticError = () => {
        if (OS === "web") {
            return;
        }
        haptics(Haptics.NotificationFeedbackType.Error);
    };

    const hapticImpact = (strengthLevel = 1) => {
        if (OS === "web") {
            return;
        }
        if (strengthLevel > 3) {
            strengthLevel = 3;
        } else if (strengthLevel < 1) {
            strengthLevel = 1;
        }
        const strengthLevels = ["Light", "Medium", "Heavy"];
        Haptics.impactAsync(
            Haptics.ImpactFeedbackStyle[strengthLevels[strengthLevel]]
        );
    };

    return {
        hapticSuccess,
        hapticWarning,
        hapticError,
        hapticImpact,
    };
};
