export const oopsImagesOptions = {
    ['file']: {
        img: require('../assets/images/oops/file.png'),
            aspectRatio: 0.766,
            aspectRatioFactor: 0.16,
    },
        ['tv']: {
        img: require('../assets/images/oops/tv.png'),
            aspectRatio: 1.047,
            aspectRatioFactor: 0.21,
    },
        ['story']: {
        img: require('../assets/images/oops/story.png'),
            aspectRatio: 1.641,
            aspectRatioFactor: 0.3,
    }
}
