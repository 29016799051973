import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SVGComponent = (props) => (
    <Svg
        width={75}
        height={59}
        viewBox="0 0 75 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M74.3544 35.7406C73.8248 42.9133 66.932 49.9576 59.7677 50.7199C45.6765 52.0552 31.495 52.1163 17.3955 50.9033C17.3748 50.9015 17.3543 50.8997 17.3343 50.8972C17.3084 50.8958 17.2821 50.8935 17.2563 50.8912C9.12836 50.0822 1.27928 42.0659 0.773823 33.9487C0.348433 26.3726 6.7947 19.7485 14.6008 18.5007C15.0923 9.20488 22.9998 0.794512 31.6269 0.525382C36.9336 0.381902 41.8753 3.41605 45.0448 8.33208C46.5491 7.69836 48.1605 7.40299 49.8013 7.46609C56.7842 7.787 63.0066 14.287 63.1774 21.5458C63.1838 21.8664 63.1778 22.186 63.1593 22.5054C66.0028 23.0352 68.6415 24.4362 70.6958 26.4714C73.26 29.0205 74.5891 32.2382 74.3544 35.7406"
            fill="#EFE2FE"
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.5973 38.9416C47.6345 38.905 45.6641 38.8772 43.6894 38.8583C43.2155 38.8534 42.8343 39.2136 42.8364 39.6621C42.8651 45.4694 42.711 51.2792 42.3742 57.0714C42.3199 57.9513 41.5922 58.7078 40.7547 58.743C38.4661 58.8385 36.1745 58.8385 33.886 58.743C33.0487 58.7077 32.3218 57.9517 32.2674 57.0723C31.9304 51.2797 31.7762 45.4697 31.8051 39.6621C31.8073 39.2136 31.4258 38.8535 30.9521 38.8583C28.977 38.8772 27.0062 38.905 25.0433 38.9416C24.5157 38.9503 24.2371 38.4033 24.5795 38.0226C28.7639 33.3591 33.1517 28.2435 36.8791 23.4683C37.1087 23.1733 37.5327 23.1733 37.7623 23.4683C41.4898 28.2435 45.8774 33.3591 50.062 38.0226C50.4045 38.4033 50.1257 38.9503 49.5973 38.9416"
            fill="#5F3389"
        />
    </Svg>
);
export default SVGComponent;
