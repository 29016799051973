import React, { useCallback, useEffect, useState } from "react";
import {
    Image,
    Keyboard,
    Platform,
    Text,
    TouchableOpacity,
    View,
} from "react-native";
import { useSelector } from "react-redux";
import { AuthLayout } from "../../../components";
import { COLOURS, images, navigation } from "../../../constants";
import { useStylesUtils } from "../../../hooks";
import { useAuth } from "../../../hooks/useAuth";
import { getUserVerifyInfo } from "../../../store/auth/selectors";
import { Button, PinInput } from "../components";
import { authProductId, authTenantId } from "../../../api/constants";

export const EnterPassCode = () => {
    const { styles } = useStylesUtils(getStyles);

    const { onLogin, generateOtp } = useAuth();
    const verifyInfo = useSelector(getUserVerifyInfo);

    const [pin, setPin] = useState("");
    const [invalidPin, setInvalidPin] = useState(false);
    const [pinError, setPinError] = useState(false);

    useEffect(() => {
        setInvalidPin(false);
    }, [pin]);

    useEffect(() => {
        setInvalidPin(pinError);
    }, [pinError]);

    const onSubmit = useCallback(() => {
        Keyboard.dismiss();
        const body = {
            password: pin,
            admission_number: verifyInfo.uid,
        };

        if (body.password?.length) {
            onLogin(body).catch(() => {
                setPin("");
                setInvalidPin(true);
                setPinError(true);
            });
        }
    }, [pin]);

    const loginWithOtp = () => {
        generateOtp(
            {
                phone: verifyInfo?.phone,
                isd_code: verifyInfo?.isdCode,
                admission_number: verifyInfo?.uid,
                tenant_id: authTenantId,
                product_id: authProductId,
            },
            navigation.ENTER_VERIFY_OTP,
            {
                loginWithOtp: true,
            }
        );
    };

    return (
        <AuthLayout headerProps={{ backButton: true }}>
            <Text style={styles.label}>Enter your passcode to continue</Text>
            <View
                style={[
                    styles.formContainer,
                    Platform.OS === "web" ? { width: "auto" } : {},
                ]}>
                <PinInput pinState={{ pin, setPin, isError: invalidPin }} />
                <Button
                    disabled={pin.length !== 4}
                    label={"Submit"}
                    onPress={onSubmit}
                    style={styles.submit}
                    iconProps={{ ratio: 0.11 }}
                />
            </View>
            {pinError && (
                <View style={styles.forgotContainer}>
                    <View style={styles.flexRow}>
                        <Image
                            style={styles.errorCodeIcon}
                            source={images.importantIcon}></Image>
                        <Text style={styles.errorCodeText}>
                            Incorrect passcode
                        </Text>
                    </View>
                </View>
            )}
            <View style={styles.forgotContainer}>
                <TouchableOpacity onPress={() => {}}>
                    <Text style={styles.forgotCodeText}>
                        having trouble logging in?
                    </Text>
                </TouchableOpacity>
                {/*<View style={styles.forgotSeparator} />*/}
                <TouchableOpacity onPress={loginWithOtp}>
                    <Text style={styles.forgotCodeText}>Log in via OTP</Text>
                </TouchableOpacity>
            </View>
        </AuthLayout>
    );
};

const getStyles = ({ width }) => ({
    label: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        marginBottom: 10,
        fontSize: 24,
    },
    formContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 10,
    },
    forgotContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: 25,
    },
    forgotCodeContainer: {
        marginLeft: -90,
        justifyContent: "center",
    },
    forgotSeparator: {
        borderWidth: 1,
        borderColor: COLOURS.lightOrange,
        marginHorizontal: 8,
        height: "100%",
    },
    forgotCodeText: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        fontSize: 12,
    },
    submit: {
        marginLeft: width * 0.005,
        marginTop: 5,
    },
    flexRow: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    errorCodeText: {
        fontWeight: 700,
        color: COLOURS.errorRed,
        fontSize: 12,
        marginBottom: 10,
    },
    errorCodeIcon: {
        resizeMode: "contain",
        height: 15,
        width: 15,
        marginBottom: 10,
        marginRight: 5,
    },
});
