import moment from "moment";
import React, { useCallback, useMemo, useContext } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import {
    FilterBtnIcon,
    NavLeftBtnIcon,
    NavRightBtnIcon,
    PenBtnIcon,
} from "../../assets/svg";
import { usePopups, useStylesUtils } from "../../hooks";
import { isFilterInitialState } from "../../store/diary/selectors";
import { timeLineDateUtil } from "../../utils/date.utils";
import GlobalAudioContext from "../../providers/GlobalAudioContext";

export const TimeLineContainer = ({
    dates = { startDate: new Date(), endDate: new Date() },
    showFilterButton,
    penProps,
    setDates = () => {},
}) => {
    const { styles, imageSizes } = useStylesUtils(getStyles);
    const { homeWorkFilter } = usePopups();
    const { playClickSound } = useContext(GlobalAudioContext);

    const isFilterInitial = useSelector(isFilterInitialState);

    const onPressLeftArrow = useCallback(() => {
        playClickSound();
        setDates({
            startDate: moment(dates.startDate).subtract(1, "days"),
            endDate: moment(dates.endDate).subtract(1, "days"),
        });
    }, [dates]);

    const onPressRightArrow = useCallback(() => {
        playClickSound();
        setDates({
            startDate: moment(dates.startDate).add(1, "days"),
            endDate: moment(dates.endDate).add(1, "days"),
        });
    }, [dates]);

    const isDisabledRightArrow = useMemo(
        () =>
            moment(dates.startDate).isSame(Date.now(), "day") &&
            moment(dates.endDate).isSame(Date.now(), "day"),
        [dates]
    );

    return (
        <View style={styles.navContainer}>
            <TouchableOpacity style={styles.btn} onPress={onPressLeftArrow}>
                <NavLeftBtnIcon {...imageSizes.type1} style={styles.image} />
            </TouchableOpacity>
            <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={styles.navContainerText}>
                {timeLineDateUtil(dates.startDate, dates.endDate, true)}
            </Text>
            <TouchableOpacity
                disabled={isDisabledRightArrow}
                style={styles.btn}
                onPress={onPressRightArrow}>
                <NavRightBtnIcon
                    {...imageSizes.type1}
                    style={[
                        styles.image,
                        isDisabledRightArrow ? styles.disabledImage : {},
                    ]}
                />
            </TouchableOpacity>
            {showFilterButton && (
                <TouchableOpacity
                    style={styles.btn}
                    onPress={() => {
                        playClickSound();
                        homeWorkFilter();
                    }}>
                    <FilterBtnIcon
                        {...imageSizes.type1}
                        style={styles.image}
                        isSelected={!isFilterInitial}
                    />
                </TouchableOpacity>
            )}
            {penProps?.showPenButton && (
                <TouchableOpacity style={styles.btn} onPress={penProps.onPress}>
                    <PenBtnIcon
                        {...imageSizes.type1}
                        style={styles.image}
                        isSelected={penProps.value}
                    />
                </TouchableOpacity>
            )}
        </View>
    );
};

const getStyles = ({ width, height }) => ({
    btn: {},
    image: {
        marginHorizontal: width * 0.01,
    },
    disabledImage: {
        opacity: 0.5,
    },
    navContainer: {
        width: "100%",
        paddingVertical: height * 0.01,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10,
    },
    navContainerText: {
        fontSize: 30,
        color: "#fff",
        fontWeight: 700,
    },
});
