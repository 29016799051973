import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Image, Text, View } from "react-native";
import { useSelector } from "react-redux";
import { InfoIcon } from "../../../assets/svg";
import { PaginationCarousel } from "../../../components";
import { COLOURS } from "../../../constants";
import { useCreateHook, useStylesUtils, usePopups } from "../../../hooks";
import { useDiaryHook } from "../../../hooks/useDiaryHook";
import {
    getStudentHomeworksRedux,
    isFilterInitialState,
} from "../../../store/diary/selectors";
import { parseListsUtils } from "../../../utils/parse-lists.utils";
import { useLocation } from "react-router-native";

export const ContentContainer = () => {
    const { pathname } = useLocation();
    const { styles, height } = useStylesUtils(getStyles);

    const { noHomeworkToShow } = usePopups();

    const {
        getHomeworksList,
        getHomeworkDocument,
        getHomeworkSubjectsList,
        getHomeworkVideo,
    } = useDiaryHook();

    const { homeworkInfo } = usePopups();

    const { getStory } = useCreateHook();
    const { homeworks, filter, totalRecords, pageNumber, pageSize, subjects } =
        useSelector(getStudentHomeworksRedux);

    const isFilterInitial = useSelector(isFilterInitialState);

    const carouselRef = useRef();

    const list = useMemo(() => {
        return (
            parseListsUtils(homeworks, (props) => {
                return {
                    key:
                        String(props.homeworkId) +
                        String(props.homeworkAssetId),
                    onPress: () => onPressCategory(props),
                };
            }) || []
        );
    }, [homeworks]);

    const totalPages = useMemo(
        () => Math.ceil(totalRecords / pageSize),
        [totalRecords, pageSize]
    );

    useEffect(() => {
        const { initialState, ...rest } = filter;
        const fetch = async () => {
            if (!subjects?.length) {
                await getHomeworkSubjectsList();
            }

            await getHomeworksList({
                ...rest,
                pageSize: 5,
                addToExist: false,
            }).then(() => carouselRef?.current?.scrollToIndex({ index: 0 }));
        };

        fetch();
    }, [filter, pathname]);

    useEffect(() => {
        if (!isFilterInitial && list.length === 0 && subjects?.length) {
            noHomeworkToShow();
        }
    }, [isFilterInitial, list]);

    const fetchMore = useCallback(() => {
        if (totalPages < pageNumber + 1) {
            return;
        }
        getHomeworksList({
            ...filter,
            pageNumber: pageNumber + 1,
            pageSize: 5,
            addToExist: true,
        });
    }, [totalPages, filter, pageNumber]);

    const openCategoryPage = (data) => {
        const {
            type,
            cmsAssetId,
            id,
            homeworkAssetId,
            videoProgressInSeconds,
            status,
        } = data;
        switch (type) {
            case "VIDEO":
                return getHomeworkVideo({
                    videoID: cmsAssetId,
                    id,
                    homeworkAssetId,
                    videoProgressInSeconds,
                    isComplete: status === "COMPLETED",
                });
            case "STORY":
                return getStory({
                    params: {
                        storyId: cmsAssetId,
                        showCompletePopup: true,
                        homeworkAssetId,
                        id,
                    },
                });
            case "DOCUMENT":
                return getHomeworkDocument({
                    documentId: cmsAssetId,
                    showCompletePopup: true,
                    homeworkAssetId,
                    id,
                });
        }
    };

    const onPressCategory = (data) => {
        if (data?.assetDescription) {
            homeworkInfo(data.assetDescription, () => openCategoryPage(data));
        } else {
            openCategoryPage(data);
        }
    };
    return list?.length ? (
        <PaginationCarousel
            data={list}
            onFetch={fetchMore}
            showLoader={totalPages >= pageNumber + 1}
            ref={carouselRef}
        />
    ) : (
        <View style={styles.container}>
            <View style={styles.board}>
                <Image
                    style={styles.image}
                    source={require("../../../assets/images/diaryIcons/girl2.png")}
                    resizeMode={"contain"}
                />
                <View style={styles.boardContent}>
                    <InfoIcon height={height * 0.09} width={height * 0.09} />
                    <Text style={styles.boardContentText}>
                        No homework for today!{"\n"} Time to explore!
                        {/*Looks like!{"\n"} There is no homework for Today!*/}
                        {/*{"\n"} Time to explore!*/}
                    </Text>
                </View>
                <Image
                    style={styles.image}
                    source={require("../../../assets/images/diaryIcons/boy2.png")}
                    resizeMode={"contain"}
                />
            </View>
        </View>
    );
};

const getStyles = ({ biggerSize, height }) => ({
    container: {
        flex: 1,
        zIndex: 1,
        width: "100%",
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
    },
    board: {
        flex: 1,
        backgroundColor: COLOURS.purple13,
        borderRadius: 20,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    image: {
        width: biggerSize * 0.2,
        height: biggerSize * 0.2,
    },
    boardContent: {
        flex: 1,
        alignItems: "center",
    },
    boardContentText: {
        fontSize: 34,
        fontWeight: 700,
        color: COLOURS.purple8,
        textAlign: "center",
        marginTop: height * 0.03,
    },
});
