import { uamToCmsExamId } from "../../utils/uamToCmsExamId.utils";
import { uamToCmsGradeId } from "../../utils/uamToCmsGradeId.utils";

export const getUserAuthorized = ({ auth }) => auth.isAuthorized;
export const getUserInfo = ({ auth }) => auth.user;
export const getUserInfoForQueries = ({ auth }) =>
    auth.user
        ? {
              userId: auth.user.userId,
              subtenantId: auth.user?.subTenant,
              gradeId: auth.user?.grade?.gradeId,
              cmsGradeId: uamToCmsGradeId(
                  auth?.user.exams?.[0]?.examId,
                  auth?.user?.grade?.gradeId
              ),
              tenantId: auth?.user?.tenantId,
              accessToken: auth.accessToken,
              examId: uamToCmsExamId(
                  auth?.user?.exams?.[0]?.examId,
                  auth?.user?.grade?.gradeId
              ),
              examIdRaw: auth.user.exams?.[0]?.examId,
              admissionNumber: auth?.user?.admissionNumber,
          }
        : {};

// {
//      "cmsExamId": 32,
//      "cmsExamName": "SC_Grade 6 AP State Board",
//      "cmsGradeId": 6,
//      "cmsGradeName": "6",
//      "id": 36,
//      "uamExamId": 7,
//      "uamExamName": "AP State Board",
//      "uamGradeId": 13,
//      "uamGradeName": "6th"
// }

export const getMappingInfo = ({ auth }) =>
    auth?.mappingInfo ? auth.mappingInfo : {};
export const getUserVerifyInfo = ({ auth }) => auth.verifyUidInfo;
export const getTimeLastLogin = ({ auth }) => auth.timeLastLogin;
