import React from "react";
import { View, Text } from "react-native";
import { useStylesUtils } from "../../../../../hooks";
import { OptionsRow } from "./OptionsRow";
import { SvgDrawer } from "./SvgDrawer";
import { COLOURS } from "../../../../../constants";

export const QuizMatchCard = () => {
    const { styles } = useStylesUtils(getStyles());

    return (
        <View style={{ flex: 1, flexDirection: "row" }}>
            <View style-={{ flex: 0, flexDirection: "column" }}>
                <View style={styles.typeView}>
                    <View style={styles.typeWrapper}>
                        <Text style={styles.typeText}>A</Text>
                    </View>
                </View>
                <View style={{ flex: 1 }} />
                <View style={styles.typeView}>
                    <View style={styles.typeWrapper}>
                        <Text style={styles.typeText}>B</Text>
                    </View>
                </View>
            </View>
            <View style={styles.container}>
                <OptionsRow type={"a"} />
                <SvgDrawer />
                <OptionsRow type={"b"} />
            </View>
        </View>
    );
};

const getStyles =
    () =>
    ({ biggerSize }) => ({
        container: {
            flex: 1,
            width: "100%",
            height: "100%",
            flexDirection: "column",
            justifyContent: "space-between",
        },
        typeView: {
            flex: 1,
            width: biggerSize * 0.05,
            justifyContent: "center",
            alignItems: "center",
        },
        typeWrapper: {
            paddingBottom: biggerSize * 0.002,
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            borderRadius: 5,
            overflow: "hidden",
        },
        typeText: {
            backgroundColor: COLOURS.white,
            paddingVertical: biggerSize * 0.002,
            paddingHorizontal: biggerSize * 0.008,
            fontSize: 20,
            fontWeight: 700,
        },
    });
