import moment from "moment";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfoForQueries } from "../store/auth/selectors";
import {
    getStudentHomeworksSubjectsThunk,
    getStudentHomeworksThunk,
    getVideoByVideoIdThunk,
    setHomeworkStatusToCompleteThunk,
    setHomeworkVideoProgressThunk,
} from "../store/diary/thunks";
import { usePopups } from "./usePopups";
import { getDocumentByDocumentIdThunk } from "../store/main/thunks";
import { navigation } from "../constants";
import { useNavigate } from "react-router-native";
import { parseDatesToStrUtil } from "../utils/date.utils";
import { useAuth } from "./useAuth";

export const useDiaryHook = () => {
    const dispatch = useDispatch();
    const { getMainListError } = usePopups();
    const { checkRejectResponse } = useAuth();

    const { admissionNumber } = useSelector(getUserInfoForQueries);
    const navigate = useNavigate();

    const getHomeworksList = useCallback(async (data = {}) => {
        const params = parseDatesToStrUtil({
            ...data,
            admNo: admissionNumber,
        });

        if (
            moment(data.startDate).isSame(Date.now(), "day") &&
            moment(data.endDate).isSame(Date.now(), "day")
        ) {
            params.startDate = "";
            params.endDate = "";
        }
        const result = await dispatch(getStudentHomeworksThunk(params));
        if (getStudentHomeworksThunk.fulfilled.match(result)) {
            return Promise.resolve(result);
        }

        if (getStudentHomeworksThunk.rejected.match(result)) {
            await checkRejectResponse(result);
            getMainListError({ result });
        }
    }, []);

    const getHomeworkSubjectsList = useCallback(async () => {
        const result = await dispatch(getStudentHomeworksSubjectsThunk());

        if (getStudentHomeworksSubjectsThunk.rejected.match(result)) {
            await checkRejectResponse(result);
        }
    }, []);

    const getHomeworkDocument = useCallback(async (data = {}) => {
        const result = await dispatch(getDocumentByDocumentIdThunk(data));
        if (getDocumentByDocumentIdThunk.fulfilled.match(result)) {
            const state = {
                documentUrl: result.payload?.documentUrl,
                showCompletePopup: data.showCompletePopup,
            };
            changeHwStatus(data);
            navigate(navigation.DOCUMENT_SCREEN, { state });

            return Promise.resolve(result);
        }

        if (getDocumentByDocumentIdThunk.rejected.match(result)) {
            await checkRejectResponse(result);
            getMainListError({ result });
        }
    }, []);

    const getHomeworkVideo = useCallback(async (data = {}) => {
        const {
            videoID,
            id,
            homeworkAssetId,
            videoProgressInSeconds,
            isComplete,
        } = data;

        const result = await dispatch(getVideoByVideoIdThunk({ videoID }));

        if (getVideoByVideoIdThunk.fulfilled.match(result)) {
            navigate(navigation.VIDEO_SCREEN, {
                state: {
                    video: result.payload.video,
                    studentAssignmentId: id,
                    homeworkAssetId,
                    videoProgressInSeconds,
                    isComplete,
                },
            });

            return Promise.resolve(result);
        }

        if (getVideoByVideoIdThunk.rejected.match(result)) {
            await checkRejectResponse(result);
            getMainListError({ result });
        }
    }, []);

    const setHomeworkStatusToComplete = async (data) => {
        const result = await dispatch(setHomeworkStatusToCompleteThunk(data));

        if (setHomeworkStatusToCompleteThunk.rejected.match(result)) {
            await checkRejectResponse(result);
            getMainListError({ result });
        }
    };

    const setHomeworkVideoProgress = async (data) => {
        const result = await dispatch(setHomeworkVideoProgressThunk(data));
        if (setHomeworkVideoProgressThunk.fulfilled.match(result)) {
            return Promise.resolve(result);
        }

        if (setHomeworkVideoProgressThunk.rejected.match(result)) {
            await checkRejectResponse(result);
            getMainListError({ result });
        }
    };

    const changeHwStatus = (data = {}) => {
        const { id = null, homeworkAssetId = null } = data;

        if (id && homeworkAssetId) {
            setHomeworkStatusToComplete({
                studentAssignmentId: id,
                homeworkAssetId,
            });
        }
    };

    return {
        getHomeworkSubjectsList,
        getHomeworksList,
        getHomeworkDocument,
        getHomeworkVideo,
        setHomeworkStatusToComplete,
        setHomeworkVideoProgress,
        changeHwStatus,
    };
};
