import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={51}
            height={40}
            viewBox="0 0 51 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.974.532H7.97c-.48 0-.932.187-1.273.528a1.81 1.81 0 00-.535 1.29v27.256c0 1 .816 1.815 1.82 1.818 4.23.01 11.32.892 16.21 6.008V8.908c0-.339-.087-.657-.25-.92C19.928 1.524 12.215.542 7.974.532M45.043 29.606V2.35c0-.488-.19-.945-.535-1.29a1.789 1.789 0 00-1.274-.527h-.004c-4.24.01-11.953.992-15.967 7.456-.163.263-.25.581-.25.92v28.524c4.89-5.117 11.979-6 16.21-6.01a1.824 1.824 0 001.82-1.816z"
                fill="#B7245C"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M49.183 6.818h-1.318v22.789a4.65 4.65 0 01-4.635 4.639c-3.59.008-9.506.71-13.697 4.676 7.248-1.774 14.889-.621 19.243.371a1.81 1.81 0 001.54-.35c.435-.347.684-.865.684-1.42V8.634a1.819 1.819 0 00-1.817-1.817M3.34 29.607V6.817H2.02A1.82 1.82 0 00.204 8.636v28.887c0 .556.249 1.073.683 1.42a1.81 1.81 0 001.541.351c4.355-.993 11.996-2.146 19.244-.372-4.191-3.966-10.109-4.667-13.697-4.675-2.556-.007-4.636-2.088-4.636-4.64"
                fill="#B7245C"
            />
        </Svg>
    );
}

export default SvgComponent;
