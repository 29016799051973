import { TouchableOpacity, View } from "react-native";
import { useStylesUtils } from "../../../../hooks";
import { COLOURS } from "../../../../constants";
import { EditIcon } from "../../../../assets/svg";

export const EditBtn = ({ onPress }) => {
    const { styles, width } = useStylesUtils(getStyles);

    return (
        <TouchableOpacity onPress={onPress}>
            <View style={styles.btn}>
                <EditIcon width={width * 0.03} height={width * 0.03} />
            </View>
        </TouchableOpacity>
    );
};

const getStyles = ({ width }) => ({
    btn: {
        width: width * 0.06,
        height: width * 0.06,
        borderRadius: width,
        borderWidth: 3,
        borderColor: "#7E1121",
        backgroundColor: COLOURS.lightPink,
        justifyContent: "center",
        alignItems: "center",
    },
});
