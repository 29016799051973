import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MainLayout } from "../../../components";
import { TimeLineContainer } from "../../../components/screens/TimeLineContainer";
import { getStudentHomeworksRedux } from "../../../store/diary/selectors";
import { setFilterData, resetFilter } from "../../../store/diary/slice";
import { ContentContainer } from "./ContentContainer";
import { homeWorkTabs } from "../../../components/tabs/homeWorkTabs";

export const HomeWorkScreen = () => {
    const dispatch = useDispatch();

    const {
        filter: { startDate, endDate },
    } = useSelector(getStudentHomeworksRedux);

    const setDates = useCallback(
        (payload) => {
            dispatch(setFilterData(payload));
        },
        [startDate, endDate]
    );

    useEffect(() => {
        return () => {
            dispatch(resetFilter());
        };
    }, []);

    return (
        <MainLayout
            viewStyle={{ zIndex: 15 }}
            tabs={homeWorkTabs()}
            headerProps={{
                backButton: true,
                homeButton: true,
                showBgCloud: true,
                titleContent: <></>,
            }}>
            <TimeLineContainer
                dates={{ startDate, endDate }}
                setDates={setDates}
                showFilterButton={true}
            />
            <ContentContainer />
        </MainLayout>
    );
};
