import {
    QUIZ_OPTION_STATUS,
    QUIZ_QUESTION_STATUS,
} from "../../../../constants";
import React from "react";
import { findAnswer } from "../utils/QuizUtils";
import _ from "lodash";
import { value } from "lodash/seq";

const tagsRegExp = /(<([^>]+)>)|&nbsp;/gi;
const optionRegExp = /(\[\[.*?\]\])/g;
const srcRegExp = /src="data:image\/png;base64,([a-zA-Z0-9+/=]+)"/;

class FillTheBlanksFactory {
    static prepareQuestionData(question, answers, setAnswers, isFinished) {
        const splittedTemplate = this.prepareSplittedTemplate(
            question.question_text
        );

        return {
            questionText: question.question_text,
            splittedTemplate,
            listOfOptions: this.prepareListOfOptions(
                question,
                answers,
                setAnswers,
                isFinished
            ),
            onOptionPick: this.getOnOptionPickHandler(
                question,
                answers,
                setAnswers,
                isFinished
            ),
        };
    }
    static prepareSplittedTemplate(question_text) {
        return question_text.replace(tagsRegExp, "").split(optionRegExp);
    }
    static getOptionsPlaceholders(template) {
        return template.replace(tagsRegExp, "").match(optionRegExp);
    }
    static prepareListOfOptions(question, answers, setAnswers, isFinished) {
        return question.correct_answer.map((option, index) => ({
            id: `${question.question_id}_${index + 1}`,
            validValue: option,
            status: this.getOptionStatus(
                question,
                answers,
                `${question.question_id}_${index + 1}`,
                option,
                isFinished
            ),
            value: this.getOptionValue(
                answers,
                question,
                `${question.question_id}_${index + 1}`
            ),
        }));
    }
    static getOptionValue(answers, question, optionId) {
        const answer = findAnswer(answers, question.question_id);
        if (!answer) {
            return "";
        }
        const option = answer.options.find(
            (option) => option.optionId === optionId
        );
        if (!option) {
            return "";
        }

        return option.value;
    }
    static getOptionStatus(
        question,
        answers,
        optionId,
        validValue,
        isFinished
    ) {
        const answer = findAnswer(answers, question.question_id);
        if (isFinished) {
            if (!answer || !answer?.options || !answer?.options.length) {
                return QUIZ_OPTION_STATUS.ERROR;
            }

            const answerOption = answer?.options.find(
                (el) => el.optionId === optionId
            );
            if (!answerOption) {
                return QUIZ_OPTION_STATUS.ERROR;
            }
            if (
                answerOption.value.trim().toLowerCase() ===
                validValue.trim().toLowerCase()
            ) {
                return QUIZ_OPTION_STATUS.SUCCESS;
            }
            return QUIZ_OPTION_STATUS.ERROR;
        }

        if (!isFinished) {
            const answerOption = answer?.options.find(
                (el) => el.optionId === optionId
            );

            if (answer && answerOption) {
                return QUIZ_OPTION_STATUS.CHECKED;
            }
            return QUIZ_OPTION_STATUS.NONE;
        }

        return QUIZ_OPTION_STATUS.NONE;
    }
    static getOnOptionPickHandler(question, answers, setAnswers, isFinished) {
        if (isFinished) {
            return () => {};
        }
        return (optionId, value) => {
            setAnswers((prevState) => {
                const answer = findAnswer(prevState, question.question_id);
                if (!answer) {
                    prevState.push({
                        question_id: question.question_id,
                        options: [{ optionId, value }],
                    });
                    return this.checkAnswersState(question, [...prevState]);
                }

                const option = answer.options.find(
                    (el) => el.optionId === optionId
                );
                if (option) {
                    option.value = value;
                    return this.checkAnswersState(question, [...prevState]);
                }

                answer.options.push({ optionId, value });
                return this.checkAnswersState(question, [...prevState]);
            });
        };
    }

    ///////////////////////
    static calcQuestion(question, answers, isFinished) {
        return {
            status: this.getQuestionStatus(question, answers, isFinished),
            mark: this.getQuestionMark(question, answers, isFinished),
        };
    }
    static getQuestionStatus(question, answers, isFinished) {
        if (!question || !answers) {
            return isFinished
                ? QUIZ_QUESTION_STATUS.ERROR
                : QUIZ_QUESTION_STATUS.NEW;
        }
        const answer = findAnswer(answers, question.question_id);
        if (isFinished) {
            if (!answer) {
                return QUIZ_QUESTION_STATUS.ERROR;
            }
            return this.questionStatusByAnswer(question, answer, isFinished);
        }
        if (!answer) {
            return QUIZ_QUESTION_STATUS.NEW;
        }
        if (answer.options.length > 0) {
            return QUIZ_QUESTION_STATUS.VISITED;
        }
        return QUIZ_QUESTION_STATUS.NEW;
    }
    static questionStatusByAnswer(question, answer, isFinished) {
        let validCount = 0;
        let invalidCount = 0;

        for (const correctAnswer of question.correct_answer) {
            let answerIsValid = false;
            for (const answerOption of answer.options) {
                if (
                    answerOption.value.trim().toLowerCase() ===
                    correctAnswer.trim().toLowerCase()
                ) {
                    answerIsValid = true;
                    break;
                }
            }
            if (answerIsValid) {
                validCount++;
            } else {
                invalidCount++;
            }
        }

        if (validCount === question.correct_answer.length) {
            return QUIZ_QUESTION_STATUS.SUCCESS;
        }
        if (validCount > 0 && invalidCount > 0) {
            return QUIZ_QUESTION_STATUS.SEMI_ERROR;
        }

        return QUIZ_QUESTION_STATUS.ERROR;
    }
    static getQuestionMark(question, answers, isFinished) {
        return 0;
    }

    static checkAnswersState(question, state = []) {
        const answer = findAnswer(state, question?.question_id);

        if (!question || !state?.length || !answer?.options?.length) {
            return state;
        }

        const { answer_options, correct_answer } = question;

        const respObj = {};
        correct_answer.forEach((value, index) => {
            respObj[`${question.question_id}_${index + 1}`] = "";
        });

        const optionsResponse = answer.options.map(({ optionId, value }) => {
            respObj[optionId] = value.replaceAll("~`", "");
            return value.trim().toLowerCase();
        });

        const notSensetiveCorrectAnswear = correct_answer.map((value) => {
            return value?.trim()?.toLowerCase();
        });
        answer.correct = _.isEqual(optionsResponse, notSensetiveCorrectAnswear);

        let responseString = "";
        let isFirst = true;
        for (const [key, value] of Object.entries(respObj)) {
            if (isFirst) {
                responseString = responseString + (respObj[key] || "");
                isFirst = false;
            } else {
                responseString = responseString + "~`" + (respObj[key] || "");
            }
        }

        answer.response = responseString;

        return state;
    }
}

export default FillTheBlanksFactory;
