import React from "react";
import { ImageBackground, TextInput } from "react-native";
import { COLOURS, images } from "../../../constants";
import { useStylesUtils } from "../../../hooks";

const types = {
    default: {
        image: images.inputBg,
        imageError: images.inputBgError,
        ratio: { height: 0.044, width: 0.23 },
    },
    2: {
        image: images.inputBg2,
        ratio: { height: 0.06, width: 0.5 },
    },
};

export const Input = ({
    children,
    inputState: {
        value = "",
        setValue = () => {},
        isError = false,
        style: inputStyle = {},
    },
    style = {},
    backgroundImageStyle = {},
    type = "default",
}) => {
    const { image, ratio, imageError } = types[type];

    const { styles } = useStylesUtils(getStyles(ratio));

    return (
        <ImageBackground
            source={isError && imageError ? imageError : image}
            imageStyle={[styles.background, backgroundImageStyle]}
            style={{ ...styles.container, ...style }}
            resizeMode="cover">
            <TextInput
                style={[styles.input, inputStyle]}
                value={value}
                onChangeText={setValue}
                disableFullscreenUI={true}
            />
            {children}
        </ImageBackground>
    );
};

const getStyles =
    (ratio) =>
    ({ width, biggerSize }) => ({
        container: {
            height: biggerSize * ratio.height,
            width: biggerSize * ratio.width,
            justifyContent: "center",
        },
        input: {
            width: "100%",
            height: biggerSize * 0.05,
            paddingHorizontal: 10,
            color: COLOURS.dark_purple,
            borderWidth: 0,
            fontSize: 16,
        },
        background: {
            height: biggerSize * ratio.height,
            width: biggerSize * ratio.width,
        },
    });
