import _ from "lodash";
import { parseDatesToStrUtil } from "../../utils/date.utils";

export const getStudentHomeworksRedux = ({ diary }) => diary.studentHomeworks;
export const isFilterInitialState = ({ diary }) => {
    const { initialState, ...rest } = diary.studentHomeworks.filter;

    return _.isEqual(
        parseDatesToStrUtil(rest),
        parseDatesToStrUtil(initialState)
    );
};
