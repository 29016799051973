import * as NavigationBar from "expo-navigation-bar";
import { useEffect } from "react";
import {
    ImageBackground,
    Keyboard,
    KeyboardAvoidingView,
    Platform,
    SafeAreaView,
    TouchableWithoutFeedback,
    View,
} from "react-native";
import { COLOURS, images } from "../../constants";
import { useKeyboardListener, useStylesUtils } from "../../hooks";
import { HardwareBackProvider } from "../../providers/hardwareBackProvider";
import { HeaderComponent } from "./Header";

const { isPad } = Platform;

export const AuthLayout = ({ children, headerProps = {} }) => {
    const { styles, isAndroid, isWeb } = useStylesUtils(getStyles);

    const isKeyboardVisible = useKeyboardListener();

    const hideKeyboard = () => {
        if (Platform.OS === "web") return;
        Keyboard.dismiss();
    };

    useEffect(() => {
        if (isAndroid) {
            NavigationBar.setBehaviorAsync("overlay-swipe");
            NavigationBar.setVisibilityAsync("hidden");
        }
    }, []);
    useEffect(() => {
        // if (isWeb) {
        //     localStorage.clear();
        // }
    }, []);

    return (
        <HardwareBackProvider>
            <TouchableWithoutFeedback
                style={styles.touchable}
                onPress={hideKeyboard}>
                <View style={styles.container}>
                    <ImageBackground
                        source={images.authBG}
                        imageStyle={styles.background}
                        resizeMode="cover"
                    />
                    <HeaderComponent
                        hide={isKeyboardVisible && !isPad}
                        showBgCloud={false}
                        {...headerProps}
                    />
                    <SafeAreaView style={styles.content}>
                        <KeyboardAvoidingView
                            style={styles.content}
                            behavior={
                                Platform.OS === "ios" ? "padding" : "height"
                            }
                            keyboardVerticalOffset={0}>
                            {children}
                        </KeyboardAvoidingView>
                    </SafeAreaView>
                </View>
            </TouchableWithoutFeedback>
        </HardwareBackProvider>
    );
};

const getStyles = ({ height }) => ({
    touchable: {
        zIndex: -1,
    },
    container: {
        flex: 1,
        backgroundColor: COLOURS.lightOrange,
        overflow: "hidden",
    },
    background: {
        height: height,
        transform: [{ scale: 1.1 }],
    },
    content: {
        flex: 1,
        justifyContent: "flex-start",
        alignItems: "center",
    },
});
