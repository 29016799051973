import { Text, TouchableOpacity, View, FlatList } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import React, { useCallback, useContext, useRef, useLayoutEffect } from "react";
import QuizContext from "../../QuizContext";
import { COLOURS } from "../../../../../constants";
import { useStylesUtils } from "../../../../../hooks";
import { Button } from "../../../../../components";

export const QuizProgressIndicator = () => {
    const insets = useSafeAreaInsets();
    const { styles } = useStylesUtils(getStyles(insets));

    const carouselRef = useRef(null);
    const {
        questions,
        currentQuestion,
        getQuestionStatus,
        onQuestionPick,
        answers,
        submitButtonShow,
        submitButtonOnPress,
        isFinished,
    } = useContext(QuizContext);

    useLayoutEffect(() => {
        if (submitButtonShow) {
            carouselRef?.current?.scrollToIndex({
                index: questions.length - 1,
                animated: true,
            });
        }
    }, [carouselRef, submitButtonShow, questions]);

    const buttonStyle = useCallback(
        (question) => {
            const [prev, curr, next] = [
                question.index === currentQuestion.index - 1,
                question.index === currentQuestion.index,
                question.index === currentQuestion.index + 1,
            ];

            const { backgroundColor, color = COLOURS.black } =
                styles[getQuestionStatus(question, answers, isFinished)];
            return [
                {
                    backgroundColor,
                    ...(curr ? styles.selected : {}),
                    ...(prev || next ? styles.selected_neighbour : {}),
                },
                { color },
            ];
        },
        [currentQuestion, answers, isFinished, questions]
    );

    return (
        <View style={styles.container}>
            <View style={styles.scrollContainer}>
                <FlatList
                    showsHorizontalScrollIndicator={true}
                    showsVertivalScrollIndicator={true}
                    onScrollToIndexFailed={(e) => console.log(e)}
                    ref={carouselRef}
                    data={questions}
                    horizontal={true}
                    contentContainerStyle={{
                        alignItems: "flex-end",
                        margin: "auto",
                    }}
                    renderItem={({ item }, i) => {
                        const [button, text] = buttonStyle(item);

                        return (
                            <TouchableOpacity
                                key={item.index + i}
                                style={[styles.item, button]}
                                onPress={() => onQuestionPick(item)}>
                                <Text style={[styles.text, text]}>
                                    {item.index + 1}
                                </Text>
                            </TouchableOpacity>
                        );
                    }}
                />
            </View>

            {submitButtonShow && (
                <View>
                    <Button
                        label={"Submit"}
                        textStyle={{ color: "black" }}
                        iconProps={{ fill: COLOURS.blue7, ratio: 0.12 }}
                        onPress={submitButtonOnPress}
                    />
                </View>
            )}
        </View>
    );
};

const getStyles =
    (insets) =>
    ({ width, sizes, biggerSize, isWeb }) => ({
        container: {
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
        },
        scrollContainer: {
            flex: 1,
            alignItems: isWeb ? "stretch" : "center",
            justifyContent: "center",
            paddingBottom: insets.bottom ? insets.bottom : biggerSize * 0.015,
        },
        item: {
            width: sizes.quizCard,
            height: sizes.quizCard,
            borderRadius: 8,
            backgroundColor: COLOURS.white,
            marginHorizontal: sizes.quizCard * 0.08,
            justifyContent: "center",
            alignItems: "center",
            justifySelf: "flex-end",
        },
        //proportion 100/70 = 1.42857
        selected: {
            width: sizes.quizCard * 1.42857,
            height: sizes.quizCard * 1.42857,
            borderWidth: 4,
            borderColor: COLOURS.white,
        },
        //proportion 80/70 = 1.14285
        selected_neighbour: {
            width: sizes.quizCard * 1.14285,
            height: sizes.quizCard * 1.14285,
        },
        question_new: {
            backgroundColor: COLOURS.white,
        },
        question_visited: {
            backgroundColor: COLOURS.quizItemVisited,
            color: COLOURS.white,
        },
        question_success: {
            backgroundColor: COLOURS.quizItemSuccess,
            color: COLOURS.white,
        },
        question_semi_error: {
            backgroundColor: COLOURS.quizItemSemiError,
        },
        question_error: {
            backgroundColor: COLOURS.quizItemError,
            color: COLOURS.white,
        },
        text: {
            fontWeight: 700,
            fontSize: 14,
        },
    });
