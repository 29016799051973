import { View } from "react-native";
import { useStylesUtils } from "../../../../../hooks";
import Svg, { Circle, Path } from "react-native-svg";
import { useMemo, useContext, useState } from "react";
import { calcCurvePath } from "../../utils/QuizUtils";
import { COLOURS } from "../../../../../constants";
import QuizContext from "../../QuizContext";

export const SvgDrawer = () => {
    const {
        currentQuestionData: { list, options },
    } = useContext(QuizContext);

    const { styles, biggerSize, isWeb } = useStylesUtils(getStyles);
    const [coords, setCoords] = useState({ h: 0, w: 0 });

    const r = biggerSize * 0.006;

    const { a, b, curves, acy, bcy } = useMemo(() => {
        const { h, w } = coords;

        const acy = 15;
        const bcy = h - acy;

        const addCxCoords = (value, i, all) => {
            const color = options?.find(
                (item) => item[value.type] === value.index
            )?.color;

            return {
                ...value,
                cx: (w / all.length) * i + w / all.length / 2,
                color,
            };
        };

        const a = list?.a?.map(addCxCoords);
        const b = list?.b?.map(addCxCoords);

        const curves = options
            ?.map((value) => {
                const acx = a.find((item) => item.index === value.a)?.cx;
                const bcx = b.find((item) => item.index === value.b)?.cx;

                if (!acx || !bcx) {
                    return undefined;
                }

                return {
                    path: calcCurvePath(
                        { cx: acx, y: acy },
                        { cx: bcx, y: bcy }
                    ),
                    color: value.color,
                };
            })
            .filter(Boolean);

        return {
            a,
            b,
            curves,
            acy,
            bcy,
        };
    }, [options, list, coords]);

    const setLayoutCoords = (event) => {
        const layout = event.nativeEvent.layout;
        setCoords({
            h: layout.height,
            w: layout.width,
        });
    };

    const centralLinePath = !isWeb
        ? "M1.344 1.89H1476.7"
        : calcCurvePath(
              { cx: 0, y: coords.h / 2 - 1 },
              { cx: coords.w, y: coords.h / 2 - 1 }
          );

    return (
        <View style={{ flex: 1 }} onLayout={setLayoutCoords}>
            {coords.h && coords.w ? (
                <View style={styles.container}>
                    <Svg
                        viewBox={`0 0 ${coords.w} ${coords.h}`}
                        fill="black"
                        stroke="black">
                        <Path
                            y={coords.h / 2 - 1}
                            d={centralLinePath}
                            stroke={COLOURS.blue8}
                            strokeOpacity={0.32}
                            strokeWidth={2}
                            strokeLinecap="round"
                        />
                        {/*top dots*/}
                        {a?.map(({ cx, color = COLOURS.yellow1 }) => (
                            <Circle
                                key={cx + acy}
                                cx={cx}
                                cy={acy}
                                r={r}
                                stroke={"transparent"}
                                fill={color}
                            />
                        ))}
                        {/*/!*bottom dots*!/*/}
                        {b?.map(({ cx, color = COLOURS.yellow1 }) => (
                            <Circle
                                key={cx + bcy}
                                cx={cx}
                                cy={bcy}
                                r={r}
                                stroke={"transparent"}
                                fill={color}
                            />
                        ))}
                        {/* curves */}
                        {curves?.map(({ path, color }) => (
                            <Path
                                key={path + color}
                                d={path}
                                stroke={color}
                                strokeWidth={r}
                                fillRule={"evenodd"}
                                fill="transparent"
                            />
                        ))}
                    </Svg>
                </View>
            ) : (
                <></>
            )}
        </View>
    );
};

const getStyles = () => ({
    container: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
    },
});
