import * as React from "react";
import Svg, { G, Path, Defs } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent(props) {
    return (
        <Svg
            width={124}
            height={115}
            viewBox="0 0 124 115"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <G filter="url(#filter0_d_10142_3)">
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.898 24.707C6.243 46.002 7.986 75.829 29.282 90.485c21.295 14.655 62.086 10.332 76.741-10.963 14.656-21.295 5.096-50.565-16.199-65.22C68.53-.354 35.554 3.412 20.898 24.707"
                    fill="#6DB160"
                />
                <Path
                    d="M20.898 24.707C6.243 46.002 7.986 75.829 29.282 90.485c21.295 14.655 62.086 10.332 76.741-10.963 14.656-21.295 5.096-50.565-16.199-65.22C68.53-.354 35.554 3.412 20.898 24.707"
                    stroke="#5F0FAA"
                    strokeWidth={5}
                />
            </G>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.783 55.205c4.71 3.261 7.174 3.852 11.233 10.69 4.06 6.838 4.77 11.49 5.839 12.345 1.068.854 6.41-.214 9.188-1.71 2.778-1.496 2.882-2.321 5.476-8.386 3.595-8.41 9.436-18.733 13.923-24.716 4.487-5.983 13.934-13.853 14.362-15.349.427-1.495-4.06-2.19-8.334-.908-4.273 1.282-13.275 11.184-18.711 18.975-6.039 8.655-8.185 15.961-9.253 15.748-1.069-.214-3.157-4.571-6.148-7.776-2.991-3.205-6.143-5.377-9.776-4.736s-9.972 4.319-7.8 5.823"
                fill="#fff"
            />
            <Path
                d="M31.783 55.205c4.71 3.261 7.174 3.852 11.233 10.69 4.06 6.838 4.77 11.49 5.839 12.345 1.068.854 6.41-.214 9.188-1.71 2.778-1.496 2.882-2.321 5.476-8.386 3.595-8.41 9.436-18.733 13.923-24.716 4.487-5.983 13.934-13.853 14.362-15.349.427-1.495-4.06-2.19-8.334-.908-4.273 1.282-13.275 11.184-18.711 18.975-6.039 8.655-8.185 15.961-9.253 15.748-1.069-.214-3.157-4.571-6.148-7.776-2.991-3.205-6.143-5.377-9.776-4.736s-9.972 4.319-7.8 5.823"
                stroke="#fff"
                strokeWidth={2}
            />
            <Defs></Defs>
        </Svg>
    );
}

export default SvgComponent;
