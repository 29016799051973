import React, {
    createContext,
    useCallback,
    useMemo,
    useState,
    useEffect,
} from "react";
import { usePopups, useTestsHook } from "../../../hooks";
import { QuizFactory } from "./QuizCardFactory";
import { useSelector } from "react-redux";
import { getUserCurrentTest } from "../../../store/tests/selectors";
import { useNavigate } from "react-router-native";

const QuizContext = createContext({
    setupQuiz: () => {},
    isFinished: false,
    //general data
    questions: [],
    answers: [],
    currentQuestion: undefined,
    currentQuestionData: undefined,
    calcQuestion: () => {},
    getQuestionStatus: () => {},
    getQuestionMark: () => {},
    //TitleContent vars
    totalPages: 0,
    currentPage: 0,
    //eventHandlers
    onPressPrevQuestion: () => {},
    onPressNextQuestion: () => {},
    onQuestionPick: () => {},
    //TimerComponent vars
    countdownDuration: 0,
    timerRemainTime: () => {},
    onTimerFinished: () => {},
    //SubmitButton vars
    submitButtonShow: false,
    submitButtonOnPress: () => {},
});

export const QuizContextProvider = (props) => {
    const navigate = useNavigate();
    const testData = useSelector(getUserCurrentTest);

    const {
        studentTestFinish,
        markStudentTestQuestionAsVisited,
        updateStudentTestSpentTime,
    } = useTestsHook();
    const { testSubmit, testTimeIsOver } = usePopups();

    const [quizData, setQuizData] = useState(undefined);
    const [questions, setQuestions] = useState(undefined);
    const [currentQuestion, setCurrentQuestion] = useState(undefined);
    const [answers, setAnswers] = useState([]);
    const [isFinished, setIsFinished] = useState(false);

    useEffect(() => {
        if (currentQuestion && !isFinished) {
            markStudentTestQuestionAsVisited(currentQuestion);
        }
    }, [currentQuestion]);

    useEffect(() => {
        setupQuiz(testData);
    }, []);

    const { countdownDuration, studentTestId } = useMemo(() => {
        const studentTestId = quizData?.detailsSimplified?.studentTestId;

        let time = 0;

        if (quizData?.detailsSimplified?.duration) {
            time = quizData?.detailsSimplified?.duration * 1000;
        }
        if (quizData?.spentTimeRedis) {
            time = quizData?.spentTimeRedis * 1000;
        }

        return {
            countdownDuration: time,
            studentTestId,
        };
    }, [quizData]);

    const setupQuiz = (quizData) => {
        if (!Object.keys(quizData?.detailsSimplified)?.length) {
            // navigate(-1);
            return;
        }
        const curQuestionId =
            testData?.details?.scheduleTest[0]?.lastAttempt?.queId;
        let curQuestion = undefined;
        if (curQuestionId) {
            curQuestion = quizData?.detailsSimplified?.questionListDetails.find(
                (el) => el.testQuestion_id === curQuestionId
            );
        }
        setQuizData(quizData);
        setQuestions(quizData?.detailsSimplified?.questionListDetails);
        setCurrentQuestion({
            ...(curQuestion ||
                quizData?.detailsSimplified?.questionListDetails[0]),
            studentTestId: quizData?.detailsSimplified?.studentTestId,
            startTime: Date.now(),
            finishTime: null,
            prev: null,
        });
        setIsFinished(quizData.isFinished);

        const userAnswer =
            quizData?.detailsSimplified?.questionListDetails.reduce(
                (result, value) => {
                    if (value?.userAnswer) {
                        result.push(value?.userAnswer);
                    }
                    return result;
                },
                []
            );

        if (userAnswer?.length) {
            setAnswers(userAnswer);
        }
    };

    const onQuestionPick = useCallback(
        (question) => {
            setCurrentQuestion((prevQuestion) => {
                const { prev, ...rest } = prevQuestion;

                const answer = prevQuestion
                    ? answers.find(
                          ({ question_id }) =>
                              question_id === prevQuestion.question_id
                      )
                    : null;

                return {
                    ...question,
                    studentTestId: prevQuestion.studentTestId,
                    startTime: Date.now(),
                    prev: {
                        ...rest,
                        answer,
                        finishTime: Date.now(),
                    },
                };
            });
        },
        [answers, questions]
    );

    const onPressPrevQuestion = () => {
        const index = currentQuestion.index;
        const findPrev = questions[index - 1];

        if (index > 0 && !!findPrev) {
            onQuestionPick(findPrev);
        }
    };

    const onPressNextQuestion = () => {
        const index = currentQuestion.index;
        const findNext = questions[index + 1];

        if (index < questions.length - 1 && !!findNext) {
            onQuestionPick(findNext);
        }
    };

    const saveCurrentQuestion = useCallback(async () => {
        if (isFinished) {
            return;
        }

        const { prev, ...rest } = currentQuestion;

        const parseAnswers = await QuizFactory.checkAnswersState(rest, answers);

        rest.answer = parseAnswers.find(
            ({ question_id }) => rest.question_id === question_id
        );

        await markStudentTestQuestionAsVisited(rest, true);
    }, [currentQuestion, answers, isFinished]);

    const finishTest = useCallback(
        async ({ studentTestId }) => {
            await saveCurrentQuestion();

            studentTestFinish({ studentTestId })
                .then(() => {
                    setIsFinished(true);
                })
                .catch((err) => {
                    console.error("finishTest ERROR", err);
                });
        },
        [currentQuestion, answers]
    );

    const timerRemainTime = useCallback(
        (time) => {
            const spentTime = Math.ceil(time / 1000);

            if (spentTime % 10 === 0) {
                updateStudentTestSpentTime({ spentTime, studentTestId });
            }

            if (spentTime === 0 && !isFinished) {
                testTimeIsOver(() => finishTest({ studentTestId }));
            }
        },
        [isFinished, countdownDuration, studentTestId]
    );

    const currentQuestionData = useMemo(() => {
        return QuizFactory.prepareQuestionData(
            currentQuestion,
            answers,
            setAnswers,
            isFinished
        );
    }, [answers, currentQuestion, isFinished]);

    const submitButtonOnPress = useCallback(async () => {
        testSubmit(() =>
            finishTest({
                studentTestId: quizData?.detailsSimplified?.studentTestId,
            })
        );
    }, [quizData, currentQuestion, answers]);

    const contextValue = {
        isFinished,
        //general data
        quizData,
        questions,
        answers,
        currentQuestion,
        currentQuestionData,
        calcQuestion: QuizFactory.calcQuestion,
        getQuestionStatus: QuizFactory.getQuestionStatus,
        getQuestionMark: QuizFactory.getQuestionMark,
        //TitleContent vars
        totalPages: !!questions ? questions.length : 0,
        currentPage: !!currentQuestion ? currentQuestion.index + 1 : 0,
        //eventHandlers
        onPressPrevQuestion,
        onPressNextQuestion,
        onQuestionPick,
        //TimerComponent vars
        countdownDuration,
        timerRemainTime,
        submitButtonShow:
            currentQuestion?.index === questions?.length - 1 && !isFinished,
        submitButtonOnPress,
        saveCurrentQuestion,
    };

    return (
        <QuizContext.Provider value={contextValue}>
            {props.children}
        </QuizContext.Provider>
    );
};
export default QuizContext;
