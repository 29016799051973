// works
export const getBooksListRedux = ({ main }) => main.booksList;
export const getCategoriesListRedux = ({ main }) => main.categoriesList;
export const getChaptersRedux = ({ main }) => main.chaptersList;
export const getSubjectsListRedux = ({ main }) => main.subjectsList;
export const getPlaylistsForRedux = ({ main }) => main.playlistsFor;
export const getPlaylistsForUserRedux = ({ main }) => main.playlistsForUser;
export const getVideoPlaylistRedux = ({ main }) => main.videoPlaylist;

// need rework
export const getUserStories = ({ main }) => main.userStories;
export const getBooksAndAssetCountRedux = ({ main }) => main.booksAndAssetCount;
export const getSubjectsForExamRedux = ({ main }) => main.subjectsForExam;
export const getUserImages = ({ main }) => main.userImages;
export const getUserDocuments = ({ main }) => main.userDocuments;
