import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={25}
            height={5}
            viewBox="0 0 25 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                d="M2.5 2.5h20"
                stroke="#FFD9D0"
                strokeWidth={5}
                strokeLinecap="round"
            />
        </Svg>
    );
}

export default SvgComponent;
