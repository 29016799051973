import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
    return (
        <Svg
            width={229}
            height={210}
            viewBox="0 0 229 210"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M207.312 43.0961C240.196 90.8778 236.283 157.804 188.501 190.688C140.72 223.571 49.1942 213.871 16.3104 166.09C-16.5729 118.308 4.87611 52.6328 52.6574 19.749C100.439 -13.1348 174.428 -4.68526 207.312 43.0961Z"
                fill="#E93F0D"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M55.834 69.9595L36.7171 78.6863C32.7378 80.5028 30.9846 85.2012 32.8011 89.1805L63.0328 155.406C64.8494 159.385 69.5479 161.138 73.527 159.322L92.6439 150.595C96.6232 148.778 98.3764 144.08 96.5599 140.101L66.3282 73.8755C64.5117 69.8962 59.8133 68.143 55.834 69.9595"
                fill="white"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M185.559 95.1864C188.437 101.491 186.7 107.792 180.25 111.302C170.592 116.368 159.813 121.225 150.426 124.767C150.175 124.861 150.646 125.513 151.044 126.124C158.748 137.957 163.089 138.18 168.065 151.534C169.807 156.209 169.412 163.45 166.461 167.293C163.869 170.668 155.691 175.69 150.997 166.51C149.542 163.962 147.962 160.616 146.542 158.542C138.612 146.961 123.906 142.542 110.196 140.347C109.263 140.198 107.94 139.999 106.626 139.806C103.721 139.378 101.23 137.506 100.01 134.834L73.244 76.1871C71.7723 72.9623 72.4145 69.168 74.8635 66.6049C79.3572 61.902 87.2433 56.5591 98.909 51.2337C105.388 48.2758 104.657 48.5118 109.76 46.2267C122.379 40.5748 132.908 36.7453 138.86 39.4966C139.1 39.6126 139.664 39.9925 139.793 40.0883C140.774 40.8158 141.968 41.9678 142.811 43.8054L143.776 45.9194C145.11 48.8419 145.55 51.7387 143.906 54.2383C150.517 51.2203 156.281 55.2749 158.584 60.3208L159.787 62.955C161.113 65.86 160.755 69.1027 159.124 71.5894L159.51 71.4133C163.913 69.4037 169.917 72.2579 172.305 77.4897L173.243 79.546C174.395 82.0679 173.777 86.6766 172.652 88.9888C177.989 86.9691 183.155 89.9194 185.559 95.1864"
                fill="white"
            />
        </Svg>
    )
}

export default SvgComponent
