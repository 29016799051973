import { Pressable, Text, View } from "react-native";
import { ProfileUnknownIcon } from "../assets/svg";
import { useStylesUtils } from "../hooks/useStylesUtils";
import { COLOURS } from "../constants";
import { Button } from "./Button";
import { useCallback } from "react";
import { closePopup } from "../store/app/slice";
import { useDispatch } from "react-redux";

export const AdmissionNumberCard = ({
    uid,
    Icon,
    name,
    grade,
    selected,
    phone,
    isdCode,
    onSelect,
}) => {
    const dispatch = useDispatch();
    const { styles } = useStylesUtils(getStyles);

    const close = useCallback(() => {
        dispatch(closePopup());
    }, []);
    const selectAdmission = async (phone) => {
        await onSelect(phone);
        close();
    };

    return (
        <Pressable style={styles.container} onPress={() => {}}>
            <View style={styles.iconView}>
                {!!Icon ? (
                    <Icon width={50} height={50} />
                ) : (
                    <ProfileUnknownIcon width={50} height={50} />
                )}
            </View>
            <View style={styles.textView}>
                <Text style={styles.uidView}>{uid}</Text>
                <Text style={styles.descriptionView}>
                    {name} | Grade: {grade}
                </Text>
            </View>
            <View style={{ justifyContent: "center" }}>
                <Button
                    onPress={() => {
                        selectAdmission({ phone, isdCode, uid });
                    }}
                    label={"Select"}
                    iconProps={{
                        stroke: selected ? "#FFDE29" : "white",
                        strokeOpacity: 1,
                    }}
                />
            </View>
        </Pressable>
    );
};

const getStyles = {
    container: {
        flex: 1,
        backgroundColor: COLOURS.lightBlue5,
        flexDirection: "row",
        padding: 10,
        marginBottom: 10,
        borderRadius: 20,
    },
    iconView: {
        width: 50,
        height: 50,
        justifyContent: "flex-end",
        alignItems: "center",
    },
    textView: {
        flex: 1,
        justifyContent: "space-between",
        marginLeft: 20,
        marginRight: 20,
    },
    uidView: {
        color: COLOURS.dark_purple,
        fontWeight: 700,
        fontSize: 24,
    },
    descriptionView: {
        color: COLOURS.purple1,
        fontWeight: 700,
        fontSize: 16,
    },
};
