import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { InfoIcon } from "../../../assets/svg";
import {
    LeftArrowIcon,
    RightArrowIcon,
} from "../../../assets/svg/btn/arrowIcons";
import { DashedLine } from "../../../components";
import { CustomScrollView } from "../../../components/CustomScrollView";
import { COLOURS } from "../../../constants";
import { usePopups, useStylesUtils } from "../../../hooks";
import { ColumnTitle } from "./ColumnTitle";

export const Table = ({ table, mode, styles }) => {
    const { width } = useStylesUtils();

    const [ref, setRef] = useState(null);
    const [dataSourceCords, setDataSourceCords] = useState([]);
    const [selected, setSelected] = useState(1);
    const [scrollViewSizes, setScrollViewSizes] = useState({});

    useLayoutEffect(() => {
        if (ref && dataSourceCords.length > selected) {
            scrollTo(selected);
        }
    }, [ref, dataSourceCords, selected, mode]);

    const scrollTo = (index) => {
        const viewSize = scrollViewSizes?.scrollView?.width || 0;
        const blockSize = scrollViewSizes?.scrollItem?.width || 0;

        ref?.scrollTo({
            yx: 0,
            x: (viewSize - blockSize) / 2 + dataSourceCords[index - 1],
            animated: true,
        });
    };

    const itemOnLayout = (index) => (event) => {
        const { x, y, width, height } = event.nativeEvent.layout;

        setDataSourceCords((prev) => {
            prev[index] = x;
            return [...prev];
        });

        if (!scrollViewSizes?.scrollItem?.width) {
            setLayoutSizes({
                scrollItem: { width, height },
            });
        }
    };

    const parseList = useMemo(
        () =>
            table.map((item, index, all) => ({
                ...item,
                isSelected: selected === index,
                isLast: index === all.length - 1,
                index,
            })),
        [selected]
    );

    const changeSelected = (index) => {
        if (!Object.prototype.hasOwnProperty.call(dataSourceCords, index)) {
            return;
        }
        setSelected(index);
    };

    const pressPrev = useCallback(() => {
        changeSelected(selected - 1);
    }, [ref, selected, dataSourceCords]);

    const pressNext = useCallback(() => {
        changeSelected(selected + 1);
    }, [ref, selected, dataSourceCords]);

    const setLayoutSizes = (props) => {
        setScrollViewSizes((prev) => ({
            ...prev,
            ...props,
        }));
    };

    return (
        <View style={styles.rightBlock}>
            {!mode && (
                <View style={styles.tableButton}>
                    <TouchableOpacity onPress={pressPrev}>
                        <LeftArrowIcon
                            width={width * 0.05}
                            height={width * 0.05}
                        />
                    </TouchableOpacity>
                </View>
            )}
            <CustomScrollView
                horizontal={true}
                setRef={setRef}
                layoutSizes={setLayoutSizes}>
                {parseList.map(
                    ({
                        id,
                        title,
                        subTitle,
                        periods,
                        isSelected,
                        isLast,
                        index,
                    }) => (
                        <TouchableOpacity
                            onPress={() => changeSelected(index)}
                            onLayout={itemOnLayout(index)}
                            key={"tableColumn" + id}
                            style={[
                                styles.tableColumn,
                                {
                                    marginRight: isLast ? 0 : 20,
                                    borderWidth: isSelected ? 5 : 0,
                                    borderColor: COLOURS.yellow2,
                                    borderStyle: "solid",
                                },
                            ]}>
                            <ColumnTitle
                                styles={styles}
                                title={title}
                                subTitle={subTitle}
                                isSelected={isSelected}
                            />
                            <PeriodsList
                                list={periods}
                                styles={styles}
                                title={`${title} ${subTitle}`}
                            />
                        </TouchableOpacity>
                    )
                )}
            </CustomScrollView>
            {!mode && (
                <View style={styles.tableButton}>
                    <TouchableOpacity onPress={pressNext}>
                        <RightArrowIcon
                            width={width * 0.05}
                            height={width * 0.05}
                        />
                    </TouchableOpacity>
                </View>
            )}
        </View>
    );
};

const PeriodsList = ({ list, styles, title }) => {
    return list.map(({ id, name, type, line, info }, index, all) => (
        <View
            key={"tableRow" + id}
            style={{
                width: "100%",
                alignItems: "center",
            }}>
            <PeriodWithTypeLayout
                type={type}
                styles={styles}
                line={line}
                title={title}
                info={info}>
                <Text
                    style={[styles.tableRowTitle]}
                    numberOfLines={1}
                    ellipsizeMode="tail">
                    {name}
                </Text>
            </PeriodWithTypeLayout>
            {index !== all.length - 1 && (
                <DashedLine vertical={false} viewStyle={styles.tableRowLine} />
            )}
        </View>
    ));
};

const PeriodWithTypeLayout = ({
    children,
    type,
    styles,
    line,
    info,
    title,
}) => {
    const { width, style, height } = styles.tableRowIconProps;

    const { timeTableInfo } = usePopups();

    const onPressInfo = useCallback(() => {
        if (info) {
            timeTableInfo({
                title: title,
                content: info,
            });
        }
    }, []);

    return (
        <View style={styles.tableRow(line)}>
            {type ? (
                <TouchableOpacity
                    activeOpacity={info ? 0.2 : 1}
                    onPress={onPressInfo}
                    style={[styles[`tableRow${type}`], { height: "100%" }]}>
                    {children}
                    {info && (
                        <InfoIcon width={width} height={height} style={style} />
                    )}
                </TouchableOpacity>
            ) : (
                children
            )}
        </View>
    );
};
