import React from "react";
import { Text, View } from "react-native";
import { useStylesUtils } from "../../../hooks";
import { COLOURS } from "../../../constants";
import { Image } from "../../Image";

export const CardsFooterBar = ({
    category = "",
    icon = null,
    style: containerStyle = {},
    textStyle = {},
    wrapperStyle = {},
}) => {
    const { styles } = useStylesUtils(getStyles, {
        containerStyle,
        textStyle,
        wrapperStyle,
    });

    return category || icon ? (
        <View style={[styles.container, styles.containerStyle]}>
            {icon && (
                <View style={[styles.imageWrapper, styles.wrapperStyle]}>
                    <Image
                        style={styles.image}
                        source={icon}
                        resizeMode={"contain"}
                    />
                </View>
            )}
            {category && (
                <View style={[styles.textWrapper, styles.wrapperStyle]}>
                    <Text
                        style={[styles.category, styles.textStyle]}
                        numberOfLines={1}
                        ellipsizeMode="tail">
                        {category}
                    </Text>
                </View>
            )}
        </View>
    ) : null;
};

const getStyles = ({ width }) => ({
    container: {
        position: "absolute",
        bottom: -3,
        zIndex: 10,
        width: "100%",
        flexDirection: "row",
        justifyContent: "center",
    },
    textWrapper: {
        backgroundColor: COLOURS.purple11,
        paddingHorizontal: width * 0.015,
        paddingVertical: width * 0.005,
        borderRadius: 60,
        shadowColor: "rgba(0, 0, 0, 0.14902)",
        shadowOffset: { width: 3, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        maxWidth: "90%",
    },
    category: {
        fontSize: 12,
        fontWeight: 700,
        color: COLOURS.purple12,
    },
    imageWrapper: {
        backgroundColor: COLOURS.purple11,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: 30,
        marginRight: 5,
    },
    image: {},
});
