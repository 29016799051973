import React, { useState } from "react";
import { Image, Pressable, Text, View } from "react-native";
import { CustomCheckBox } from "../../../components/CustomCheckBox";
import { COLOURS } from "../../../constants";
import { useStylesUtils } from "../../../hooks";

export const ClassActivityItem = ({
    data: { name, content, image },
    last,
    index,
    editMode,
}) => {
    const { styles } = useStylesUtils(getStyles(last));

    const [showMore, setShowMore] = useState(false);

    return (
        <View style={styles.itemContainer}>
            <View style={styles.leftBlock}>
                <View style={styles.titleBlock}>
                    <Text style={styles.title}>Period {index + 1}</Text>
                    <Text style={styles.subTitle}>{name}</Text>
                </View>
            </View>
            <View style={styles.contentBlock}>
                <View style={styles.checkBoxBlock}>
                    {editMode && <CustomCheckBox />}
                </View>
                <View style={styles.contentIcon}>
                    <Image
                        style={styles.image}
                        source={image}
                        resizeMode={"contain"}
                    />
                </View>
                <Text
                    numberOfLines={showMore ? 0 : 3}
                    ellipsizeMode={"tail"}
                    style={styles.contentText}>
                    {content}
                </Text>
                <Pressable
                    style={styles.showMoreButton}
                    onPress={() => setShowMore((prev) => !prev)}>
                    <Text style={styles.showMoreText}>
                        Read {showMore ? "less" : "more"}
                    </Text>
                </Pressable>
            </View>
        </View>
    );
};

const getStyles =
    (last) =>
    ({ width, height }) => ({
        itemContainer: {
            flexDirection: "row",
            marginBottom: last ? 0 : width * 0.02,
        },
        leftBlock: {
            width: "20%",
        },
        titleBlock: {
            backgroundColor: "#FFFFFF",
            marginRight: width * 0.03,
            borderRadius: width * 0.015,
            justifyContent: "center",
            alignItems: "center",
            paddingVertical: width * 0.015,
        },
        title: {
            color: COLOURS.dark_purple,
            fontSize: 18,
            fontWeight: 700,
        },
        subTitle: {
            color: COLOURS.dark_purple,
            fontSize: 28,
            fontWeight: 700,
        },
        bashedBlock: {
            alignItems: "center",
        },
        contentBlock: {
            flex: 1,
            backgroundColor: "#FFFFFF",
            borderRadius: 15,
            paddingVertical: width * 0.01,
            paddingLeft: width * 0.015,
            paddingRight: width * 0.04,
            flexDirection: "row",
            alignItems: "flex-start",
        },
        contentIcon: {
            paddingTop: width * 0.005,
            justifyContent: "flex-start",
        },
        contentText: {
            flex: 1,
            marginLeft: width * 0.01,
            marginBottom: width * 0.01,
            fontSize: 16,
            fontWeight: 500,
            color: COLOURS.dark_purple,
        },
        checkBoxBlock: {
            marginTop: width * 0.015,
        },
        image: {
            width: width * 0.06,
            height: width * 0.06,
        },
        showMoreButton: {
            position: "absolute",
            bottom: width * 0.005,
            right: width * 0.01,
        },
        showMoreText: {
            color: COLOURS.blue2,
            textDecorationLine: "underline",
            fontSize: 12,
        },
    });
