import {
    ActivityIndicator,
    AppState,
    TouchableOpacity,
    View,
} from "react-native";
import { WebView } from "react-native-webview";
import { BackArrowIcon } from "../../assets/svg";
import { useHaptic, useStylesUtils, usePopups } from "../../hooks";
import { useLocation, useNavigate } from "react-router-native";
import { useEffect, useRef, useState } from "react";
import * as FileSystem from "expo-file-system";
import * as IntentLauncher from "expo-intent-launcher";
import { startLoading, stopLoading } from "../../store/app/slice";
import { useDispatch } from "react-redux";

export const DocumentScreen = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { styles, imageSizes, width, height, isAndroid, isWeb } =
        useStylesUtils(getStyles);
    const { hapticImpact } = useHaptic();
    const webViewRef = useRef(null);
    const navigate = useNavigate();
    const { completeHomeWork } = usePopups();

    const appState = useRef(AppState.currentState);

    const [isLoading, setIsLoading] = useState(true);

    const appStateSubscription = AppState.addEventListener(
        "change",
        (nextAppState) => {
            if (
                appState.current.match(/inactive|background/) &&
                nextAppState === "active"
            ) {
                // console.log("App has come to the foreground!");
                if (isAndroid) {
                    navigateBack();
                }
            }
            appState.current = nextAppState;
        }
    );

    const navigateBack = () => {
        const navBack = () => {
            navigate(-1);
            hapticImpact(1);
        };

        if (state.showCompletePopup) {
            completeHomeWork(navBack, "DOCUMENT");
        } else {
            navBack();
        }
    };
    const androidReader = async () => {
        if (!state.documentUrl) {
            return;
        }
        // console.log("androidReader", state.documentUrl);
        const cacheDir = FileSystem.cacheDirectory + "document.pdf";
        // console.log("cacheDir", cacheDir);
        let res = await FileSystem.downloadAsync(state.documentUrl, cacheDir);
        // console.log("res", res);

        let content = await FileSystem.getContentUriAsync(res.uri);
        // console.log("content", content);
        try {
            await IntentLauncher.startActivityAsync(
                "android.intent.action.VIEW",
                {
                    data: content,
                    flags: 1,
                    type: res.mimeType,
                }
            ).then(dispatch(stopLoading()));
        } catch (e) {
            console.log("[ERROR] Intent launcher ", JSON.stringify(e));
        }
    };
    useEffect(() => {
        if (isAndroid) {
            androidReader().catch(() => null);
        }
    }, []);

    useEffect(() => {
        return () => {
            appStateSubscription.remove();
        };
    }, []);
    useEffect(() => {
        if (!isWeb) {
            dispatch(startLoading());
        }
    }, []);

    const iosView = state ? (
        <View style={styles.container}>
            <WebView
                ref={webViewRef}
                source={{
                    uri: state.documentUrl,
                }}
                onLoadEnd={() => {
                    setIsLoading(false);
                    dispatch(stopLoading());
                }}
            />
            <TouchableOpacity style={styles.backButton} onPress={navigateBack}>
                <BackArrowIcon {...imageSizes.standard} />
            </TouchableOpacity>
        </View>
    ) : (
        <View>
            <Text>somethig goes wrong</Text>
        </View>
    );

    const androidView = (
        <View>
            <TouchableOpacity style={styles.backButton} onPress={navigateBack}>
                <BackArrowIcon {...imageSizes.standard} />
            </TouchableOpacity>
        </View>
    );

    const openInWeb = () => {
        window.open(state.documentUrl, "_blank");
        navigateBack();
    };

    if (isWeb) {
        return openInWeb();
    } else {
        return isAndroid ? androidView : iosView;
    }

    // return iosView;
};
const getStyles = {
    container: {
        flex: 1,
    },
    backButton: {
        position: "absolute",
        zIndex: 100,
        top: 10,
        left: 0,
        marginTop: 10,
        marginLeft: 20,
    },
};
