import React, { useCallback, useState } from "react";
import { Text, View } from "react-native";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-native";
import { AuthLayout } from "../../../components";
import { COLOURS, navigation } from "../../../constants";
import { usePopups, useStylesUtils } from "../../../hooks";
import { useAuth } from "../../../hooks/useAuth";
import { getUserVerifyInfo } from "../../../store/auth/selectors";
import { Button, PinInput } from "../components";

export const SetNewPasscode = () => {
    const { styles } = useStylesUtils(getStyles);

    const navigate = useNavigate();
    const { setNewPasscode } = useAuth();
    const { passcodeIsSetPopup } = usePopups();
    const verifyInfo = useSelector(getUserVerifyInfo);

    const [pin1, setPin1] = useState("");
    const [pin2, setPin2] = useState("");

    const onConfirm = () => {
        navigate(navigation.HOME);
    };

    const onSubmit = useCallback(() => {
        if (
            pin1 &&
            pin1.length === 4 &&
            pin2 &&
            pin2.length === 4 &&
            pin1 === pin2
        ) {
            setNewPasscode({ password: pin1 }).then(() => {
                passcodeIsSetPopup(onConfirm);
            });
        }
    }, [pin1, pin2]);

    return (
        <AuthLayout headerProps={{ backButton: true }}>
            <View style={styles.container}>
                <Text style={styles.title}>Set new Passcode</Text>
                <Text style={styles.label}>Please enter new Passcode</Text>
                <PinInput
                    pinState={{ pin: pin1, setPin: setPin1 }}
                    style={styles.inputs}
                    inputStyle={styles.input}
                />
                <Text style={styles.label}>Please confirm new Passcode</Text>
                <PinInput
                    pinState={{ pin: pin2, setPin: setPin2 }}
                    style={styles.inputs}
                    inputStyle={styles.input}
                />
                <View style={styles.formContainer}>
                    <Button label={"Submit"} onPress={onSubmit} />
                </View>
            </View>
        </AuthLayout>
    );
};

const getStyles = {
    title: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        marginBottom: 10,
        fontSize: 30,
    },
    label: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        fontSize: 16,
    },
    formContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    container: {
        alignItems: "center",
        justifyContent: "flex-start",
    },
    inputs: {
        flex: 1,
        marginBottom: 15,
    },
    input: {
        marginLeft: 3,
        marginRight: 3,
    },
};
