import { View, Text, TouchableOpacity } from "react-native";
import { useStylesUtils } from "../../../hooks";
import { DetailsBtnIcon } from "../../../assets/svg";
export const NotificationCard = () => {
    const { width, height, styles, imageSizes } = useStylesUtils(getStyles);

    return (
        <View style={styles.container}>
            <View style={styles.iconContainer}></View>
            <View style={styles.infoContainer}>
                <View style={{}}>
                    <Text style={styles.title}>
                        Risus at ultrices mi tempus imperdiet. Et pharetra
                        pharetra massa massa ultricies.
                    </Text>
                    <View style={styles.dateContainer}>
                        <Text>1 day ago</Text>
                        <Text>Due Date: 24th March </Text>
                    </View>
                </View>
                <TouchableOpacity>
                    <DetailsBtnIcon {...imageSizes.type1} />
                </TouchableOpacity>
            </View>
        </View>
    );
};

const getStyles = ({ width, height }) => ({
    container: {
        width: width * 0.7,
        height: width * 0.1,
        borderRadius: 15,
        flexDirection: "row",
        marginBottom: height * 0.02,
    },
    iconContainer: {
        borderBottomLeftRadius: 15,
        borderTopLeftRadius: 15,
        backgroundColor: "#CEF7A0",
        width: width * 0.1,
        height: width * 0.1,
    },
    infoContainer: {
        borderBottomRightRadius: 15,
        borderTopRightRadius: 15,
        backgroundColor: "#E0FFDE",
        width: "83%",
        height: width * 0.1,
        padding: 5,
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
    },
    title: {
        flexDirection: "row",
        alignItems: "center",
        fontWeight: 700,
        fontSize: 18,
        // width: "90%",
    },
    dateContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        // width: "90%",
    },
    lable: {
        marginLeft: width * 0.01,
        fontSize: 30,
        textAlign: "center",
        fontWeight: 700,
        color: "#fff",
        textShadowColor: "#000",
        textShadowOffset: { height: 0, width: 0 },
        textShadowRadius: 3,
        verticalAlign: "middle",
    },
});
