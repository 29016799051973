import { useMemo } from "react";
import { Text } from "react-native";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-native";
import { Videos2Icon } from "../../assets/svg";
import { CarrouselComponent, MainLayout } from "../../components";
import { navigation } from "../../constants";
import { useStylesUtils } from "../../hooks";
import { getVideoPlaylistRedux } from "../../store/main/selectors";
import { COLOURS } from "../../constants";
import { parseListsUtils } from "../../utils/parse-lists.utils";
import { useKeepAwake } from "expo-keep-awake";
export const VideosScreen = () => {
    useKeepAwake();
    const { width, height, styles } = useStylesUtils(getStyles);

    const { state } = useLocation();
    const navigate = useNavigate();

    const videoPlaylist = useSelector(getVideoPlaylistRedux);

    const parseList = useMemo(() => {
        return parseListsUtils(videoPlaylist?.data?.videos, (props) => ({
            onPress: () => {
                navigate(navigation.PLAYLIST, {
                    state: {
                        activeVideo: {
                            url: props.url,
                            image: props.image,
                        },
                    },
                });
            },
        }));
    }, [videoPlaylist]);

    return (
        <MainLayout
            headerProps={{
                backButton: true,
                homeButton: true,
                showBgCloud: true,
                titleContent: state?.titleContent || (
                    <>
                        <Videos2Icon
                            height={height * 0.2}
                            width={width * 0.14}
                        />
                        <Text style={styles.titleText}>Videos</Text>
                    </>
                ),
            }}>
            <CarrouselComponent data={parseList} type={"video"} />
        </MainLayout>
    );
};

const getStyles = {
    titleText: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        fontSize: 40,
        textAlign: "center",
        marginTop: 20,
    },
};
