import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={56}
            height={42}
            viewBox="0 0 56 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.798 13.828c-.375-5.61-5.496-11.525-10.73-12.483C38.528.46 33.014 0 27.502.001 21.989 0 16.477.459 10.936 1.345 5.702 2.303.581 8.218.206 13.828a110.462 110.462 0 000 13.39c.375 5.61 5.496 11.525 10.73 12.483 5.54.885 11.054 1.345 16.566 1.344 5.512.001 11.026-.459 16.566-1.344 5.234-.958 10.355-6.873 10.73-12.483a110.125 110.125 0 000-13.39zm-19.75 7.865c-3.846 2.401-7.7 4.854-11.517 7.223-.83.515-1.872-.152-1.878-1.194-.026-4.799-.026-9.6 0-14.398.006-1.042 1.049-1.709 1.878-1.194 3.818 2.37 7.671 4.822 11.516 7.223.836.523.836 1.817 0 2.34"
                fill="#B7245C"
            />
        </Svg>
    );
}

export default SvgComponent;
