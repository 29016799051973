import React from "react";
import { useNavigate } from "react-router-native";
import { CarrouselComponent, MainLayout } from "../../../components";
import { diaryIcons, navigation } from "../../../constants";
import { CardView } from "./CardView";
import { TitleContent } from "./TitleContent";

export const DiaryScreen = () => {
    const navigate = useNavigate();

    const diaries = [
        {
            id: 1,
            title: "Homework",
            image: diaryIcons.HomeworkIcon,
            onPress: () => {
                navigate(navigation.HOME_WORK_SCREEN);
            },
        },
        /*removed from current scope*/
        // {
        //     id: 2,
        //     title: "Time-table",
        //     image: diaryIcons.TimeTableIcon,
        //     onPress: () => {
        //         // navigate(navigation.TIME_TABLE_SCREEN);
        //         navigate(navigation.COMING_SOON_SCREEN);
        //     },
        // },
        // {
        //     id: 3,
        //     title: "Events",
        //     image: diaryIcons.EventsIcon,
        //     onPress: () => {
        //         // navigate(navigation.Events_SCREEN);
        //         navigate(navigation.COMING_SOON_SCREEN);
        //     },
        // },
    ];

    return (
        <MainLayout
            headerProps={{
                backButton: true,
                homeButton: true,
                titleContent: <TitleContent />,
            }}>
            <CarrouselComponent data={diaries} CardView={CardView} />
        </MainLayout>
    );
};
