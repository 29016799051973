import { ScrollView, TouchableOpacity, Text, View } from "react-native";
import { COLOURS } from "../../../constants";
import { capitalizeStr } from "../../../utils/text.utils";
import React, { useMemo, useEffect } from "react";
import { parseListsUtils } from "../../../utils/parse-lists.utils";
import {
    toggleTestFilterCategories,
    resetTestFilterCategories,
} from "../../../store/tests/slice";
import { useSelector, useDispatch } from "react-redux";
import { getUserTests } from "../../../store/tests/selectors";
import { getCategoriesListRedux } from "../../../store/main/selectors";
import { useTestsHook, useStylesUtils } from "../../../hooks";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export const FilterContainer = () => {
    const dispatch = useDispatch();
    const insets = useSafeAreaInsets();

    const { styles } = useStylesUtils(getStyles(insets));
    const { getStudentTestSubjects } = useTestsHook();

    const { filter } = useSelector(getUserTests);
    const categoriesList = useSelector(getCategoriesListRedux);

    useEffect(() => {
        if (!categoriesList?.data?.childNodes?.length) {
            getStudentTestSubjects();
        }
    }, []);

    const filterDataParse = useMemo(() => {
        const check = parseListsUtils(
            categoriesList?.data?.childNodes,
            (item) => ({
                isSelected: filter.testCategories.includes(item.id),
                onPress: () => dispatch(toggleTestFilterCategories(item.id)),
            })
        );

        check.unshift({
            id: 0,
            title: "all",
            isSelected: filter.testCategories.includes(0),
            onPress: () => dispatch(resetTestFilterCategories()),
        });

        return check;
    }, [categoriesList, filter]);

    return filterDataParse?.length > 0 ? (
        <View style={styles.filterContainer}>
            <View>
                <ScrollView
                    showsHorizontalScrollIndicator={true}
                    horizontal={true}
                    contentContainerStyle={styles.filterScrollView}>
                    {filterDataParse.map(
                        ({ id, title, isSelected, onPress }) => (
                            <TouchableOpacity
                                key={"filterItem" + id}
                                style={styles.filterButton(isSelected)}
                                onPress={onPress}>
                                <Text
                                    style={[
                                        styles.filterButtonText,
                                        isSelected
                                            ? { color: COLOURS.brown7 }
                                            : {},
                                    ]}>
                                    {capitalizeStr(title)}
                                </Text>
                            </TouchableOpacity>
                        )
                    )}
                </ScrollView>
            </View>
        </View>
    ) : null;
};

const getStyles =
    (insets) =>
    ({ biggerSize, isPad }) => ({
        filterContainer: {
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
        },
        filterScrollView: {
            paddingHorizontal:
                insets.left || insets.right ? 0 : biggerSize * 0.02,
        },
        filterButton: (selected) => ({
            paddingVertical: biggerSize * 0.005,
            paddingHorizontal: biggerSize * 0.015,
            borderRadius: 50,
            borderWidth: 2,
            borderColor: selected ? COLOURS.white : COLOURS.brown6,
            backgroundColor: selected ? COLOURS.white : COLOURS.transparent,
            marginRight: biggerSize * 0.01,
            alignSelf: "center",
        }),
        filterButtonText: {
            fontSize: isPad ? 24 : 16,
            fontWeight: 700,
            color: COLOURS.white,
        },
    });
