import React, { useContext } from "react";
import { TouchableOpacity, ImageBackground } from "react-native";
import { useStylesUtils } from "../hooks";
import GlobalAudioContext from "../providers/GlobalAudioContext";

export const MainButton = ({
    children,
    onPress,
    style = {},
    imageStyle = {},
    source,
}) => {
    const { styles } = useStylesUtils(getStyles, { style });
    const { playClickSound } = useContext(GlobalAudioContext);

    const rewriteOnPress = (...props) => {
        playClickSound();
        onPress(...props);
    };

    return source ? (
        <TouchableOpacity onPress={rewriteOnPress}>
            <ImageBackground
                source={source}
                style={[styles.container, styles.style]}
                imageStyle={imageStyle}>
                {children}
            </ImageBackground>
        </TouchableOpacity>
    ) : null;
};

const getStyles = {
    container: {
        justifyContent: "center",
        alignItems: "center",
    },
};
