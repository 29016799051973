import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { OverLayout } from "../layout/OverLayout";
import { useLocation } from "react-router-native";
import { closeModal } from "../../store/app/slice";

export const Modal = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const { open, content } = useSelector((state) => state.app.modal);

    const [show, setShow] = useState(open);

    useEffect(() => {
        setShow(open);
    }, [open]);

    useEffect(() => {
        if (show) {
            setShow(false);
            dispatch(closeModal());
        }
    }, [pathname]);

    return show ? <OverLayout>{content}</OverLayout> : null;
};
