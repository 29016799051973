import { Text, View } from "react-native";
import { PdfDownloadIcon } from "../../assets/svg";
import { COLOURS } from "../../constants";
import { useStylesUtils } from "../../hooks";

export const PdfDownloadCard = ({
    title = "cardTitle",
    colour,
    image = false,
    onPress = () => {},
    isActive,
    status = null,
    category = null,
    icon = null,
}) => {
    const { styles, biggerSize } = useStylesUtils(getStyles);

    return (
        <View style={[styles.container]}>
            <PdfDownloadIcon
                width={biggerSize * 0.26}
                height={biggerSize * 0.2}
            />
            <View style={styles.title}>
                <Text style={styles.titleText}>{title}</Text>
            </View>
        </View>
    );
};

const getStyles = ({ biggerSize }) => ({
    container: {
        marginRight: biggerSize * 0.02,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    title: {
        position: "absolute",
        top: biggerSize * 0.005,
        justifyContent: "center",
    },
    titleText: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        fontSize: 18,
        textAlign: "center",
    },
});
