import * as React from "react";
import { ActivityIndicator, Pressable, Text, View } from "react-native";
import { ScanAndUploadCardIcon } from "../../assets/svg";
import { COLOURS } from "../../constants";
import { useStylesUtils } from "../../hooks";
import { fillColorUtils } from "../../utils/fillColor.utils";
import { CardsFooterBar } from "./components/CardsFotterBar";
import { CardsImage } from "./components/CardsImage";
import { CardsStatus } from "./components/CardsStatus";

export const ScanAndUploadCard = ({
    title = "cardTitle",
    colour,
    image = false,
    onPress = () => {},
    isActive,
    status = null,
    category = null,
    icon = null,
    fill,
}) => {
    const { styles, biggerSize } = useStylesUtils(getStyles);

    return (
        <Pressable style={styles.container} onPress={onPress}>
            <View style={styles.tvContainer}>
                <View style={styles.activityIndicator}>
                    <ActivityIndicator size="large" color={COLOURS.orange} />
                </View>
                <CardsImage
                    source={image}
                    style={styles.cardImage}
                    resizeMode={"stretch"}
                />
                <ScanAndUploadCardIcon
                    width={biggerSize * 0.26}
                    height={biggerSize * 0.2}
                    fill={fillColorUtils(isActive, colour, fill)}
                    style={styles.icon}
                />
                <CardsFooterBar category={category} icon={icon} />
                <CardsStatus status={status} style={styles.statusImage} />
            </View>
            <Text numberOfLines={1} style={styles.title}>
                {title}
            </Text>
        </Pressable>
    );
};

const getStyles = ({ biggerSize }) => ({
    container: {
        marginRight: biggerSize * 0.02,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    tvContainer: {
        marginTop: biggerSize * 0.02,
        marginBottom: biggerSize * 0.008,
    },
    cardImage: {
        position: "absolute",
        width: biggerSize * 0.23,
        height: biggerSize * 0.16,
        top: biggerSize * 0.02,
        left: biggerSize * 0.014,
        zIndex: 1,
    },
    activityIndicator: {
        position: "absolute",
        width: biggerSize * 0.23,
        height: biggerSize * 0.16,
        top: biggerSize * 0.02,
        left: biggerSize * 0.014,
        zIndex: 0,
        backgroundColor: COLOURS.purple21,
        alignItems: "center",
        justifyContent: "center",
    },
    title: {
        color: "#fff",
        fontSize: 20,
        fontWeight: 700,
        textAlign: "center",
        maxWidth: biggerSize * 0.25,
    },
    icon: {
        zIndex: 1,
    },
    statusImage: {
        position: "absolute",
        zIndex: 100,
        right: -5,
        top: 0,
        width: biggerSize * 0.04,
        height: biggerSize * 0.04,
    },
});
