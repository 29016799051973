import React from "react";
import { Text, View } from "react-native";
import { COLOURS } from "../../../../constants";
import { useStylesUtils } from "../../../../hooks/useStylesUtils";

export const NoHomeworkToShowContent = () => {
    const { styles, width } = useStylesUtils(getStyles);

    return (
        <View style={{ flex: 1, alignItems: "center" }}>
            <Text style={styles.text}>
                <Text style={styles.boldText}>CHANGE</Text> the dates, or tap on
                <Text style={styles.boldText}>RESET</Text>
                {"\n"}to go back to the Homework screen
            </Text>
        </View>
    );
};

const getStyles = {
    text: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: 600,
        color: COLOURS.dark_purple,
    },
};
