import { useMemo } from "react";
import { Text } from "react-native";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-native";
import { CarrouselComponent, MainLayout } from "../../components";
import { COLOURS, getSubjectThumbnail } from "../../constants";
import { useLearnHook, useStylesUtils } from "../../hooks";
import { getCurrentSubjectId } from "../../store/app/selectors";
import { getSubjectsListRedux } from "../../store/main/selectors";
import { parseListsUtils } from "../../utils/parse-lists.utils";

export const SubjectsScreen = () => {
    const { styles } = useStylesUtils(getStyles);

    const { state } = useLocation();
    const { getChapter } = useLearnHook();
    const subjects = useSelector(getSubjectsListRedux);
    const currentSubject = useSelector(getCurrentSubjectId);

    const parseList = useMemo(() => {
        const filteredSubjects = subjects?.data.child?.filter(
            (subj) => subj?.topicsCount + subj?.videoCount >= 0
        );

        return parseListsUtils(filteredSubjects, (props, index) => ({
            image: getSubjectThumbnail(index, currentSubject),
            onPress: () =>
                getChapter({
                    params: {
                        chapterId: props.id,
                    },
                    chapterName: props.title,
                }),
        }));
    }, [subjects]);

    return (
        <MainLayout
            headerProps={{
                backButton: true,
                homeButton: true,
                titleContent: (
                    <Text
                        style={styles.titleText}
                        numberOfLines={1}
                        ellipsizeMode="tail">
                        {state.title ?? ""}
                    </Text>
                ),
            }}>
            <CarrouselComponent data={parseList} type={"file"} />
        </MainLayout>
    );
};

const getStyles = ({ width }) => ({
    titleText: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        fontSize: 40,
        textAlign: "center",
        marginTop: 20,
        maxWidth: width * 0.6,
    },
});
