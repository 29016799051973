import * as React from "react"
import Svg, { Path } from "react-native-svg"

export const LineHorizontal = (props) => {
    return (
        <Svg
            width={200}
            height={2}
            viewBox="0 0 368 3"
            fill="none"
            stroke="#C7AAEB"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                clipRule="evenodd"
                d="M.572 1.5h366.856H.572z"
                strokeWidth={2}
                strokeDasharray="9 9"
            />
        </Svg>
    )
}

export const LineVertical = (props) => {
    return (
        <Svg
            width={2}
            height={200}
            viewBox="0 0 3 436"
            stroke="#DFBFFF"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                clipRule="evenodd"
                d="M1.5.5v435V.5z"
                strokeWidth={2}
                strokeDasharray="8 8"
            />
        </Svg>
    )
}
