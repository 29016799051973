import React, { useMemo, useState } from "react";
import { View } from "react-native";
import { LineHorizontal, LineVertical } from "../assets/svg/line";
import { COLOURS } from "../constants";

const lineBaseWidth = 200;

export const DashedLine = ({
    color = COLOURS.purple7,
    vertical = true,
    viewStyle = {},
    iconStyle = {},
}) => {
    const Component = vertical ? LineVertical : LineHorizontal;
    const [layoutOptions, setLayoutOptions] = useState({});

    const lineCount = useMemo(() => {
        const size = vertical ? layoutOptions.height : layoutOptions.width;
        return Math.ceil(size ? size / lineBaseWidth : 1);
    }, [layoutOptions]);

    const onLayout = (event) => setLayoutOptions(event.nativeEvent.layout);

    return (
        <View
            style={[
                vertical ? {} : { flexDirection: "row" },
                { overflow: "hidden" },
                viewStyle,
            ]}
            onLayout={onLayout}>
            {[...Array(lineCount).keys()].map((index) => (
                <Component
                    key={"lineIcon" + index}
                    stroke={color}
                    style={iconStyle}
                />
            ))}
        </View>
    );
};
