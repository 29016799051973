import { ResizeMode } from "expo-av";
import VideoPlayer from "expo-video-player";
import React, { useCallback } from "react";
import { View, Text } from "react-native";
import { useNavigate } from "react-router-native";
import { Button } from "../../components";
import { COLOURS, navigation } from "../../constants";
import { useStylesUtils } from "../../hooks";
import Constants from "expo-constants";

export const SplashScreen = () => {
    const { styles } = useStylesUtils(getStyles);

    const navigate = useNavigate();

    const videoURI = require("../../assets/videos/splash.mp4");

    const onVideoEnd = (e) => {
        const { didJustFinish } = e || {};
        if (!didJustFinish) return;
        navigate(navigation.LOGIN);
    };

    const pressSkip = useCallback(() => navigate(navigation.LOGIN), []);

    return (
        <View>
            <View style={styles.playerContainer}>
                <VideoPlayer
                    videoProps={{
                        shouldPlay: true,
                        resizeMode: ResizeMode.CONTAIN,
                        isMuted: true,
                        source: videoURI,
                        videoStyle: styles.player,
                    }}
                    style={styles.player}
                    fullscreen={false}
                    timeVisible={false}
                    defaultControlsVisible={false}
                    slider={{ visible: false }}
                    activityIndicator={{ display: "none" }}
                    useNativeControls={false}
                    icon={{ color: "transparent" }}
                    playbackCallback={onVideoEnd}
                />
            </View>
            <Button
                label={"SKIP"}
                style={styles.skipBtn}
                onPress={pressSkip}
                fillColor={COLOURS.mainBlue}
            />
            <Text style={styles.verText}>v. {Constants.manifest.version}</Text>
        </View>
    );
};

const getStyles = ({ width, height }) => ({
    playerContainer: {
        backgroundColor: COLOURS.black,
        width,
        height,
        justifyContent: "center",
        alignItems: "center",
    },
    player: {
        width,
        height,
    },
    skipBtn: {
        position: "absolute",
        zIndex: 100,
        bottom: 20,
        right: 20,
    },
    verText: {
        position: "absolute",
        zIndex: 100,
        bottom: 10,
        left: width * 0.04,
        color: COLOURS.white,
        fontSize: 20,
        fontWeight: 700,
    },
});
