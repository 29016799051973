import { useDispatch, useSelector } from "react-redux";
import { getUserImages } from "../store/main/selectors";
import { setUserImages } from "../store/main/slice";

export const useImages = () => {
    const images = useSelector(getUserImages);
    const dispatch = useDispatch();

    const addImage = (uri) => {
        const newImagesState = [...images, uri];
        dispatch(setUserImages(newImagesState));
    };
    const removeImage = (imageIndex) => {
        const oldImagesState = [...images];
        oldImagesState.splice(imageIndex, 1);
        const newImagesState = [...oldImagesState];
        dispatch(setUserImages(newImagesState));
    };
    const editImage = (imageIndex, uri) => {
        const oldImagesState = [...images];
        oldImagesState.splice(imageIndex, 1, uri);
        const newImagesState = [...oldImagesState];
        dispatch(setUserImages(newImagesState));
    };
    const removeImages = () => {
        dispatch(setUserImages([]));
    };

    return { addImage, removeImage, editImage, removeImages };
};
