import * as React from "react";
import Svg, { Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const LeftArrowIcon = (props) => {
    return (
        <Svg
            width={77}
            height={83}
            viewBox="0 0 77 83"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.937 44.315C27.517 53.4 43.27 63.798 57.71 71.223c4.316 2.22 10.167-1.959 10.511-7.061a425.802 425.802 0 000-51.476c-.344-5.102-6.195-9.28-10.51-7.06-14.44 7.425-30.194 17.823-45.774 26.907-4.688 2.749-4.688 9.034 0 11.782z"
                fill="#5F0FAA"
            />
            <Path
                d="M11.937 44.315C27.517 53.4 43.27 63.798 57.71 71.223c4.316 2.22 10.167-1.959 10.511-7.061a425.802 425.802 0 000-51.476c-.344-5.102-6.195-9.28-10.51-7.06-14.44 7.425-30.194 17.823-45.774 26.907-4.688 2.749-4.688 9.034 0 11.782"
                stroke="#EAD5FD"
                strokeWidth={5}
            />
        </Svg>
    );
};

export const RightArrowIcon = (props) => {
    return (
        <Svg
            width={77}
            height={83}
            viewBox="0 0 77 83"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M65.256 44.315C49.675 53.4 33.92 63.798 19.482 71.223c-4.316 2.22-10.167-1.959-10.511-7.06a425.796 425.796 0 010-51.476c.344-5.103 6.195-9.282 10.511-7.061 14.44 7.425 30.193 17.823 45.774 26.908 4.687 2.748 4.687 9.033 0 11.781z"
                fill="#5F0FAA"
            />
            <Path
                d="M65.256 44.315C49.675 53.4 33.92 63.798 19.482 71.223c-4.316 2.22-10.167-1.959-10.511-7.06a425.796 425.796 0 010-51.476c.344-5.103 6.195-9.282 10.511-7.061 14.44 7.425 30.193 17.823 45.774 26.908 4.687 2.748 4.687 9.033 0 11.781"
                stroke="#EAD5FD"
                strokeWidth={5}
            />
        </Svg>
    );
};
