import React, { useState } from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { MainLayout } from "../../../components";
import { CustomScrollView } from "../../../components/CustomScrollView";
import { COLOURS } from "../../../constants";
import { useStylesUtils } from "../../../hooks";
import { LeftBlock } from "./LeftBlock";
import { Table } from "./Table";
import { TitleContent } from "./TitleContent";

const table = [
    {
        id: 1,
        title: "Yesterday",
        subTitle: "Wednesday, 13th March",
        periods: [
            { id: 1, name: "Maths" },
            { id: 2, name: "Science", type: "Green", line: 3 },
            { id: 3, name: "Lunch Break" },
            { id: 4, name: "EVS" },
            { id: 5, name: "Science" },
        ],
    },
    {
        id: 2,
        title: "Today",
        subTitle: "Wednesday, 13th March",
        periods: [
            {
                id: 1,
                name: "Maths",
                type: "Purple",
                info:
                    "Sports day Practice from 12.30pm to 2.30pm.\n Please carry following things:\n" +
                    "1. Extra T-shirt.\n" +
                    "2. Sport shoes\n" +
                    "3. Extra water bottle\n" +
                    "4. Badminton kit",
            },
            { id: 2, name: "Science" },
            { id: 3, name: "Lunch Break", type: "Yellow", line: 2 },
            { id: 4, name: "EVS", type: "Green" },
            { id: 6, name: "Lunch Break" },
            { id: 7, name: "EVS" },
        ],
    },
    {
        id: 3,
        title: "Tomorrow",
        subTitle: "Wednesday, 14th March",
        periods: [
            { id: 1, name: "Maths" },
            { id: 2, name: "Science" },
            { id: 3, name: "Lunch Break" },
            { id: 4, name: "EVS" },
            { id: 5, name: "Science" },
            { id: 6, name: "Lunch Break" },
            { id: 7, name: "EVS" },
        ],
    },
    {
        id: 4,
        title: "Thursday",
        subTitle: "Wednesday, 15th March",
        periods: [
            { id: 1, name: "Maths" },
            { id: 2, name: "Science" },
            { id: 3, name: "Lunch Break" },
            { id: 4, name: "EVS" },
            { id: 8, name: "Science" },
            { id: 9, name: "Lunch Break" },
            { id: 10, name: "EVS" },
        ],
    },
];

export const TimeTableScreen = () => {
    const insets = useSafeAreaInsets();
    const [mode, setMode] = useState(false);

    const { styles } = useStylesUtils(getStyles(insets, mode));

    return (
        <MainLayout
            headerProps={{
                backButton: true,
                homeButton: true,
                showBgCloud: true,
                titleContent: (
                    <TitleContent modeToggle={() => setMode((prev) => !prev)} />
                ),
            }}>
            <CustomScrollView contentContainerStyle={styles.container}>
                <LeftBlock styles={styles} />
                <Table table={table} mode={mode} styles={styles} />
            </CustomScrollView>
        </MainLayout>
    );
};

const getStyles =
    (insets, mode) =>
    ({ width }) => ({
        container: {
            width: "100%",
            flexDirection: "row",
            paddingHorizontal: insets.left || insets.right ? 0 : 20,
        },
        leftBlock: {
            overflow: "hidden",
            paddingTop: mode ? 0 : width * 0.01,
            flex: 1,
            maxWidth: width * 0.25,
        },
        rightBlock: {
            overflow: "hidden",
            flexDirection: "row",
            flex: 1,
            backgroundColor: mode ? "transparent" : COLOURS.purple19,
            marginLeft: width * 0.02,
            borderRadius: mode ? 0 : 30,
            padding: mode ? 0 : width * 0.01,
        },
        tableButton: {
            height: width * 0.08,
            alignItems: "center",
            justifyContent: "center",
        },
        tableColumn: {
            minWidth: width * 0.25,
            maxWidth: width * 0.25,
            overflow: "hidden",
            flex: 1,
            backgroundColor: "#fff",
            borderRadius: 30,
            alignItems: "center",
        },
        tableTitle: {
            width: "100%",
            height: width * 0.08,
            backgroundColor: COLOURS.purple14,
            justifyContent: "center",
            alignItems: "center",
        },
        tableTitleSelected: {
            height: width * 0.08 - 5,
        },
        tableTitleLight: {
            backgroundColor: COLOURS.purple11,
        },
        tableTitleText: {
            fontSize: 24,
            fontWeight: 700,
            color: "#fff",
        },
        tableTitleTextLight: {
            color: COLOURS.purple12,
        },
        tableTitleText1: {
            fontSize: 16,
            fontWeight: 700,
            color: "#fff",
        },
        tableTitleText1Light: {
            color: COLOURS.purple12,
        },
        tableRow: (line = 1) => ({
            width: "100%",
            height: width * 0.05 * line + (line - 1) * 2,
            justifyContent: "center",
            alignItems: "center",
            paddingVertical: 5,
        }),
        tableRowLine: {
            width: "80%",
        },
        tableRowTitle: {
            fontSize: 20,
            fontWeight: 700,
            color: COLOURS.purple12,
        },
        tableRowYellow: {
            width: "70%",
            backgroundColor: COLOURS.yellow3,
            paddingHorizontal: 10,
            borderWidth: 2,
            borderColor: COLOURS.purple14,
            borderRadius: 10,
            alignItems: "center",
            justifyContent: "center",
        },
        tableRowPurple: {
            width: "70%",
            backgroundColor: COLOURS.purple20,
            paddingHorizontal: 10,
            borderWidth: 2,
            borderColor: COLOURS.purple14,
            borderRadius: 10,
            alignItems: "center",
            justifyContent: "center",
        },
        tableRowGreen: {
            width: "70%",
            backgroundColor: COLOURS.green3,
            paddingHorizontal: 10,
            borderWidth: 2,
            borderColor: COLOURS.purple14,
            borderRadius: 10,
            alignItems: "center",
            justifyContent: "center",
        },
        tableRowIconProps: {
            width: width * 0.015,
            height: width * 0.015,
            style: {
                position: "absolute",
                right: 2,
                bottom: 2,
            },
        },
        tableRowSubTitle: {
            fontSize: 14,
            fontWeight: 500,
            color: COLOURS.purple12,
        },
    });
