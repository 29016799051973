import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={85}
            height={78}
            viewBox="0 0 85 78"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M76.184 16.618c11.998 17.434 10.57 41.853-6.863 53.85-17.433 11.998-50.827 8.459-62.825-8.975C-5.502 44.06 2.324 20.098 19.758 8.1 37.19-3.898 64.186-.815 76.184 16.618z"
                fill="#FFE9F3"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.762 34.084c4.44 0 8.052-3.612 8.052-8.052 0-4.44-3.612-8.052-8.052-8.052-4.44 0-8.052 3.613-8.052 8.052 0 4.44 3.612 8.052 8.052 8.052zM57.693 30.954c4.84 0 8.779-3.938 8.779-8.779 0-4.84-3.938-8.779-8.78-8.779-4.84 0-8.778 3.939-8.778 8.78 0 4.84 3.938 8.778 8.779 8.778zM78.86 45.01l-8.956-9.51a7.808 7.808 0 00-5.663-2.445H51.144c-2.135 0-4.2.891-5.663 2.446l-1.644 1.713-1.38 1.43-.797-.856a5.475 5.475 0 00-3.995-1.74H23.858c-1.51 0-2.967.634-3.996 1.74l-8.184 8.797a2.662 2.662 0 00.005 3.621 2.665 2.665 0 003.604.251l5.807-4.686a.992.992 0 011.6.952l-3.966 21.599h17.887v-4.574c0-.27-.11-.527-.303-.714l-7.77-7.485a2.776 2.776 0 01-.246-3.745 2.77 2.77 0 011.874-1.034 2.77 2.77 0 012.054.6l5.918 4.766a.994.994 0 00.112.077 9.103 9.103 0 005.707 2.004 9.104 9.104 0 005.707-2.004.988.988 0 00.112-.077l5.917-4.766a2.77 2.77 0 012.055-.6c.743.082 1.408.45 1.874 1.034a2.776 2.776 0 01-.246 3.745l-7.77 7.485a.993.993 0 00-.304.715v4.572h15.165V44.097a.992.992 0 011.638-.755l6.803 5.812a2.855 2.855 0 003.85-.116 2.876 2.876 0 00.097-4.027M43.961 56.232c-3.95 0-7.163-3.213-7.163-7.163 0-3.949 3.213-7.162 7.163-7.162s7.163 3.213 7.163 7.163c0 3.949-3.214 7.162-7.163 7.162z"
                fill="#A0466F"
            />
        </Svg>
    );
}

export default SvgComponent;
