import {
    BigBtnIcon,
    BtnIcon,
    LeftTabBtnIcon,
    RightTabBtnIcon,
    YellowBtnIcon,
    TabBtnIcon,
    OrangewBtnIcon,
} from "../assets/svg";
import { COLOURS } from "./colours";

export const btnTypes = {
    ["small"]: {
        Icon: BtnIcon,
        IconBG: require("../assets/images/components/btnBg.png"),
        width: 90,
        height: 40,
        ratio: 0.1,
    },
    ["big"]: {
        Icon: BigBtnIcon,
        IconBG: require("../assets/images/components/btnBgBlue.png"),
        width: 210,
        height: 55,
        ratio: 0.3,
    },
    ["tab"]: {
        Icon: TabBtnIcon,
        width: 260,
        height: 70,
        ratio: 0.3,
        selectedColors: {
            fill: COLOURS.purple9,
            stroke: COLOURS.purple10,
        },
    },
    ["tab-left"]: {
        Icon: LeftTabBtnIcon,
        width: 260,
        height: 70,
        ratio: 0.3,
        selectedColors: {
            fill: COLOURS.purple9,
            stroke: COLOURS.purple10,
        },
    },
    ["tab-right"]: {
        Icon: RightTabBtnIcon,
        width: 260,
        height: 70,
        ratio: 0.3,
        selectedColors: {
            fill: COLOURS.purple9,
            stroke: COLOURS.purple10,
        },
    },
    ["yellow"]: {
        Icon: YellowBtnIcon,
        width: 374,
        height: 125,
        ratio: 0.15,
    },
    ["orange"]: {
        Icon: OrangewBtnIcon,
        width: 250,
        height: 125,
        ratio: 0.17,
    },
};
