import {
    CompleteStatusIcon,
    OverdueStatusIcon,
    PendingStatusIcon,
} from "../assets/svg";

export const homeWorkStatuses = [
    { id: 1, title: "COMPLETED", Icon: CompleteStatusIcon },
    { id: 2, title: "PENDING", Icon: PendingStatusIcon },
    { id: 3, title: "OVERDUE", Icon: OverdueStatusIcon },
];
export const homeWorkStatusesStrings = homeWorkStatuses.map(
    ({ title }) => title
);

export const homeWorkFilterInitialState = (
    selectedSubjects = [],
    selectedStatuses = [],
    start = new Date(),
    end = new Date()
) => {
    return {
        dates: {
            start: new Date(start),
            end: new Date(end),
        },
        selectedSubjects,
        selectedStatuses,
    };
};
