import React from "react";
import { Image, Pressable, Text } from "react-native";
import { images } from "../../../constants";
import { useStylesUtils } from "../../../hooks";

export const TitleContent = ({ modeToggle }) => {
    const { styles } = useStylesUtils(getStyles);

    return (
        <Pressable onPress={modeToggle} style={{ flexDirection: "row" }}>
            <Image
                style={styles.image}
                source={images.btnImage5}
                resizeMode="contain"
            />
            <Text style={styles.text}>{"Time-table"}</Text>
        </Pressable>
    );
};

const getStyles = ({ width, height, OS }) => ({
    image: {
        width: width * 0.15,
        height: height * 0.2,
        marginRight: -20,
    },
    text: {
        height: height * 0.3,
        paddingTop: height * 0.4 * 0.3,
        paddingLeft: width * 0.02,
        fontSize: 60,
        lineHeight: 14,
        textAlign: "center",
        fontWeight: 700,
        color: "#fff",
        textShadowColor: "#000",
        textShadowOffset: { height: 0, width: 0 },
        textShadowRadius: 3,
        verticalAlign: "middle",
    },
});
