import { useDispatch, useSelector } from "react-redux";
import React, { useCallback } from "react";
import {
    getStudentTestWithParamsThunk,
    getStudentTestInstructionThunk,
    getStudentTestDetailsThunk,
    markStudentTestQuestionAsVisitedThunk,
    getStudentSpentTimeRedisThunk,
    updateStudentTestSpentTimeThunk,
    studentTestFinishThunk,
    studentTestResultReviewThunk,
} from "../store/tests/thunks";
import { getMappingInfo, getUserInfoForQueries } from "../store/auth/selectors";
import { useAuth } from "./useAuth";
import { getSubjectsForExamThunk } from "../store/main/thunks";
import { useNavigate } from "react-router-native";
import { navigation, getTestFinishedStatus } from "../constants";
import { usePopups } from "./usePopups";
import { getUserTests, getUserCurrentTest } from "../store/tests/selectors";
import { changeCurrentTestFinishedStatus } from "../store/tests/slice";

export const useTestsHook = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { checkRejectResponse } = useAuth();
    const { testDetailsInfoBeforeStart } = usePopups();

    const { cmsExamId, cmsGradeId } = useSelector(getMappingInfo);
    const { examId, gradeId, subtenantId } = useSelector(getUserInfoForQueries);
    const { details } = useSelector(getUserCurrentTest);

    let { pageLimit, testCategory, filter, pageNo } = useSelector(getUserTests);

    const getTestsListFor = useCallback(
        async (params) => {
            const result = await dispatch(
                getStudentTestWithParamsThunk({
                    // examId: cmsExamId,
                    pageLimit,
                    testCategory,
                    pageNo,
                    status: filter.status,
                    testCategories: [1],
                    subjectIds: filter.testCategories,
                    addToExist: false,
                    loader: true,

                    ...params,
                })
            );
            if (getStudentTestWithParamsThunk.rejected.match(result)) {
                await checkRejectResponse(result);
            }
        },
        [pageLimit, pageNo, filter]
    );

    const getStudentTestSubjects = useCallback(async () => {
        const result = await dispatch(
            getSubjectsForExamThunk({ examId: cmsExamId, subtenantId })
        );
        if (getSubjectsForExamThunk.rejected.match(result)) {
            await checkRejectResponse(result);
        }
    }, []);

    const getStudentTestDetails = useCallback(async (studentTestId) => {
        const resultTestDetails = await dispatch(
            getStudentTestDetailsThunk({ studentTestId })
        );

        const isFinished = getTestFinishedStatus(resultTestDetails);

        if (getStudentTestDetailsThunk.fulfilled.match(resultTestDetails)) {
            await dispatch(changeCurrentTestFinishedStatus(isFinished));
            await getStudentSpentTimeRedis({ studentTestId });
            navigate(navigation.QUIZZ_SCREEN);
        }

        if (getStudentTestInstructionThunk.rejected.match(resultTestDetails)) {
            await checkRejectResponse(resultTestDetails);
        }
    }, []);

    const getStudentTestInstruction = useCallback(async (test) => {
        const {
            testStartTimeInMs,
            scheduleId,
            id,
            testDuration: duration,
            studentTestId,
        } = test;

        if (!scheduleId) {
            return;
        }

        const instructionsResult = await dispatch(
            getStudentTestInstructionThunk({
                scheduleId,
                testId: id,
            })
        );

        if (
            getStudentTestInstructionThunk.fulfilled.match(instructionsResult)
        ) {
            const startTimestamp = new Date(testStartTimeInMs).getTime();
            const testIsReadyToBegin = startTimestamp > Date.now();
            // const testIsReadyToBegin = false;

            const { totalMarks, totalQuestions, instruction, syllabus } =
                instructionsResult.payload;

            testDetailsInfoBeforeStart(
                {
                    syllabus,
                    instructionText: instruction,
                    details: { duration, totalMarks, totalQuestions },
                },
                !testIsReadyToBegin,
                () => getStudentTestDetails(studentTestId)
            );
        }

        if (getStudentTestDetailsThunk.rejected.match(instructionsResult)) {
            await checkRejectResponse(instructionsResult);
        }
    }, []);

    const markStudentTestQuestionAsVisited = useCallback(
        async (currentQuestion, loader = false) => {
            const setVisited = async ({
                question_id,
                finishTime,
                startTime,
                answer,
                studentTestId,
                isVisited,
                negativeMarks,
                noAttemptMarks,
                positiveMarks,
                testQuestion_id,
                scheduleId,
                ...rest
            }) => {
                const questionGap =
                    finishTime && startTime
                        ? (finishTime - startTime) / 1000
                        : 1;

                let data = {
                    loader,
                    isVisited: true,
                    attemptedTime: Math.round(Date.now() / 1000),
                    testQuestionId: testQuestion_id,
                    questionGap,
                    studentTestId,
                    scheduleId,
                    clearResponse: false,
                    markForReview: false,
                    isResponse: false,
                    marks: 0,
                    correct: false,
                };

                if (answer) {
                    const { clearResponse, correct, response } = answer;
                    data = {
                        ...data,
                        correct,
                        response,
                        isResponse: !!response,
                        marks: correct ? positiveMarks : negativeMarks,
                    };

                    if (isVisited !== undefined) {
                        data.clearResponse = !isVisited;
                    }
                }

                const result = await dispatch(
                    markStudentTestQuestionAsVisitedThunk(data)
                );

                if (
                    markStudentTestQuestionAsVisitedThunk.fulfilled.match(
                        result
                    )
                ) {
                }

                if (
                    markStudentTestQuestionAsVisitedThunk.rejected.match(result)
                ) {
                    await checkRejectResponse(result);
                }
            };

            const { prev = null, ...rest } = currentQuestion;

            await setVisited(rest);

            if (prev) {
                await setVisited(prev);
            }
        },
        []
    );

    const getStudentSpentTimeRedis = useCallback(async ({ studentTestId }) => {
        const result = await dispatch(
            getStudentSpentTimeRedisThunk({
                studentTestId,
            })
        );

        if (getStudentSpentTimeRedisThunk.fulfilled.match(result)) {
        }

        if (getStudentSpentTimeRedisThunk.rejected.match(result)) {
            await checkRejectResponse(result);
        }
    }, []);

    const updateStudentTestSpentTime = useCallback(
        async (body) => {
            const result = await dispatch(
                updateStudentTestSpentTimeThunk(body)
            );

            if (updateStudentTestSpentTimeThunk.fulfilled.match(result)) {
            }

            if (updateStudentTestSpentTimeThunk.rejected.match(result)) {
                await checkRejectResponse(result);
            }
        },
        [details]
    );

    const studentTestFinish = useCallback(async ({ studentTestId }) => {
        const result = await dispatch(
            studentTestFinishThunk({
                studentTestId,
            })
        );

        if (studentTestFinishThunk.fulfilled.match(result)) {
            navigate(navigation.FINISH_TEST_SCREEN, {
                state: {
                    testResult: result.payload.score,
                    showResultButton: false,
                },
                replace: true,
            });

            return Promise.resolve(result);
        }

        if (studentTestFinishThunk.rejected.match(result)) {
            await checkRejectResponse(result);
            return Promise.reject(result);
        }
    }, []);

    const studentResultPreview = async ({ studentTestId }) => {
        const result = await dispatch(
            studentTestResultReviewThunk({
                studentTestId,
            })
        );
        if (studentTestResultReviewThunk.fulfilled.match(result)) {
            const data = {
                testDetails: result.payload.testDetails,
                testResult: result.payload.testResult,
                showResultButton:
                    !!result.payload?.testResult?.data?.showAnswers,
            };
            navigate(navigation.FINISH_TEST_SCREEN, {
                state: data,
                // replace: true,
            });

            return Promise.resolve(result);
        }

        if (studentTestResultReviewThunk.rejected.match(result)) {
            await checkRejectResponse(result);
            return Promise.reject(result);
        }
    };

    const getStudentTestReview = useCallback(async ({ studentTestId }) => {
        const result = await dispatch(
            studentTestResultReviewThunk({
                studentTestId,
            })
        );

        if (studentTestResultReviewThunk.fulfilled.match(result)) {
            navigate(navigation.QUIZZ_SCREEN, {
                replace: true,
            });
        }

        if (studentTestResultReviewThunk.rejected.match(result)) {
            await checkRejectResponse(result);
        }
    }, []);

    return {
        getTestsListFor,
        getStudentTestSubjects,
        getStudentTestInstruction,
        markStudentTestQuestionAsVisited,
        getStudentSpentTimeRedis,
        updateStudentTestSpentTime,
        studentTestFinish,
        studentResultPreview,
        getStudentTestReview,
    };
};
