import { Text, TextInput, View } from "react-native";
import { CustomCheckBox } from "../../../../components/CustomCheckBox";
import { EditBtn } from "../components/EditBtn";
import { COLOURS } from "../../../../constants";
import { useStylesUtils } from "../../../../hooks";
import Slider from "@react-native-community/slider";
import {
    HapticIcon,
    MinusIcon,
    PlusIcon,
    SoundIcon,
} from "../../../../assets/svg";
import { SelectBtn } from "../components/SelectBtn";
import { useState } from "react";

const CustomThumb = () => {
    const { styles } = useStylesUtils(getStyles);
    return <View style={styles.thumb} />;
};

export const DeviceSettings = () => {
    const { styles, width, imageSizes, height } = useStylesUtils(getStyles);

    const [hapticStatus, setHapticStatus] = useState(true);

    return (
        <View style={styles.tab}>
            <View style={styles.container}>
                <View style={styles.lable}>
                    <SoundIcon width={width * 0.015} height={width * 0.015} />
                    <Text style={styles.text}>App Sound Effects</Text>
                </View>
                <View style={styles.sliderContainer}>
                    <MinusIcon width={width * 0.015} />
                    <Slider
                        renderThumbComponent={CustomThumb}
                        style={{ width: "90%" }}
                        minimumTrackTintColor={"#7E1121"}
                        maximumTrackTintColor={"#FFB5B5"}
                    />
                    <PlusIcon width={width * 0.015} />
                </View>
            </View>
            <View style={styles.container}>
                <View style={styles.lable}>
                    <HapticIcon width={width * 0.015} height={width * 0.015} />
                    <Text style={styles.text}>Haptics Control</Text>
                </View>
                <View style={styles.btns}>
                    <SelectBtn
                        lable={"ON"}
                        isSelected={hapticStatus}
                        onPress={() => setHapticStatus(true)}
                    />
                    <SelectBtn
                        lable={"OFF"}
                        isSelected={!hapticStatus}
                        onPress={() => setHapticStatus(false)}
                    />
                </View>
            </View>
        </View>
    );
};

const getStyles = ({ height, width }) => ({
    tab: {
        width: width * 0.6,
        height: height * 0.7,
        borderRadius: 25,
        borderWidth: 3,
        borderColor: "#7E1121",
        backgroundColor: "#FFFFFF",
        padding: width * 0.05,
        alignItems: "flex-start",
        justifyContent: "flex-start",
    },
    container: {
        flexDirection: "column",
        marginBottom: height * 0.03,
        width: "100%",
        alignItems: "flex-start",
        justifyContent: "flex-start",
    },
    lable: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: height * 0.015,
    },
    text: {
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 5,
        color: COLOURS.blue4,
        marginLeft: width * 0.01,
    },
    sliderContainer: {
        backgroundColor: COLOURS.lightPink,
        borderRadius: height * 0.015,
        width: "65%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        paddingHorizontal: width * 0.01,
    },
    sliderIcons: {
        fontWeight: 700,
        fontSize: 20,
        color: "#FFD9D0",
    },
    thumb: {
        borderWidth: 3,
        borderColor: "red",
        height: width * 0.01,
        width: width * 0.01,
    },
    btns: {
        flexDirection: "row",
        width: "27%",
        justifyContent: "space-between",
        alignItems: "center",
    },
});
