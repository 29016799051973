export * from "./tab-btn";
export * from "./btns";
export { default as RetakeBtnIcon } from "./retake-btn";
export { default as ApproveBtn } from "./approve-btn";
export { default as RemoveBtn } from "./remove-btn";
export { default as CloseIcon } from "./close";
export { default as BtnIcon } from "./btn";
export { default as BigBtnIcon } from "./big-btn";
export { default as ArrowIcon } from "./arrow";
export { default as MicIcon } from "./mic";
export { default as BellIcon } from "./notifications-bell-btn";
export { default as DetailsBtnIcon } from "./details-btn";
export { default as Btn1Icon } from "./btn1";
