export * from "./learn";
export * from "./scrollIndicator";
export * from "./popup";
export * from "./profile/index";
export * from "./btn";
export * from "./header";
export * from "./dairy";
export * from "./calendarIcons";
export * from "./ScanAndUploadCardIcon";
export * from "./userProfile";
export * from "./quiz";

export { default as LeaderBoardIcon } from "./leaderboard";
export { default as RoundedIcon } from "./rounded-icon";
export { default as LetsReadIcon } from "./lets-read";
export { default as LetsCreateIcon } from "./lets-create";
export { default as TvIcon } from "./tvIcon";
export { default as BgBookIcon } from "./bg-book-icon";
export { default as BookIcon } from "./bookIcon";
export { default as BookTestIcon } from "./books1";
export { default as ScrollIcon } from "./scroll";
export { default as GameConsoleIcon } from "./gameConsole";
export { default as FileIcon } from "./file";
export { default as PdfPlaceholderIcon } from "./pdfPlaceholder";
export { default as TestPlaceholderIcon } from "./testPlaceholder";
export { default as FlashIcon } from "./flash-icon";
export { default as CalendarIcon } from "./calendarIcon";
export { default as MagnifyingGlassIcon } from "./MagnifyingGlass";
export { default as MicrophoneSpoken } from "./MicrophoneSpoken";
export { default as PdfDownloadIcon } from "./PdfDownloadIcon";
export { default as Heart2Icon } from "./heart2";
