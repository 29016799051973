import { useMemo, useContext } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { btnTypes } from "../constants";
import { useHaptic } from "../hooks/useHaptic";
import { useStylesUtils } from "../hooks/useStylesUtils";
import GlobalAudioContext from "../providers/GlobalAudioContext";

export const Button = ({
    // ButtonComponentProps
    label,
    onPress,
    style: buttonStyle,
    disabled = false,
    selected = false,
    iconProps = {},
    fillColor,
    strokeColor,
    selectedColors,
    type = "small",
    textStyle = {},
}) => {
    const { playClickSound } = useContext(GlobalAudioContext);
    const {
        styles,
        biggerSize: windowWidth,
        isWeb,
    } = useStylesUtils(getStyles({ disabled }), { textStyle, buttonStyle });
    const { hapticImpact } = useHaptic();

    const {
        width: iconWidth,
        height: iconHeight,
        ratio: iconRatio,
        ...rest
    } = iconProps;

    const { width, height, Icon, IconBG, iconAttr } = useMemo(() => {
        let {
            width,
            height,
            Icon,
            IconBG,
            selectedColors: saveSelectedColors,
            ratio,
        } = btnTypes[type];

        const iconAttr = {};

        if (fillColor) {
            iconAttr.fill = fillColor;
        }

        if (strokeColor) {
            iconAttr.stroke = strokeColor;
        }

        if (selectedColors && selected) {
            iconAttr.fill = selectedColors.fill || iconAttr.fill;
            iconAttr.stroke = selectedColors.stroke || iconAttr.stroke;
        } else if (!selectedColors && saveSelectedColors) {
            iconAttr.fill = saveSelectedColors?.fill;
            iconAttr.stroke = saveSelectedColors?.stroke;
        }

        const staticRatio = iconRatio ? iconRatio : ratio;
        const staticWidth = windowWidth * staticRatio;
        const staticHeight = staticWidth / (width / height);

        return {
            width: iconWidth ? iconWidth : staticWidth,
            height: iconHeight ? iconHeight : staticHeight,
            Icon,
            IconBG,
            iconAttr,
        };
    }, [type, fillColor, iconWidth, iconHeight, iconRatio, selected]);

    const content = () => {
        if (typeof label === "string") {
            return (
                <Text style={[styles.label, styles.textStyle]}>{label}</Text>
            );
        } else if (typeof label === "function") {
            return label({ style: [styles.label, styles.textStyle] });
        }

        return <></>;
    };

    const onBtnPressed = (e) => {
        hapticImpact(1);
        playClickSound();
        return onPress(e);
    };

    return (
        <TouchableOpacity
            onPress={onBtnPressed}
            style={[
                styles.touchableStyle,
                isWeb ? {} : styles.buttonShadow,
                styles.buttonStyle,
            ]}
            disabled={disabled}>
            <View
                style={[
                    styles.container,
                    { width: width + 15, height: height },
                ]}>
                <Icon
                    style={[styles.background, {}]}
                    height={height}
                    width={width}
                    {...iconAttr}
                    {...rest}
                />
                {content()}
            </View>
        </TouchableOpacity>
    );
};

const getStyles =
    ({ disabled }) =>
    () => ({
        buttonShadow: {
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.23,
            shadowRadius: 2.62,
            elevation: 4,
        },
        touchableStyle: {
            opacity: disabled ? 0.2 : 1,
        },
        container: {
            alignItems: "center",
            justifyContent: "center",
        },
        background: {
            position: "absolute",
            zIndex: 1,
            transform: [{ scale: 1 }],
        },
        backgroundContainer: {
            flex: 1,
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
        },
        label: {
            fontWeight: 700,
            fontSize: 16,
            color: "white",
            zIndex: 1,
        },
    });
