import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={45}
            height={46}
            viewBox="0 0 45 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M41.08 24.258a1.745 1.745 0 01-.532-1.253c0-.982.814-1.778 1.82-1.771l2.568.015c-.471-5.29-3.076-10.148-6.821-13.917C34.355 3.577 29.505.967 24.217.5l.015 2.57c.006 1.004-.789 1.815-1.764 1.815a1.75 1.75 0 01-1.255-.53 1.818 1.818 0 01-.515-1.286L20.712.5c-5.287.47-10.134 3.08-13.89 6.832C3.081 11.1.47 15.959 0 21.249l2.568-.015c.997-.007 1.81.789 1.81 1.77 0 .49-.203.932-.532 1.253a1.805 1.805 0 01-1.278.518L0 24.76C.471 30.044 3.082 34.9 6.822 38.67c3.756 3.759 8.603 6.36 13.89 6.829l-.014-2.556c-.003-.504.194-.96.515-1.286a1.74 1.74 0 011.255-.534c.975 0 1.77.812 1.764 1.82l-.015 2.556c5.288-.467 10.137-3.067 13.897-6.829 3.746-3.772 6.351-8.627 6.822-13.911l-2.568.015a1.822 1.822 0 01-1.287-.517m-10.798 6.57a1.114 1.114 0 01-1.574.013l-5.107-5.117c-.347.146-.731.228-1.13.228a2.956 2.956 0 01-2.95-2.947c0-1.23.754-2.287 1.825-2.73.002-3.927.007-7.853.017-11.776.002-.612.5-1.124 1.107-1.124.6 0 1.102.512 1.104 1.124.01 3.922.015 7.847.017 11.773a2.964 2.964 0 011.822 2.733c0 .4-.079.782-.227 1.132 1.71 1.712 3.416 3.419 5.11 5.11a1.116 1.116 0 01-.014 1.58"
                fill="#F686BD"
            />
        </Svg>
    );
}

export default SvgComponent;
