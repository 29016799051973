import { useFonts } from "@expo-google-fonts/quicksand";
import { StatusBar } from "expo-status-bar";
import React, { useEffect } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Route, Routes } from "react-router-native";
import { LoaderLayout, Modal, PopupLayout } from "../components";
import { NotificationsLayout } from "../components/layout/Notifications";
import { navigation, quicksandFont, getLocationType } from "../constants";
import {
    EnterAdmissionNumber,
    EnterPassCode,
    EnterRegisteredPhone,
    EnterVerifyOtp,
    LoginScreen,
    SetNewPasscode,
} from "./auth";
import { AcademicPlannerScreen } from "./main/AcademicPlannerScreen";
import { AcademicScreen } from "./main/AcademicScreen";
import { BooksScreen } from "./main/BooksScreen";
import { CameraScreen } from "./main/Camera/CameraScreen";
import { GalleryScreen } from "./main/Camera/GalleryScreen";
import { PreviewScreen } from "./main/Camera/PreviewScreen";
import { ChaptersScreen } from "./main/ChaptersScreen";
import { ClassActivityScreen } from "./main/ClassActivityScreen";
import { ComingSoonScreen } from "./main/ComingSoonScreen";
import { DiaryScreen } from "./main/Diary";
import { EventsScreen } from "./main/EventsScreen";
import { HomeScreen } from "./main/HomeScreen/HomeScreen";
import { HomeWorkScreen } from "./main/HomeWorkScreen";
import { LetsCreateScreen } from "./main/LetsCreateScreen";
import { LetsLearnScreen } from "./main/LetsLearnScreen";
import { LetsReadScreen } from "./main/LetsRead";
import { MusicScreen } from "./main/MusicScreen";
import { OopsScreen } from "./main/OopsScreen";
import { ParentsCornerScreen } from "./main/ParentsCorner";
import { PlaylistScreen } from "./main/PlaylistScreen";
import { SearchScreen } from "./main/SearchScreen";
import { StoriesScreen } from "./main/StoriesScreen";
import { StoryScreen } from "./main/StoryScreen";
import { ProfileScreen } from "./main/StudentProfile";
import { SubjectsScreen } from "./main/SubjectsScreen";
import { TimeTableScreen } from "./main/TimeTableScreen";
import { TracingBoardScreen } from "./main/TracingBoardScreen";
import { UploadHomeWorkScreen } from "./main/UploadHomeWorkScreen";
import { VideosScreen } from "./main/VideosScreen";
import { SplashScreen } from "./main/SplashScreen";
import { WebStoryScreen } from "./main/WebStoryScreen";
import { DocumentScreen } from "./main/DocumentScreen";
import { VideoScreen } from "./main/VideoScreen";
import { FavoriteScreen } from "./main/FavoriteScreen";
import { LetsExploreScreen } from "./main/LetsExploreScreen";
import { TestScreen } from "./main/TestScreen";
import { FinishTestScreen } from "./main/TestScreen/FinishTestScreen";
import { setLayoutType } from "../store/app/slice";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { QuizContextProvider, QuizScreen } from "./main/QuizV3";

const RootNavigator = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const type = useSelector((state) => state.app.layoutType);

    const [fontsLoaded] = useFonts(quicksandFont);

    useEffect(() => {
        const checkType = getLocationType(pathname);

        if (checkType && checkType !== type) {
            dispatch(setLayoutType(checkType));
        }
    }, [pathname, type]);

    if (!fontsLoaded) {
        return null;
    }

    return (
        <SafeAreaProvider>
            <StatusBar hidden={true} />
            <Routes>
                <Route
                    exact
                    path={navigation.SPLASH}
                    element={<SplashScreen />}
                />
                // Auth
                <Route path={navigation.LOGIN} element={<LoginScreen />} />
                <Route
                    path={navigation.ENTER_ADMISSION_NUMBER}
                    element={<EnterAdmissionNumber />}
                />
                <Route
                    path={navigation.ENTER_PASS_CODE}
                    element={<EnterPassCode />}
                />
                <Route
                    path={navigation.ENTER_REGISTERED_PHONE}
                    element={<EnterRegisteredPhone />}
                />
                <Route
                    path={navigation.ENTER_VERIFY_OTP}
                    element={<EnterVerifyOtp />}
                />
                <Route
                    path={navigation.SET_NEW_PASSCODE}
                    element={<SetNewPasscode />}
                />
                // Main
                <Route path={navigation.HOME} element={<HomeScreen />} />
                <Route path={navigation.DIARY} element={<DiaryScreen />} />
                <Route
                    path={navigation.LETS_READ}
                    element={<LetsReadScreen />}
                />
                <Route
                    path={navigation.LETS_CREATE}
                    element={<LetsCreateScreen />}
                />
                <Route
                    path={navigation.LETS_LEARN}
                    element={<LetsLearnScreen />}
                />
                <Route
                    path={navigation.PLAYLIST}
                    element={<PlaylistScreen />}
                />
                <Route
                    path={navigation.BOOKS_SCREEN}
                    element={<BooksScreen />}
                />
                <Route
                    path={navigation.CHAPTERS_SCREEN}
                    element={<ChaptersScreen />}
                />
                <Route
                    path={navigation.MUSIC_SCREEN}
                    element={<MusicScreen />}
                />
                <Route
                    path={navigation.STORY_SCREEN}
                    element={<StoryScreen />}
                />
                <Route
                    path={navigation.WEB_STORY_SCREEN}
                    element={<WebStoryScreen />}
                />
                <Route
                    path={navigation.SUBJECTS_SCREEN}
                    element={<SubjectsScreen />}
                />
                <Route
                    path={navigation.STORIES_SCREEN}
                    element={<StoriesScreen />}
                />
                <Route
                    path={navigation.VIDEOS_SCREEN}
                    element={<VideosScreen />}
                />
                <Route path={navigation.OOPS_SCREEN} element={<OopsScreen />} />
                <Route
                    path={navigation.COMING_SOON_SCREEN}
                    element={<ComingSoonScreen />}
                />
                <Route
                    path={navigation.TRACING_BOARD_SCREEN}
                    element={<TracingBoardScreen />}
                />
                <Route
                    path={navigation.CAMERA_SCREEN}
                    element={<CameraScreen />}
                />
                <Route
                    path={navigation.HOME_WORK_SCREEN}
                    element={<HomeWorkScreen />}
                />
                <Route
                    path={navigation.UPLOAD_HOMEWORK_SCREEN}
                    element={<UploadHomeWorkScreen />}
                />
                <Route
                    path={navigation.PREVIEW_SCREEN}
                    element={<PreviewScreen />}
                />
                <Route
                    path={navigation.GALLERY_SCREEN}
                    element={<GalleryScreen />}
                />
                <Route
                    path={navigation.CLASS_ACTIVITY_SCREEN}
                    element={<ClassActivityScreen />}
                />
                <Route
                    path={navigation.TIME_TABLE_SCREEN}
                    element={<TimeTableScreen />}
                />
                <Route
                    path={navigation.Events_SCREEN}
                    element={<EventsScreen />}
                />
                <Route
                    path={navigation.SEARCH_SCREEN}
                    element={<SearchScreen />}
                />
                <Route
                    path={navigation.PARENTS_CORNER_SCREEN}
                    element={<ParentsCornerScreen />}
                />
                <Route
                    path={navigation.PROFILE_SCREEN}
                    element={<ProfileScreen />}
                />
                <Route
                    path={navigation.ACADEMIC_SCREEN}
                    element={<AcademicScreen />}
                />
                <Route
                    path={navigation.ACADEMIC_PLANNER_SCREEN}
                    element={<AcademicPlannerScreen />}
                />
                <Route
                    path={navigation.DOCUMENT_SCREEN}
                    element={<DocumentScreen />}
                />
                <Route
                    path={navigation.VIDEO_SCREEN}
                    element={<VideoScreen />}
                />
                <Route
                    path={navigation.FAVORITE_SCREEN}
                    element={<FavoriteScreen />}
                />
                <Route
                    path={navigation.LETS_EXPLORE}
                    element={<LetsExploreScreen />}
                />
                <Route path={navigation.TEST_SCREEN} element={<TestScreen />} />
                <Route
                    path={navigation.QUIZZ_SCREEN}
                    element={
                        <QuizContextProvider>
                            <QuizScreen />
                        </QuizContextProvider>
                    }
                />
                <Route
                    path={navigation.FINISH_TEST_SCREEN}
                    element={<FinishTestScreen />}
                />
            </Routes>
            <Modal />
            <PopupLayout />
            <NotificationsLayout />
            <LoaderLayout />
        </SafeAreaProvider>
    );
};

export default RootNavigator;
