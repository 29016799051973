import { COLOURS } from "./colours";
import { images } from "./images";
import { navigation } from "./navigation";

export const mainLayoutOptions = {
    ["brown"]: {
        locations: [navigation.TEST_SCREEN],
        bgColor: COLOURS.brown2,
        image: images.testBrownBG,
        contentBgColor: COLOURS.brown12,
        fill: COLOURS.brown10,
    },
    ["lightGreen"]: {
        locations: [navigation.ACADEMIC_PLANNER_SCREEN],
        bgColor: COLOURS.green5,
        image: images.academicPlannerBG,
        fill: COLOURS.green4,
    },
    ["pink"]: {
        locations: [navigation.PROFILE_SCREEN],
        bgColor: COLOURS.pink6,
        image: images.mainBGPink,
        fill: COLOURS.pink6,
    },
    ["pink2"]: {
        locations: [navigation.ACADEMIC_SCREEN],
        bgColor: COLOURS.lightPink1,
        image: images.mainBGPink2,
    },
    ["pink3"]: {
        locations: [navigation.FAVORITE_SCREEN],
        bgColor: COLOURS.pink7,
        image: images.mainBGPink3,
    },
    ["purple"]: {
        locations: [
            navigation.DIARY,
            navigation.Events_SCREEN,
            navigation.HOME_WORK_SCREEN,
        ],
        bgColor: COLOURS.purple3,
        image: images.mainBGPurple,
        fill: COLOURS.purple4,
        contentBgColor: COLOURS.purple24,
    },
    ["yellow"]: {
        locations: [navigation.PARENTS_CORNER_SCREEN],
        bgColor: COLOURS.yellow4,
        image: images.mainBGYellow,
        fill: COLOURS.yellow6,
    },
    ["orange"]: {
        locations: [navigation.HOME, navigation.SEARCH_SCREEN],
        bgColor: COLOURS.lightOrange,
        image: images.mainBG,
        fill: COLOURS.yellow2,
    },
    ["red"]: {
        locations: [navigation.LETS_CREATE, navigation.LETS_EXPLORE],
        bgColor: COLOURS.mainRed,
        image: images.mainBGRed,
        fill: COLOURS.red1,
    },
    ["blue"]: {
        locations: [navigation.LETS_LEARN, navigation.BOOKS_SCREEN],
        bgColor: COLOURS.mainBlue,
        image: images.mainBGBlue,
        fill: COLOURS.blue1,
    },
    ["green"]: {
        locations: [navigation.LETS_READ],
        bgColor: COLOURS.mainGreen,
        image: images.mainBGGreen,
        fill: COLOURS.green2,
    },
    ["white"]: {
        locations: [navigation.OOPS_SCREEN],
        bgColor: COLOURS.white,
        image: images.mainBGWhite,
        fill: COLOURS.blue1,
    },
    ["coming"]: {
        locations: [navigation.COMING_SOON_SCREEN],
        bgColor: COLOURS.white,
        image: images.comingSoonBg,
    },
    ["quizz"]: {
        locations: [navigation.QUIZZ_SCREEN],
        bgColor: COLOURS.white,
        image: images.quizzBg,
    },
    ["finishTest"]: {
        locations: [navigation.FINISH_TEST_SCREEN],
        bgColor: COLOURS.yellow10,
        image: images.finishTestBG,
    },
};

export const getLocationType = (path) => {
    return Object.entries(mainLayoutOptions).find(([key, value]) =>
        value.locations?.includes(path)
    )?.[0];
};
