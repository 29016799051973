import { letsReadIcons } from "../../../constants";

export const letsReedCategoriesNames = [
    "rhymes",
    "stories",
    "phonics",
    "soft skills",
    "reading",
];

export const letsReedGetIcon = (categoryName) => {
    switch (categoryName) {
        case "rhymes":
            return letsReadIcons.rhymesIcon;
        case "stories":
            return letsReadIcons.storiesIcon;
        case "phonics":
            return letsReadIcons.phonicsIcon;
        case "soft skills":
            return letsReadIcons.softSkillsIcon;
        case "reading":
            return letsReadIcons.readingIcon;
        default:
            return null;
    }
};
