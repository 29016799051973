import React from "react";
import { Image, Text } from "react-native";
import { images } from "../../../constants";
import { useStylesUtils } from "../../../hooks";

export const TitleContent = () => {
    const { styles } = useStylesUtils(getStyles);

    return (
        <>
            <Text style={styles.text}>{"Let's \nExplore"}</Text>
            <Image
                style={styles.image}
                source={images.btnImage2}
                resizeMode="contain"
            />
        </>
    );
};

const getStyles = ({ biggerSize, height, OS }) => ({
    image: {
        width: biggerSize * 0.2,
        height: height * 0.3,
        marginRight: -20,
    },
    text: {
        height: height * 0.3,
        paddingTop: height * 0.3 * 0.3,
        fontSize: 60,
        lineHeight: 14,
        textAlign: "center",
        fontWeight: 700,
        color: "#fff",
        textShadowColor: "#000",
        textShadowOffset: { height: 0, width: 0 },
        textShadowRadius: 3,
        verticalAlign: "middle",
    },
});
