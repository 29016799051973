import { useCallback, useMemo, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { CalendarIcon } from "../../../../assets/svg";
import { COLOURS } from "../../../../constants";
import {
    homeWorkFilterInitialState,
    homeWorkStatuses,
    homeWorkStatusesStrings,
} from "../../../../constants/home-work";
import { useStylesUtils } from "../../../../hooks/useStylesUtils";
import { closePopup } from "../../../../store/app/slice";
import { getStudentHomeworksRedux } from "../../../../store/diary/selectors";
import { setFilterData } from "../../../../store/diary/slice";
import { firstLetterUppercase } from "../../../../utils/text.utils";
import { Button } from "../../../Button";
import { DatePicker } from "../../../DatePicker";

export const HomeWorkFilterContent = () => {
    const dispatch = useDispatch();
    const { styles, width } = useStylesUtils(getStyles);

    const { filter, subjects } = useSelector(getStudentHomeworksRedux);

    const initialState = homeWorkFilterInitialState(
        filter?.subjectIds,
        filter?.statuses,
        filter?.startDate,
        filter?.endDate
    );

    const [state, setState] = useState(initialState);

    const resetForm = useCallback(() => {
        setState(
            homeWorkFilterInitialState(
                subjects.map(({ id }) => id),
                homeWorkStatusesStrings
            )
        );
    }, [state]);

    const applyForm = useCallback(async () => {
        await dispatch(
            setFilterData({
                subjectIds: state.selectedSubjects,
                statuses: state.selectedStatuses,
                startDate: state.dates.start,
                endDate: state.dates.end,
            })
        );

        dispatch(closePopup());
    }, [state]);

    const toggleSelected = (id, type) => {
        setState((prev) => ({
            ...prev,
            [type]: prev[type].includes(id)
                ? prev[type].filter((prevID) => prevID !== id)
                : [...prev[type], id],
        }));
    };

    const parseListSubjects = useMemo(() => {
        return subjects.map((item) => ({
            key: item.id,
            title: item.title,
            isSelected: state.selectedSubjects.includes(item.id),
            onPress: () => toggleSelected(item.id, "selectedSubjects"),
        }));
    }, [state.selectedSubjects]);

    const parseListStatuses = useMemo(() => {
        return homeWorkStatuses.map((item) => ({
            key: item.id,
            title: item.title,
            isSelected: state.selectedStatuses.includes(item.title),
            onPress: () => toggleSelected(item.title, "selectedStatuses"),
        }));
    }, [state.selectedStatuses]);

    const onDateChange = (date, type) => {
        if (!date) {
            return null;
        }

        setState((prev) => ({
            ...prev,
            dates: { ...prev.dates, [type]: new Date(date) },
        }));
    };

    return (
        <>
            <View style={styles.content}>
                <View style={styles.contentLeft}>
                    <Text style={styles.contentTitle}>Due Date:</Text>
                    <View style={styles.calendarField}>
                        <CalendarIcon
                            width={width * 0.025}
                            height={width * 0.025}
                        />
                        <DatePicker
                            value={state.dates.start}
                            onChange={(e) => onDateChange(e, "start")}
                            textColor={COLOURS.dark_purple}
                            maxDate={new Date(Date.now())}
                            mode={"date"}
                            style={styles.calendarTitle}
                        />
                    </View>
                    <Text
                        style={[
                            styles.contentTitle,
                            { width: "100%", textAlign: "center" },
                        ]}>
                        to:
                    </Text>
                    <View style={styles.calendarField}>
                        <CalendarIcon
                            width={width * 0.025}
                            height={width * 0.025}
                        />
                        <DatePicker
                            value={state.dates.end}
                            onChange={(e) => onDateChange(e, "end")}
                            textColor={COLOURS.dark_purple}
                            minDate={state.dates.start}
                            mode={"date"}
                            style={styles.calendarTitle}
                        />
                    </View>
                </View>
                <View style={styles.contentRight}>
                    <Text style={styles.contentTitle}>Subjects:</Text>
                    <View style={styles.contentList}>
                        {parseListSubjects.map(
                            ({ title, key, onPress, isSelected }) => (
                                <TouchableOpacity
                                    key={key}
                                    style={styles.view(isSelected)}
                                    onPress={onPress}>
                                    <Text style={styles.title}>
                                        {firstLetterUppercase(title)}
                                    </Text>
                                </TouchableOpacity>
                            )
                        )}
                    </View>
                    <Text style={styles.contentTitle}>Homework status:</Text>
                    <View style={styles.contentList}>
                        {parseListStatuses.map(
                            ({ title, key, onPress, isSelected }) => (
                                <TouchableOpacity
                                    key={key}
                                    style={styles.view(isSelected)}
                                    onPress={onPress}>
                                    <Text style={styles.title}>
                                        {firstLetterUppercase(title)}
                                    </Text>
                                </TouchableOpacity>
                            )
                        )}
                    </View>
                </View>
            </View>
            <View style={styles.buttonsContainer}>
                <Button
                    label={"Reset"}
                    onPress={resetForm}
                    style={{ marginRight: 10 }}
                />
                <Button
                    label={"Apply"}
                    onPress={applyForm}
                    disabled={
                        state.selectedStatuses.length <= 0 ||
                        state.selectedSubjects.length <= 0
                    }
                />
            </View>
        </>
    );
};

const getStyles = ({ width, height }) => ({
    btn: {
        flex: 1,
        justifyContent: "center",
        flexDirection: "row",
    },
    content: {
        flex: 1,
        justifyContent: "space-between",
        flexDirection: "row",
    },
    contentLeft: {
        flex: 1,
        width: "30%",
        paddingRight: 20,
    },
    contentRight: {
        width: "70%",
        paddingLeft: 20,
    },
    contentTitle: {
        color: COLOURS.purple14,
        fontSize: 16,
        fontWeight: 700,
    },
    calendarField: {
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: COLOURS.purple15,
        marginVertical: 5,
        paddingHorizontal: width * 0.015,
        paddingVertical: width * 0.008,
        borderRadius: 9999,
    },
    calendarTitle: {
        fontSize: 16,
        fontWeight: 700,
        flex: 1,
        padding: 0,
    },
    contentList: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 5,
    },
    buttonsContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 10,
    },
    view: (isSelected) => ({
        backgroundColor: isSelected ? COLOURS.yellow3 : COLOURS.purple15,
        borderColor: isSelected ? COLOURS.dark_purple : COLOURS.purple15,
        borderWidth: 2,
        paddingHorizontal: width * 0.015,
        paddingVertical: width * 0.008,
        borderRadius: 9999,
        marginBottom: 10,
        marginRight: 10,
    }),
    title: {
        fontSize: 16,
        fontWeight: 700,
        color: COLOURS.dark_purple,
        autoCapitalize: "words",
    },
});
