import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
    return (
        <Svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 122.88 66.49"
            fill="#77CCFF"
            {...props}
        >
            <Path
                className="cls-1"
                d="M2.27,18.3H34.05a2.27,2.27,0,0,1,2.27,2.27V64.22a2.27,2.27,0,0,1-2.27,2.27H2.27A2.27,2.27,0,0,1,0,64.22V20.57A2.27,2.27,0,0,1,2.27,18.3ZM68.4,9.44V31H61V17.39l-4.52,1.8-2-5.3,7.66-4.45ZM26,49.45H11V43.81l2.84-2.75c.62-.57,1.21-1.13,1.77-1.7L17,38a7.69,7.69,0,0,0,1-1.24,3.52,3.52,0,0,0,.54-1,2.82,2.82,0,0,0,.09-.71,1.08,1.08,0,0,0-.5-1.08,4.54,4.54,0,0,0-1.73-.23,24,24,0,0,0-4.32.36l-.69.13L11,28.58a21.3,21.3,0,0,1,7.12-1.21A9.13,9.13,0,0,1,24,29.06a5.64,5.64,0,0,1,2.12,4.68,9.36,9.36,0,0,1-1,4.7A11.9,11.9,0,0,1,21.45,42l-2.19,1.54H26v5.9ZM103.1,37.19q4.82,0,7,1.37t2.18,4.85a6.81,6.81,0,0,1-.44,2.75,4.59,4.59,0,0,1-1.56,1.74A6.49,6.49,0,0,1,112,49.78a5.44,5.44,0,0,1,.52,2.64q0,4.09-2.13,5.65t-6.58,1.55A28.12,28.12,0,0,1,98,59l-1.08-.23L97.07,53q3.77.33,5.37.33a8,8,0,0,0,2.13-.18.76.76,0,0,0,.53-.79.91.91,0,0,0-.37-.83,2.48,2.48,0,0,0-1.37-.33H98.74v-5.8h4.32c1.18,0,1.77-.33,1.77-1s-.75-1-2.26-1c-1,0-2.53.08-4.52.23l-.85.07L97,38.14a26.29,26.29,0,0,1,6.06-1ZM45.55,0H77.33A2.27,2.27,0,0,1,79.6,2.27v62a2.27,2.27,0,0,1-2.27,2.27H45.55a2.27,2.27,0,0,1-2.27-2.27V2.27A2.27,2.27,0,0,1,45.55,0ZM88.83,30.35h31.78a2.28,2.28,0,0,1,2.27,2.27v31.6a2.27,2.27,0,0,1-2.27,2.27H88.83a2.27,2.27,0,0,1-2.27-2.27V32.62a2.27,2.27,0,0,1,2.27-2.27Z"
            />
        </Svg>
    )
}

export default SvgComponent
