import { useCallback } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { COLOURS } from "../../../constants";
import { useStylesUtils } from "../../../hooks";

export const TabsContainer = ({ active, setActive }) => {
    const { styles } = useStylesUtils(getStyles);

    const tab = useCallback(
        (tabID, title) => (
            <TouchableOpacity
                onPress={() => setActive(tabID)}
                style={[
                    styles.tabButton,
                    active === tabID ? styles.tabButtonActive : {},
                    { marginRight: 10 },
                ]}>
                <Text
                    style={[
                        styles.tabButtonText,
                        active === tabID ? styles.tabButtonTextActive : {},
                    ]}>
                    {title}
                </Text>
            </TouchableOpacity>
        ),
        [active]
    );

    return (
        <View style={styles.tabsContainer} pointerEvents="box-none">
            {tab(1, "Academic Activity")}
            {tab(2, "Academic Report")}
        </View>
    );
};

const getStyles = ({ width, height }) => ({
    tabsContainer: {
        flexDirection: "row",
        justifyContent: "center",
    },
    tabButton: {
        width: width * 0.25,
        paddingVertical: 20,
        backgroundColor: COLOURS.pink,
        alignItems: "center",
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,
    },
    tabButtonActive: {
        backgroundColor: COLOURS.pink1,
        color: "#fff",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: -3,
        },
        shadowOpacity: 0.2,
        shadowRadius: 3,
        elevation: 4,
    },
    tabButtonText: {
        fontSize: 16,
        fontWeight: 700,
        color: "#fff",
    },
    tabButtonTextActive: {
        color: COLOURS.blue4,
    },
});
