import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={128}
            height={101}
            viewBox="0 0 128 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.459 17.611c.33.614 1.185.642 1.519.058.89-1.56 2.75-3.915 6.193-4.88-.016.267-.04.533-.04.806 0 7.08 5.74 12.82 12.82 12.82 7.082 0 12.823-5.74 12.823-12.82 0-.278-.025-.549-.043-.822l.063.015c3.443.966 5.303 3.322 6.193 4.881.334.584 1.188.556 1.52-.058l3.045-5.664c1.044-1.94.309-4.323-1.64-5.162-.97-.42-2.167-.791-3.59-.988-2.457-.344-5.89.74-7.355 1.261C40.732 3.299 36.642.774 31.95.774c-4.682 0-8.766 2.518-11.003 6.266-1.494-.528-4.879-1.582-7.306-1.243a13.792 13.792 0 00-3.589.988c-1.949.84-2.684 3.223-1.643 5.162l3.049 5.664zM62.65 53.171l-8.04-13.503a21.14 21.14 0 00-18.164-10.326h-8.989A21.14 21.14 0 009.293 39.668L1.35 53.004c-1.069 1.8-.528 4.24 1.338 5.192a3.591 3.591 0 004.638-1.206l9.848-11.93c.665-.804 1.972-.34 1.98.702l.022 3.33-12.123 17.6c-1.153 2.35-.066 5.295 2.23 6.044a73.714 73.714 0 0010.083 2.502l.144 20.055a4.818 4.818 0 005.808 4.467c2.146-.433 3.676-2.359 3.81-4.545l1.175-18.895c1.1.025 2.2.025 3.299 0l1.173 18.897c.134 2.184 1.665 4.11 3.812 4.543a4.82 4.82 0 005.808-4.467l.142-20.055a73.84 73.84 0 0010.085-2.502c2.293-.749 3.38-3.693 2.23-6.044l-12.08-17.598-.048-.013.025-3.655c.008-1.049 1.33-1.509 1.987-.693L56.5 56.874c.8 1.208 2.232 1.924 3.66 1.673a3.59 3.59 0 002.49-5.376M108.809 14.235c0 7.08-5.74 12.82-12.82 12.82-7.083 0-12.821-5.74-12.821-12.82s5.738-12.82 12.82-12.82c7.081 0 12.821 5.74 12.821 12.82zM126.686 53.808l-8.041-13.503a21.139 21.139 0 00-18.164-10.326h-8.989a21.142 21.142 0 00-18.164 10.326l-7.94 13.336c-1.074 1.8-.53 4.24 1.335 5.192a3.59 3.59 0 004.639-1.206l9.848-11.93c.664-.804 1.971-.34 1.979.702l.356 49.531a4.818 4.818 0 005.809 4.467c2.146-.433 3.675-2.359 3.81-4.545l1.683-27.075h2.282l1.681 27.075c.137 2.186 1.666 4.112 3.812 4.545a4.82 4.82 0 005.809-4.467l.356-49.867c.005-1.049 1.327-1.51 1.984-.693l9.765 12.141c.801 1.208 2.232 1.924 3.66 1.673a3.59 3.59 0 002.49-5.376"
                fill="#FEA08A"
            />
        </Svg>
    );
}

export default SvgComponent;
