import React from "react";
import { ImageBackground, View } from "react-native";
import { images } from "../../../constants";
import { useStylesUtils } from "../../../hooks";

export const HeaderBG = ({ children, showBgCloud, style }) => {
    const { styles, width } = useStylesUtils(getStyles);

    return (
        <View style={styles.pressableContainer} pointerEvents="box-none">
            {showBgCloud ? (
                <ImageBackground
                    pointerEvents="box-none"
                    source={images.headerBG}
                    style={[styles.container, style]}
                    imageStyle={{ height: width / 9.6 }}>
                    {children}
                </ImageBackground>
            ) : (
                <View
                    style={[styles.container, style]}
                    pointerEvents="box-none">
                    {children}
                </View>
            )}
        </View>
    );
};

const getStyles = ({ height }) => ({
    pressableContainer: {
        zIndex: 1,
        pointerEvents: "box-none",
    },
    container: {
        pointerEvents: "box-none",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        paddingBottom: 20,
        paddingTop: 20,
        maxHeight: height * 0.3,
    },
});
