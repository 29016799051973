import { TESTSInstance } from "./config";

export const testsApi = {
    getStudentTestWithParams: async (body = {}) => {
        return await TESTSInstance.post("k5/v1/test/getStudentTest", body);
    },
    getStudentTestInstruction: async (params) => {
        return await TESTSInstance.get("/k5/v1/test/getTestOverview", {
            params,
        });
    },

    getTestSyllabus: async (params) => {
        return await TESTSInstance.get("/v1/test/getTestSyllabus", {
            params,
        });
    },
    getStudentTestDetails: async (params) => {
        return await TESTSInstance.get("/v2/test/getStudentTestDetails", {
            params,
        });
    },
    getStudentSpentTimeRedis: async (params) => {
        return await TESTSInstance.get("/v1/test/getStudentSpentTimeRedis", {
            params,
        });
    },
    updateStudentTestSpentTime: async (params) => {
        return await TESTSInstance.put("/v1/test/studentTestSpentTime", null, {
            params,
        });
    },
    markStudentTestQuestionAsVisited: async (body) => {
        return await TESTSInstance.post(
            "/v1/test/createStudentTestQuestion",
            body
        );
    },
    studentTestFinish: async (body = {}) => {
        return await TESTSInstance.post("/v2/test/studentTestFinish", body, {
            params: body,
        });
    },
    getStudentTestScore: async (params) => {
        return await TESTSInstance.get("/k5/v1/test/getStudentTestResult", {
            params,
        });
    },
    getStudentTestDetailsPaper: async (params) => {
        return await TESTSInstance.get("/v1/test/getStudentTestDetailsPaper", {
            params,
        });
    },
    getStudentTestReport: async (params) => {
        return await TESTSInstance.get("/k5/v1/test/getStudentTestReport", {
            params,
        });
    },
    // getStudentTestScore: async (params) => {
    //     return await TESTSInstance.get("/v1/test/getStudentTestScore", {
    //         params,
    //     });
    // },
};
