import React, { useMemo } from "react";
import { View } from "react-native";

import { useStylesUtils } from "../../../../../hooks";
import { COLOURS, QUIZ_OPTION_STATUS } from "../../../../../constants";
import {
    QuizStatusOk,
    QuizStatusError,
    QuizStatusEmpty,
} from "../../../../../assets/svg";

export const QuizOptionMarker = ({ status, inversion, isAbsolute = true }) => {
    const { styles } = useStylesUtils(getStyles(inversion, isAbsolute));

    const { Icon, fill, bgColor } = useMemo(() => {
        switch (status) {
            case QUIZ_OPTION_STATUS.CHECKED:
                return {
                    Icon: undefined,
                    fill: COLOURS.quizItemChecked,
                    bgColor: COLOURS.quizItemChecked,
                };
            case QUIZ_OPTION_STATUS.SUCCESS:
                return {
                    Icon: QuizStatusOk,
                    fill: inversion ? COLOURS.white : COLOURS.quizItemSuccess,
                    bgColor: inversion
                        ? COLOURS.quizItemSuccess
                        : COLOURS.white,
                };
            case QUIZ_OPTION_STATUS.ERROR:
                return {
                    Icon: QuizStatusError,
                    fill: inversion ? COLOURS.white : COLOURS.red2,
                    bgColor: inversion ? COLOURS.red2 : COLOURS.white,
                };
            default:
                return {
                    Icon: QuizStatusEmpty,
                    fill: COLOURS.transparent,
                    bgColor: COLOURS.transparent,
                };
        }
    }, [status, inversion]);

    return Icon ? (
        <View style={[styles.container, { backgroundColor: bgColor }]}>
            {Icon && <Icon style={styles.image} fill={fill} />}
        </View>
    ) : null;
};

const getStyles =
    (inversion, isAbsolute) =>
    ({ width }) => ({
        container: {
            width: width * 0.02,
            height: width * 0.02,
            backgroundColor: COLOURS.white,
            borderRadius: 990,
            justifyContent: "center",
            alignItems: "center",
            ...(isAbsolute
                ? {
                      position: "absolute",
                      right: width * 0.01,
                      top: inversion ? width * 0.01 : "auto",
                      flex: 1,
                  }
                : {}),
        },
        image: {
            maxWidth: "60%",
            maxHeight: "60%",
        },
    });
