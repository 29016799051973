import { View, SafeAreaView, TouchableOpacity } from "react-native";
import React, { useContext } from "react";
import { useStylesUtils } from "../../../hooks";
import { MainLayout } from "../../../components";
import { TitleContent } from "./QuizCard/components/TitleContent";
import { NextNavButtonIcon, PrevNavButtonIcon } from "../../../assets/svg";
import QuizContext from "./QuizContext";
import { QuizProgressIndicator } from "./QuizCard/components/QuizProgressIndicator";
import { QuizCard } from "./QuizCard/QuizCard";
import { QuizRightBlockContent } from "./QuizRightBlockContent";

export const QuizScreen = () => {
    const {
        questions,
        totalPages,
        currentPage,
        onPressPrevQuestion,
        onPressNextQuestion,
        currentQuestion,
        saveCurrentQuestion,
    } = useContext(QuizContext);

    const { imageSizes, styles } = useStylesUtils(
        getStyles(
            currentQuestion?.index === 0,
            currentQuestion?.index === questions?.length - 1
        )
    );

    return questions ? (
        <MainLayout
            isSaveArea={false}
            headerProps={{
                showBgCloud: false,
                backButton: async ({ goBack }) => {
                    await saveCurrentQuestion();
                    goBack();
                },
                homeButton: false,
                titleContent: (
                    <TitleContent
                        totalPages={totalPages}
                        currentPage={currentPage}
                    />
                ),
                rightBlockContent: <QuizRightBlockContent />,
            }}
            viewStyle={{ flexDirection: "column" }}>
            <SafeAreaView style={styles.saveArea}>
                <TouchableOpacity
                    style={styles.prevButton}
                    onPress={onPressPrevQuestion}>
                    <PrevNavButtonIcon {...imageSizes.standard} />
                </TouchableOpacity>
                <View style={styles.cardWrapper}>
                    <QuizCard />
                </View>
                <TouchableOpacity
                    style={styles.nextButton}
                    onPress={onPressNextQuestion}>
                    <NextNavButtonIcon {...imageSizes.standard} />
                </TouchableOpacity>
            </SafeAreaView>
            <QuizProgressIndicator />
        </MainLayout>
    ) : (
        <></>
    );
};

const getStyles = (isFirst, isLast) => () => ({
    saveArea: {
        flex: 1,
        width: "100%",
        maxWidth: "100%",
        flexDirection: "row",
        marginBottom: 10,
    },
    prevButton: {
        alignSelf: "center",
        opacity: isFirst ? 0.2 : 1,
    },
    nextButton: {
        alignSelf: "center",
        opacity: isLast ? 0.2 : 1,
    },
    cardWrapper: {
        flex: 1,
        justifyContent: "space-between",
        width: "100%",
    },
});
