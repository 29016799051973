import React from "react";
import { Text, View } from "react-native";
import { MainLayout } from "../../../components";
import { DashedLine } from "../../../components/DashedLine";
import { COLOURS } from "../../../constants";
import { useStylesUtils } from "../../../hooks";
import { ContainerFooter } from "./footer";
import { CustomScrollView } from "../../../components/CustomScrollView";

export const UploadHomeWorkScreen = () => {
    const { styles, width } = useStylesUtils(getStyles);

    return (
        <MainLayout
            headerProps={{
                backButton: true,
                homeButton: true,
                showBgCloud: true,
                titleContent: (
                    <>
                        <Text style={styles.titleText}>
                            {"Please upload your Homework sheet"}
                        </Text>
                    </>
                ),
            }}>
            <View style={styles.container}>
                <View style={styles.containerTitle}>
                    <Text style={styles.containerTitleText}>
                        Task: Solve the Equations
                    </Text>
                    <Text style={styles.containerTitleText}>
                        Subject: Social Science
                    </Text>
                </View>
                <View style={styles.containerContent}>
                    <DashedLine />
                    <View>
                        <CustomScrollView showIndicator={true}>
                            <Text style={styles.containerContentText}>
                                Risus at ultrices mi tempus imperdiet. Et
                                pharetra pharetra massa massa ultricies. Magnis
                                dis parturient montes nascetur ridiculus mus.
                                Fermentum et sollicitudin ac orci. Faucibus
                                pulvinar elementum integer enim neque volutpat.
                                Egestas congue quisque egestas diam in arcu
                                cursus. Et malesuada fames ac turpis egestas sed
                                tempus urna. Dignissim sodales ut eu sem. Ut
                                porttitor leo a diam sollicitudin tempor id eu
                                nisl. Quisque sagittis purus sit amet. Nunc
                                congue nisi vitae suscipit tellus mauris a diam.
                                Sit amet tellus cras adipiscing enim eu. Morbi
                                tristique senectus et netus. Nascetur ridiculus
                                mus mauris vitae. Amet luctus venenatis lectus
                                magna fringilla urna. Vulputate eu scelerisque
                                felis imperdiet proin fermentum leo. Pulvinar
                                sapien et ligula ullamcorper malesuada proin.
                                Duis ultricies lacus sed turpis. Risus at
                                ultrices mi tempus imperdiet. Et pharetra
                                pharetra massa massa ultricies. Magnis dis
                                parturient montes nascetur ridiculus mus.
                                Fermentum et sollicitudin ac orci. Faucibus
                                pulvinar elementum integer enim neque volutpat.
                                Egestas congue quisque egestas diam in arcu
                                cursus. Et malesuada fames ac turpis egestas sed
                                tempus urna. Dignissim sodales ut eu sem. Ut
                                porttitor leo a diam sollicitudin tempor id eu
                                nisl. Quisque sagittis purus sit amet. Nunc
                                congue nisi vitae suscipit tellus mauris a diam.
                                Sit amet tellus cras adipiscing enim eu. Morbi
                                tristique senectus et netus. Nascetur ridiculus
                                mus mauris vitae. Amet luctus venenatis lectus
                                magna fringilla urna. Vulputate eu scelerisque
                                felis imperdiet proin fermentum leo. Pulvinar
                                sapien et ligula ullamcorper malesuada proin.
                                Duis ultricies lacus sed turpis.
                            </Text>
                        </CustomScrollView>
                    </View>
                </View>
                <View style={styles.containerFooter}>
                    <ContainerFooter styles={styles} width={width} />
                </View>
            </View>
        </MainLayout>
    );
};

const getStyles = {
    titleText: {
        fontWeight: 700,
        color: "#fff",
        fontSize: 30,
        paddingTop: 20,
    },
    container: {
        flex: 1,
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
    },
    containerTitle: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 5,
    },
    containerTitleText: {
        fontWeight: 700,
        color: "#fff",
        fontSize: 16,
    },
    containerContent: {
        flex: 1,
        width: "100%",
        backgroundColor: "#fff",
        borderRadius: 20,
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 30,
        paddingRight: 40,
        overflow: "hidden",
        flexDirection: "row",
    },
    containerContentScroll: {
        paddingLeft: 30,
    },
    containerFooter: {
        width: "100%",
        alignItems: "center",
        marginTop: 10,
    },
    uploadBtn: {
        width: "100%",
        paddingLeft: 10,
        paddingRight: 35,
        zIndex: 10,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    tooltipTitle: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        fontSize: 16,
        width: "100%",
        textAlign: "center",
        paddingBottom: 10,
    },
    tooltipText: {
        fontWeight: 500,
        color: COLOURS.dark_purple,
        fontSize: 14,
    },
    containerContentText: {
        width: "90%",
        fontWeight: 500,
        color: COLOURS.dark_purple,
        fontSize: 20,
    },
    indStyle: {
        backgroundColor: COLOURS.purple8,
        borderRadius: 0,
    },
};
