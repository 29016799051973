import { createSlice } from "@reduxjs/toolkit";
import { popupCheckType } from "../../constants";

const initialState = {
    isLoading: false,
    permissions: {
        isAccessToCamera: false,
        isAccessToMicrophone: false,
    },
    popup: {
        open: false,
        title: "",
        subTitle: "",
        type: null,
        content: "",
        confirmButton: false,
        cancelButton: false,
        showCloseBtn: false,
        styles: {},
    },
    notification: {
        open: false,
        notifications: [],
    },
    modal: {
        open: false,
        content: <></>,
    },
    layoutType: "orange",
    checkUserPaymentStatus: false,
    scrollProgress: {},
    selectedSubjectID: null,
    selectedSubjectIndex: null,
    soundStatus: true,
};

const slice = createSlice({
    name: "app",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        stopLoading(state) {
            state.isLoading = false;
        },
        openPopup(state, { payload }) {
            state.popup = {
                ...initialState.popup,
                open: true,
                ...payload,
                type: popupCheckType(payload.type),
            };
        },
        openNotification(state, { payload }) {
            state.notification = {
                ...initialState.notification,
                open: true,
                ...payload,
            };
        },
        closeNotification(state) {
            state.notification = initialState.notification;
        },
        closePopup(state) {
            state.popup = initialState.popup;
        },
        setLayoutType(state, { payload }) {
            state.layoutType = payload;
        },
        openModal(state, { payload }) {
            state.modal = {
                open: true,
                content: payload,
            };
        },
        closeModal(state) {
            state.modal = initialState.modal;
        },
        saveScrollProgress(state, { payload }) {
            state.scrollProgress = {
                ...state.scrollProgress,
                ...payload,
            };
        },
        setSelectedSubjectId(state, { payload }) {
            state.selectedSubjectID = payload;
        },
        setSelectedSubjectIndex(state, { payload }) {
            state.selectedSubjectIndex = payload;
        },
        setCameraAccessStatus(state, { payload }) {
            state.permissions.isAccessToCamera = payload;
        },
        setAudioRecordingStatus(state, { payload }) {
            state.permissions.isAccessToMicrophone = payload;
        },
        toggleSoundStatus(state, { payload }) {
            state.soundStatus = payload;
        },
    },
    extraReducers: (builder) => {},
});

export const {
    startLoading,
    stopLoading,
    openPopup,
    closePopup,
    setLayoutType,
    openModal,
    closeModal,
    saveScrollProgress,
    setSelectedSubjectId,
    setSelectedSubjectIndex,
    setCameraAccessStatus,
    openNotification,
    closeNotification,
    setAudioRecordingStatus,
    toggleSoundStatus,
} = slice.actions;
export default slice.reducer;
