import * as React from "react";
import { Svg, G, Path, Defs } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent(props) {
    return (
        <Svg
            viewBox="0 0 620 663"
            xmlns="http://www.w3.org/2000/svg"
            fill="#6AA84F"
            stroke="#F69253"
            strokeOpacity={0.54902}
            {...props}>
            <G>
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M119.784 55.718S210.211 44 300.21 44c99.4 0 199.307 11.718 199.307 11.718 39.239 5.603 67.319 43.285 71.331 96.678 0 0 4.196 59.501 4.196 119.139 0 57.509-3.496 244.256-3.496 244.256-4.013 53.394-33.704 94.848-65.737 96.678-15.37 1.817-100.535 5.81-195.112 5.859-95.382.05-152.796-.957-190.915-5.859-38.12-4.902-70.328-42.732-71.331-94.725 0 0-3.497-201.993-3.497-265.74 0-52.299 2.797-98.631 2.797-98.631 2.007-55.495 33.293-92.052 72.031-97.654z"
                    stroke="none"
                />
                <Path
                    d="M119.784 55.718l-1.285-9.917-2.392.31-.129.089c-43.543 7.579-76.08 48.848-78.214 106.693l-.001.023-.024.409-.09 1.583c-.078 1.385-.188 3.416-.32 6.014-.264 5.196-.615 12.663-.967 21.769-.702 18.204-1.406 42.997-1.406 69.313 0 31.964.876 98.481 1.75 156.925a52114.204 52114.204 0 001.71 106.702l.028 1.704.008.435.002.11v.035c0 .001 0 .002 9.999-.171l-9.999.173v.02c1.075 55.706 35.88 98.769 80.055 104.45 38.887 5.001 97.001 5.991 192.196 5.941 94.014-.048 179.24-3.988 195.984-5.894 20.101-1.273 37.875-14.681 50.726-33.283 13.051-18.892 21.981-44.353 24.104-72.61l.022-.281.005-.282-9.998-.187c9.998.187 9.998.187 9.998.185v-.006l.001-.026.001-.102.008-.402.029-1.575.111-6.027a44991.71 44991.71 0 001.598-92.447c.874-53.849 1.75-115.003 1.75-143.856 0-30.032-1.056-59.985-2.109-82.403a2059.115 2059.115 0 00-1.449-27.11c-.198-3.274-.363-5.849-.479-7.609l-.135-2.015-.035-.513-.001-.008-.009-.135-.003-.035v-.01l-.001-.003-9.975.702 9.975-.703-.001-.023-.002-.023c-4.207-55.977-34.2-99.304-79.89-105.828l-.124-.018-.124-.014-1.165 9.931c1.165-9.931 1.164-9.932 1.163-9.932h-.005l-.016-.002-.059-.007-.226-.026-.875-.1c-.769-.088-1.904-.215-3.381-.377-2.953-.323-7.273-.784-12.764-1.336a2083.168 2083.168 0 00-45.435-4.048C400.374 36.949 350.266 34 300.21 34c-45.393 0-90.81 2.953-124.825 5.897a1713.357 1713.357 0 00-41.17 4.052c-4.976.553-8.892 1.014-11.571 1.338a702.144 702.144 0 00-3.863.478l-.206.027-.055.007-.014.002h-.005c-.001 0-.002 0 1.283 9.917z"
                    fill="none"
                    strokeWidth={20}
                />
            </G>
        </Svg>
    );
}

export default SvgComponent;
