import Heap from "@heap/react-native-heap";
import "react-native-gesture-handler";
import { Provider } from "react-redux";
import { NativeRouter } from "react-router-native";
import { PersistGate } from "redux-persist/integration/react";
import { LoaderLayout } from "./src/components";
import RootNavigator from "./src/screens/RootNavigation";
import { persisStore, store } from "./src/store";
import { GlobalAudioContextProvider } from "./src/providers/GlobalAudioContext";

export default function App() {
    Heap.setAppId("3725047661");

    return (
        <Provider store={store}>
            <PersistGate loading={<LoaderLayout />} persistor={persisStore}>
                <NativeRouter>
                    <GlobalAudioContextProvider>
                        <RootNavigator />
                    </GlobalAudioContextProvider>
                </NativeRouter>
            </PersistGate>
        </Provider>
    );
}
