import * as React from "react";
import Svg, { Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const NavLeftBtnIcon = (props) => {
    return (
        <Svg
            width={107}
            height={100}
            viewBox="0 0 107 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M87.933 22.013c12.219 17.754 10.765 42.621-6.989 54.84-17.754 12.218-51.761 8.614-63.98-9.14C4.746 49.959 12.716 25.556 30.47 13.338 48.224 1.12 75.715 4.259 87.933 22.013"
                fill="#5F0FAA"
            />
            <Path
                d="M87.933 22.013c12.219 17.754 10.765 42.621-6.989 54.84-17.754 12.218-51.761 8.614-63.98-9.14C4.746 49.959 12.716 25.556 30.47 13.338 48.224 1.12 75.715 4.259 87.933 22.013"
                stroke="#EAD5FF"
                strokeWidth={5}
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.506 48.698c9.745 5.682 19.598 12.186 28.63 16.83 2.7 1.39 6.36-1.224 6.574-4.416.65-10.722.65-21.474 0-32.197-.215-3.191-3.874-5.805-6.574-4.416-9.032 4.644-18.885 11.148-28.63 16.83-2.932 1.72-2.932 5.65 0 7.37"
                fill="#fff"
            />
        </Svg>
    );
};

export const NavRightBtnIcon = (props) => {
    return (
        <Svg
            width={107}
            height={100}
            viewBox="0 0 107 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.059 22.013C6.84 39.766 8.294 64.633 26.049 76.852c17.753 12.218 51.76 8.614 63.979-9.14 12.218-17.754 4.248-42.156-13.505-54.374C58.769 1.12 31.277 4.259 19.059 22.013"
                fill="#5F0FAA"
            />
            <Path
                d="M19.059 22.013C6.84 39.766 8.294 64.633 26.049 76.852c17.753 12.218 51.76 8.614 63.979-9.14 12.218-17.754 4.248-42.156-13.505-54.374C58.769 1.12 31.277 4.259 19.059 22.013"
                stroke="#EAD5FF"
                strokeWidth={5}
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M73.487 48.698c-9.746 5.682-19.6 12.186-28.63 16.83-2.7 1.39-6.36-1.225-6.575-4.416a266.334 266.334 0 010-32.197c.215-3.191 3.875-5.805 6.574-4.416 9.032 4.644 18.885 11.148 28.63 16.83 2.932 1.719 2.932 5.65 0 7.369"
                fill="#fff"
            />
        </Svg>
    );
};

export const FilterBtnIcon = ({ isSelected = false, ...rest }) => {
    return isSelected ? (
        <Svg
            width={107}
            height={100}
            viewBox="0 0 107 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.669 22.013c-12.218 17.753-10.765 42.62 6.99 54.839 17.753 12.218 51.76 8.614 63.98-9.14 12.217-17.754 4.248-42.156-13.506-54.374C58.379 1.12 30.887 4.259 18.669 22.013"
                fill="#FFDE29"
            />
            <Path
                d="M18.669 22.013c-12.218 17.753-10.765 42.62 6.99 54.839 17.753 12.218 51.76 8.614 63.98-9.14 12.217-17.754 4.248-42.156-13.506-54.374C58.379 1.12 30.887 4.259 18.669 22.013"
                stroke="#5F0FAA"
                strokeWidth={5}
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M72.031 25.803a230.53 230.53 0 00-37.85 0c-1.404.117-2.724 1.344-2.915 2.723-.193 1.42-.366 2.843-.52 4.266-.044.407.124.972.377 1.264 5.057 5.869 10.9 12.275 16.783 18.744.307.338.477.769.48 1.214.035 6.707.158 13.405.368 20.054.014.421.316.619.677.44 2.417-1.198 4.902-2.442 7.443-3.716.38-.19.699-.688.71-1.11.134-5.205.215-10.434.243-15.668.003-.445.172-.876.479-1.214 5.883-6.468 11.726-12.875 16.783-18.744.253-.292.42-.857.377-1.264a157.735 157.735 0 00-.52-4.266c-.19-1.38-1.51-2.605-2.915-2.723"
                fill="#5F0FAA"
            />
        </Svg>
    ) : (
        <Svg
            width={107}
            height={100}
            viewBox="0 0 107 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.067 22.013C6.848 39.766 8.302 64.633 26.056 76.852c17.754 12.218 51.761 8.614 63.98-9.14 12.218-17.754 4.248-42.156-13.506-54.374C58.776 1.12 31.285 4.259 19.067 22.013"
                fill="#5F0FAA"
            />
            <Path
                d="M19.067 22.013C6.848 39.766 8.302 64.633 26.056 76.852c17.754 12.218 51.761 8.614 63.98-9.14 12.218-17.754 4.248-42.156-13.506-54.374C58.776 1.12 31.285 4.259 19.067 22.013"
                stroke="#EAD5FF"
                strokeWidth={5}
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M72.428 25.803a230.53 230.53 0 00-37.85 0c-1.404.117-2.724 1.344-2.915 2.723-.192 1.42-.366 2.843-.52 4.266-.044.407.125.972.377 1.264 5.057 5.869 10.9 12.275 16.784 18.744.306.338.476.769.479 1.214.036 6.707.159 13.405.368 20.054.014.421.316.619.678.44a1841.31 1841.31 0 007.443-3.716c.38-.19.698-.688.71-1.11.133-5.205.215-10.434.243-15.668.002-.445.172-.876.478-1.214 5.884-6.468 11.727-12.875 16.784-18.744.252-.292.42-.857.376-1.264a158.541 158.541 0 00-.52-4.266c-.19-1.38-1.51-2.605-2.915-2.723"
                fill="#fff"
            />
        </Svg>
    );
};

export const PenBtnIcon = ({ isSelected = false, ...rest }) => {
    return isSelected ? (
        <Svg
            width={107}
            height={100}
            viewBox="0 0 107 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.067 22.013c-12.218 17.753-10.765 42.62 6.99 54.839 17.753 12.218 51.76 8.614 63.98-9.14 12.217-17.754 4.248-42.156-13.506-54.374C58.777 1.12 31.285 4.259 19.067 22.013"
                fill="#FFDE29"
            />
            <Path
                d="M19.067 22.013c-12.218 17.753-10.765 42.62 6.99 54.839 17.753 12.218 51.76 8.614 63.98-9.14 12.217-17.754 4.248-42.156-13.506-54.374C58.777 1.12 31.285 4.259 19.067 22.013"
                stroke="#5F0FAA"
                strokeWidth={5}
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M69.035 44.514c-5.87-11.616-7.076-20.134-7.069-20.184a36.852 36.852 0 01-.02-.147 261.13 261.13 0 00-16.884 0l-.019.147c.007.05-1.197 8.563-7.069 20.184l-.047.093.072.078c10.415 11.366 13.23 17.25 13.672 27.732l.007.156c.423.004.846.007 1.269.008a9375.05 9375.05 0 01-.04-25.45c-1.823-.276-3.222-1.777-3.22-3.59.003-2.005 1.715-3.64 3.818-3.64 2.102 0 3.814 1.634 3.817 3.64.002 1.813-1.396 3.314-3.22 3.59-.001 8.49-.015 16.979-.04 25.45.423-.001.846-.004 1.27-.008l.007-.156c.441-10.481 3.256-16.366 13.671-27.732l.072-.078-.047-.093M62.559 17.654a198.55 198.55 0 00-18.109 0 574.983 574.983 0 00-.209 4.743 240.039 240.039 0 0118.526 0 570.81 570.81 0 00-.208-4.743"
                fill="#5F0FAA"
            />
        </Svg>
    ) : (
        <Svg
            width={107}
            height={100}
            viewBox="0 0 107 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.067 22.013c-12.218 17.753-10.765 42.62 6.99 54.839 17.753 12.218 51.76 8.614 63.98-9.14 12.217-17.754 4.248-42.156-13.506-54.374C58.777 1.12 31.285 4.259 19.067 22.013"
                fill="#5F0FAA"
            />
            <Path
                d="M19.067 22.013c-12.218 17.753-10.765 42.62 6.99 54.839 17.753 12.218 51.76 8.614 63.98-9.14 12.217-17.754 4.248-42.156-13.506-54.374C58.777 1.12 31.285 4.259 19.067 22.013"
                stroke="#EAD5FF"
                strokeWidth={5}
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M69.035 44.514c-5.87-11.616-7.076-20.134-7.069-20.184a36.852 36.852 0 01-.02-.147 261.13 261.13 0 00-16.884 0l-.019.147c.007.05-1.197 8.563-7.069 20.184l-.047.093.072.078c10.415 11.366 13.23 17.25 13.672 27.732l.007.156c.423.004.846.007 1.269.008a9375.05 9375.05 0 01-.04-25.45c-1.823-.276-3.222-1.777-3.22-3.59.003-2.005 1.715-3.64 3.818-3.64 2.102 0 3.814 1.634 3.817 3.64.002 1.813-1.396 3.314-3.22 3.59-.001 8.49-.015 16.979-.04 25.45.423-.001.846-.004 1.27-.008l.007-.156c.441-10.481 3.256-16.366 13.671-27.732l.072-.078-.047-.093M62.559 17.654a198.55 198.55 0 00-18.109 0 574.983 574.983 0 00-.209 4.743 240.039 240.039 0 0118.526 0 570.81 570.81 0 00-.208-4.743"
                fill="#fff"
            />
        </Svg>
    );
};
