import { MainLayout, Button } from "../../../components";
import { View, ImageBackground, Text, Image } from "react-native";
import { useStylesUtils, useTestsHook } from "../../../hooks";
import { useState, useMemo } from "react";
import { COLOURS, images } from "../../../constants";
import { useLocation } from "react-router-native";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../store/auth/selectors";
import { CardsImage } from "../../../components/carrouselCards/components/CardsImage";

export const FinishTestScreen = () => {
    const userInfo = useSelector(getUserInfo);
    const { width, styles, biggerSize, height, isWeb } =
        useStylesUtils(getStyles);

    const [bgImage, bgImageState] = useState({ width: 0, height: 0 });

    const {
        state: { testDetails, testResult, showResultButton = false },
    } = useLocation();

    const { getStudentTestReview } = useTestsHook();

    const getRankEnding = (rk) => {
        let j = rk % 10,
            k = rk % 100;
        if (j === 1 && k !== 11) {
            return "st";
        }
        if (j === 2 && k !== 12) {
            return "nd";
        }
        if (j === 3 && k !== 13) {
            return "rd";
        }
        return "th";
    };

    const rank = useMemo(() => {
        const rk = testResult?.data?.rank || 0;
        return {
            available: rk > 0,
            content: getRankEnding(rk),
        };
    }, [testResult?.data?.rank]);

    const gradeIcon = testResult?.data?.gradeDetails?.gradeIcon;

    const runPreview = () => {
        getStudentTestReview({ studentTestId: testDetails.studentTestId });
    };

    const RankButton = ({ style }) => {
        return (
            <View
                style={{
                    flex: 1,
                    width: "100%",
                    zIndex: 2,
                    flexDirection: "row",
                    alignItems: "center",
                    paddingHorizontal: "5%",
                }}>
                <View
                    style={{
                        flex: 3,
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    <Image
                        resizeMode="contain"
                        source={images.quizRankStar}
                        style={{
                            flex: 1,
                            maxHeight: "80%",
                            aspectRatio: 1,
                            height: isWeb ? height * 0.07 : "100%",
                        }}
                    />
                    <View
                        style={{
                            flexDirection: "row",
                            alignItems: "flex-start",
                        }}>
                        <Text style={styles.textRank}>
                            {testResult?.data?.rank}
                        </Text>
                        <Text style={styles.textRank2}>{rank.content}</Text>
                    </View>
                </View>

                <Text style={[style, { flex: 3, textAlign: "center" }]}>
                    Rank
                </Text>
            </View>
        );
    };
    const ScoreButton = ({ style }) => {
        return (
            <View
                style={{
                    flex: 1,
                    width: "100%",
                    zIndex: 2,
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                <View
                    style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    <Image
                        resizeMode="cover"
                        source={images.quizRankScoreBg}
                        style={{
                            maxHeight: "90%",
                            aspectRatio: 1,
                            height: isWeb ? height * 0.07 : "100%",
                        }}
                    />
                    <View
                        style={{
                            position: "absolute",
                            transform: [{ rotate: "-20deg" }],
                        }}>
                        <Text style={styles.textScore}>
                            {testResult?.data?.score}
                        </Text>
                        <Text style={styles.textScore}>
                            {testResult?.data?.totalMarks}
                        </Text>
                    </View>
                </View>

                <Text style={[style, { flex: 1 }]}>Score</Text>
            </View>
        );
    };

    return (
        <MainLayout
            headerProps={{
                homeButton: true,
                backButton: true,
                showBgCloud: false,
            }}>
            <View style={styles.container}>
                <ImageBackground
                    source={require("../../../assets/images/test/board.png")}
                    resizeMode={"contain"}
                    onLayout={({ nativeEvent }) =>
                        bgImageState(nativeEvent.layout)
                    }
                    style={styles.bg}>
                    {showResultButton && (
                        <View
                            style={{
                                width: bgImage.width + bgImage.width * 0.2,
                                alignItems: "flex-end",
                            }}>
                            <Button
                                label={() => (
                                    <View style={styles.previewBtn}>
                                        <Text style={styles.previewLable}>
                                            {"View\nAnswers"}
                                        </Text>
                                    </View>
                                )}
                                type={"orange"}
                                textStyle={{ color: COLOURS.black }}
                                onPress={runPreview}
                            />
                        </View>
                    )}
                    <View style={styles.starContainer}>
                        {gradeIcon && (
                            <Image
                                source={require("../../../assets/images/test/star.png")}
                                style={{
                                    width: bgImage.width * 0.25,
                                    height: bgImage.width * 0.25,
                                }}
                                resizeMode={"contain"}
                            />
                        )}

                        {gradeIcon && (
                            <CardsImage
                                source={{
                                    uri: gradeIcon,
                                    headers: {
                                        Accept: "*/*",
                                    },
                                }}
                                style={{
                                    width: bgImage.width * 0.13,
                                    height: bgImage.width * 0.13,
                                    position: "absolute",
                                    left: bgImage.width * 0.06,
                                }}
                                resizeMode={"contain"}
                            />
                        )}
                    </View>
                    <View
                        style={{
                            width: "100%",
                            flexDirection: "row",
                            justifyContent: "center",
                        }}>
                        <Button
                            style={{ opacity: rank.available ? 1 : 0 }}
                            label={RankButton}
                            type={"yellow"}
                            textStyle={{
                                fontSize: 18,
                                color: COLOURS.black,
                            }}
                            onPress={() => {}}
                        />
                        <Button
                            label={ScoreButton}
                            type={"yellow"}
                            textStyle={{
                                fontSize: 18,
                                color: COLOURS.black,
                            }}
                            onPress={() => {}}
                        />
                    </View>
                    <Text style={styles.text}>
                        {testResult?.data?.gradeDetails?.displayMessage || ""}
                    </Text>
                </ImageBackground>
            </View>

            <ImageBackground
                source={require("../../../assets/images/test/img.png")}
                style={{
                    width: biggerSize * 0.28,
                    height: biggerSize * 0.28,
                    position: "absolute",
                    bottom: -biggerSize * 0.02,
                    left: -biggerSize * 0.02,
                    zIndex: 1,
                }}
                resizeMode={"contain"}>
                <Text
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    style={{
                        position: "absolute",
                        width: biggerSize * 0.28 * 0.39,
                        top: biggerSize * 0.28 * 0.05,
                        right: biggerSize * 0.28 * 0.12,
                        textAlign: "center",
                        ...styles.nameLable,
                    }}>
                    {`Hey ${userInfo?.firstName || ""}`}
                </Text>
            </ImageBackground>
        </MainLayout>
    );
};

const getStyles = ({ width, height, isWeb }) => ({
    container: {
        width: "65%",
        height: "100%",
        maxWidth: width,
        marginTop: "-10%",
    },
    bg: {
        flex: 1,
        width: "100%",
        maxHeight: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    starContainer: {
        justifyContent: "center",
        alignItems: "center",
    },
    text: {
        marginTop: width * 0.01,
        fontSize: 30,
        fontWeight: 700,
    },
    textScore: {
        fontSize: 16,
        fontWeight: "700",
        textAlign: "center",
    },
    textRank: {
        fontSize: 20,
        fontWeight: "700",
        textAlign: "center",
    },
    textRank2: {
        fontSize: 12,
        lineHeight: 4,
    },
    nameLable: {
        fontWeight: 700,
        fontSize: 16,
        color: "white",
    },
    previewBtn: {
        zIndex: 2,
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        textAlign: "center",
        paddingTop: 0,
    },
    previewLable: {
        width: "100%",
        color: "white",
        fontWeight: 700,
        fontSize: 25,
        textAlign: "center",
    },
});
