import { useMemo } from "react";
import { COLOURS } from "../../constants";
import { changeTestFilterStatus } from "../../store/tests/slice";
import { useDispatch, useSelector } from "react-redux";
import { getUserTestFilterData } from "../../store/tests/selectors";

export const testTabs = () => {
    const dispatch = useDispatch();
    const { status } = useSelector(getUserTestFilterData);

    return useMemo(() => {
        return [
            {
                id: 1,
                onPress: () => dispatch(changeTestFilterStatus("SCHEDULED")),
                selected: status === "SCHEDULED",
                label: "Scheduled",
                textStyle: {
                    fontSize: 30,
                    color:
                        status === "SCHEDULED" ? COLOURS.white : COLOURS.brown5,
                },
                fillColor: COLOURS.brown3,
                strokeColor: COLOURS.brown4,
                selectedColors: {
                    stroke: COLOURS.white,
                    fill: COLOURS.brown4,
                },
            },
            {
                id: 2,
                onPress: () => dispatch(changeTestFilterStatus("PAST")),
                selected: status === "PAST",
                label: "Past",
                textStyle: {
                    fontSize: 30,
                    color: status === "PAST" ? COLOURS.white : COLOURS.brown5,
                },
                fillColor: COLOURS.brown3,
                strokeColor: COLOURS.brown4,
                selectedColors: {
                    stroke: COLOURS.white,
                    fill: COLOURS.brown4,
                },
            },
        ];
    }, [status]);
};
