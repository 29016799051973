import React, { useMemo } from "react";
import { TouchableOpacity, View, Text, Image } from "react-native";
import { TestPlaceholderIcon } from "../../assets/svg";
import { useStylesUtils } from "../../hooks";
import { CardsFooterBar } from "./components/CardsFotterBar";
import { CardsImage } from "./components/CardsImage";
import { CardsStatus } from "./components/CardsStatus";
import { COLOURS, getTestStatusTab, testsStatuses } from "../../constants";
import { timeLineDateUtil } from "../../utils/date.utils";
import {
    getTestTiming,
    getCurrentFooterButtonName,
} from "../../utils/tests.utils";
import { fillColorUtils } from "../../utils/fillColor.utils";
import { CardsGrade } from "./components/CardsGrade";

export const TestCard = (props) => {
    const {
        title = "cardTitle",
        image,
        onPress,
        colour,
        isActive,
        status = null,
        icon = null,
        testSubmissionCount = null,
        testDuration = null,
        category = null,
        gradeDetails = null,
        testStartTimeInMs,
        testEndTimeInMs,
        testCompleted,
        scheduleDetails,
        syllabustest,
        subjects,
        fill,
    } = props;

    const baseSize = { width: 449, height: 600 };
    const { isPast, isScheduled } = getTestStatusTab({ status });

    const { width, height, isPad, biggerSize } = useStylesUtils();

    const cardSize = useMemo(() => {
        let cardWidth = height * 0.3;

        if (isPad) {
            cardWidth = biggerSize * 0.3;
        } else if (width > height) {
            cardWidth = width * 0.18;
        }

        return {
            width: cardWidth,
            height: cardWidth * (baseSize.height / baseSize.width),
        };
    }, [width, biggerSize]);

    const { styles } = useStylesUtils(getStyles(cardSize));

    const footerButtonTitle = getCurrentFooterButtonName(testCompleted, status);

    return (
        <TouchableOpacity style={styles.container} onPress={onPress}>
            <TestPlaceholderIcon
                width={cardSize.width}
                height={cardSize.height}
                fill={fillColorUtils(isActive, colour, fill)}
                style={{
                    zIndex: -1,
                }}
            />
            <View style={styles.contentWrapper}>
                <View style={styles.category}>
                    <Text
                        style={styles.categoryText}
                        numberOfLines={1}
                        ellipsizeMode="tail">
                        {subjects[0]?.subjectName}
                    </Text>
                </View>
                {image && (
                    <View
                        style={[
                            styles.imageWrapper,
                            styles.rowTwoLine,
                            {
                                marginBottom: title ? 0 : "auto",
                            },
                        ]}>
                        <CardsImage source={image} style={styles.cardImage} />
                    </View>
                )}

                {title && (
                    <View style={[styles.row, styles.rowTwoLine]}>
                        <Text
                            numberOfLines={2}
                            ellipsizeMode="tail"
                            style={[
                                styles.textType3,
                                isPad
                                    ? {
                                          lineHeight: cardSize.height * 0.08,
                                      }
                                    : {},
                            ]}>
                            {title}
                        </Text>
                    </View>
                )}

                {testStartTimeInMs && testEndTimeInMs && (
                    <View style={styles.row}>
                        <Image
                            source={require("../../assets/images/test/calendar.png")}
                            style={{
                                width: cardSize.width * 0.05,
                                height: cardSize.width * 0.05,
                            }}
                        />
                        <Text
                            style={styles.textType2}
                            numberOfLines={1}
                            ellipsizeMode="tail">
                            {timeLineDateUtil(
                                testStartTimeInMs,
                                testEndTimeInMs
                            )}
                        </Text>
                    </View>
                )}

                {testDuration && isScheduled && (
                    <View style={styles.row}>
                        <Image
                            source={require("../../assets/images/test/clock.png")}
                            style={{
                                width: cardSize.width * 0.07,
                                height: cardSize.width * 0.07,
                            }}
                        />
                        <Text
                            style={styles.textType2}
                            numberOfLines={1}
                            ellipsizeMode="tail">
                            {getTestTiming(
                                testDuration,
                                testStartTimeInMs,
                                testEndTimeInMs
                            )}
                        </Text>
                    </View>
                )}

                {!!isPast && !gradeDetails && testSubmissionCount !== null && (
                    <View style={styles.row}>
                        <Image
                            source={require("../../assets/images/test/people.png")}
                            style={{
                                width: cardSize.width * 0.05,
                                height: cardSize.width * 0.05,
                            }}
                        />
                        <Text
                            style={styles.textType2}
                            numberOfLines={1}
                            ellipsizeMode="tail">
                            {testSubmissionCount}
                        </Text>
                    </View>
                )}

                <CardsGrade
                    grade={gradeDetails}
                    style={styles.rank}
                    imageStyle={{
                        width: cardSize.width * 0.22,
                        height: cardSize.width * 0.22,
                    }}
                />
            </View>
            <CardsStatus
                status={status}
                style={styles.statusImage}
                statusList={testsStatuses}
            />
            <CardsFooterBar
                category={footerButtonTitle}
                icon={icon}
                style={{ bottom: 0 }}
                wrapperStyle={{
                    paddingVertical: cardSize.width * 0.025,
                    paddingHorizontal: cardSize.width * 0.06,
                    backgroundColor: COLOURS.purple12,
                }}
                textStyle={{
                    color: COLOURS.white,
                    fontSize: 14,
                }}
            />
        </TouchableOpacity>
    );
};

const getStyles =
    (cardSize) =>
    ({ biggerSize, isPad, isWeb }) => ({
        container: {
            width: cardSize.width,
            height: cardSize.height,
            marginRight: cardSize.width * 0.1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        imageWrapper: {
            maxWidth: "100%",
            height: "100%",
            maxHeight: cardSize.width * 0.23,
        },
        cardImage: {
            alignItems: "center",
            justifyContent: "center",
            maxHeight: "100%",
            maxWidth: "100%",
        },
        contentWrapper: {
            position: "absolute",
            top: cardSize.height * 0.14,
            width: cardSize.width * 0.7,
            height: "100%",
            maxHeight: cardSize.height * 0.691,
            alignItems: "center",
            // paddingBottom: cardSize.height * 0.06,
        },
        rank: {
            height: cardSize.height * 0.16,
        },
        category: {
            width: "100%",
            height: cardSize.height * 0.06,
            maxWidth: cardSize.width * 0.48,
            marginBottom: cardSize.height * 0.024,
            justifyContent: "center",
        },
        categoryText: {
            color: COLOURS.brown8,
            fontSize: 12,
            textAlign: "center",
            fontWeight: 700,
        },
        statusImage: {
            position: "absolute",
            zIndex: 100,
            right: 0,
            top: cardSize.height * 0.15,
            width: cardSize.width * 0.2,
            height: cardSize.width * 0.2,

            ...(isWeb
                ? {}
                : {
                      shadowOffset: {
                          width: 0,
                          height: 2,
                      },
                      shadowOpacity: 0.23,
                      shadowRadius: 2.62,
                      elevation: 4,
                  }),
            shadowColor: COLOURS.black,
        },
        row: {
            width: "100%",
            height: cardSize.height * 0.09,
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
            paddingLeft: cardSize.width * 0.03,
        },
        rowTwoLine: {
            paddingLeft: 0,
            height: cardSize.height * 0.18,
            marginBottom: "auto",
            alignItems: "flex-start",
        },
        textType2: {
            marginLeft: cardSize.width * 0.01,
            fontSize: 8,
            fontWeight: 700,
            color: COLOURS.brown6,
        },
        textType3: {
            width: "100%",
            fontSize: 15,
            lineHeight: 6,
            fontWeight: 700,
            color: COLOURS.brown6,
            textAlign: "center",
            textAlignVertical: "center",
        },
    });
