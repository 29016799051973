import React from "react";
import { Platform, View } from "react-native";
import { COLOURS } from "../../../constants";
import { useStylesUtils } from "../../../hooks";
import { Button } from "../../Button";

const { OS } = Platform;

export const RenderButtons = ({ cancelButton, confirmButton, close }) => {
    const { styles } = useStylesUtils(getStyles);

    const press = async (e, button) => {
        e.preventDefault();

        if (typeof button === "function") {
            await button({ close: () => close() });
        } else {
            close();
        }
    };

    const RenderButton = ({ btnProps, style, ...rest }) => {
        if (!btnProps) {
            return <></>;
        } else if (typeof btnProps === "object") {
            rest = {
                ...rest,
                ...btnProps,
                onPress: (e) => press(e, btnProps.onPress),
                style: [style || {}, btnProps.style || {}],
            };
        }

        return <Button {...rest} />;
    };

    return (
        <View
            style={[styles.container, OS === "android" ? { height: 36 } : {}]}>
            <RenderButton
                btnProps={cancelButton}
                onPress={(e) => press(e, cancelButton)}
                label={
                    typeof cancelButton === "string" ? cancelButton : "Cancel"
                }
                iconProps={{ fill: COLOURS.purple2 }}
                textStyle={{ color: COLOURS.pastel1 }}
            />
            <RenderButton
                btnProps={confirmButton}
                onPress={(e) => press(e, confirmButton)}
                label={typeof confirmButton === "string" ? confirmButton : "OK"}
                style={{
                    marginLeft: !!cancelButton ? 20 : 0,
                }}
            />
        </View>
    );
};

const getStyles = () => ({
    container: {
        flexDirection: "row",
        justifyContent: "center",
    },
});
