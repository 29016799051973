import React from "react";
import { Image, Text } from "react-native";
import { useLocation } from "react-router-native";
import { CarrouselComponent, MainLayout } from "../../components";
import { letsCreateIcons, letsCreateThumbnails } from "../../constants";
import { useCreateHook, useStylesUtils } from "../../hooks";
import { TitleContent } from "./Diary/TitleContent";

export const TracingBoardScreen = () => {
    const { styles } = useStylesUtils(getStyles);
    const { getStory } = useCreateHook();
    const { state } = useLocation();

    const getStoryName = (storyId) => {
        const name = state.find((story) => story?.storyId === storyId);
        return name?.title;
    };

    const getImage = (storyId) => {
        switch (storyId) {
            case 4334:
                return letsCreateThumbnails[4334];
            case 4392:
                return letsCreateThumbnails[4392];
            case 4393:
                return letsCreateThumbnails[4393];
            case 4394:
                return letsCreateThumbnails[4394];
            default:
                return null;
        }
    };

    const onPressStories = (id) => {
        getStory({ params: { storyId: id } });
    };

    const tracingList = [
        {
            id: 4334,
            title: getStoryName(4334),
            image: getImage(4334),
            titleContent: <TitleContent />,
            onPress: () => onPressStories(4334),
        },
        {
            id: 4392,
            title: getStoryName(4392),
            image: getImage(4392),
            titleContent: <TitleContent />,
            onPress: () => onPressStories(4392),
        },
        {
            id: 4393,
            title: getStoryName(4393),
            image: getImage(4393),
            titleContent: <TitleContent />,
            onPress: () => onPressStories(4393),
        },
        {
            id: 4394,
            title: getStoryName(4394),
            image: getImage(4394),
            titleContent: <TitleContent />,
            onPress: () => onPressStories(4394),
        },
    ];

    return (
        <MainLayout
            headerProps={{
                backButton: true,
                homeButton: true,
                showBgCloud: true,
                titleContent: (
                    <>
                        <Image
                            style={styles.image}
                            source={letsCreateIcons.TracingImage}
                            resizeMode="contain"
                        />
                        <Text style={styles.text}>{"Tracing \nBoard"}</Text>
                    </>
                ),
            }}>
            <CarrouselComponent data={tracingList} type={"story"} />
        </MainLayout>
    );
};

const getStyles = ({ width, height, OS }) => ({
    image: {
        width: width * 0.2,
        height: height * 0.25,
    },
    text: {
        height: height * 0.3,
        paddingTop: height * 0.3 * 0.25,
        fontSize: 50,
        lineHeight: 14,
        textAlign: "center",
        fontWeight: 700,
        color: "#fff",
        textShadowColor: "#000",
        textShadowOffset: { height: 0, width: 0 },
        textShadowRadius: 3,
        verticalAlign: "middle",
    },
});
