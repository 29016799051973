import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={586}
            height={505}
            viewBox="0 0 586 505"
            fill="#425E3A"
            stroke="#425E3A"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fill="none"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M218.851 0.481445L274.544 56.1752L218.851 0.481445Z"
                stroke="rgba(255,255,255,0.3)"
                strokeWidth={5}
                strokeLinecap="round"
            />
            <Path
                fill="none"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M331.33 3.75781L278.913 56.1755L331.33 3.75781Z"
                stroke="rgba(255,255,255,0.3)"
                strokeWidth={5}
                strokeLinecap="round"
            />
            <Path
                stroke="none"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M274.545 92.2128C297.161 92.2128 315.496 78.2786 315.496 61.0898C315.496 43.901 297.161 29.9668 274.545 29.9668C251.928 29.9668 233.593 43.901 233.593 61.0898C233.593 78.2786 251.928 92.2128 274.545 92.2128Z"
            />
            <Path
                stroke="none"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M82.6289 70.2159C82.6289 70.2159 182.483 61.1602 281.865 61.1602C391.628 61.1602 501.952 70.2159 501.952 70.2159C545.283 74.5458 576.289 103.666 580.72 144.928C580.72 144.928 585.353 190.909 585.353 236.996C585.353 281.439 581.492 425.754 581.492 425.754C577.061 467.016 544.274 499.052 508.901 500.466C491.93 501.869 397.886 504.956 293.449 504.994C188.12 505.031 124.722 504.254 82.6289 500.466C40.5327 496.677 4.9663 467.443 3.85937 427.263C3.85937 427.263 -0.000976562 271.166 -0.000976562 221.903C-0.000976562 181.487 3.08838 145.682 3.08838 145.682C5.30322 102.797 39.8501 74.5458 82.6289 70.2159L82.6289 70.2159Z"
            />
            <Path
                fill="none"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M516.5 414.909H564.55H516.5Z"
                stroke="rgba(255,255,255,0.3)"
                strokeWidth={5}
                strokeLinecap="round"
            />
            <Path
                fill="none"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M516.5 394.16H564.55H516.5Z"
                stroke="rgba(255,255,255,0.3)"
                strokeWidth={5}
                strokeLinecap="round"
            />
            <Path
                fill="none"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M516.5 373.411H564.55H516.5Z"
                stroke="rgba(255,255,255,0.3)"
                strokeWidth={5}
                strokeLinecap="round"
            />
            <Path
                fill="none"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M516.5 352.663H564.55H516.5Z"
                stroke="rgba(255,255,255,0.3)"
                strokeWidth={5}
                strokeLinecap="round"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M539.75 312.804C551.209 312.804 560.498 303.514 560.498 292.055C560.498 280.596 551.209 271.307 539.75 271.307C528.29 271.307 519.001 280.596 519.001 292.055C519.001 303.514 528.29 312.804 539.75 312.804Z"
                stroke="none"
                fill="rgba(255,255,255,0.3)"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M530.626 278.951L556.835 305.16L530.626 278.951Z"
                stroke="rgba(255,255,255,0.2)"
                strokeWidth={10}
                strokeLinecap="round"
                fill="none"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M539.75 258.201C551.209 258.201 560.498 248.912 560.498 237.453C560.498 225.994 551.209 216.704 539.75 216.704C528.29 216.704 519.001 225.994 519.001 237.453C519.001 248.912 528.29 258.201 539.75 258.201Z"
                stroke="none"
                fill="rgba(255,255,255,0.3)"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M525.553 223.256L552.854 250.557L525.553 223.256Z"
                stroke="rgba(255,255,255,0.2)"
                strokeWidth={10}
                strokeLinecap="round"
                fill="none"
            />
        </Svg>
    );
}

export default SvgComponent;
