import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
    AbacusIcon,
    BooksIcon,
    DanceIcon,
    MusicIcon,
} from "../../../assets/svg";
import { CarrouselComponent, MainLayout } from "../../../components";
import { useLearnHook } from "../../../hooks";
import { getPlaylistsForUserRedux } from "../../../store/main/selectors";
import { CardView } from "./CardView";
import { TitleContent } from "./TitleContent";

export const LetsLearnScreen = () => {
    const { getVideoPlaylist, getSubjectsForExam } = useLearnHook();

    const playListData = useSelector(getPlaylistsForUserRedux);

    const list = [
        {
            id: 1,
            title: "Books",
            onPress: getSubjectsForExam,
            Icon: BooksIcon,
            needCheck: false,
        },
        {
            id: 2,
            title: "Music",
            onPress: getVideoPlaylist,
            Icon: MusicIcon,
            needCheck: true,
        },
        {
            id: 3,
            title: "Dance",
            onPress: getVideoPlaylist,
            Icon: DanceIcon,
            needCheck: true,
        },
        {
            id: 4,
            title: "Abacus",
            onPress: getVideoPlaylist,
            Icon: AbacusIcon,
            needCheck: true,
        },
        // {
        //     id: 5,
        //     title: "Stories",
        //     onPress: getVideoPlaylist,
        //     Icon: StoriesIcon,
        //     needCheck: true,
        // },
        // {
        //     id: 6,
        //     title: "English",
        //     onPress: getVideoPlaylist,
        //     Icon: EnglishIcon,
        //     needCheck: true,
        // },
        // {
        //     id: 7,
        //     title: "Videos",
        //     onPress: getVideoPlaylist,
        //     Icon: VideosIcon,
        //     needCheck: true,
        // },
        // {
        //     id: 8,
        //     title: "Activities",
        //     onPress: getVideoPlaylist,
        //     Icon: ActivitiesIcon,
        //     needCheck: true,
        // },
    ];

    const parseList = useMemo(() => {
        if (!playListData?.length) {
            return list;
        }
        const listReduce = (acc, value) => {
            if (!value.needCheck) {
                acc.push(value);
            } else {
                const find = playListData.find((item) =>
                    item.title
                        .toLocaleLowerCase()
                        .split(" ")
                        .includes(value.title.toLocaleLowerCase())
                );

                if (find) {
                    value.params = { playlistId: find.playlistId };
                    acc.push(value);
                }
            }

            return acc;
        };

        return list.reduce(listReduce, []);
    }, [playListData]);

    return (
        <MainLayout
            headerProps={{
                backButton: true,
                homeButton: true,
                titleContent: <TitleContent />,
            }}>
            <CarrouselComponent data={parseList} CardView={CardView} />
        </MainLayout>
    );
};
