import { projectType } from "../api/constants";

export const imageCacheKey = (id) => `${id}-thumb`;

export const loaderTime = 60; // sec
export const timeToCheckToken = 1000 * 60 * 24 * 2; // 2 days
export const srcRegExp = /src\s*=\s*"(.+?)"/;
const allowedGradeIDs = {
    ["Gateway"]: [1, 2, 3, 4, 5, 18, 19, 20],
    ["PreProd"]: [10, 11, 12, 16, 17, 18, 19, 20],
    ["Staging"]: [26, 31, 32, 33, 34],
    ["Prod"]: [10, 11, 12, 16, 17, 18, 19, 20],
};

export const currentAllowedGradeIDs = allowedGradeIDs["Gateway"];
export const newcurrentAllowedGradeIDs = allowedGradeIDs[projectType];
