import {
    AUTHInstance,
    OTPInstance,
    SELFLERNInstance,
    UAMInstance,
} from "./config";

export const authApi = {
    verifyUid: async ({ admission_number }) => {
        return await AUTHInstance.post("/v1/user/meta/verify-user", {
            admission_number,
        });
    },
    getUidByPhoneWithoutToken: async ({ isd, phone }) => {
        return await UAMInstance.post("/users/getUidByPhoneWithoutToken", {
            isd,
            phone,
        });
    },
    login: async (body) => {
        return await AUTHInstance.post("/v1/user/meta/login", body);
    },
    getUserDto: async () => {
        return await AUTHInstance.get("/v1/user/meta/dto");
    },
    loginWithOtp: async (body) => {
        return await AUTHInstance.post("/v1/user/meta/login-with-otp", body); // ready for test
    },
    generateOTP: async (body) => {
        return await OTPInstance.post("/generateOTP", body);
    },
    setNewPasscode: async (body) => {
        return await AUTHInstance.put("/v1/user/meta/update-password", body);
    },
    validateBearer: async () => {
        return await AUTHInstance.post("/v1/auth/verify-bearer");
    },
    logout: async () => {
        return await AUTHInstance.post("/v2/auth/logout"); // ready for test
    },
    getMapping: async (params = {}) => {
        return await SELFLERNInstance.get("/api/selflearn/getMappingV2", {
            params,
            headers: {
                security: "infinitylearn",
            },
        });
    },
};
