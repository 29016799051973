import { useRef, useState } from "react";
import { ScrollView, View } from "react-native";
import { Calendar } from "react-native-calendars";
import { MainLayout } from "../../../components";
import { COLOURS } from "../../../constants";
import { useStylesUtils } from "../../../hooks";
import { CalendarHeader } from "./components/CalendarHeader";
import { EventCard } from "./components/EventCard";

export const EventsScreen = () => {
    const calendarRef = useRef();
    const [selected, setSelected] = useState("");

    const { styles, height } = useStylesUtils(getStyles());

    return (
        <MainLayout
            headerProps={{
                backButton: true,
                homeButton: true,
                showBgCloud: true,
                // titleContent: <TitleContent />,
            }}>
            <View
                style={{
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                <View style={styles.container}>
                    <ScrollView
                        showsHorizontalScrollIndicator={true}
                        showsVertivalScrollIndicator={true}
                        contentContainerStyle={{
                            flexGrow: 1,
                            justifyContent: "center",
                        }}>
                        <Calendar
                            ref={calendarRef}
                            customHeader={(ref) => (
                                <CalendarHeader headerRef={ref} />
                            )}
                            onDayPress={(day) => {
                                setSelected(day.dateString);
                            }}
                            markedDates={{
                                [selected]: {
                                    selected: true,
                                    disableTouchEvent: true,
                                    selectedDotColor: "orange",
                                },
                            }}
                            style={styles.calendarStyles}
                            theme={{
                                weekVerticalMargin: height * 0.005,
                            }}
                        />
                    </ScrollView>
                    <ScrollView
                        showsHorizontalScrollIndicator={true}
                        showsVertivalScrollIndicator={true}
                        style={styles.eventsStyles}>
                        <EventCard />
                        <EventCard />
                        <EventCard />
                        <EventCard />
                    </ScrollView>
                </View>
            </View>
        </MainLayout>
    );
};

const getStyles =
    () =>
    ({ width, height }) => ({
        container: {
            width: width * 0.85,
            backgroundColor: "white",
            borderRadius: 25,
            justifyContent: "space-around",
            padding: height * 0.025,
            flexDirection: "row",
            overflow: "hidden",
            marginTop: -height * 0.07,
        },
        calendarStyles: {
            width: width * 0.4,
            borderColor: COLOURS.dark_purple,
            borderWidth: 4,
            borderRadius: 25,
            overflow: "hidden",
        },
        eventsStyles: {
            width: width * 0.4,
            paddingHorizontal: width * 0.015,
        },
    });
