import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStylesUtils } from "../../hooks";
import { getLoadingState } from "../../store/app/selectors";
import { stopLoading } from "../../store/app/slice";
import { Image } from "../Image";
import { loaderTime } from "../../constants/app-config";
import Animated from "react-native-reanimated";
import { OverLayout } from "./OverLayout";
import { ActivityIndicator } from "react-native";
import { COLOURS } from "../../constants";

export const LoaderLayout = () => {
    const { styles, width, height } = useStylesUtils(getStyles);
    const dispatch = useDispatch();

    const loader = useSelector(getLoadingState);
    const [timeoutId, setTimeoutID] = useState(null);

    useEffect(() => {
        if (loader) {
            clearTimeoutId(timeoutId);
            const id = setTimeout(() => {
                dispatch(stopLoading());
            }, loaderTime * 1000);
            setTimeoutID(id);
        } else {
            clearTimeoutId(timeoutId);
        }
    }, [loader]);

    const clearTimeoutId = (id) => {
        if (id) {
            clearTimeout(id);
            setTimeoutID(null);
            dispatch(stopLoading());
        }
    };

    return loader ? (
        <OverLayout style={styles.horizontal}>
            <Animated.View style={[styles.overlayStyle]}>
                {/*<Image*/}
                {/*    source={require("../../assets/gif/loader.gif")}*/}
                {/*    style={{*/}
                {/*        width,*/}
                {/*        height,*/}
                {/*    }}*/}
                {/*    resizeMode="cover"*/}
                {/*/>*/}

                <ActivityIndicator
                    size="large"
                    color={COLOURS.orange}
                    style={{ transform: [{ scale: 2.5 }] }}
                />
            </Animated.View>
        </OverLayout>
    ) : null;
};

const getStyles = ({ isIOS }) => ({
    container: {
        position: "absolute",
        backgroundColor: isIOS ? "red" : "rgba(255,255,255, 0.8)",
        flex: 1,
        justifyContent: "center",
        flexDirection: "row",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    horizontal: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
    },
});
