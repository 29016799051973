import { useMemo } from "react";
import { Text } from "react-native";
import { useLocation } from "react-router-native";
import { StoriesIcon } from "../../assets/svg";
import { CarrouselComponent, MainLayout } from "../../components";
import { COLOURS } from "../../constants";
import { useLearnHook, useStylesUtils } from "../../hooks";
import { parseListsUtils } from "../../utils/parse-lists.utils";

export const StoriesScreen = () => {
    const { styles, width, height } = useStylesUtils(getStyles);

    const { state } = useLocation();
    const { getStory } = useLearnHook();

    const parseList = useMemo(() => {
        return parseListsUtils(state.stories, (props) => ({
            key: props.id,
            onPress: () => getStory({ params: { storyId: props.id } }),
        }));
    }, [state]);

    return (
        <MainLayout
            headerProps={{
                backButton: true,
                homeButton: true,
                titleContent: (
                    <>
                        <StoriesIcon
                            height={height * 0.2}
                            width={width * 0.14}
                        />
                        <Text style={styles.titleText}>Stories</Text>
                    </>
                ),
            }}>
            <CarrouselComponent data={parseList} type="story" />
        </MainLayout>
    );
};

const getStyles = {
    titleText: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        fontSize: 40,
        textAlign: "center",
        marginTop: 20,
    },
};
