import React, { useContext } from "react";
import { Image, Text, View } from "react-native";
import { useStylesUtils } from "../../../../hooks";
import QuizContext from "../QuizContext";
import { QuizOptionInput } from "./components/QuizOptionInput";
import { MathJaxView } from "../../../../components";
import { parse } from "node-html-parser";
import { CustomScrollView } from "../../../../components/CustomScrollView";
import { COLOURS, srcRegExp } from "../../../../constants";

const TEMPLATE_TYPES = Object.freeze({
    IMAGE: "IMAGE",
    MATH: "MATH",
    INPUTS: "INPUTS",
    HTML: "HTML",
});

const parseQuestionText = (questionText) => {
    const imageTagRegexp = /<img/g;
    const mathTagRegexp = /<math/g;
    const inputPlaceholderRegexp = /\[\[\d+\]\]/g;

    const parsedDOM = parse(questionText);
    const listOfChildren = parsedDOM.childNodes;

    const parsedTemplate = [];
    for (let child of listOfChildren) {
        if (child?.innerHTML.match(imageTagRegexp)) {
            //handle img
            parsedTemplate.push({
                type: TEMPLATE_TYPES.IMAGE,
                data: child?.innerHTML,
            });
            continue;
        }
        if (child?.innerHTML.match(mathTagRegexp)) {
            //handle math
            parsedTemplate.push({
                type: TEMPLATE_TYPES.MATH,
                data: child?.innerHTML,
            });
            continue;
        }
        if (child?.innerHTML.match(inputPlaceholderRegexp)) {
            //handle input string
            parsedTemplate.push({
                type: TEMPLATE_TYPES.INPUTS,
                data: child?.innerHTML,
            });
            continue;
        }
        parsedTemplate.push({
            type: TEMPLATE_TYPES.HTML,
            data: child?.innerHTML,
        });
    }

    return parsedTemplate;
};

export const QuizFillCard = () => {
    const { currentQuestionData, currentQuestion, isFinished } =
        useContext(QuizContext);

    const { styles, width } = useStylesUtils(getStyles(isFinished));

    const prepareImageRender = (markup) => {
        const imgSrc = markup.match(srcRegExp);
        return (
            <Image
                source={{ uri: imgSrc[1] }}
                style={styles.image}
                resizeMode={"contain"}
            />
        );
    };

    const prepateInputRender = (markup) => {
        const htmlRegExp = /(&nbsp;|\u00A0)|<[^>]+>/g;
        const optionRegExp = /(\[\[.*?\]\])/g;

        const splitedTemplate = markup
            .replace(htmlRegExp, "")
            .split(optionRegExp);

        return splitedTemplate.map((tplElement, index) => {
            if (tplElement[0] === "[") {
                const optId = tplElement
                    .replaceAll("[", "")
                    .replaceAll("]", "");
                const option = currentQuestionData.listOfOptions.find(
                    (el) => el.id === `${currentQuestion.question_id}_${optId}`
                );

                const value = !option.value && isFinished ? "-" : option.value;
                return (
                    option && (
                        <QuizOptionInput
                            key={option.id + index}
                            value={value}
                            status={option.status}
                            isFinished={isFinished}
                            onChange={(value) =>
                                currentQuestionData.onOptionPick(
                                    option.id,
                                    value
                                )
                            }
                        />
                    )
                );
            }

            return (
                <Text key={tplElement} style={styles.text}>
                    {tplElement}
                </Text>
            );
        });
    };

    const renderQuestionNode = (questionNode, id, all) => {
        const isLast = id === all.length - 1;

        switch (questionNode.type) {
            case TEMPLATE_TYPES.MATH:
            case TEMPLATE_TYPES.HTML:
                return (
                    <MathJaxView
                        html={questionNode.data}
                        isTextCentered={true}
                        textStyles={{ fontSize: 20 }}
                    />
                );
            case TEMPLATE_TYPES.IMAGE:
                return prepareImageRender(questionNode.data);
            case TEMPLATE_TYPES.INPUTS:
                return (
                    <View
                        style={[
                            styles.inputRow,
                            { marginBottom: isLast ? 0 : width * 0.02 },
                        ]}
                        key={id}>
                        {prepateInputRender(questionNode.data)}
                    </View>
                );
            default:
                return <></>;
        }
    };

    return (
        <CustomScrollView showIndicator={true}>
            <View style={styles.cardContainer}>
                {parseQuestionText(currentQuestionData.questionText).map(
                    renderQuestionNode
                )}
            </View>
        </CustomScrollView>
    );
};

const getStyles =
    (isFinished) =>
    ({ width, biggerSize }) => ({
        cardContainer: {
            flex: 1,
        },
        inputRow: {
            flexDirection: "row",
            paddingTop: width * (isFinished ? 0.035 : 0.01),
            paddingBottom: width * 0.01,
            alignItems: "center",
            justifyContent: "center",
            marginHorizontal: "auto",
            borderRadius: 10,
            backgroundColor: COLOURS.white,
        },
        image: {
            marginVertical: width * 0.02,
            height: width * 0.25,
        },
        text: {
            fontSize: 16,
            fontWeight: 700,
        },
    });
