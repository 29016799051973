import React, { useContext } from "react";
import { QUIZ_QUESTION_TYPE } from "../../../../constants";
import { QuizOptionChoiceCard } from "./QuizOptionChoiceCard";
import { QuizFillCard } from "./QuizFillCard";
import { QuizMatchCard } from "./MatchCard/QuizMatchCard";
import QuizContext from "../QuizContext";

export const QuizCard = () => {
    const { currentQuestion } = useContext(QuizContext);
    switch (currentQuestion.questionTypeRemapped) {
        case QUIZ_QUESTION_TYPE.SINGLE_CORRECT:
        case QUIZ_QUESTION_TYPE.TRUE_FALSE:
        case QUIZ_QUESTION_TYPE.MULTI_CORRECT:
            return <QuizOptionChoiceCard />;
        case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_REGULAR:
        case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_NUMERIC:
        case QUIZ_QUESTION_TYPE.FILL_IN_THE_BLANKS_INTEGER:
            return <QuizFillCard />;
        case QUIZ_QUESTION_TYPE.MATCH_THE_OPTIONS:
            return <QuizMatchCard />;
        default:
            return <></>;
    }
};
