import { useMemo, useRef, useState } from "react";
import { Platform, ScrollView, View } from "react-native";
import { PanGestureHandler } from "react-native-gesture-handler";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RailsIcon, TrainIcon } from "../assets/svg";
import { useHaptic, useStylesUtils } from "../hooks";
import { getScrollProgress, getScreenColors } from "../store/app/selectors";
import { saveScrollProgress } from "../store/app/slice";
import { CardViewComponent } from "./carrouselCards";

const { OS } = Platform;

export const CarrouselComponent = ({
    data,
    CardView = CardViewComponent,
    type: layType = null,
    showTrain = true,
    containerStyle = {},
}) => {
    const dispatch = useDispatch();
    const insets = useSafeAreaInsets();
    const { pathname } = useLocation();
    const { styles, width } = useStylesUtils(getStyles(insets));
    const { hapticImpact } = useHaptic();
    const scrollRef = useRef();

    const scrollProgress = useSelector(getScrollProgress);
    const { fill } = useSelector(getScreenColors);

    const [indicatorPosition, setIndicatorPosition] = useState(0);
    const [contentWidth, setContentWidth] = useState(0);

    const list = useMemo(() => {
        return data.map(({ key, id, onPress, type, ...rest }) => (
            <CardView
                fill={fill}
                key={key ? key : id}
                {...rest}
                type={type ? type : layType ? layType : null}
                onPress={() => {
                    hapticImpact(1);
                    onPress?.(rest);
                }}
            />
        ));
    }, [data]);

    const scrollProgressCoordinates = useMemo(() => {
        const position = scrollProgress[`${pathname}`];
        return position || null;
    }, [pathname]);

    const getIndicatorPosition = (scrollEvent, type) => {
        const contentOffsetX = scrollEvent?.contentOffset?.x;
        dispatch(saveScrollProgress({ [`${pathname}`]: contentOffsetX }));
        const screenWidth = scrollEvent?.layoutMeasurement?.width;
        const indicatorWidth = (110 / screenWidth) * 100;
        const scrollPosPercent =
            ((contentOffsetX / (contentWidth - screenWidth)) *
                (100 - indicatorWidth)) /
            100;
        setIndicatorPosition(screenWidth * scrollPosPercent);
    };
    const handleGestureEvent = (event) => {
        const contentOffsetX = event.nativeEvent.translationX;
        const safeAreaBorders = insets.left + insets.right;
        const screenWidth = width - safeAreaBorders;
        const indicatorWidth = (70 / screenWidth) * 100;

        const scrollPosition =
            (contentOffsetX / (contentWidth - screenWidth)) *
            (100 - indicatorWidth);
        // scrollToPosition(carouselPosition + scrollPosition);
    };

    const scrollToPosition = (position) => {
        scrollRef?.current?.scrollTo({
            x: position * 10,
            y: 0,
            animated: true,
        });
    };

    const handleScroll = (e) => {
        const position = e?.wheelDeltaY;
        scrollToPosition(position);
    };

    // useEffect(() => {
    //     document.addEventListener("wheel", handleScroll, true);
    // }, []);

    return (
        <View style={[styles.container, containerStyle]}>
            <View>
                <ScrollView
                    ref={scrollRef}
                    contentOffset={{
                        x: scrollProgressCoordinates || 0,
                        y: 0,
                    }}
                    scrollEventThrottle={16}
                    onScroll={(e) =>
                        getIndicatorPosition(e.nativeEvent, "scroll")
                    }
                    onContentSizeChange={(contentWidth) =>
                        setContentWidth(contentWidth)
                    }
                    bounces={false}
                    showsHorizontalScrollIndicator={true}
                    showsVertivalScrollIndicator={true}
                    rollIndicator={false}
                    horizontal={true}
                    contentContainerStyle={styles.scrollView}
                    scrollEnabled={width * 0.95 < contentWidth}
                    on>
                    {list}
                </ScrollView>
            </View>
            {width * 0.95 < contentWidth && showTrain && (
                <PanGestureHandler
                    onGestureEvent={(e) => handleGestureEvent(e)}>
                    <View
                        style={styles.indicatorContainer}
                        pointerEvents="box-none">
                        <View
                            style={{
                                position: "absolute",
                                bottom: 6,
                                left:
                                    Number(indicatorPosition + insets?.left) ||
                                    0,
                                zIndex: 10,
                            }}>
                            <TrainIcon />
                        </View>
                        <RailsIcon width={width} />
                    </View>
                </PanGestureHandler>
            )}
        </View>
    );
};

const getStyles =
    (insets) =>
    ({ width, isMobile }) => ({
        container: {
            flex: 1,
            width: "100%",
            height: "100%",
            flexDirection: isMobile ? "row" : "",
            alignItems: isMobile ? "center" : "",
            justifyContent: "center",
        },
        scrollView: {
            alignItems: "center",
            paddingLeft: "1.5%",
            paddingBottom: 10,
            margin: "auto",
        },
        indicatorContainer: {
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: width * 0.0624,
            justifyContent: "flex-end",
            marginBottom: -insets?.bottom,
            left: Number(-insets?.left) || 0,
        },
    });
