import React from "react";
import { Linking, Text, View } from "react-native";
import { useDispatch } from "react-redux";
import { AdmissionNumberCard } from "../components/AdmissionNumberCard";
import { ClassActivityCheckAcknowledgingContent } from "../components/layout/Popup/content/ClassActivityCheckAcknowledgingContent";
import { HomeWorkFilterContent } from "../components/layout/Popup/content/HomeWorkFilterContent";
import { NoHomeworkToShowContent } from "../components/layout/Popup/content/NoHomeworkToShowContent";
import { TeamMembersList } from "../components/layout/Popup/TeamMembersList";
import { COLOURS, popupTypes } from "../constants";
import { openPopup, closePopup } from "../store/app/slice";
import { maskPhone } from "../utils/transformData";
import { useStylesUtils } from "./useStylesUtils";
import { resetFilter } from "../store/diary/slice";
import { HomeWorksDescription } from "../components/layout/Popup/content/HomeWorksDescription";
import { useNavigate } from "react-router-native";
import { StartTestContent } from "../components/layout/Popup/content/StartTestContent";

//////////////////////////////////////////////////////////////////////////////////////////
// openPopup(props)
// props: {
//      type: popupTypes
//      title: string
//      subTitle: string
//      content: string | Component
//      confirmButton: ({ close: () => void }) => void | string | boolean | undefined | ButtonComponentProps
//      cancelButton: ({ close: () => void }) => void | string | boolean | undefined | ButtonComponentProps
//      showCloseBtn: boolean
//      closeWithConfirm: boolean
//      styles: {
//          dialog: StyleSheet,
//          title: StyleSheet,
//          subTitle: StyleSheet,
//          content: StyleSheet,
//          contentScroll: StyleSheet,
//          buttons: StyleSheet,
//          closeBtn: StyleSheet,
//      },
// }
//////////////////////////////////////////////////////////////////////////////////////////

export const usePopups = () => {
    const { styles, width } = useStylesUtils(getStyles);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const testPopup = () => {
        dispatch(
            openPopup({
                type: popupTypes.IMPORTANT,
                title: "Oops! No admission number found",
                content:
                    "No admission number is linked with phone number +91-98XXXXXX21 \n" +
                    "Please try again with registered Mobile number",
                confirmButton: true,
                cancelButton: true,
            })
        );
    };

    const loginError = () => {
        dispatch(
            openPopup({
                type: popupTypes.ERROR,
                title: "Login Error",
                content: (
                    <View style={styles.loginErrorContentView}>
                        <Text style={styles.loginErrorContentText}>
                            "Entered admission number is either INACTIVE or you
                            have NOT paid the digital fee. For any query, please
                            contact our support at 1800-419-4247."
                        </Text>
                    </View>
                ),
                confirmButton: {
                    onPress: () => Linking.openURL(`tel:1800-419-4247`),
                    label: "Call Now",
                },
                cancelButton: true,
            })
        );
    };

    const enteredWrongPasscode = () => {
        dispatch(
            openPopup({
                type: popupTypes.ERROR,
                title: "Login Error",
                content:
                    "Entered admission number is either Inactive or you have not paid the digital fee",
                confirmButton: "Ok",
            })
        );
    };

    const loginCMSGradeError = (logoutCallback) => {
        dispatch(
            openPopup({
                type: popupTypes.ERROR,
                title: "Login Error",
                content:
                    "Only students belonging to Nursery to Grade 5th\n" +
                    "are allowed to login into this app.\n" +
                    "For any query, please contact our support at 1800-419-4247.",
                confirmButton: logoutCallback,
            })
        );
    };

    const getMainListError = ({ result }) => {
        const content =
            result?.payload?.message || result?.error?.message || "GET ERROR";

        dispatch(
            openPopup({
                type: popupTypes.ERROR,
                title: "Error",
                content,
                confirmButton: true,
            })
        );
    };

    const getListError = ({ title, content }) => {
        dispatch(
            openPopup({
                type: popupTypes.ERROR,
                title,
                content,
                confirmButton: true,
            })
        );
    };

    const paymentNotPaid = () => {
        dispatch(
            openPopup({
                type: popupTypes.ERROR,
                title: "Payment Error",
                confirmButton: true,
            })
        );
    };
    const openErrorModal = (message, btnLabel, onSubmit) => {
        dispatch(
            openPopup({
                type: popupTypes.ERROR,
                content: message,
                confirmButton: { onPress: onSubmit, label: btnLabel },
            })
        );
    };
    const tokenIsExpired = () => {
        dispatch(
            openPopup({
                type: popupTypes.ERROR,
                title: "Autologin Error",
                content: "Your token is expired, please relogin",
                confirmButton: true,
            })
        );
    };

    const selectYourAdmission = (list, onSelect) => {
        dispatch(
            openPopup({
                title: "Please select your admission number",
                content: list.map(({ id, uid, ...props }) => (
                    <AdmissionNumberCard
                        key={id + uid}
                        uid={uid}
                        {...props}
                        onSelect={onSelect}
                    />
                )),
                confirmButton: false,
                cancelButton: false,
            })
        );
    };

    const emptyAdmissionList = (phoneNumber) => {
        dispatch(
            openPopup({
                type: popupTypes.ERROR,
                title: "Oops! No admission number found",
                content: `No admission number is linked with phone number +91-${maskPhone(
                    phoneNumber
                )} Please try again with your registered mobile number`,
                confirmButton: false,
                cancelButton: "Try again",
            })
        );
    };

    const passcodeIsSetPopup = (onConfirm) => {
        dispatch(
            openPopup({
                type: popupTypes.SUCCESS,
                title: "Your new passcode is set",
                content: "Please log in to continue",
                confirmButton: { onPress: onConfirm, label: "Log in" },
                cancelButton: false,
            })
        );
    };

    const oopsPopUp = () => {
        dispatch(
            openPopup({
                title: "OOPS! Looks like something went wrong on our side. Try again later.",
                confirmButton: false,
                cancelButton: false,
            })
        );
    };

    const logoutPopup = (callback) => {
        dispatch(
            openPopup({
                type: popupTypes.IMPORTANT,
                title: "Are you sure,\n You want to logout?",
                confirmButton: { onPress: callback, label: "Logout" },
                cancelButton: true,
            })
        );
    };

    const homeWorkFilter = () => {
        dispatch(
            openPopup({
                title: "Please select the details",
                content: <HomeWorkFilterContent />,
                showCloseBtn: true,
            })
        );
    };

    const noHomeworkToShow = () => {
        dispatch(
            openPopup({
                type: popupTypes.IMPORTANT,
                title: "Oops! Looks like\n there is no homework to show",
                content: <NoHomeworkToShowContent />,
                cancelButton: {
                    label: "Change",
                    onPress: ({ close }) => {
                        homeWorkFilter();
                        close();
                    },
                },
                confirmButton: {
                    label: "Reset",
                    onPress: () => {
                        dispatch(resetFilter());
                        dispatch(closePopup());
                    },
                },
            })
        );
    };

    const completeHomeWork = (onPress, type = "VIDEO") => {
        const getType = () => {
            switch (type) {
                case "VIDEO":
                    return "video";
                case "STORY":
                    return "activity";
                case "DOCUMENT":
                    return "pdf";
            }
        };

        dispatch(
            openPopup({
                type: popupTypes.SUCCESS,
                title:
                    "Great work! You have successfully\n completed this " +
                    getType(),
                content: "Click on CLOSE button to go back.",
                confirmButton: { label: "Close", onPress: onPress },
            })
        );
    };

    const homeworkInfo = (assetDescription, onPress) => {
        dispatch(
            openPopup({
                type: popupTypes.INFO,
                content: (
                    <HomeWorksDescription
                        title={assetDescription}
                        onPress={onPress}
                    />
                ),
                confirmButton: { label: "OK", onPress: onPress },
            })
        );
    };
    const youAreYetToFinishHomework = () => {
        dispatch(
            openPopup({
                type: popupTypes.IMPORTANT,
                title: "Oops! You are yet to\n Finish the assigned task.",
                content:
                    "Please click CONTINUE to continue with task, \n or click BACK button to go back to Homework" +
                    " screen",
                confirmButton: {
                    label: "Continue",
                    onPress: () => navigate(-1),
                },
                cancelButton: "Back",
            })
        );
    };

    const teamMembers = () => {
        dispatch(
            openPopup({
                subTitle: "Your team members for this activity are:",
                content: <TeamMembersList />,
            })
        );
    };

    const classActivityCheckAcknowledging = () => {
        dispatch(
            openPopup({
                type: popupTypes.INFO,
                title: "Hi! You are acknowledging Homework and Class activity for 13th Mar 23",
                content: <ClassActivityCheckAcknowledgingContent />,
            })
        );
    };

    const classActivitySuccessAcknowledging = () => {
        dispatch(
            openPopup({
                type: popupTypes.INFO,
                title: "Great work! You have acknowledged Homework and class activity successfully.",
                content: (
                    <Text style={styles.standardText}>
                        Click on <Text style={styles.boldText}>CLOSE</Text>{" "}
                        button to go back.
                    </Text>
                ),
                confirmButton: "Close",
            })
        );
    };

    const timeTableInfo = ({ title, content }) => {
        dispatch(
            openPopup({
                showCloseBtn: true,
                title,
                content,
            })
        );
    };

    const testDetailsInfoBeforeStart = (
        contentProps,
        testIsReadyToBegin = false,
        confirmOnPress = () => {}
    ) => {
        const confirmButton = testIsReadyToBegin
            ? {
                  label: "Lets begin",
                  onPress: confirmOnPress,
                  iconProps: { ratio: 0.11 },
              }
            : null;

        dispatch(
            openPopup({
                showCloseBtn: true,
                content: <StartTestContent {...contentProps} />,
                confirmButton,
                styles: {
                    buttons: {
                        width: "auto",
                        position: "absolute",
                        bottom: width * 0.02,
                        right: width * 0.04,
                    },
                },
            })
        );
    };

    const testSubmit = (confirmOnPress = () => {}) => {
        dispatch(
            openPopup({
                showCloseBtn: true,
                title: "Are you sure you want to submit?",
                confirmButton: {
                    label: "Submit",
                    onPress: confirmOnPress,
                    iconProps: { fill: COLOURS.orange3 },
                },
                cancelButton: {
                    label: "Cancel",
                    iconProps: { fill: COLOURS.orange4 },
                    textStyle: { color: COLOURS.black },
                },
                styles: {
                    dialog: {
                        height: "100%",
                        justifyContent: "center",
                    },
                },
            })
        );
    };

    const testTimeIsOver = (confirmOnPress = () => {}) => {
        dispatch(
            openPopup({
                showCloseBtn: true,
                closeWithConfirm: true,
                title: "Sorry, Times Up!",
                confirmButton: {
                    label: "Ok",
                    onPress: confirmOnPress,
                    iconProps: { fill: COLOURS.orange3 },
                },
                styles: {
                    dialog: {
                        height: "100%",
                        justifyContent: "center",
                    },
                },
            })
        );
    };

    return {
        testPopup,
        loginError,
        loginCMSGradeError,
        getMainListError,
        paymentNotPaid,
        tokenIsExpired,
        selectYourAdmission,
        emptyAdmissionList,
        oopsPopUp,
        getListError,
        passcodeIsSetPopup,
        logoutPopup,
        homeWorkFilter,
        noHomeworkToShow,
        completeHomeWork,
        homeworkInfo,
        youAreYetToFinishHomework,
        teamMembers,
        classActivityCheckAcknowledging,
        classActivitySuccessAcknowledging,
        timeTableInfo,
        testDetailsInfoBeforeStart,
        testSubmit,
        testTimeIsOver,
        openErrorModal,
    };
};

const getStyles = ({ width }) => ({
    loginErrorContentView: {
        alignItems: "center",
    },
    loginErrorContentText: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: 600,
        color: COLOURS.dark_purple,
        maxWidth: width * 0.4,
    },
    standardText: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: 600,
        color: COLOURS.dark_purple,
    },
});
