import React from "react";
import { Text, View } from "react-native";

export const ColumnTitle = ({ styles, isSelected, title, subTitle }) => {
    return (
        <View
            style={[
                styles.tableTitle,
                isSelected ? styles.tableTitleSelected : {},
            ]}>
            <Text
                style={styles.tableTitleText}
                numberOfLines={1}
                ellipsizeMode="tail">
                {title}
            </Text>
            {subTitle && (
                <Text
                    style={styles.tableTitleText1}
                    numberOfLines={1}
                    ellipsizeMode="tail">
                    {subTitle}
                </Text>
            )}
        </View>
    );
};
