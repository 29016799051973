import React, { useCallback, useContext } from "react";
import { TouchableOpacity, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-native";
import {
    CloseIcon,
    LogoutIcon,
    SoundOffIcon,
    SoundOnIcon,
} from "../../assets/svg";
import { COLOURS, navigation } from "../../constants";
import { useHaptic, useStylesUtils } from "../../hooks";
import { useAuth } from "../../hooks/useAuth";
import { closeModal, toggleSoundStatus } from "../../store/app/slice";
import { getUserInfo } from "../../store/auth/selectors";
import { ProfileIcon } from "../ProfileIcon";
import GlobalAudioContext from "../../providers/GlobalAudioContext";
import { getSoundStatus } from "../../store/app/selectors";

export const ProfileModalContent = () => {
    const { playClickSound } = useContext(GlobalAudioContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { onLogOut } = useAuth();
    const { hapticImpact } = useHaptic();

    const userInfo = useSelector(getUserInfo);
    const appSoundStatus = useSelector(getSoundStatus);

    const { width, height, styles, imageSizes, isAndroid } =
        useStylesUtils(getStyles);

    const close = useCallback(() => {
        playClickSound();
        hapticImpact(1);
        dispatch(closeModal());
    }, []);

    const openLogoutPopup = () => {
        playClickSound();
        hapticImpact(1);
        onLogOut(true);
    };
    const onPressParentsCorner = () => {
        playClickSound();
        hapticImpact(1);
        navigate(navigation.PARENTS_CORNER_LOGIN);
    };
    const onPressProfile = () => {
        // navigate(navigation.PROFILE_SCREEN);
        {
            /*removed from current scope*/
        }
        // navigate(navigation.COMING_SOON_SCREEN);
    };
    const onPressSetting = () => {
        playClickSound();
        navigate(navigation.COMING_SOON_SCREEN);
    };

    const onPressSound = () => {
        playClickSound();
        if (appSoundStatus) {
            dispatch(toggleSoundStatus(false));
        } else {
            dispatch(toggleSoundStatus(true));
        }
    };

    return (
        <View
            style={{
                height,
                width,
                paddingHorizontal: isAndroid ? 40 : 20,
                paddingVertical: 20,
            }}>
            <TouchableOpacity onPress={onPressProfile} style={styles.button}>
                <ProfileIcon />
                <View>
                    <Text style={styles.buttonText}>
                        {userInfo?.firstName || ""}
                    </Text>
                    <Text
                        style={
                            styles.buttonText
                        }>{`Class: ${userInfo.grade.name}`}</Text>
                </View>
            </TouchableOpacity>
            {/*removed from current scope*/}
            {/*<TouchableOpacity onPress={onPressParentsCorner} style={styles.button}>*/}
            {/*    <ParentsIcon {...imageSizes.standard} />*/}
            {/*    <Text style={styles.buttonText}>Parents corner</Text>*/}
            {/*</TouchableOpacity>*/}
            {/*removed from current scope*/}
            {/*<TouchableOpacity onPress={onPressSetting} style={styles.button}>*/}
            {/*    <SettingsIcon {...imageSizes.standard} />*/}
            {/*    <Text style={styles.buttonText}>Settings</Text>*/}
            {/*</TouchableOpacity>*/}
            <TouchableOpacity onPress={onPressSound} style={styles.button}>
                {appSoundStatus ? (
                    <>
                        <SoundOnIcon
                            {...imageSizes.standard}
                            style={styles.boxShadow}
                        />
                        <Text style={styles.buttonText}>Sound On</Text>
                    </>
                ) : (
                    <>
                        <SoundOffIcon
                            {...imageSizes.standard}
                            style={styles.boxShadow}
                        />
                        <Text style={styles.buttonText}>Sound Off</Text>
                    </>
                )}
            </TouchableOpacity>
            <TouchableOpacity onPress={openLogoutPopup} style={styles.button}>
                <LogoutIcon {...imageSizes.standard} style={styles.boxShadow} />
                <Text style={styles.buttonText}>Logout</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={close} style={styles.button}>
                <CloseIcon {...imageSizes.standard} style={styles.boxShadow} />
                <Text style={styles.buttonText}>Close</Text>
            </TouchableOpacity>
        </View>
    );
};

const getStyles = {
    button: {
        flexDirection: "row",
        alignItems: "center",
        alignSelf: "flex-start",
    },
    buttonText: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        marginLeft: 10,
        fontSize: 24,
    },
};
