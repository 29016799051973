import React, { useMemo } from "react";
import { View, TextInput } from "react-native";
import { QuizOptionMarker } from "./QuizOptionMarker";
import { useStylesUtils } from "../../../../../hooks";
import { COLOURS, QUIZ_OPTION_STATUS } from "../../../../../constants";

export const QuizOptionInput = ({ status, value, onChange, isFinished }) => {
    const color = useMemo(() => {
        switch (status) {
            case QUIZ_OPTION_STATUS.SUCCESS:
                return COLOURS.quizItemSuccess;
            case QUIZ_OPTION_STATUS.ERROR:
                return COLOURS.red2;
            default:
                return COLOURS.black;
        }
    }, [status]);

    const { styles } = useStylesUtils(getStyles(color, isFinished));

    return (
        <View style={styles.container}>
            <View style={styles.marker}>
                <QuizOptionMarker
                    status={status}
                    isAbsolute={false}
                    inversion={true}
                />
            </View>
            <TextInput
                style={styles.input}
                onChangeText={onChange}
                value={value}
                editable={!isFinished}
            />
        </View>
    );
};

const getStyles =
    (color, isFinished) =>
    ({ width, biggerSize }) => ({
        container: {
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
        },
        input: {
            borderColor: color,
            borderBottomWidth: 3,
            resizeMode: "contain",
            height: biggerSize * 0.024,
            fontSize: 16,
            fontWeight: 700,
            textAlign: "center",
            minWidth: width * 0.05,
            // maxWidth: width * 0.2,
            color: color,
            paddingHorizontal: biggerSize * 0.005,
            marginHorizontal: -biggerSize * 0.001,
        },
        marker: {
            marginTop: -width * (isFinished ? 0.028 : 0.008),
            marginBottom: width * 0.008,
        },
    });
