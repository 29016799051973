import { testsMatchColors } from "../../../../constants";

export const findAnswer = (answers, question_id) =>
    answers.find((el) => el.question_id === question_id);

export const arrAreEqual = (arr1, arr2) =>
    arr1.length === arr2.length && arr1.every((x) => arr2.includes(x));

export const getUniqColor = (list) => {
    const checkColors = testsMatchColors.filter(
        (col) => !list.find((state) => state.color === col)
    );

    return checkColors[Math.floor(Math.random() * checkColors.length)];
};

export const calcCurvePath = (a, b) =>
    `M ${a.cx} ${a.y} C  ${a.cx} ${b.y} ${b.cx} ${a.y} ${b.cx} ${b.y}`;
