import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={52}
            height={55}
            viewBox="0 0 52 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.099 39.047a2.451 2.451 0 01-1.764-.737 2.45 2.45 0 01-.703-1.778 563.49 563.49 0 00-.06-22.895 545.428 545.428 0 00-35.81.327 3.253 3.253 0 01-2.536-1.004 3.163 3.163 0 01-.86-2.37 3.617 3.617 0 011.086-2.373A3.676 3.676 0 013.83 7.144a416.9 416.9 0 013.206-.162c.04-.86.082-1.727.126-2.59a3.693 3.693 0 011.065-2.39A3.59 3.59 0 0110.774.905c.879 0 1.694.345 2.296.97.621.646.944 1.514.908 2.443-.03.757-.06 1.55-.09 2.4a422.954 422.954 0 0129.013.122 3.584 3.584 0 012.4 1.069 3.599 3.599 0 011.06 2.402c.33 8.687.396 17.498.194 26.187a2.547 2.547 0 01-.761 1.753 2.571 2.571 0 01-1.75.751 705.057 705.057 0 01-1.945.045z"
                fill="#EFE2FE"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.246 16.852a2.45 2.45 0 011.764.737 2.45 2.45 0 01.703 1.778 563.475 563.475 0 00.06 22.894 545.428 545.428 0 0035.81-.327l.148-.003c.925 0 1.774.358 2.39 1.008.602.635.907 1.477.859 2.37a3.616 3.616 0 01-1.087 2.373 3.675 3.675 0 01-2.378 1.073 413.29 413.29 0 01-3.206.162c-.04.86-.081 1.727-.126 2.59a3.693 3.693 0 01-1.065 2.39 3.59 3.59 0 01-2.547 1.096 3.156 3.156 0 01-2.296-.97c-.621-.646-.944-1.513-.908-2.443.03-.756.06-1.55.09-2.4a423.077 423.077 0 01-29.013-.122 3.584 3.584 0 01-2.4-1.068 3.599 3.599 0 01-1.06-2.402 429.764 429.764 0 01-.194-26.187c.016-.66.286-1.282.761-1.753a2.572 2.572 0 011.75-.751 800.775 800.775 0 011.945-.045z"
                fill="#EFE2FE"
            />
            <Path
                clipRule="evenodd"
                d="M18.36 19.5l16 17-16-17z"
                stroke="#EFE2FE"
                strokeWidth={5}
                strokeLinecap="round"
            />
        </Svg>
    );
}

export default SvgComponent;
