import { useMemo } from "react";
import { Text } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-native";
import { ChapterIcon } from "../../assets/svg";
import { CarrouselComponent, MainLayout } from "../../components";
import { COLOURS, navigation } from "../../constants";
import { useStylesUtils } from "../../hooks";
import { getChaptersRedux } from "../../store/main/selectors";
import { setVideoPlayList } from "../../store/main/slice";
import { concatAllVideosChapters } from "../../utils/parse-lists.utils";

export const ChaptersScreen = () => {
    const dispatch = useDispatch();
    const { styles, width } = useStylesUtils(getStyles);
    const { state } = useLocation();

    const navigate = useNavigate();
    const chaptersData = useSelector(getChaptersRedux);

    const list = useMemo(() => {
        return [
            {
                id: 1,
                title: "Let's Watch",
                type: "video",
                onPress: async (props) => {
                    if (props.isActive) {
                        await dispatch(
                            setVideoPlayList({
                                videos: concatAllVideosChapters(
                                    chaptersData?.data?.video
                                ),
                            })
                        );
                        navigate(navigation.VIDEOS_SCREEN);
                    }
                },
                isActive: !!chaptersData?.data?.video,
            },
            {
                id: 2,
                title: "Let's Practice",
                type: "story",
                onPress: (props) => {
                    if (props.isActive) {
                        navigate(navigation.STORIES_SCREEN, {
                            state: { stories: chaptersData?.data?.stories },
                        });
                    }
                },
                isActive: !!chaptersData?.data?.stories,
            },
            {
                id: 3,
                title: "PDF",
                type: "document",
                onPress: () => console.log("book"),
                isActive: false,
            },
        ].filter(({ isActive }) => isActive);
    }, [chaptersData]);

    return (
        <MainLayout
            headerProps={{
                backButton: true,
                homeButton: true,
                titleContent: (
                    <>
                        <ChapterIcon
                            height={width * 0.14}
                            width={width * 0.14}
                        />
                        <Text
                            style={styles.titleText}
                            numberOfLines={1}
                            ellipsizeMode="tail">
                            {state ? state.title : "Chapter"}
                        </Text>
                    </>
                ),
            }}>
            <CarrouselComponent data={list} />
        </MainLayout>
    );
};

const getStyles = ({ width }) => ({
    titleText: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        fontSize: 40,
        textAlign: "center",
        marginTop: 20,
        maxWidth: width * 0.6,
    },
});
