import { Image } from "react-native";
import React from "react";
import { useSelector } from "react-redux";
import { getUserInfo } from "../store/auth/selectors";
import { useStylesUtils } from "../hooks";

export const ProfileIcon = () => {
    const userInfo = useSelector(getUserInfo);

    const userGender = userInfo?.gender;

    const { imageSizes } = useStylesUtils();

    const getIcon = () => {
        switch (userGender) {
            case "male":
                return (
                    <Image
                        source={require("../assets/images/header/male.png")}
                        style={{
                            ...imageSizes.standard,
                            resizeMode: "contain",
                        }}
                    />
                );
            case "female":
                return (
                    <Image
                        source={require("../assets/images/header/female.png")}
                        style={{
                            ...imageSizes.standard,
                            resizeMode: "contain",
                        }}
                    />
                );
            default:
                return (
                    <Image
                        source={require("../assets/images/header/notSelected.png")}
                        style={{
                            ...imageSizes.standard,
                            resizeMode: "contain",
                        }}
                    />
                );
        }
    };

    return <>{getIcon()}</>;
};
