import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={85}
            height={78}
            viewBox="0 0 85 78"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M76.184 16.392c11.998 17.433 10.57 41.852-6.863 53.85C51.888 82.24 18.494 78.7 6.496 61.267-5.502 43.833 2.324 19.87 19.758 7.874 37.19-4.124 64.186-1.041 76.184 16.392z"
                fill="#CEF7A0"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.568 29.36c.026.409.37.727.784.727a.782.782 0 00.785-.728l1.287-20c.076-1.184-.873-2.185-2.072-2.185-1.198 0-2.147 1.001-2.071 2.185l1.287 20zM21.212 19.284l15.202 13.241a.792.792 0 001.075-.033.772.772 0 00.034-1.064L24.142 16.384a2.09 2.09 0 00-3.026-.095 2.04 2.04 0 00.096 2.995M65.49 57.3l-15.2-13.24a.792.792 0 00-1.075.033.772.772 0 00-.035 1.064L62.561 60.2a2.09 2.09 0 003.026.095 2.04 2.04 0 00-.096-2.994M72.588 36.242l-20.21 1.275a.781.781 0 00-.736.776c0 .41.322.75.736.776l20.21 1.274c1.196.075 2.208-.865 2.208-2.05 0-1.186-1.012-2.126-2.208-2.05M49.215 32.492a.792.792 0 001.074.034l15.202-13.242a2.04 2.04 0 00.096-2.995 2.09 2.09 0 00-3.026.095l-13.38 15.044a.772.772 0 00.034 1.064zM36.268 14.267c1.05 0 1.9-.842 1.9-1.882 0-1.039-.85-1.881-1.9-1.881-1.05 0-1.902.842-1.902 1.881 0 1.04.852 1.882 1.902 1.882zM61.922 49.734c.717 0 1.298-.575 1.298-1.285s-.581-1.285-1.298-1.285c-.718 0-1.299.575-1.299 1.285s.581 1.285 1.299 1.285zM46.604 36.298a.824.824 0 00.828-.819c0-.452-.37-.819-.828-.819a.823.823 0 00-.828.82c0 .452.37.819.828.819zM49.99 22.98c0-.71-.58-1.285-1.298-1.285-.717 0-1.298.576-1.298 1.285 0 .71.581 1.285 1.298 1.285.717 0 1.299-.575 1.299-1.285zM68.45 31.373c.717 0 1.298-.576 1.298-1.285 0-.71-.581-1.285-1.298-1.285-.717 0-1.299.575-1.299 1.285s.582 1.285 1.299 1.285zM57.5 33.595l-.476-2.335-1.805 1.576 2.281.759zM50.99 12.592l-2.835 1.298 2.554 1.78.28-3.078zM34.192 18.98l-2.319-.638.601 2.306 1.718-1.668zM67.188 45.795l1.718-1.669-2.32-.638.602 2.307zM46.162 39.213l-.735 2.268 2.352-.504-1.617-1.764zM37.382 26.028l.688.34.885-3.161-1.573-.098v2.92zM59.379 43.055l.088.58 2.4-.85-.634-1.021-1.854 1.291zM63.8 25.988l1.626-1.941-1.073-.559-.928 2.047.374.453zM42.362 33.968l-1.072-.558-.929 2.047.375.453 1.626-1.942zM55.537 18.971l-.863-1.545-2.817 1.775.09.857 3.59-1.087zM32.895 34.542c-1.457-1.442-3.921-.996-4.77.862L15.125 63.907c-.672 1.473.856 2.985 2.344 2.32L46.27 53.36c1.878-.839 2.328-3.278.871-4.72L32.895 34.542z"
                fill="#548320"
            />
        </Svg>
    );
}

export default SvgComponent;
