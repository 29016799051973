import * as React from "react";
import Svg, { Path, G } from "react-native-svg";

export const TabBtnIcon = (props) => {
    return (
        <Svg
            width={587}
            height={151}
            viewBox="0 0 587 151"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                d="M583.72 148.382c-186.97-1.04-380.215.513-579.999-.317.31-28.66.999-75.873.999-75.873C6 36.832 25.94 13.542 50.63 9.972c0 0 167.63-7.47 224.98-7.47 63.35 0 260.02 7.47 260.02 7.47 25.01 3.57 42.9 27.58 45.46 61.6 0 0 2.27 42.09 2.63 76.81z"
                strokeWidth={5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
};

export const LeftTabBtnIcon = (props) => {
    return (
        <Svg
            width={587}
            height={152}
            viewBox="0 0 587 152"
            fill="#7E42B7"
            stroke="#592C84"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M583.72 148.88C396.75 147.84 201.1 143.61 3 134.75c.31-28.66 1.72-62.06 1.72-62.06C6 37.33 25.94 14.04 50.63 10.47c0 0 167.63-7.47 224.98-7.47 63.35 0 260.02 7.47 260.02 7.47 25.01 3.57 42.9 27.58 45.46 61.6 0 0 2.27 42.09 2.63 76.81z"
            />
            <Path
                d="M583.72 148.88C396.75 147.84 201.1 143.61 3 134.75c.31-28.66 1.72-62.06 1.72-62.06C6 37.33 25.94 14.04 50.63 10.47c0 0 167.63-7.47 224.98-7.47 63.35 0 260.02 7.47 260.02 7.47 25.01 3.57 42.9 27.58 45.46 61.6 0 0 2.27 42.09 2.63 76.81"
                strokeWidth={5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
};

export const RightTabBtnIcon = (props) => {
    return (
        <Svg
            width={587}
            height={152}
            viewBox="0 0 587 152"
            fill="#7E42B7"
            stroke="#592C84"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M583.64 139.98c-174.95 5.46-371.54 9.21-580.63 9.15-.01-1.27-.01-2.47-.01-3.6 0-33.32 1.78-72.84 1.78-72.84C6.06 37.33 26 14.04 50.69 10.47c0 0 167.63-7.47 224.98-7.47 63.35 0 260.02 7.47 260.02 7.47 25.01 3.57 42.9 27.58 45.46 61.6 0 0 1.89 36.79 2.49 67.91"
            />
            <Path
                d="M583.64 139.98c-174.95 5.46-371.54 9.21-580.63 9.15-.01-1.27-.01-2.47-.01-3.6 0-33.32 1.78-72.84 1.78-72.84C6.06 37.33 26 14.04 50.69 10.47c0 0 167.63-7.47 224.98-7.47 63.35 0 260.02 7.47 260.02 7.47 25.01 3.57 42.9 27.58 45.46 61.6 0 0 1.89 36.79 2.49 67.91"
                strokeWidth={5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
};

export const YellowBtnIcon = (props) => {
    return (
        <Svg
            width={374}
            height={125}
            viewBox="0 0 374 125"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                d="M6.368 20.796C10.03 13.073 18.242 9.216 26.76 8.538 72.806 4.871 229.948-5.949 343.657 6.871c13.024 1.468 23.599 10.936 25.817 23.854 3.108 18.1 5.931 44.71 1.236 66.92-2.318 10.971-12.32 17.742-23.485 18.778-51.169 4.747-202.4 16.272-316.432-.738-10.807-1.612-19.91-8.807-23.137-19.246C1.708 77.203-5.064 44.89 6.368 20.796z"
                fill="#FFE27B"
            />
        </Svg>
    );
};

export const OrangewBtnIcon = (props) => {
    return (
        <Svg
            width={214}
            height={112}
            viewBox="0 0 214 112"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <G filter="url(#filter0_d_3321_83951)">
                <Path
                    d="M7.975 23.813C9.854 14.93 18.196 9.32 27.832 8.27 58.77 4.898 128.247-1.01 183.4 6.4c12.218 1.642 21.495 11.401 22.749 23.707 1.346 13.201 2.342 30.389.861 45.775-1.065 11.067-10.304 19.067-21.72 20.49-32.853 4.091-100.5 10.158-155.471-.165-10.432-1.96-18.484-9.98-20.415-20.404C6.722 61.33 4.382 40.8 7.975 23.813z"
                    fill="#EB731B"
                    stroke="#fff"
                    strokeWidth={4}
                    strokeMiterlimit={10}
                />
            </G>
        </Svg>
    );
};
