import { storiesPlaceholders } from "../../constants";
import { BookCard } from "./bookCard";
import { FileCard } from "./fileCard";
import { GameConsoleCard } from "./gameConsoleCard";
import { PdfCard } from "./pdfCard";
import { ScanAndUploadCard } from "./scanAndUploadCard";
import { TvCard } from "./tvCard";

import BookPlaceholder from "../../assets/images/placeholders/book.png";
import TvPlaceholder from "../../assets/images/placeholders/tvImagePlaceholder.png";
import PDFPlaceholder from "../../assets/images/placeholders/pdfPlaceholder.png";
import FilePlaceholder from "../../assets/images/placeholders/chapter.png";
import ScanPlaceholder from "../../assets/images/placeholders/scanPlaceholder.png";
import { TestCard } from "./testCard";
import { useContext } from "react";
import GlobalAudioContext from "../../providers/GlobalAudioContext";

export const CardViewComponent = ({ type, image, onPress, ...rest }) => {
    const { playClickSound } = useContext(GlobalAudioContext);

    const rewriteOnPress = (...props) => {
        playClickSound();
        onPress?.(...props);
    };

    const randomizedPlaceholder = () => {
        const imageIndex = Math.random() * 2;
        return storiesPlaceholders[imageIndex.toFixed(0)];
    };

    switch (type?.toLowerCase()) {
        case "video":
            return (
                <TvCard
                    image={image ? image : TvPlaceholder}
                    onPress={rewriteOnPress}
                    {...rest}
                />
            );
        case "story":
            return (
                <GameConsoleCard
                    image={image ? image : randomizedPlaceholder()}
                    onPress={rewriteOnPress}
                    {...rest}
                />
            );
        case "document":
            return (
                <PdfCard
                    image={image ? image : PDFPlaceholder}
                    onPress={rewriteOnPress}
                    {...rest}
                />
            );
        case "file":
            return (
                <FileCard
                    image={image ? image : FilePlaceholder}
                    onPress={rewriteOnPress}
                    {...rest}
                />
            );
        case "scan":
            return (
                <ScanAndUploadCard
                    image={image ? image : ScanPlaceholder}
                    onPress={rewriteOnPress}
                    {...rest}
                />
            );
        case "test":
            return (
                <TestCard image={image} {...rest} onPress={rewriteOnPress} />
            );
        case "book":
        default:
            return (
                <BookCard
                    image={image ? image : BookPlaceholder}
                    onPress={rewriteOnPress}
                    {...rest}
                />
            );
    }
};
