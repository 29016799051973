import React, { useContext } from "react";
import QuizContext from "./QuizContext";
import { TimerComponent } from "../../../components/Timer";
import { View, Text } from "react-native";
import { COLOURS } from "../../../constants";
import { useStylesUtils } from "../../../hooks";

export const QuizRightBlockContent = () => {
    const {
        isFinished,
        quizData,
        countdownDuration,
        timerRemainTime,
        currentQuestion,
    } = useContext(QuizContext);

    const { styles } = useStylesUtils(getStyles);

    // console.log("currentQuestion", currentQuestion);

    return isFinished ? (
        <View style={styles.container}>
            <Text style={styles.text}>{currentQuestion.marks || 0}</Text>
            <Text style={styles.text}>Marks</Text>
        </View>
    ) : (
        <TimerComponent
            countdownDuration={countdownDuration}
            remainTime={timerRemainTime}
        />
    );
};

const getStyles = ({ biggerSize }) => {
    return {
        container: {
            backgroundColor: COLOURS.yellow1,
            paddingVertical: biggerSize * 0.005,
            paddingHorizontal: biggerSize * 0.02,
            borderRadius: 15,
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.23,
            shadowRadius: 2.62,
            elevation: 4,
        },
        text: {
            fontSize: 16,
            fontWeight: 700,
            color: COLOURS.black,
            textAlign: "center",
        },
    };
};
