import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={55}
            height={36}
            viewBox="0 0 55 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.716.007C32.783-.188 30.11 3.601 27.5 3.595h-.002C24.888 3.6 22.215-.188 16.282.007 9.17.325 4.182 6.37 1.737 13.03c-2.488 6.698-1.98 13.963-.502 17.55 2.123 5.057 7.696 5.876 10.383 3.18a615.778 615.778 0 015.33-4.96c1.247-1.192 2.506-2.173 4.056-2.16 4.33.038 8.66.038 12.99 0 1.55-.013 2.809.968 4.055 2.16a618.716 618.716 0 015.33 4.96c2.688 2.697 8.26 1.877 10.384-3.18 1.478-3.587 1.986-10.852-.502-17.55C50.816 6.369 45.828.325 38.716.007m-16.725 14.24a.933.933 0 01-.925.931l-2.921.01c-.008.977-.01 1.955-.01 2.933.002.51-.409.924-.917.923l-2.626-.006a.926.926 0 01-.925-.92c-.002-.97.002-1.94.013-2.91-.973.005-1.947.011-2.92.018a.889.889 0 01-.905-.902c.016-.864.039-1.727.068-2.59a.973.973 0 01.947-.925c.967-.018 1.935-.034 2.902-.048.03-.969.065-1.938.107-2.905a.988.988 0 01.947-.935c.86-.018 1.72-.035 2.58-.05.5-.009.89.397.876.907-.03.975-.054 1.95-.074 2.927.967-.01 1.934-.018 2.901-.024a.901.901 0 01.904.92c-.01.882-.017 1.764-.022 2.647m14.743 6.319c-1.656.007-2.99-1.344-2.99-3.016 0-1.671 1.333-3.022 2.989-3.013 1.655.01 3.017 1.36 3.017 3.016 0 1.655-1.36 3.005-3.016 3.013zm-.032-8.466c-1.653-.012-3.006-1.373-3.032-3.04-.026-1.669 1.258-3.004 2.879-2.976 1.62.029 3.001 1.397 3.06 3.048.057 1.652-1.255 2.98-2.907 2.968m8.037 4.257a3.06 3.06 0 01-3.039-2.999c-.03-1.651 1.257-2.966 2.9-2.931 1.643.035 3.042 1.381 3.088 3.002.045 1.62-1.291 2.931-2.949 2.928"
                fill="#B7245C"
            />
        </Svg>
    );
}

export default SvgComponent;
