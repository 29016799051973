import { ResizeMode } from "expo-av";
import VideoPlayer from "expo-video-player";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { AppState, Platform, Pressable, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useLocation, useNavigate } from "react-router-native";
import { BackArrowIcon } from "../../assets/svg";
import { MainLayout } from "../../components";
import { COLOURS } from "../../constants";
import { usePopups, useStylesUtils, usePrevious } from "../../hooks";
import { useDiaryHook } from "../../hooks/useDiaryHook";
import { useKeepAwake } from "expo-keep-awake";

const { OS } = Platform;

export const VideoScreen = () => {
    useKeepAwake();
    const playerRef = useRef(null);
    const appState = useRef(AppState.currentState);

    const insets = useSafeAreaInsets();
    const { styles, imageSizes } = useStylesUtils(getStyles(insets));

    const {
        state: {
            video,
            studentAssignmentId,
            homeworkAssetId,
            videoProgressInSeconds,
            isComplete,
        },
    } = useLocation();
    const navigate = useNavigate();
    const { youAreYetToFinishHomework, completeHomeWork } = usePopups();

    const { setHomeworkStatusToComplete, setHomeworkVideoProgress } =
        useDiaryHook();

    const [isCompleted, setIsCompleted] = useState(false);

    const appStateSubscription = AppState.addEventListener(
        "change",
        (nextAppState) => {
            appState.current = nextAppState;
            // savePlaybackProgress();
        }
    );

    const [progress, setProgress] = useState(0);
    const [isLoad, setIsLoad] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isBuffering, setIsBuffering] = useState(false);

    const prevProgress = usePrevious(progress);
    const prevIsPlaying = usePrevious(isPlaying);

    const progressListener = (e) => {
        const {
            positionMillis,
            durationMillis,
            isPlaying,
            isLoaded,
            isBuffering,
        } = e;

        if ((!studentAssignmentId && !homeworkAssetId) || isComplete) {
            return;
        }

        const completionPercent = Math.ceil(durationMillis * 0.9);

        setProgress(Math.floor(positionMillis / 1000));
        setIsPlaying(isPlaying);
        setIsLoaded(isLoaded);
        setIsBuffering(isBuffering);

        if (!isLoad) {
            setIsLoad(true);
        }

        if (positionMillis >= completionPercent && !isCompleted) {
            console.log("completed");
            setHomeworkStatusToComplete({
                studentAssignmentId,
                homeworkAssetId,
            }).then(() => setIsCompleted(true));
        }
    };

    useEffect(() => {
        const loadedPlayer =
            isLoad &&
            isLoaded &&
            !isBuffering &&
            prevIsPlaying !== undefined &&
            prevProgress !== undefined;

        const isChangePlayOrPause = loadedPlayer && isPlaying !== prevIsPlaying;

        const isSeek = loadedPlayer && progress - prevProgress >= 2;

        const isNeedSaveProgress =
            loadedPlayer && (progress - videoProgressInSeconds) % 15 === 0;

        if (isChangePlayOrPause || isSeek || isNeedSaveProgress) {
            savePlaybackProgress();
        }
    }, [
        isBuffering,
        videoProgressInSeconds,
        progress,
        prevProgress,
        isLoad,
        isPlaying,
        prevIsPlaying,
        isLoaded,
    ]);

    const savePlaybackProgress = useCallback(() => {
        if (isCompleted || isComplete) {
            return;
        }

        setHomeworkVideoProgress({
            studentAssignmentId,
            homeworkAssetId,
            videoProgressInSeconds: progress,
        }).then();
    }, [progress, isCompleted, isComplete]);

    const onGoBack = () => {
        if (isComplete) {
            navigate(-1);
            return;
        }

        savePlaybackProgress();
        if (isCompleted) {
            completeHomeWork(() => {
                navigate(-1);
            });
        } else {
            youAreYetToFinishHomework();
        }
    };

    useEffect(() => {
        if (videoProgressInSeconds && isLoad) {
            playerRef.current.setPositionAsync(videoProgressInSeconds * 1000);
        }
    }, [isLoad, videoProgressInSeconds]);

    useEffect(() => {
        return () => {
            appStateSubscription.remove();
        };
    }, []);

    return (
        <MainLayout
            headerProps={{ hide: true }}
            isSaveArea={false}
            isCenteredContent={OS !== "web"}>
            <View style={styles.video}>
                <VideoPlayer
                    timeVisible={true}
                    videoProps={{
                        shouldPlay: true,
                        resizeMode: ResizeMode.CONTAIN,
                        source: { uri: video?.videoURL },
                        ref: playerRef,
                        posterSource: { uri: video?.thumbnail },
                        videoStyle: styles.player,
                        progressUpdateIntervalMillis: 1000,
                    }}
                    fullscreen={false}
                    slider={{
                        thumbTintColor: COLOURS.mainGreen,
                        minimumTrackTintColor: COLOURS.mainGreen,
                        maximumTrackTintColor: COLOURS.grey1,
                    }}
                    activityIndicator={{
                        color: COLOURS.mainGreen,
                    }}
                    style={styles.player}
                    playbackCallback={progressListener}
                />
            </View>

            <Pressable style={styles.backButton} onPress={onGoBack}>
                <BackArrowIcon {...imageSizes.standard} />
            </Pressable>
        </MainLayout>
    );
};

const getStyles =
    (insets) =>
    ({ width, height }) => ({
        video: {
            backgroundColor: COLOURS.black,
            width,
            height: height - insets.bottom,
            top: 0,
            left: 0,
            right: 0,
            justifyContent: "center",
            alignItems: "center",
        },
        player: {
            height: height - insets.bottom,
            width,
        },
        backButton: {
            position: "absolute",
            zIndex: 100,
            top: 0,
            left: 0,
            padding: 20,
        },
    });
