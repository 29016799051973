import * as React from "react";
import Svg, { Path } from "react-native-svg";

function QuestionIcon(props) {
    return (
        <Svg
            width={28}
            height={29}
            viewBox="0 0 28 29"
            fill="#E9DCF5"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 .589C10.296.576 6.883 2.298 4.29 4.862c-2.583 2.578-4.284 6-4.28 9.696-.004 3.696 1.697 7.117 4.28 9.696 2.594 2.564 6.007 4.286 9.71 4.273 3.702.013 7.115-1.709 9.709-4.273 2.583-2.579 4.284-6 4.28-9.696.004-3.696-1.697-7.118-4.28-9.696C21.115 2.298 17.702.576 13.999.59m2.728 20.769c-.701 1.992-2.129 2.542-3.237 2.539-.302 0-.58-.042-.813-.105-.668-.18-1.258-.65-1.62-1.289-.316-.558-.616-1.506-.226-2.86.613-2.122 1.026-3.534 1.327-4.564.274-.94.442-1.538.593-2.033-.386.193-.998.577-1.431.844-.359.222-.754.124-.94-.164-.193-.3-.12-.697.183-.936.941-.74 2.344-1.577 3.493-1.685.912-.086 1.577.263 1.942 1.022.415.861.09 2.185-.252 3.249-.52 1.616-1.074 3.334-1.476 4.605-.186.584-.09 1.243.344 1.47.467.243 1.006.136 1.668-.39a.293.293 0 01.282-.054.276.276 0 01.163.351M15.442 9.402a1.993 1.993 0 01-2.117-1.864c-.069-1.1.756-2.056 1.84-2.124 1.083-.068 2.03.776 2.115 1.875a1.962 1.962 0 01-1.838 2.113z"
            />
        </Svg>
    );
}

export default QuestionIcon;
