import { View, Text, Pressable } from "react-native";
import { ScrollIcon } from "../../assets/svg";
import { useStylesUtils } from "../../hooks";
import { CardsImage } from "./components/CardsImage";

export const ScrollCard = ({ title = "cardTitle", onPress, image }) => {
    const { styles, biggerSize } = useStylesUtils(getStyles);

    return (
        <View style={[styles.container]}>
            <Pressable style={styles.bookContainer} onPress={onPress}>
                <ScrollIcon
                    width={biggerSize * 0.21}
                    height={biggerSize * 0.24}
                />
                <CardsImage
                    source={image}
                    style={{
                        width: biggerSize * 0.21,
                        height: biggerSize * 0.24,
                        resizeMode: "contain",
                    }}
                />
                <Text style={styles.title}>{title}</Text>
            </Pressable>
        </View>
    );
};

const getStyles = ({ biggerSize }) => ({
    container: {
        marginRight: biggerSize * 0.02,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    bookContainer: {
        width: biggerSize * 0.2,
        height: biggerSize * 0.23,
        marginBottom: 15,
    },
    cardImage: {
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
        top: biggerSize * 0.014,
        left: biggerSize * 0.02,
        width: biggerSize * 0.155,
        height: biggerSize * 0.211,
        borderRadius: 10,
        zIndex: -10,
    },
    title: {
        position: "absolute",
        bottom: biggerSize * 0.015,
        left: biggerSize * 0.01,
        color: "black",
        fontSize: 13,
        textAlign: "center",
        width: biggerSize * 0.12,
    },
});
