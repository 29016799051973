import * as React from "react";
import Svg, { Path } from "react-native-svg";

/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent(props) {
    return (
        <Svg
            width={160}
            height={155}
            viewBox="0 0 160 155"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                d="M64.376 3.336c39.919 0 88.646 22.814 88.646 62.734 0 39.919-36.453 79.098-76.371 79.098-39.92 0-76.372-39.179-76.372-79.098 0-39.92 24.178-62.734 64.097-62.734z"
                fill="#fff"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M63.239 11.651c34.771 0 77.215 19.873 77.215 54.645 0 34.772-31.752 68.9-66.524 68.9-34.771 0-66.524-34.128-66.524-68.9 0-34.772 21.061-54.645 55.833-54.645z"
                fill="#0A3056"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M85.074 73.424c6.207 6.232 12.14 12.294 17.103 17.718 1.676 1.867 2.306 3.42 1.994 4.772-.306 1.349-1.83 3.051-4.44 5.132-2.616 2.081-4.587 3.21-5.999 3.357-1.427.153-2.798-.745-4.363-2.769-4.242-5.516-9.625-11.568-15.397-17.738-5.771 6.174-11.155 12.226-15.402 17.744-1.618 2.024-2.997 2.916-4.366 2.773-1.365-.145-3.332-1.284-6.009-3.394-2.677-2.11-4.216-3.807-4.512-5.105-.3-1.296.349-2.85 2.083-4.766 4.967-5.426 10.901-11.49 17.106-17.724-6.207-6.233-12.141-12.298-17.106-17.725-1.75-1.808-2.413-3.334-2.1-4.689.303-1.35 1.84-3.067 4.49-5.175 2.646-2.11 4.636-3.25 6.048-3.4 1.424-.148 2.797.747 4.366 2.77 4.247 5.521 9.63 11.575 15.402 17.747 5.776-6.172 11.149-12.224 15.397-17.74 1.621-2.019 2.988-2.91 4.363-2.768 1.365.145 3.318 1.271 5.96 3.352 2.64 2.084 4.177 3.812 4.497 5.214.321 1.403-.318 2.934-2.012 4.697-4.964 5.42-10.896 11.486-17.103 17.717"
                fill="#fff"
            />
        </Svg>
    );
}

export default SvgComponent;
