import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
    return (
        <Svg
            width={158}
            height={145}
            viewBox="0 0 158 145"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M142.635 29.9797C165.208 62.7799 162.522 108.722 129.722 131.296C96.9222 153.869 34.0936 147.21 11.5202 114.41C-11.0528 81.6095 3.67102 36.5263 36.471 13.9529C69.2712 -8.6205 120.062 -2.82025 142.635 29.9797"
                fill="#A0DDFF"
            />
        </Svg>
    )
}

export default SvgComponent
