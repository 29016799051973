import React, { useCallback, useEffect } from "react";
import { Text, View } from "react-native";
import { useNavigate } from "react-router-native";
import { AuthLayout, Button } from "../../../components";
import { COLOURS, navigation } from "../../../constants";
import { useStylesUtils } from "../../../hooks";
import { useAuth } from "../../../hooks/useAuth";
import { TitleContent } from "../TitleContent";

export const LoginScreen = () => {
    const navigate = useNavigate();
    const { width, styles, biggerSize } = useStylesUtils(getStyles);

    const { checkToAuthorized } = useAuth();

    useEffect(() => {
        checkToAuthorized();
    }, []);

    const pressOnLogInWithID = useCallback(() => {
        navigate(navigation.ENTER_ADMISSION_NUMBER);
    }, []);

    return (
        <AuthLayout
            headerProps={{ backButton: false, titleContent: <TitleContent /> }}
            showLogo={true}>
            <Text style={styles.label}>Hi! Please select any one continue</Text>
            <View style={styles.formContainer}>
                <Button
                    onPress={pressOnLogInWithID}
                    label={"Log-in with Student ID"}
                    type={"big"}
                    textStyle={{ fontSize: 20 }}
                />
            </View>
        </AuthLayout>
    );
};

const getStyles = {
    container: {
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
    },
    label: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        marginBottom: 10,
        fontSize: 24,
    },
    formContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 10,
    },
    forgotCodeContainer: {
        marginLeft: -90,
        justifyContent: "center",
    },
    forgotCodeText: {
        fontWeight: 700,
        color: COLOURS.purple,
        fontSize: 12,
    },
};
