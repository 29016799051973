import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
    return (
        <Svg
            width={761}
            height={194}
            viewBox="0 0 761 194"
            xmlns="http://www.w3.org/2000/svg"
            fill="#006BA6"
            stroke="#fff"
            strokeOpacity={0.8}
            strokeWidth={8}
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M72.177 36.777S288.316 32 377.447 32c98.442 0 311.332 4.777 311.332 4.777 38.861 2.284 35.023 17.645 38.997 39.412 0 0 .693-.66.693 23.651v20.36c-3.974 21.766-1.733 38.665-33.457 39.411-15.222.741-213.512 2.368-307.177 2.388-94.463.021-277.906-.39-315.658-2.388-37.752-1.999-38.004-17.42-38.998-38.615 0 0-.843-4.525-.692-24.017V76.586c1.987-22.622 1.326-37.525 39.69-39.809z"
                stroke="none"
            />
            <Path
                d="M28.487 76.58l.197-1.512c.08-28.399 10.407-40.724 39.148-41.911l6.707-.432 7.014-.152a36629.227 36629.227 0 01109.762-2.184C256.485 29.195 332.839 28 377.447 28c49.262 0 127.129 1.195 192.654 2.389a32990.046 32990.046 0 01109.425 2.184l6.926.152 1.802.04.459.01.116.003h.037c.001 0 .002 0-.087 3.999l.089-3.999.073.002.072.004c19.735 1.16 30.231 5.692 35.713 14.232 2.63 4.096 3.848 8.755 4.683 13.453.387 2.174.705 4.45 1.025 6.736l.081.58c.33 2.356.672 4.748 1.111 7.211.104.305.153.546.174.65l.004.019c.126.616.224 1.537.311 2.992.181 3.001.354 9.015.354 21.183V120.333h-3.268l3.203.585c-.484 2.65-.875 5.236-1.257 7.786l-.058.389c-.358 2.396-.714 4.777-1.127 7.056-.866 4.773-2.041 9.473-4.317 13.578-2.341 4.224-5.773 7.69-10.878 10.091-4.989 2.348-11.392 3.593-19.612 3.79-15.727.748-213.81 2.371-307.319 2.391-94.38.021-277.981-.388-315.87-2.394-19.208-1.017-30.303-5.485-36.308-13.752-2.947-4.057-4.408-8.696-5.219-13.496-.758-4.487-.986-9.387-1.215-14.312l-.03-.644-.011-.08c-.03-.213-.068-.513-.111-.918a78.018 78.018 0 01-.29-3.891c-.197-3.671-.371-9.757-.295-19.549V76.58z"
                fill="none"
            />
        </Svg>
    )
}

export default SvgComponent
