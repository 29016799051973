import AsyncStorage from "@react-native-async-storage/async-storage";

export const rootPersistConfig = {
    key: "root",
    storage: AsyncStorage,
    whitelist: ["auth"],
};

export const appPersistConfig = {
    key: "app",
    storage: AsyncStorage,
    whitelist: ["layoutType", "permissions", "soundStatus"],
};

export const mainPersistConfig = {
    key: "main",
    storage: AsyncStorage,
    blacklist: ["resetForm", "userImages"],
};

export const dairyPersistConfig = {
    key: "diary",
    storage: AsyncStorage,
    blacklist: ["studentHomeworks"],
};
