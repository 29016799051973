import React, { useContext } from "react";
import { Image, View } from "react-native";
import { COLOURS, srcRegExp } from "../../../../constants";
import { useStylesUtils } from "../../../../hooks";
import QuizContext from "../QuizContext";
import { QuizOption } from "./components/QuizOption";
import { MathJaxView } from "../../../../components";
import { CustomScrollView } from "../../../../components/CustomScrollView";
import { parse } from "node-html-parser";

const TEMPLATE_TYPES = Object.freeze({
    IMAGE: "IMAGE",
    MATH: "MATH",
    INPUTS: "INPUTS",
    HTML: "HTML",
});

const parseQuestionText = (questionText) => {
    const imageTagRegexp = /<img/g;
    const mathTagRegexp = /<math/g;
    const inputPlaceholderRegexp = /\[\[\d+\]\]/g;

    const parsedDOM = parse(questionText);
    const listOfChildren = parsedDOM.childNodes;

    const parsedTemplate = [];
    for (let child of listOfChildren) {
        if (child?.innerHTML.match(imageTagRegexp)) {
            //handle img
            parsedTemplate.push({
                type: TEMPLATE_TYPES.IMAGE,
                data: child?.innerHTML,
            });
            continue;
        }
        if (child?.innerHTML.match(mathTagRegexp)) {
            //handle math
            parsedTemplate.push({
                type: TEMPLATE_TYPES.MATH,
                data: child?.innerHTML,
            });
            continue;
        }
        if (child?.innerHTML.match(inputPlaceholderRegexp)) {
            //handle input string
            parsedTemplate.push({
                type: TEMPLATE_TYPES.INPUTS,
                data: child?.innerHTML,
            });
            continue;
        }
        parsedTemplate.push({
            type: TEMPLATE_TYPES.HTML,
            data: child?.innerHTML,
        });
    }

    return parsedTemplate;
};

export const QuizOptionChoiceCard = () => {
    const { styles, width, biggerSize } = useStylesUtils(getStyles);

    const { currentQuestionData, currentQuestion, isFinished } =
        useContext(QuizContext);

    if (!currentQuestionData || !currentQuestionData.listOfOptions) {
        return <></>;
    }

    const prepareImageRender = (markup) => {
        const imgSrc = markup.match(srcRegExp);
        return (
            <Image
                source={{ uri: imgSrc[1] }}
                style={styles.image}
                resizeMode={"contain"}
            />
        );
    };

    const renderQuestionNode = (questionNode, id, all) => {
        switch (questionNode.type) {
            case TEMPLATE_TYPES.MATH:
            case TEMPLATE_TYPES.HTML:
                return (
                    <MathJaxView
                        key={"renderQuestionNode" + id}
                        html={questionNode.data}
                        isTextCentered={true}
                        textStyles={{ fontSize: 20 }}
                    />
                );
            case TEMPLATE_TYPES.IMAGE:
                return prepareImageRender(questionNode.data);

            default:
                return <></>;
        }
    };

    // useEffect(() => {
    //     let questionMarkup = currentQuestionData.questionText;
    //     const base64RegExp = /data:[^;]*;base64,([A-Za-z0-9+/]+=*)/;
    //     const base64 = questionMarkup.match(base64RegExp);
    //
    //     if (base64 && base64.length > 1) {
    //         const base64String = base64[1];
    //         console.log("Base64 String:", base64String);
    //     } else {
    //         console.log("No base64 string found in the HTML.");
    //     }
    // }, [currentQuestionData]);

    return (
        <CustomScrollView showIndicator={true}>
            <View style={styles.cardContainer}>
                <View
                    style={{
                        width: "100%",
                        flex: 1,
                        justifyContent: "center",
                    }}>
                    {/*<MathJaxView*/}
                    {/*    html={currentQuestionData.questionText}*/}
                    {/*    isTextCentered={true}*/}
                    {/*/>*/}
                    {parseQuestionText(currentQuestionData.questionText).map(
                        renderQuestionNode
                    )}
                </View>
                <View style={styles.optionsContainer}>
                    {currentQuestionData.listOfOptions.map((option, index) => (
                        <QuizOption
                            key={option.id + index}
                            text={option.text}
                            image={option.image}
                            status={option.status}
                            isSelected={option.isSelected}
                            isFinished={isFinished}
                            onOptionPick={() =>
                                option.onOptionPick(option.id, option.text)
                            }
                        />
                    ))}
                </View>
            </View>
        </CustomScrollView>
    );
};

const getStyles = ({ width, biggerSize }) => ({
    cardContainer: {
        flex: 1,
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    questionContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    qText: {
        fontSize: biggerSize * 0.02,
        fontWeight: 700,
        color: COLOURS.black,
    },
    qImagesContainer: {
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
    },
    qImageContainer: {
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLOURS.white,
        borderRadius: 20,
        overflow: "hidden",
        width: biggerSize * 0.15,
        height: (biggerSize * 0.15) / 1.4,
    },
    qImage: {
        width: "100%",
        height: "100%",
        resizeMode: "contain",
    },
    optionsContainer: {
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        alignItems: "center",
        width: "75%",
        marginTop: biggerSize * 0.01,
    },
    image: {
        marginVertical: width * 0.02,
        height: width * 0.5,
    },
});
