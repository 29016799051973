import { View } from "react-native";
import { useStylesUtils } from "../../../../../hooks";
import { MatchOption } from "./MatchOption";
import { useContext } from "react";
import QuizContext from "../../QuizContext";

export const OptionsRow = ({ type }) => {
    const {
        currentQuestionData: { list, currentPickedOption },
    } = useContext(QuizContext);

    const { styles, biggerSize } = useStylesUtils({
        row: {
            width: "100%",
            flex: 1,
            flexDirection: "row",
            height: "100%",
        },
    });

    const gap = biggerSize * 0.01;

    return (
        <View style={styles.row}>
            {list[type].map((option, index, arr) => (
                <MatchOption
                    key={`option_${option.id}`}
                    isPickup={currentPickedOption?.[type] === option.id}
                    style={{
                        width: `${100 / arr.length}%`,
                        marginLeft: index === 0 ? 0 : gap / 2,
                        marginRight: index === arr.length - 1 ? 0 : gap / 2,
                    }}
                    {...option}
                />
            ))}
        </View>
    );
};
