import * as React from "react";
import Svg, { Path } from "react-native-svg";

export const QuizStatusOk = (props) => {
    return (
        <Svg
            width={28}
            height={23}
            viewBox="0 0 28 23"
            fill="#fff"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                d="M10.219 17.052l-6.48-6.849h0a1.714 1.714 0 00-2.513 0l8.993 6.849zm0 0L24.437 2.026a1.714 1.714 0 012.512 0h0c.676.715.676 1.86 0 2.575h0L11.475 20.955c-.341.36-.796.547-1.256.547-.46 0-.915-.187-1.256-.547l.348-.33-.348.33-7.737-8.177a1.887 1.887 0 010-2.575l8.993 6.849z"
                stroke="#fff"
            />
        </Svg>
    );
};

export const QuizStatusError = (props) => {
    return (
        <Svg
            width={25}
            height={26}
            viewBox="0 0 25 26"
            fill="#fff"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                d="M21.226 1.945l-8.612 8.612-8.612-8.612A1.73 1.73 0 001.557 4.39l8.612 8.612-8.612 8.612a1.73 1.73 0 000 2.445h0a1.73 1.73 0 002.445 0l8.612-8.612 8.612 8.612a1.73 1.73 0 002.446-2.445l-8.612-8.612 8.612-8.612a1.73 1.73 0 000-2.445v0a1.73 1.73 0 00-2.446 0z"
                stroke="#fff"
            />
        </Svg>
    );
};
export const QuizStatusEmpty = (props) => {
    return (
        <Svg
            width={25}
            height={26}
            viewBox="0 0 25 26"
            fill="transparent"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path d="" stroke="transparent" />
        </Svg>
    );
};
