import { useMemo, useState } from "react";
import { TouchableOpacity } from "react-native";
import {
    CheckBoxCheckedIcon,
    CheckBoxUnCheckedIcon,
} from "../assets/svg/checkBox";
import { useStylesUtils } from "../hooks";

export const CustomCheckBox = ({
    defaultValue = false,
    iconProps = {},
    viewStyle = {},
}) => {
    const { width } = useStylesUtils();
    const [value, setValue] = useState(defaultValue);

    const Icon = useMemo(
        () => (value ? CheckBoxCheckedIcon : CheckBoxUnCheckedIcon),
        [value]
    );

    return (
        <TouchableOpacity
            onPress={() => setValue((prev) => !prev)}
            style={viewStyle}>
            <Icon width={width * 0.04} height={width * 0.04} {...iconProps} />
        </TouchableOpacity>
    );
};
