import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
    return (
        <Svg
            width={389}
            height={524}
            viewBox="0 0 389 524"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.271 29.9057C30.1171 170.342 30.0946 307.761 39.2027 448.183L39.2139 448.343C39.2317 448.62 39.2495 448.897 39.2672 449.174C38.9998 449.166 38.7332 449.157 38.4648 449.148C37.7924 449.14 37.1462 449.136 36.5243 449.133C35.9556 449.163 35.4703 449.204 35.0316 449.251C35.0242 449.25 35.0129 449.249 35.0064 449.247C33.0041 449.456 31.0467 449.876 29.1828 450.511C27.3217 451.174 25.5551 452.025 23.9877 453.081L23.9606 453.078C23.8624 453.141 23.7426 453.231 23.5949 453.34C23.4004 453.469 23.2788 453.557 23.2273 453.574C22.7775 453.875 22.3323 454.176 21.8825 454.476C21.8283 453.865 21.7731 453.252 21.7179 452.642C10.9143 317.559 9.56945 186.483 17.9929 52.2078C18.0191 52.1002 18.0416 51.9927 18.0668 51.8842C19.0628 49.4452 20.922 46.6002 23.275 43.6534C25.7683 40.5559 28.8386 37.321 32.2045 34.2283C33.1416 33.3632 34.102 32.5477 35.0662 31.6779C36.023 30.8895 36.9572 30.103 37.9102 29.3689C38.4031 28.977 38.8922 28.5852 39.3832 28.1933C39.3448 28.7638 39.3074 29.3352 39.271 29.9057M368.163 446.364C263.069 461.613 156.347 462.931 50.9705 450.314C50.6834 450.28 50.3982 450.246 50.1101 450.211C50.0905 449.906 50.0737 449.601 50.055 449.296C41.4622 306 41.624 165.288 50.5403 21.8975C50.5534 21.6637 50.5703 21.4299 50.5843 21.196C50.805 21.1063 51.0266 21.0165 51.2474 20.9276C51.4596 20.8472 51.6186 20.7752 51.83 20.6948C52.017 20.619 52.2256 20.5657 52.4098 20.4881L52.5417 20.4722C157.203 7.60174 263.18 9.00363 367.555 24.6788C367.851 24.7227 368.142 24.7667 368.437 24.8106C368.461 25.1118 368.484 25.412 368.506 25.7131C379.329 167.056 379.529 304.148 369.114 445.334C369.09 445.634 369.07 445.935 369.048 446.235C368.754 446.279 368.457 446.321 368.163 446.364"
                fill="#A0DDFF"
            />
        </Svg>
    )
}

export default SvgComponent
