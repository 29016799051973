import { useMemo } from "react";
import { Text, View } from "react-native";
import { COLOURS } from "../../../constants";
import { useStylesUtils } from "../../../hooks";

export const RepostCardView = ({ title, percent }) => {
    const progressColors = useMemo(() => {
        if (percent <= 33) {
            return { color1: COLOURS.errorRed, color2: COLOURS.red4 };
        } else if (percent > 33 && percent <= 66) {
            return { color1: COLOURS.yellow1, color2: COLOURS.yellow7 };
        } else {
            return { color1: COLOURS.green1, color2: COLOURS.green2 };
        }
    }, [percent]);

    const { styles, width } = useStylesUtils(getStyles(progressColors));

    return (
        <View style={styles.container}>
            <View style={styles.contentCenter}>
                <View style={styles.circleWrapper}>
                    <View style={styles.circle}>
                        <Text style={styles.percentText}>{percent}%</Text>
                    </View>
                    <View style={[styles.smallCircle, { top: -10 }]} />
                    <View style={[styles.smallCircle, { right: -10 }]} />
                    <View style={[styles.smallCircle, { bottom: -10 }]} />
                    <View style={[styles.smallCircle, { left: -10 }]} />
                </View>
            </View>
            <View style={styles.titleWrapper}>
                <Text style={styles.title}>{title}</Text>
            </View>
        </View>
    );
};

const getStyles =
    (progressColors) =>
    ({ width }) => ({
        container: {
            width: width * 0.2,
            height: width * 0.25,
            backgroundColor: "#fff",
            marginRight: 10,
            borderWidth: 1,
            borderColor: COLOURS.pink3,
            borderRadius: 15,
        },
        circleWrapper: {
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            borderWidth: 0,
            width: width * 0.15,
            height: width * 0.15,
            borderRadius: 200,
        },
        circle: {
            borderWidth: 10,
            borderColor: COLOURS.purple20,
            backgroundColor: "#ff",
            borderRadius: 200,
            width: width * 0.15,
            height: width * 0.15,
            justifyContent: "center",
            alignItems: "center",
        },
        smallCircle: {
            position: "absolute",
            width: 20,
            height: 20,
            backgroundColor: progressColors.color1,
            borderRadius: 10,
        },
        titleWrapper: {
            height: width * 0.05,
            borderRadius: 15,
            backgroundColor: COLOURS.pink3,
            justifyContent: "center",
            alignItems: "center",
        },
        title: {
            fontSize: 24,
            fontWeight: 700,
            color: COLOURS.blue4,
        },
        percentText: {
            fontSize: 40,
            fontWeight: 700,
            color: progressColors.color2,
        },
    });
