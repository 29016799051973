import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={319}
            height={144}
            viewBox="0 0 319 144"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                d="M54.16 13.02s68.322-4.092 101.347-4.092c43.555-1.113 109.376 4.093 109.376 4.093 45.185 2.059 47.47 14.303 50.707 32.242 1.064 5.898 2.293 11.869 2.493 17.858.12 3.605.207 8.645.207 15.683v14.965c0 4.168-.502 8.32-1.307 12.41-3.873 19.673-4.957 34.268-43.713 34.975-20.482.773-31.131 2.086-103.848 2.492-93.367-.029-64.464-.407-115.262-2.492S3.023 122.982 1.684 100.873c0 0-1.138-4.72-.932-25.054V54.546C3.426 30.95 2.536 15.403 54.159 13.021z"
                fill="#F5AD5A"
            />
            <Path
                d="M54.16 5.02S122.481.929 155.506.929C199.062-.185 264.883 5.02 264.883 5.02c45.185 2.059 47.47 14.303 50.707 32.242 1.064 5.898 2.293 11.869 2.493 17.858.12 3.605.207 8.645.207 15.683v14.965c0 4.168-.502 8.32-1.307 12.41-3.873 19.673-4.957 34.268-43.713 34.975-20.482.773-31.131 2.086-103.848 2.492-93.367-.029-64.464-.407-115.262-2.492S3.023 114.982 1.684 92.873c0 0-1.138-4.72-.932-25.054V46.546C3.426 22.95 2.536 7.403 54.159 5.021z"
                fill="#fff"
            />
        </Svg>
    );
}

export default SvgComponent;
