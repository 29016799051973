import { mainLayoutOptions } from "../../constants";

export const getLoadingState = ({ app }) => app.isLoading;
export const getPopupState = ({ app }) => app.popup;
export const getNotificationState = ({ app }) => app.notification;
export const getModalState = ({ app }) => app.modal;
export const getScreenColors = ({ app }) => mainLayoutOptions[app.layoutType];
export const getScrollProgress = ({ app }) => app.scrollProgress;
export const getCurrentSubjectId = ({ app }) => app.selectedSubjectID;
export const getCurrentSubjectIndex = ({ app }) => app.selectedSubjectIndex;
export const getPermissions = ({ app }) => app.permissions;
export const getSoundStatus = ({ app }) => app.soundStatus;
