export const COLOURS = {
    black: "#000000",
    bgAbsoluteModals: "rgba(0, 0, 0, 0.8)",
    black1: "#373737",
    brown: "#4D3300",
    brown1: "#673718",
    brown2: "#F99348",
    brown3: "#D36E23",
    brown4: "#B95811",
    brown5: "#F9B786",
    brown6: "#CB6012",
    brown7: "#6A4126",
    brown8: "#603400",
    brown9: "#D05800",
    brown10: "#FFD19B",
    brown11: "#A26842",
    brown12: "#E78035FF",
    grey: "#9eadba",
    grey1: "#707070",
    grey2: "#EADCF6",
    grey3: "#A2A2A2",
    light_grey: "#c5ced6",
    dark_blue: "#4b5c6b",
    purple: "#6558f5",
    purple1: "#725C95",
    purple2: "#7356A7",
    purple3: "#996CD5",
    purple4: "#482772",
    purple5: "#622CAA",
    purple6: "#EEDDFF",
    purple7: "#DFBFFF",
    purple8: "#7E42B7",
    purple9: "#5F3389",
    purple10: "#DDBFF7",
    purple11: "#EAD5FD",
    purple12: "#350F69",
    purple13: "#E0D0EF",
    purple14: "#5F0FAA",
    purple15: "#EFE2FF",
    purple16: "#FFF2F8",
    purple17: "#BE9BE0",
    purple18: "#8144D1",
    purple19: "#FBF7FF",
    purple20: "#FFE9F3",
    purple21: "#DFBEFF",
    purple22: "#926CC4",
    purple23: "#235987",
    purple24: "#a97cdb",
    blackLight: "#20232a",
    dark_purple: "#2C0866",
    light_purple: "#c7c3fb",
    orange: "#e8833a",
    orange1: "#F69253",
    orange2: "#FEA08A",
    orange3: "#EF8537",
    orange4: "#FFC69C",
    lightOrange: "#FFD96B",
    yellow1: "#FFD002",
    yellow2: "#FFDE29",
    yellow3: "#FFEE88",
    yellow4: "#FFF9D5",
    yellow5: "#FCC861",
    yellow6: "#A18B12",
    yellow7: "#846D08",
    yellow8: "#FFB66A",
    yellow9: "#FFF8D3",
    yellow10: "#F8D147",
    lightBlue: "#d5e7f7",
    lightBlue2: "#61dafb",
    lightBlue3: "#CED8E0",
    lightBlue4: "#DCEEFF",
    lightBlue5: "#F9F5FF",
    lightBlue6: "#A0DDFF",
    blue1: "#005A8B",
    blue2: "#0C99FA",
    blue4: "#073763",
    blue3: "#BAE7FF",
    blue5: "#3C517E",
    blue6: "#71BAFF",
    blue7: "#A5D3FF",
    blue8: "#4172A0",
    pastel: "#fae6d8",
    pastel1: "#FFE0CE",
    lightGreen: "#d2e4e1",
    ultraLightGrey: "#e7e4df",
    mainGreen: "#6AA84F",
    green1: "#6DB160",
    green2: "#337357",
    green3: "#ECFFD5",
    green4: "#214D3A",
    green5: "#BAE5D2",
    green6: "#0C5845",
    green7: "#336731",
    green8: "#01A78A",
    green9: "#EAFFE1",
    green10: "#5B9045",
    green11: "#49FF00",
    mainRed: "#F94D67",
    errorRed: "#E93F0D",
    red1: "#94182A",
    red2: "#E4391C",
    red3: "#D63B0E",
    red4: "#7E1121",
    red5: "#B9567F",
    red6: "#91172E",
    red7: "#CE334A",
    red8: "#EF3100",
    red9: "#D84C28",
    mainBlue: "#3D85C6",
    lightPink: "#FFF3F0",
    lightPink1: "#F5F5F5",
    pink: "#EAA8C8",
    pink1: "#FFF3F8",
    pink2: "#F686BD",
    pink3: "#F5ADCB",
    pink4: "#FFEDF5",
    pink5: "#FFD9D0",
    pink6: "#FFB5B5",
    pink7: "#FFE4D3",
    transparent: "transparent",
    white: "#fff",
    quizItemVisited: "#71BAFF",
    quizItemSuccess: "#5AD12A",
    quizItemSuccessShadow: "#257106",
    quizItemSemiError: "#FADE55",
    quizItemError: "#D12A2A",
    quizItemErrorShadow: "#7A1900",
    quizItemChecked: "#115899",
    quizItemCheckedShadow: "#004482",
    turquoise: "#2EC7AB",
};
