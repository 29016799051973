import { Text, TextInput, View } from "react-native";
import { CustomCheckBox } from "../../../../components/CustomCheckBox";
import { COLOURS } from "../../../../constants";
import { useStylesUtils } from "../../../../hooks";
import { EditBtn } from "../components/EditBtn";

export const AddressDetails = () => {
    const { styles, width, imageSizes, height } = useStylesUtils(getStyles);
    return (
        <View style={styles.tab}>
            <View style={styles.inputsContaine}>
                <View style={styles.inputContainer}>
                    <Text style={styles.lable}>Present address:</Text>
                    <TextInput
                        multiline={true}
                        numberOfLines={5}
                        style={styles.input}
                    />
                </View>
                <View style={styles.inputContainer}>
                    <Text style={styles.lable}>Present address:</Text>
                    <TextInput
                        multiline={true}
                        numberOfLines={5}
                        style={styles.input}
                    />
                    <View style={styles.checkboxContainer}>
                        <CustomCheckBox />
                        <Text style={styles.lable}>
                            Same as Present address
                        </Text>
                    </View>
                </View>
            </View>
            <View style={styles.btnContainer}>
                <EditBtn onPress={() => {}} />
            </View>
        </View>
    );
};

const getStyles = ({ height, width }) => ({
    tab: {
        width: width * 0.6,
        height: height * 0.7,
        borderRadius: 25,
        borderWidth: 3,
        borderColor: "#7E1121",
        backgroundColor: "#FFFFFF",
        padding: width * 0.02,
        alignItems: "center",
        justifyContent: "space-between",
    },
    inputsContaine: {
        width: "100%",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
    },

    btnContainer: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "flex-end",
    },

    inputContainer: {
        width: "45%",
    },
    lable: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 5,
        color: COLOURS.blue4,
    },
    input: {
        backgroundColor: COLOURS.lightPink,
        borderRadius: height * 0.02,
        height: height * 0.37,
        fontSize: 17,
        fontWeight: 700,
        color: COLOURS.blue4,
        paddingHorizontal: 7,
        width: "100%",
    },
    checkboxContainer: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingLeft: width * 0.01,
    },
});
