import * as React from "react"
import Svg, { Path, G, Mask } from "react-native-svg"

function SvgComponent(props) {
    return (
        <Svg
            width={394}
            height={524}
            viewBox="0 0 394 524"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <Path
                id="Path 4985"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M378.46 454.783C378.46 454.783 362.341 479.028 380.733 504.526C380.733 504.526 220.033 532.559 46.9145 512.481C46.9145 512.481 17.2207 505.128 18.6606 478.493C18.6606 478.493 18.6916 460.319 39.6688 451.491C60.646 442.661 378.46 454.783 378.46 454.783"
                fill="#A0DDFF"
                style={{}}
            />
            <Path
                id="Path 4978"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44.271 29.9057C35.1171 170.342 35.0946 307.761 44.2027 448.183L44.2139 448.343C44.2317 448.62 44.2495 448.897 44.2672 449.174C43.9998 449.166 43.7332 449.157 43.4648 449.148C42.7924 449.14 42.1462 449.136 41.5243 449.133C40.9556 449.163 40.4703 449.204 40.0316 449.251C40.0242 449.25 40.0129 449.249 40.0064 449.247C38.0041 449.456 36.0467 449.876 34.1828 450.511C32.3217 451.174 30.5551 452.025 28.9877 453.081L28.9606 453.078C28.8624 453.141 28.7426 453.231 28.5949 453.34C28.4004 453.469 28.2788 453.557 28.2273 453.574C27.7775 453.875 27.3323 454.176 26.8825 454.476C26.8283 453.865 26.7731 453.252 26.7179 452.642C15.9143 317.559 14.5695 186.483 22.9929 52.2078C23.0191 52.1002 23.0416 51.9927 23.0668 51.8842C24.0628 49.4452 25.922 46.6002 28.275 43.6534C30.7683 40.5559 33.8386 37.321 37.2045 34.2283C38.1416 33.3632 39.102 32.5477 40.0662 31.6779C41.023 30.8895 41.9572 30.103 42.9102 29.3689C43.4031 28.977 43.8922 28.5852 44.3832 28.1933C44.3448 28.7638 44.3074 29.3352 44.271 29.9057M373.163 446.364C268.069 461.613 161.347 462.931 55.9705 450.314C55.6834 450.28 55.3982 450.246 55.1101 450.211C55.0905 449.906 55.0737 449.601 55.055 449.296C46.4622 306 46.624 165.288 55.5403 21.8975C55.5534 21.6637 55.5703 21.4299 55.5843 21.196C55.805 21.1063 56.0266 21.0165 56.2474 20.9276C56.4596 20.8472 56.6186 20.7752 56.83 20.6948C57.017 20.619 57.2256 20.5657 57.4098 20.4881L57.5417 20.4722C162.203 7.60174 268.18 9.00363 372.555 24.6788C372.851 24.7227 373.142 24.7667 373.437 24.8106C373.461 25.1118 373.484 25.412 373.506 25.7131C384.329 167.056 384.529 304.148 374.114 445.334C374.09 445.634 374.07 445.935 374.048 446.235C373.754 446.279 373.457 446.321 373.163 446.364"
                fill="#A0DDFF"
                visibility="hidden"
            />
            <G id="Mask Group 11">
                <Mask
                    id="mask2_0_1090"
                    style={{
                        maskType: "luminance"
                    }}
                    maskUnits="userSpaceOnUse"
                    x={48}
                    y={11}
                    width={334}
                    height={448}
                >
                    <Path
                        id="Path 4983"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M373.162 446.365C268.069 461.614 161.346 462.932 55.97 450.315C55.6829 450.281 55.3977 450.247 55.1097 450.212C55.09 449.906 55.0732 449.602 55.0545 449.297C46.4617 306.001 46.6235 165.289 55.5398 21.8984C55.5529 21.6646 55.5698 21.4308 55.5838 21.197C55.8045 21.1072 56.0262 21.0175 56.2469 20.9286C56.4592 20.8482 56.6182 20.7762 56.8295 20.6957C57.0166 20.62 57.2251 20.5667 57.4093 20.4891L57.5412 20.4732C162.202 7.60272 268.18 9.00461 372.555 24.6798C372.85 24.7237 373.142 24.7677 373.436 24.8116C373.461 25.1128 373.483 25.413 373.506 25.7141C384.329 167.057 384.528 304.149 374.114 445.335C374.089 445.635 374.07 445.936 374.047 446.236C373.754 446.28 373.456 446.322 373.162 446.365"
                        fill="white"
                    />
                </Mask>
                <G mask="url(#mask2_0_1090)">
                    <Path
                        id="Rectangle 1 copy"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M96.4178 333.766C96.4178 333.766 154.047 326.299 211.403 326.299C274.751 326.299 338.422 333.766 338.422 333.766C363.429 337.336 381.324 361.346 383.881 395.367C383.881 395.367 386.555 433.279 386.555 471.28C386.555 507.923 384.327 626.914 384.327 626.914C381.77 660.936 362.848 687.35 342.433 688.516C332.638 689.673 278.362 692.218 218.089 692.249C157.301 692.281 120.711 691.64 96.4178 688.516C72.1241 685.392 51.598 661.288 50.9583 628.159C50.9583 628.159 48.73 499.453 48.73 458.835C48.73 425.511 50.5126 395.989 50.5126 395.989C51.7915 360.629 71.7301 337.336 96.4178 333.766V333.766Z"
                        fill="#F7E2B4"
                    />
                </G>
            </G>
            <Path
                id="Path 4979"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M56.3267 11.2255C161.325 -3.41528 267.849 -3.4134 372.847 11.2293C376.278 11.7081 379.494 13.5205 381.771 16.1102C381.815 16.1167 381.859 16.1232 381.902 16.1289C384.204 18.7512 385.872 22.089 386.129 25.6101C396.397 166.894 396.397 304.278 386.129 445.562C385.873 449.058 384.213 452.315 381.887 454.913C381.851 454.918 381.816 454.923 381.781 454.928C381.778 454.972 381.774 455.016 381.771 455.061C379.501 457.572 376.277 459.464 372.847 459.942C267.849 474.586 161.324 474.587 56.3267 459.946C52.8702 459.464 49.6726 457.576 47.2617 454.913C47.2654 454.957 47.2701 455.002 47.2719 455.046C44.9442 452.447 43.2767 449.084 43.0167 445.562C32.749 304.277 32.749 166.894 43.0167 25.6101C43.2739 22.089 44.9414 18.7512 47.2719 16.1251C49.6764 13.5168 52.8711 11.7081 56.3267 11.2255M357.756 22.1358C262.676 10.844 166.47 10.844 71.3893 22.1358C62.6993 23.1804 55.1698 31.3121 54.6106 40.1461C46.491 171.299 46.491 299.872 54.6106 431.025C55.1698 439.859 62.7002 447.992 71.3893 449.035C166.469 460.327 262.676 460.327 357.756 449.035C366.448 447.992 373.978 439.859 374.535 431.025C382.656 299.872 382.656 171.299 374.535 40.1461C373.978 31.313 366.448 23.1804 357.756 22.1358"
                fill="#005A8B"
            />
            <Path
                id="Path 4980"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M59.1471 26.7196C53.865 26.6523 46.2374 30.3277 38.8379 35.6771C32.4158 40.4542 26.7596 46.3507 24.7555 50.9595C21.5093 190.448 23.3863 330.092 27.7743 469.644C23.5958 471.905 19.5192 474.014 15.5651 475.983C4.84939 333.048 2.29625 193.23 10.7319 51.0352C10.8666 50.4255 11.0022 49.8148 11.1368 49.205C13.9846 41.6934 21.9536 32.6816 30.4509 25.9387C39.4299 18.7843 49.3077 13.9474 56.4555 13.9502C57.3374 18.2036 58.2343 22.4607 59.1471 26.7196"
                fill="#005A8B"
            />
            <Path
                id="Path 4981"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M176.492 523.724C133.935 523.011 91.4164 520.025 49.1194 514.727C43.047 514.212 37.2561 511.828 31.9441 508.209C26.7948 504.63 22.13 499.751 18.7669 494.189C15.5339 488.655 13.7402 482.703 13.6139 476.981C13.4811 471.126 15.0177 465.47 18.0356 460.208C20.3372 456.336 23.3486 452.92 27.0501 450.007C30.5609 447.133 34.6778 444.878 39.0031 443.62C40.4537 443.117 42.8487 442.753 45.2672 442.361C48.028 441.921 51.0188 441.756 52.6722 441.669C52.892 446.376 53.1511 451.091 53.4438 455.815C52.3664 455.836 48.3974 455.914 46.0042 456.249C43.9542 456.404 42.2455 456.579 41.6414 456.733C39.0087 457.551 36.6127 458.776 34.3186 460.556C32.1602 462.22 30.3674 464.242 28.9131 466.753C27.1409 470.115 26.215 473.805 26.2898 477.538C26.3618 481.268 27.4439 485.051 29.3367 488.462C31.3905 491.989 34.2513 494.906 37.4366 497.001C40.5977 499.089 44.2244 500.511 47.9316 500.715C48.0093 500.722 48.0888 500.729 48.1664 500.737C60.7132 502.878 163.068 508.234 176.384 507.273M176.383 507.273C243.893 507.532 313.166 502.829 380.145 495.839C383.461 495.504 386.054 497.956 385.84 501.31C385.617 504.768 382.684 507.955 379.386 508.447C312.115 518.881 244.233 524.205 176.492 523.724M21.2243 416.702C21.3578 416.689 21.4978 416.678 21.6346 416.666C21.2886 418.282 20.9591 419.894 20.6346 421.504C20.8207 419.906 21.0184 418.305 21.2243 416.702"
                fill="#005A8B"
            />
            <Path
                id="Path 4982"
                d="M45.125 480.81C146.207 490.284 240.045 493.769 355.369 480.81C360.592 480.223 365.763 479.364 370.926 478.594"
                stroke="#005A8B"
                strokeWidth={10}
                strokeLinecap="round"
            />
        </Svg>
    )
}

export default SvgComponent
