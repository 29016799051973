import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent(props) {
    return (
        <Svg
            width={449}
            height={670}
            viewBox="0 0 449 600"
            fill="#FFD19B"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <G clipPath="url(#clip0_3015_89005)">
                <G filter="url(#filter0_d_3015_89005)">
                    <Path d="M71.492 94.16S144.079 83.72 216.324 83.72c79.79 0 159.988 10.442 159.988 10.442 31.498 4.992 54.038 38.568 57.259 86.145 0 0 3.368 53.018 3.368 106.158 0 51.243-2.806 217.645-2.806 217.645-3.221 47.577-11.746 76.205-52.769 86.145-12.337 1.619-80.701 5.177-156.62 5.221-76.566.044-122.653-.853-153.252-5.221-30.6-4.368-56.454-38.076-57.26-84.405 0 0-2.806-179.986-2.806-236.788 0-46.601 2.245-87.885 2.245-87.885 1.61-49.449 26.725-82.023 57.821-87.015z" />
                </G>
                <Path
                    d="M87.802 126.055s63.732-11.899 127.514-14.29c70.443-2.641 141.605 4.2 141.605 4.2 27.978 3.497 49.025 33.286 53.486 76.446 0 0 4.781 48.102 6.593 96.425 1.812 48.322 4.947 198.021 4.947 198.021-1.225 43.373-7.772 69.688-43.654 80.089-10.841 1.876-71.071 7.383-138.097 9.932-67.594 2.571-108.319 3.281-135.479.325-27.168-2.956-51.143-32.753-53.426-74.861 0 0-8.61-163.585-10.548-215.242-1.589-42.378-1.013-79.993-1.013-79.993-.263-45.023 20.8-75.475 48.081-81.043l-.009-.009z"
                    fill="#EBEBEC"
                />
                <Path
                    d="M89.48 115.156s64.132-9.39 127.96-9.39c70.491 0 141.348 9.39 141.348 9.39 27.827 4.489 47.743 34.686 50.583 77.472 0 0 2.976 47.678 2.976 95.464s-2.475 195.732-2.475 195.732c-2.849 42.786-10.378 68.529-46.625 77.472-10.903 1.451-71.297 4.659-138.372 4.695-67.642.036-108.366-.771-135.395-4.695-27.039-3.925-49.88-34.238-50.584-75.904 0 0-2.476-161.861-2.476-212.945 0-41.907 1.984-79.03 1.984-79.03 1.424-44.471 23.613-73.763 51.084-78.252l-.008-.009z"
                    fill="#fff"
                />
                <G
                    clipPath="url(#clip1_3015_89005)"
                    fill="#D3D0D7"
                    fillOpacity={0.45}>
                    <Path d="M371.369 186.852H72.992v2.738H371.37v-2.738zM371.369 238.375H72.992v2.739H371.37v-2.739zM371.369 289.883H72.992v2.738H371.37v-2.738zM371.369 341.383H72.992v2.738H371.37v-2.738zM371.369 392.883H72.992v2.738H371.37v-2.738zM371.369 444.391H72.992v2.738H371.37v-2.738zM371.373 497.353H72.996v2.738h298.377v-2.738z" />
                </G>
                <G filter="url(#filter1_d_3015_89005)">
                    <Path
                        d="M310.011 73.16h-30.063V52.47c0-4.212-3.419-7.606-7.612-7.606h-6.086c-5.032 0-9.634-3.223-10.946-8.058-3.526-12.827-15.289-22.26-29.267-22.26h-3.075c-13.956 0-25.718 9.433-29.267 22.26-1.333 4.835-5.935 8.058-10.945 8.058h-6.086a7.609 7.609 0 00-7.612 7.606v20.69h-30.063c-13.397 0-24.257 10.851-24.257 24.237v16.759c0 5.5 4.473 9.969 9.978 9.969h199.579c5.506 0 9.978-4.469 9.978-9.969v-16.76c0-13.385-10.859-24.235-24.256-24.235zm-85.522-21.163c-4.215 0-7.634-3.416-7.634-7.628 0-4.21 3.419-7.627 7.634-7.627s7.634 3.416 7.634 7.627c0 4.212-3.419 7.628-7.634 7.628z"
                        fill="#EAAB62"
                    />
                </G>
                <Path
                    d="M309.693 73.164H138.671c-7.547 0-14.278 3.438-18.73 8.852h208.482c-4.451-5.393-11.182-8.852-18.73-8.852zM279.652 62.482V52.47c0-4.212-3.419-7.606-7.612-7.606h-6.086c-5.032 0-9.634-3.223-10.945-8.058-3.527-12.827-15.29-22.26-29.267-22.26h-3.075c-13.957 0-25.719 9.433-29.267 22.26-1.334 4.835-5.936 8.058-10.946 8.058h-6.086a7.61 7.61 0 00-7.612 7.606v10.012h110.918-.022zm-55.459-25.719c4.215 0 7.634 3.417 7.634 7.628s-3.419 7.627-7.634 7.627c-4.214 0-7.634-3.416-7.634-7.627s3.42-7.628 7.634-7.628z"
                    fill="#EAAB62"
                />
                <Path
                    d="M224.194 55.456c4.215 0 7.634-3.417 7.634-7.628 0-.601-.086-1.16-.215-1.719-.774 3.374-3.806 5.909-7.419 5.909-3.612 0-6.623-2.514-7.418-5.909a7.679 7.679 0 00-.215 1.72c0 4.21 3.419 7.627 7.633 7.627z"
                    fill="#EAAB62"
                />
                <Path
                    d="M272.02 44.864h-6.085c-5.032 0-9.634-3.223-10.946-8.058-3.527-12.827-15.289-22.26-29.267-22.26h-3.075c-13.956 0-25.719 9.433-29.267 22.26-1.333 4.835-5.935 8.058-10.946 8.058h-6.085a7.61 7.61 0 00-7.613 7.606v3.437c0-4.21 3.419-7.606 7.613-7.606h6.085c5.032 0 9.613-3.223 10.946-8.057 3.527-12.827 15.289-22.26 29.267-22.26h3.075c13.956 0 25.719 9.433 29.267 22.26 1.333 4.834 5.935 8.057 10.946 8.057h6.085a7.61 7.61 0 017.613 7.606V52.47c0-4.212-3.419-7.606-7.613-7.606z"
                    fill="#EAAB62"
                />
            </G>
            <Defs>
                <ClipPath id="clip0_3015_89005">
                    <Path
                        fill="#fff"
                        transform="translate(0 .523)"
                        d="M0 0H449V669H0z"
                    />
                </ClipPath>
                <ClipPath id="clip1_3015_89005">
                    <Path
                        fill="#fff"
                        transform="translate(72.996 176.07)"
                        d="M0 0H352V363H0z"
                    />
                </ClipPath>
            </Defs>
        </Svg>
    );
}

export default SvgComponent;
