import * as React from "react";
import Svg, { Path } from "react-native-svg";

function PhotoIcon(props) {
    return (
        <Svg
            width={62}
            height={44}
            viewBox="0 0 62 44"
            fill="#E9DCF5"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path d="M42.364 25.532c-.047 5.892-5.406 11.277-11.299 11.316-5.892-.04-11.25-5.424-11.298-11.316.047-5.895 5.405-11.276 11.298-11.315 5.893.039 11.252 5.42 11.299 11.315z" />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M55.768 7.782c-3.65-.163-7.302-.29-10.957-.381-.8-1.311-1.6-2.625-2.395-3.94-.863-1.672-2.539-2.844-4.43-2.878a384.034 384.034 0 00-14.019 0v.012c-1.645.03-3.275.948-4.242 2.556a1170.84 1170.84 0 01-2.53 4.161l-.06.089c-3.65.09-7.3.218-10.946.38-2.725.13-5.079 2.462-5.177 5.152-.294 8.32-.194 16.61.303 24.951.166 2.694 2.54 5.087 5.225 5.266a384.002 384.002 0 0048.877 0c2.68-.179 5.056-2.572 5.222-5.266.496-8.341.597-16.63.302-24.95-.098-2.69-2.452-5.022-5.173-5.152m-24.791 32.22c-7.536-.016-13.936-6.643-14.005-14.458-.032-7.814 6.369-14.367 14.005-14.386 7.636.02 14.037 6.572 14.004 14.386-.068 7.815-6.47 14.442-14.004 14.458m26.377-23.758c-2.639-.052-5.28-.097-7.921-.133a434.113 434.113 0 00-.065-3.732c2.633.059 5.263.13 7.893.213.038 1.218.07 2.435.093 3.652"
            />
        </Svg>
    );
}

export default PhotoIcon;
