import React from "react";
import { Text, View } from "react-native";
import { CarrouselComponent, MainLayout } from "../../../components";
import { PdfDownloadCard } from "../../../components/carrouselCards/pdfDownloadCard";
import { COLOURS } from "../../../constants";
import { useStylesUtils } from "../../../hooks";

export const AcademicPlannerScreen = () => {
    const { styles } = useStylesUtils(getStyles);

    const list = [
        { id: 1, title: "Science" },
        { id: 2, title: "Maths" },
        { id: 3, title: "English" },
    ];

    return (
        <MainLayout
            headerProps={{
                backButton: true,
                notificationButton: true,
                searchButton: true,
                titleContent: (
                    <Text style={styles.titleContent}>Academic Planner</Text>
                ),
            }}>
            <View
                style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                <View style={styles.titleWrapper}>
                    <Text style={styles.title}>
                        Please select month to proceed
                    </Text>
                    <Text style={styles.subTitle}>Month:</Text>
                </View>
                <CarrouselComponent data={list} CardView={PdfDownloadCard} />
            </View>
        </MainLayout>
    );
};

const getStyles = ({ height }) => ({
    titleContent: {
        paddingTop: height * 0.1,
        fontSize: 60,
        lineHeight: 11,
        textAlign: "center",
        fontWeight: 700,
        color: "#fff",
        textShadowColor: "#000",
        textShadowOffset: { height: 0, width: 0 },
        textShadowRadius: 3,
    },
    titleWrapper: {
        width: "100%",
        alignItems: "center",
    },
    title: {
        fontSize: 30,
        fontWeight: 700,
        color: COLOURS.dark_purple,
    },
    subTitle: {
        fontSize: 20,
        fontWeight: 700,
        color: COLOURS.dark_purple,
    },
});
