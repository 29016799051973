import React, { useMemo } from "react";
import { TouchableOpacity, View } from "react-native";
import { COLOURS } from "../../../../../constants";
import { useStylesUtils } from "../../../../../hooks";
import { QuizOptionMarker } from "./QuizOptionMarker";
import { MathJaxView, Image } from "../../../../../components";

export const QuizOption = ({
    text,
    image,
    status,
    isSelected,
    onOptionPick,
    isFinished,
}) => {
    const { styles } = useStylesUtils(getStyles(!!image, isSelected));

    const { bgColor, bgColor1, textColor } = useMemo(() => {
        const textColor = COLOURS.white;
        const isImage = !!image;

        switch (status) {
            case "option_success":
                return {
                    bgColor: isImage
                        ? COLOURS.quizItemSuccess
                        : COLOURS.quizItemSuccessShadow,
                    bgColor1: COLOURS.quizItemSuccess,
                    textColor,
                };
            case "option_checked":
                return {
                    bgColor: isImage
                        ? COLOURS.quizItemChecked
                        : COLOURS.quizItemCheckedShadow,
                    bgColor1: COLOURS.quizItemChecked,
                    textColor,
                };
            case "option_error":
                return {
                    bgColor: isImage
                        ? COLOURS.quizItemError
                        : COLOURS.quizItemErrorShadow,
                    bgColor1: COLOURS.quizItemError,
                    textColor,
                };

            default:
                return {
                    bgColor: COLOURS.yellow1,
                    bgColor1: COLOURS.white,
                    textColor: COLOURS.black,
                };
        }
    }, [status, image]);

    return (
        <TouchableOpacity
            activeOpacity={isFinished ? 1 : 0.2}
            style={[styles.option, { backgroundColor: bgColor }]}
            onPress={onOptionPick}>
            {image ? (
                <View style={styles.imageWrapper}>
                    <Image style={styles.qImage} source={image} />
                    {isSelected && (
                        <QuizOptionMarker status={status} inversion={true} />
                    )}
                </View>
            ) : (
                <View
                    style={[styles.textWrapper, { backgroundColor: bgColor1 }]}>
                    {text && <MathJaxView html={text} textColor={textColor} />}
                    {isSelected && <QuizOptionMarker status={status} />}
                </View>
            )}
        </TouchableOpacity>
    );
};

const getStyles =
    (isImage, isSelected) =>
    ({ width, biggerSize }) => ({
        option: {
            alignSelf: "stretch",
            borderRadius: 15,
            width: isImage ? "auto" : "48%",
            marginBottom: isImage ? 0 : biggerSize * 0.01,
            padding: isImage && isSelected ? biggerSize * 0.005 : 0,
            paddingBottom:
                isImage && isSelected ? biggerSize * 0.015 : biggerSize * 0.005,
        },
        textWrapper: {
            width: "100%",
            height: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: 15,
            overflow: "hidden",
            paddingVertical: biggerSize * 0.015,
            paddingHorizontal: biggerSize * 0.01,
        },
        oText: {
            fontWeight: 700,
            fontSize: 15,
        },
        imageWrapper: {
            backgroundColor: COLOURS.white,
            borderRadius: 15,
            paddingVertical: biggerSize * 0.005,
            paddingHorizontal: biggerSize * 0.01,
        },
        qImage: {
            width: biggerSize * 0.1,
            height: biggerSize * 0.1,
        },
    });
