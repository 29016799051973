import { useDispatch } from "react-redux";
import { openNotification, closeNotification } from "../store/app/slice";

export const useNotification = () => {
    const dispatch = useDispatch();
    const openNotificationLayout = () => {
        dispatch(openNotification(<></>));
    };
    const closeNotificationLayout = () => {
        dispatch(closeNotification());
    };

    return {
        openNotificationLayout,
        closeNotificationLayout,
    };
};
