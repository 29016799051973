import React from "react";
import { Image, Text, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { InfoIcon } from "../../../assets/svg";
import { Button, DashedLine } from "../../../components";
import { CustomScrollView } from "../../../components/CustomScrollView";
import { COLOURS } from "../../../constants";
import { useStylesUtils } from "../../../hooks";
import { ClassActivityItem } from "./ClassActivityItem";

export const ContentContainer = ({ list = [], editMode, toggleEditMode }) => {
    const insets = useSafeAreaInsets();
    const { styles, height } = useStylesUtils(getStyles(insets));

    return list.length ? (
        <>
            <View style={styles.listView}>
                <CustomScrollView
                    showIndicator={true}
                    contentContainerStyle={styles.scrollContentStyle}
                    scrollIndicatorBGStyles={styles.scrollIndicatorBGStyles}>
                    <DashedLine viewStyle={styles.line} />
                    {list.map((item, index, all) => (
                        <ClassActivityItem
                            key={"classActivity" + item.id}
                            index={index}
                            data={item}
                            last={all.length - 1 === index}
                            editMode={editMode}
                        />
                    ))}
                </CustomScrollView>
            </View>
            {editMode && (
                <View style={styles.footerButtons}>
                    <Button label={"Acknowledge"} iconProps={{ ratio: 0.11 }} />
                    <Button
                        label={"Cancel"}
                        iconProps={{ ratio: 0.11 }}
                        style={{ marginLeft: 20 }}
                        fillColor={COLOURS.purple17}
                        onPress={toggleEditMode}
                    />
                </View>
            )}
        </>
    ) : (
        <View style={styles.container}>
            <View style={styles.board}>
                <Image
                    style={styles.image}
                    source={require("../../../assets/images/diaryIcons/girl.png")}
                    resizeMode={"contain"}
                />
                <View style={styles.boardContent}>
                    <InfoIcon height={height * 0.15} />
                    <Text style={styles.boardContentText}>
                        Looks like!{"\n"} There is no{"\n"} class activity for
                        Today
                    </Text>
                </View>
                <Image
                    style={styles.image}
                    source={require("../../../assets/images/diaryIcons/boy.png")}
                    resizeMode={"contain"}
                />
            </View>
        </View>
    );
};

const getStyles =
    (insets) =>
    ({ width, height }) => ({
        container: {
            flex: 1,
            zIndex: 1,
            width: "100%",
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 10,
        },
        board: {
            flex: 1,
            backgroundColor: COLOURS.purple13,
            borderRadius: 20,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
        },
        image: {
            width: width * 0.2,
            height: width * 0.2,
        },
        boardContent: {
            flex: 1,
            alignItems: "center",
        },
        boardContentText: {
            fontSize: 34,
            fontWeight: 700,
            color: COLOURS.purple8,
            textAlign: "center",
            marginTop: height * 0.03,
        },
        listView: {
            flex: 1,
            paddingHorizontal: width * 0.05,
            height: "100%",
        },
        scrollContentStyle: {
            paddingBottom: 20,
        },
        scrollIndicatorBGStyles: {
            right: width * 0.02,
        },
        indStyle: {
            backgroundColor: COLOURS.purple17,
            borderRadius: 0,
            zIndex: 10,
        },
        footerButtons: {
            marginBottom: -insets.bottom,
            left: -insets.left,
            width: width,
            paddingBottom: insets.bottom + 10,
            padding: height * 0.03,
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: COLOURS.purple18,
        },
        line: {
            position: "absolute",
            transform: [{ scale: 1 }],
            height: "100%",
            left: "8.5%",
        },
    });
