import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={63}
            height={58}
            viewBox="0 0 63 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M56.485 11.996c8.906 12.942 7.846 31.068-5.095 39.975-12.941 8.906-37.73 6.279-46.637-6.663-8.906-12.941-3.097-30.729 9.844-39.635C27.54-3.233 47.578-.945 56.485 11.996z"
                fill="#A0DDFF"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M45.442 13L27.224 8.46c-1.043-.26-1.443.28-.893 1.2l4.493 7.469c-7.118 3.683-17.643 12.18-9.65 28.357 0 0 2.562-13.4 15.028-19.403l4.643 7.725c.55.916 1.217.822 1.478-.225l4.542-18.216A1.959 1.959 0 0045.442 13"
                fill="#13416A"
            />
        </Svg>
    );
}

export default SvgComponent;
