import { Iframe } from "@bounceapp/iframe";
import React, { useEffect, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-native";
import { BackArrowIcon } from "../../assets/svg";
import { useStylesUtils } from "../../hooks";
import { startLoading, stopLoading } from "../../store/app/slice";
import { deleteAllCookies, cookiesSeter } from "../../utils/cookiesUtil";

export const WebStoryScreen = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const navigate = useNavigate();

    const { styles, imageSizes } = useStylesUtils(getStyles);

    const [isCookies, setIsCookies] = useState(false);

    const navigateBack = () => {
        deleteAllCookies();
        navigate(-1);
    };

    const setCookieJs = (state) => {
        const { policy, keyPairId, signature, hostname } = state;
        cookiesSeter(policy, keyPairId, signature);
    };

    const onLoadEnd = (loadEvent) => {
        dispatch(stopLoading());
    };

    useEffect(() => {
        dispatch(startLoading());
    }, []);

    useEffect(() => {
        setCookieJs(state);

        setTimeout(() => {
            setIsCookies(true);
        }, 300);
    }, [state]);
    return (
        <View style={{ flex: 1 }}>
            {state.uri && isCookies && (
                <Iframe
                    uri={state.uri}
                    style={{ flex: 1 }}
                    onLoadEnd={onLoadEnd}
                />
            )}
            <TouchableOpacity style={styles.backButton} onPress={navigateBack}>
                <BackArrowIcon {...imageSizes.standard} />
            </TouchableOpacity>
        </View>
    );
};

const getStyles = {
    container: {
        flex: 1,
    },
    backButton: {
        position: "absolute",
        zIndex: 100,
        top: 10,
        left: 0,
        marginTop: 10,
        marginLeft: 20,
    },
};
