import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
    return (
        <Svg
            width={130}
            height={120}
            viewBox="0 0 130 120"
            fill="#073763"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.712 0c33.604 0 74.622 19.205 74.622 52.81 0 33.603-30.685 66.585-64.29 66.585-33.603 0-64.29-32.981-64.29-66.585C.755 19.205 21.109 0 54.713 0z"
                fill="#fff"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M53.755 7c29.27 0 65 16.729 65 46s-26.73 58-56 58-56-28.729-56-58 17.73-46 47-46z"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M88.747 75.959c-3.108-4.76-7.334-9.128-12.712-12.768 2.257-3.489 3.533-7.037 3.702-11.489C77.991 35.68 68.13 29.786 55.811 29.786c-12.32 0-22.315 10.023-22.341 22.341-.026 12.379 9.964 22.471 22.34 22.342 4.093-.043 8.351-.42 11.654-2.349 4.141 5.567 7.958 9.591 12.386 12.115a5.196 5.196 0 003.686 1.526 5.196 5.196 0 003.685-1.526c2.035-2.035 3.562-6.241 1.526-8.276zM44.603 51.75c0-6.308 5.203-10.505 11.441-11.44 5.244-.787 10.81 2.922 11.441 11.44 1.87 6.026-5.132 11.441-11.44 11.441-6.31 0-11.442-5.132-11.442-11.44z"
                fill="#fff"
            />
        </Svg>
    )
}

export default SvgComponent
