import { Platform } from "react-native";

// cDM
if (Platform.OS === "web" && document) {
    const rnwStyleSheet = document.getElementById("react-native-stylesheet");
    if (rnwStyleSheet) {
        rnwStyleSheet.sheet.insertRule(
            `
            ::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
                background-color: rgba(255,255,255,0.5);
                cursor: pointer;
            }`,
            0
        );
        rnwStyleSheet.sheet.insertRule(
            `
            ::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: #BE9BE0;
                -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
            }
`,
            0
        );
    }
}
