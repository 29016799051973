import { AddonButton } from "../../../components/layout/Header/addonButton";
import { navigation, COLOURS } from "../../../constants";
import { Image, Text } from "react-native";
import React from "react";
import { useStylesUtils } from "../../../hooks";
import { useNavigate } from "react-router-native";

export const LeftBlockContent = () => {
    const navigate = useNavigate();
    const { biggerSize, styles } = useStylesUtils(getStyles);

    return (
        <>
            <AddonButton
                style={styles.addonButton}
                onPress={() => navigate(navigation.HOME_WORK_SCREEN)}>
                <Image
                    style={{
                        height: biggerSize * 0.05,
                        width: biggerSize * 0.05,
                    }}
                    source={require("../../../assets/images/diaryIcons/homeWorkIconBtn.png")}
                    resizeMode={"contain"}
                />
                <Text style={[styles.addonButtonText, { color: COLOURS.red5 }]}>
                    {"Home \nWork"}
                </Text>
            </AddonButton>
            <AddonButton
                style={styles.addonButton}
                onPress={() => navigate(navigation.TEST_SCREEN)}>
                <Image
                    style={{
                        height: biggerSize * 0.05,
                        width: biggerSize * 0.05,
                    }}
                    source={require("../../../assets/images/main/testIcon.png")}
                    resizeMode={"contain"}
                />
                <Text
                    style={[styles.addonButtonText, { color: COLOURS.brown4 }]}>
                    Test
                </Text>
            </AddonButton>
        </>
    );
};

const getStyles = {
    addonButtonText: {
        flex: 1,
        fontSize: 20,
        fontWeight: 700,
        textAlign: "center",
        textShadowOffset: { height: 0, width: 0 },
        textShadowRadius: 2,
        textShadowColor: COLOURS.red5,
    },
    addonButton: {
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
    },
};
