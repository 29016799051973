import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { MicrophoneSpoken } from "../../../assets/svg";
import { MainLayout } from "../../../components";
import { COLOURS } from "../../../constants";
import { usePermissions, useStylesUtils } from "../../../hooks";
import { SearchField } from "./searchField";

export const SearchScreen = () => {
    const { styles, width } = useStylesUtils(getStyles);

    const { isAccessToMicrophone, requestForMicrophoneAccess } =
        usePermissions();

    const [recording, setRecording] = useState(true);
    const [searchInfo, setSearchInfo] = useState("");

    useEffect(() => {
        if (!isAccessToMicrophone) {
            requestForMicrophoneAccess();
        }
    }, [recording]);

    return (
        <MainLayout
            headerProps={{
                showBgCloud: true,
                backButton: true,
            }}>
            <View style={styles.contentContainer}>
                {recording ? (
                    <>
                        {isAccessToMicrophone && (
                            <>
                                <Text style={styles.recordText}>
                                    Actual spoken text…
                                </Text>
                                <MicrophoneSpoken
                                    width={width * 0.6}
                                    height={(width * 0.6) / 2.83}
                                />
                                <Text style={styles.recordText}>
                                    Listening…
                                </Text>
                            </>
                        )}
                    </>
                ) : (
                    <Text>Content</Text>
                )}
            </View>
            <SearchField
                recording={recording}
                pressOnMicrophone={() => setRecording((prev) => !prev)}
                setValue={setSearchInfo}
                value={searchInfo}
            />
        </MainLayout>
    );
};

const getStyles = ({ height, width }) => ({
    contentContainer: {
        flex: 1,
        height: "100%",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    recordText: {
        fontSize: 40,
        fontWeight: 700,
        color: COLOURS.brown,
    },
});
