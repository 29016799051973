import React from "react";
import { Image, Text } from "react-native";
import { useStylesUtils } from "../hooks/useStylesUtils";

export const PlaylistTitleContent = ({ title, image }) => {
    const { styles } = useStylesUtils(getStyles);
    return (
        <>
            <Image style={styles.image} source={image} resizeMode="contain" />
            <Text style={styles.text}>{title}</Text>
        </>
    );
};

const getStyles = ({ width, height, OS }) => ({
    image: {
        width: width * 0.2,
        height: height * 0.3,
    },
    text: {
        height: height * 0.3,
        paddingTop: height * 0.3 * 0.3,
        fontSize: 60,
        lineHeight: 23,
        textAlign: "center",
        fontWeight: 700,
        color: "#fff",
        textShadowColor: "#000",
        textShadowOffset: { height: 0, width: 0 },
        textShadowRadius: 3,
        verticalAlign: "middle",
    },
});
