import { View, TextInput, Text } from "react-native";
import { COLOURS } from "../../../../constants";
import { useStylesUtils } from "../../../../hooks";

export const ProfileInput = ({
    lable = "lable",
    value,
    defaultValue,
    editable = true,
    inputWidth,
    margins = {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        marginBottom: 0,
    },
}) => {
    const { styles, width } = useStylesUtils(getStyles);
    return (
        <View
            style={{
                ...styles.container,
                width: inputWidth,
                ...margins,
            }}>
            <Text style={{ ...styles.lable }} numberOfLines={5}>
                {lable}
            </Text>
            <TextInput style={{ ...styles.input }} editable={editable} />
        </View>
    );
};

const getStyles = ({ height, width }) => ({
    container: {
        alignItems: "flex-start",
    },
    lable: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 5,
        color: COLOURS.blue4,
    },
    input: {
        backgroundColor: COLOURS.lightPink,
        borderRadius: height * 0.02,
        height: height * 0.08,
        fontSize: 17,
        fontWeight: 700,
        color: COLOURS.blue4,
        paddingHorizontal: 7,
        width: "100%",
    },
});
