import { ImageBackground, View, Text } from "react-native";
import { useStylesUtils } from "../hooks";
import { COLOURS, images } from "../constants";
import { useEffect, useState } from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export const TimerComponent = ({
    countdownDuration,
    onDone,
    remainTime = () => {},
}) => {
    const insets = useSafeAreaInsets();
    const { styles, width } = useStylesUtils(getStyles(insets));

    const [remainingTime, setRemainingTime] = useState(
        Math.max(countdownDuration, 0)
    );
    const [isRunning, setIsRunning] = useState(true);
    const [startTime] = useState(Date.now());

    useEffect(() => {
        if (remainTime && typeof remainTime === "function") {
            remainTime(remainingTime);
        }
    }, [remainingTime]);

    useEffect(() => {
        let interval = null;

        if (isRunning) {
            interval = setInterval(() => {
                if (remainingTime > 0) {
                    const now = Date.now();
                    const spent = now - startTime;
                    const rem = Math.round(countdownDuration - spent);
                    setRemainingTime(rem);
                } else {
                    setRemainingTime(0);

                    if (onDone) {
                        onDone();
                    }
                    clearInterval(interval);
                }
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isRunning, remainingTime]);

    const formatTime = (time, type = "m") => {
        const minutes = Math.floor(time / 60000);
        const seconds = Math.floor((time % 60000) / 1000);
        if (type === "m") {
            return minutes.toString().padStart(2, "0");
        } else {
            return seconds.toString().padStart(2, "0");
        }
    };

    // const handleStart = () => {
    //     setIsRunning(true);
    //     if (onStart) {
    //         onStart();
    //     }
    // };
    //
    // const handleStop = () => {
    //     setIsRunning(false);
    //     if (onStop) {
    //         onStop();
    //     }
    // };
    //
    // const handleReset = () => {
    //     setRemainingTime(countdownDuration);
    //     if (onReset) {
    //         onReset();
    //     }
    // };

    return (
        <View style={styles.container}>
            <ImageBackground
                source={images.timerBg}
                resizeMode="stretch"
                style={styles.bg}>
                <Text style={styles.text}>
                    {formatTime(remainingTime, "m")}
                </Text>
            </ImageBackground>
            <Text style={styles.separator}>:</Text>
            <ImageBackground
                source={images.timerBg}
                resizeMode="stretch"
                style={styles.bg}>
                <Text style={styles.text}>
                    {formatTime(remainingTime, "s")}
                </Text>
            </ImageBackground>
        </View>
    );
};

const getStyles =
    (insets) =>
    ({ biggerSize }) => ({
        container: {
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            // paddingRight: insets?.right || 0,
        },
        bg: {
            width: biggerSize * 0.06,
            height: biggerSize * 0.07,
            justifyContent: "center",
            alignItems: "center",
        },
        separator: {
            height: biggerSize * 0.07,
            color: COLOURS.orange3,
            fontWeight: 700,
            fontSize: 50,
            textAlign: "center",
        },
        text: {
            color: COLOURS.white,
            fontWeight: 700,
            fontSize: 40,
            textAlign: "center",
        },
    });
