import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Image, Keyboard, Text, View } from "react-native";
import { useLocation } from "react-router-dom";
import { AuthLayout } from "../../../components";
import { COLOURS, images, navigation } from "../../../constants";
import { usePopups, useStylesUtils } from "../../../hooks";
import { useAuth } from "../../../hooks/useAuth";
import { parsePhoneNumber } from "../../../utils/validators";
import { Button, Input } from "../components";
import { authProductId, authTenantId } from "../../../api/constants";

export const EnterRegisteredPhone = () => {
    const { styles } = useStylesUtils(getStyles);

    const { getUidByPhone, generateOtp } = useAuth();
    const { selectYourAdmission, emptyAdmissionList } = usePopups();
    const { state } = useLocation();

    const [phoneNumber, setPhoneNumber] = useState("+91-");
    const [phoneNumberValid, setPhoneNumberValid] = useState(true);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const phoneParsed = useMemo(() => {
        return parsePhoneNumber(phoneNumber);
    }, [phoneNumber]);
    useEffect(() => {
        setPhoneNumberValid(phoneParsed.valid);
    }, [phoneNumber]);

    const onSelect = async (body) => {
        await generateOtp(
            {
                phone: phoneParsed.phone,
                isd_code: phoneParsed.isd,
                admission_number: body?.uid,
                tenant_id: authTenantId,
                product_id: authProductId,
            },
            navigation.ENTER_VERIFY_OTP
        );
    };
    const pressSubmit = useCallback(async () => {
        Keyboard.dismiss();
        if (!phoneParsed.valid) {
            return;
        }

        if (state?.getAdmissionNumbers) {
            const admissionNumberList = await getUidByPhone(phoneParsed);
            if (admissionNumberList.length) {
                selectYourAdmission(admissionNumberList, onSelect);
            } else {
                setPhoneNumberError(true);
                emptyAdmissionList(phoneParsed.phone);
            }
        }
    }, [phoneNumber]);

    const changeNumber = (value) => {
        if (/^\+91-[0-9]{0,10}$/.test(value)) {
            setPhoneNumber((prev) => (value.length < 4 ? prev : value));
        }
    };

    return (
        <AuthLayout headerProps={{ backButton: true }}>
            <Text style={styles.label}>
                Please enter your registered phone number to proceed
            </Text>
            <View style={styles.formContainer}>
                <Input
                    label={"User ID"}
                    style={styles.input}
                    inputState={{
                        value: phoneNumber,
                        setValue: changeNumber,
                        isError: phoneNumberError,
                    }}
                />
                <Button
                    label={"Submit"}
                    onPress={pressSubmit}
                    style={{ marginLeft: 20 }}
                    disabled={!phoneNumberValid}
                />
            </View>
            {phoneNumberError && (
                <View style={styles.formContainer}>
                    <View style={styles.flexRow}>
                        <Image
                            style={styles.errorCodeIcon}
                            source={images.importantIcon}></Image>
                        <Text style={styles.errorCodeText}>
                            Incorrect phone number
                        </Text>
                    </View>
                </View>
            )}
        </AuthLayout>
    );
};

const getStyles = {
    title: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        marginBottom: 10,
        fontSize: 30,
    },
    label: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        marginBottom: 10,
        fontSize: 16,
    },
    formContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 10,
    },
    forgotCodeContainer: {
        marginLeft: -90,
        justifyContent: "center",
    },
    forgotCodeText: {
        fontWeight: 700,
        color: COLOURS.purple,
        fontSize: 12,
    },
    flexRow: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    errorCodeText: {
        fontWeight: 700,
        color: COLOURS.errorRed,
        fontSize: 12,
        marginBottom: 10,
    },
    errorCodeIcon: {
        resizeMode: "contain",
        height: 15,
        width: 15,
        marginBottom: 10,
        marginRight: 5,
    },
};
