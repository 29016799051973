import { projectType } from "../api/constants";

export const uamToCmsGradeId = (UAMExamId, UAMGradeId) => {
    const staging = {
        2: 1,
        1: 2,
        5: 3,
        10: 5,
        11: 4,
        8: 9,
    };

    const preProd = {
        2: 1,
        1: 2,
        5: 3,
        6: {
            3: 12,
            2: 11,
            1: 10,
            9: 9,
            15: 8,
            14: 7,
            13: 6,
        },
        7: {
            1: 28,
            9: 29,
            15: 30,
            14: 31,
            13: 32,
        },
        8: {
            1: 23,
            9: 24,
            15: 25,
            14: 26,
            13: 27,
        },
    };

    const dev = {
        2: 1,
        1: 2,
        5: 3,
        9: 5,
        10: 4,
    };

    const defaultConfig = {
        6: {
            16: 1,
            17: 2,
            10: 3,
            11: 4,
            12: 5,
            18: 14,
            19: 15,
            20: 16,
        },
        7: {
            16: 1,
            17: 2,
            10: 3,
            11: 4,
            12: 5,
            18: 14,
            19: 15,
            20: 16,
        },
        8: {
            16: 1,
            17: 2,
            10: 3,
            11: 4,
            12: 5,
            18: 14,
            19: 15,
            20: 16,
        },
    };

    switch (projectType) {
        case "Staging":
            return staging[UAMExamId] || UAMExamId;
        case "PreProd":
            if (preProd[UAMExamId]) {
                return typeof preProd[UAMExamId] === "number"
                    ? preProd[UAMExamId]
                    : preProd[UAMExamId][UAMGradeId] || UAMGradeId;
            } else {
                return UAMExamId;
            }
        case "Dev":
            return dev[UAMExamId] || UAMExamId;
        default:
            if (defaultConfig[UAMExamId]) {
                return typeof defaultConfig[UAMExamId] === "number"
                    ? defaultConfig[UAMExamId]
                    : defaultConfig[UAMExamId][UAMGradeId] || UAMGradeId;
            } else {
                return UAMExamId;
            }
    }
};
