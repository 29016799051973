import * as React from "react";
import Svg, { Path } from "react-native-svg";

/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent(props) {
    return (
        <Svg
            width={166}
            height={154}
            viewBox="0 0 166 154"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                d="M70.376 3c39.919 0 88.646 22.815 88.646 62.734s-36.453 79.098-76.371 79.098c-39.92 0-76.372-39.179-76.372-79.098C6.28 25.814 30.457 3 70.376 3z"
                fill="#fff"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M69.239 11.315c34.771 0 77.215 19.873 77.215 54.645 0 34.772-31.752 68.9-66.524 68.9-34.771 0-66.524-34.128-66.524-68.9 0-34.772 21.061-54.645 55.833-54.645z"
                fill="#0A3056"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M61.592 62.51c5.705 0 10.346-4.64 10.346-10.345 0-5.705-4.641-10.346-10.346-10.346-5.704 0-10.345 4.641-10.345 10.346 0 5.704 4.641 10.345 10.345 10.345zM96.195 58.488c6.22 0 11.28-5.06 11.28-11.279 0-6.22-5.06-11.28-11.28-11.28s-11.279 5.06-11.279 11.28 5.06 11.28 11.28 11.28zM123.391 76.548l-11.506-12.219a10.034 10.034 0 00-7.276-3.142H87.781a10.033 10.033 0 00-7.276 3.142l-2.111 2.202-1.774 1.838-1.023-1.1a7.035 7.035 0 00-5.134-2.236h-17.74c-1.94 0-3.812.815-5.134 2.236L37.074 78.57a3.42 3.42 0 00.007 4.653 3.424 3.424 0 004.63.323l7.46-6.021a1.275 1.275 0 012.056 1.223l-5.095 27.75h22.982v-5.875c0-.347-.14-.678-.39-.918l-9.983-9.617a3.567 3.567 0 01-.316-4.812 3.558 3.558 0 012.408-1.328 3.558 3.558 0 012.64.77l7.602 6.124c.047.037.095.07.144.099a11.696 11.696 0 007.333 2.575c2.771 0 5.321-.965 7.333-2.575.05-.03.098-.062.144-.1l7.603-6.122a3.558 3.558 0 012.64-.77c.953.105 1.809.576 2.407 1.327a3.567 3.567 0 01-.316 4.812l-9.983 9.617c-.25.24-.39.571-.39.918v5.875h19.484V75.374a1.276 1.276 0 012.104-.97l8.741 7.467a3.668 3.668 0 004.947-.149 3.695 3.695 0 00.125-5.174M78.552 90.966c-5.074 0-9.203-4.129-9.203-9.203 0-5.075 4.129-9.203 9.203-9.203 5.075 0 9.203 4.128 9.203 9.203 0 5.074-4.128 9.203-9.203 9.203z"
                fill="#fff"
            />
        </Svg>
    );
}

export default SvgComponent;
