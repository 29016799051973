import { ResizeMode } from "expo-av";
import VideoPlayer from "expo-video-player";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Platform, Pressable, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-native";
import { BackArrowIcon } from "../../../assets/svg";
import { MainLayout } from "../../../components";
import { COLOURS } from "../../../constants";
import { useHaptic, useStylesUtils } from "../../../hooks";
import { getVideoPlaylistRedux } from "../../../store/main/selectors";
import { parseListsUtils } from "../../../utils/parse-lists.utils";
import { Playlist } from "./playlist";

const { OS } = Platform;

export const PlaylistScreen = () => {
    const playerRef = useRef(null);

    const insets = useSafeAreaInsets();
    const { state } = useLocation();
    const navigate = useNavigate();
    const { hapticImpact } = useHaptic();

    const playList = useSelector(getVideoPlaylistRedux);

    const getActiveVideo = () => {
        const active = state?.activeVideo;
        const list = playList?.data?.videos;

        if (active) {
            return active;
        } else if (list?.length > 0) {
            return {
                url: list[0]?.url,
                index: 0,
                image: list[0]?.image,
            };
        }

        return null;
    };

    const [activeVideo, setActiveVideo] = useState(getActiveVideo());
    const [isFullscreen, setIsFullscreen] = useState(true);

    const parseStyles = useCallback(
        ({ width, isWeb, height }) => {
            const borderWidth = isFullscreen ? 0 : width * 0.011;

            const fixWidth = width * 0.4;
            const fixHeight = width * 0.2;

            const video = {
                position: isFullscreen ? "absolute" : "static",
                backgroundColor: COLOURS.black,
                borderRadius: isFullscreen ? 0 : 20,
                borderStyle: "solid",
                marginBottom: isFullscreen ? 0 : 20,
                width: isFullscreen ? width : fixWidth + borderWidth * 2,
                height: isFullscreen ? height : fixHeight + borderWidth * 2,
                borderWidth,
                left: isFullscreen ? -insets.left : 0,
                top: isFullscreen ? -insets.top : 0,
                right: isFullscreen ? -insets.right : 0,
                bottom: isFullscreen ? -insets.bottom : 0,
                paddingHorizontal: isFullscreen && !isWeb ? width * 0.03 : 0,
            };

            const player = {};

            if (!isFullscreen) {
                player.height = fixHeight;
                player.width = fixWidth;
            } else {
                player.height = height;
                if (isWeb) {
                    player.width = width;
                }
            }

            return getStyles({
                isFullscreen,
                video,
                player,
                width,
                isWeb,
            });
        },
        [isFullscreen, insets]
    );

    const { styles, imageSizes } = useStylesUtils(parseStyles);

    const clickBack = useCallback(() => {
        if (isFullscreen) {
            setIsFullscreen(false);
        } else {
            hapticImpact(1);
            navigate(-1);
        }
    }, [isFullscreen]);

    const selectedItemIndex = useMemo(
        () =>
            playList?.data?.videos.findIndex(
                (item) => item.url === activeVideo?.url
            ),
        [playList, activeVideo]
    );

    const parseList = useMemo(() => {
        return parseListsUtils(playList?.data?.videos, (item, index) => ({
            isSelected: index === selectedItemIndex,
        }));
    }, [playList, selectedItemIndex]);

    const playNextVideo = async () => {
        const nextVideo = parseList[selectedItemIndex + 1];

        if (nextVideo) {
            await playerRef.current.setPositionAsync(0);
            setActiveVideo({
                url: nextVideo?.url,
                image: nextVideo?.image,
            });
            await playerRef.current.playAsync();
        }
    };

    const onVideoEnd = (e) => {
        const { didJustFinish } = e || {};
        if (didJustFinish) {
            playNextVideo();
        }
    };

    useEffect(() => {
        hapticImpact(1);
    }, [isFullscreen, activeVideo]);

    return (
        <MainLayout
            headerProps={{ hide: true }}
            isCenteredContent={OS !== "web" || (!isFullscreen && OS === "web")}>
            <View style={styles.container}>
                <Playlist
                    styles={styles}
                    parseList={parseList}
                    playerRef={playerRef}
                    setActiveVideo={setActiveVideo}
                    selectedItemIndex={selectedItemIndex}
                    isFullscreen={isFullscreen}
                />
                <View style={styles.video}>
                    <VideoPlayer
                        videoProps={{
                            shouldPlay: true,
                            resizeMode: ResizeMode.CONTAIN,
                            source: { uri: activeVideo?.url },
                            ref: playerRef,
                            posterSource: { uri: activeVideo?.image },
                            videoStyle: OS !== "web" ? {} : styles.player,
                        }}
                        ImageProps={{}}
                        fullscreen={{
                            inFullscreen: isFullscreen,
                            enterFullscreen: () => setIsFullscreen(true),
                            exitFullscreen: () => setIsFullscreen(false),
                        }}
                        timeVisible={isFullscreen}
                        slider={{
                            thumbTintColor: COLOURS.mainGreen,
                            minimumTrackTintColor: COLOURS.mainGreen,
                            maximumTrackTintColor: COLOURS.grey1,
                            style: styles.slider,
                        }}
                        activityIndicator={{
                            color: COLOURS.mainGreen,
                        }}
                        playbackCallback={onVideoEnd}
                        style={styles.player}
                    />
                    <View style={styles.controlsBg} />
                </View>
            </View>

            <Pressable style={styles.backButton} onPress={clickBack}>
                <BackArrowIcon {...imageSizes.standard} />
            </Pressable>
        </MainLayout>
    );
};

const getStyles = ({ isFullscreen, video, player, width, isWeb }) => ({
    container: {
        flex: 1,
        alignItems: OS === "web" && isFullscreen ? "flex-start" : "center",
        justifyContent: "center",
        flexDirection: isFullscreen ? "column" : "column-reverse",
        paddingTop: 15,
        zIndex: 5,
    },
    video,
    player,
    list: {
        justifyContent: "center",
        alignItems: "center",
    },
    backButton: {
        position: "absolute",
        zIndex: 100,
        top: 0,
        left: 1,
        padding: 20,
    },
    title: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        fontSize: 16,
        textAlign: "center",
    },
    videoContainer: {
        backgroundColor: COLOURS.black,
        borderRadius: 20,
        borderStyle: "solid",
        borderWidth: width * 0.011,
        zIndex: 1,
        marginTop: 0,
    },
    controlsBg: {
        position: "absolute",
        bottom: 0,
        left: 0,
        width: 1000,
        heigth: 200,
        zIndex: 9999,
    },
    playlistContainer: {
        width: width * 0.25,
        marginRight: 15,
        marginLeft: 15,
        justifyContent: "center",
        alignItems: "center",
    },
    playlistImage: (isSelected) => ({
        backgroundColor: "#fff",
        height: width * 0.15,
        width: width * 0.215,
        borderRadius: 30,
        borderWidth: 2,
        borderColor: isSelected ? COLOURS.black1 : COLOURS.white,
    }),
    slider: {
        paddingBottom: isWeb ? 0 : 30,
    },
});
