import React from "react";
import { Text, View } from "react-native";
import PhotoIcon from "../../../assets/svg/PhotoIcon";
import QuestionIcon from "../../../assets/svg/question-icon";
import { Button } from "../../../components";
import { LinkWithTooltip } from "../../../components/LinkWithTooltip";
import { useNavigate } from "react-router-native";
import { COLOURS, navigation } from "../../../constants";
export const ContainerFooter = ({ styles, width }) => {
    const navigate = useNavigate();
    return (
        <>
            <Button
                label={({ style }) => (
                    <View style={styles.uploadBtn}>
                        <PhotoIcon height={width * 0.025} />
                        <Text style={style}>Click & Upload</Text>
                    </View>
                )}
                type={"big"}
                fillColor={COLOURS.dark_purple}
                onPress={() => navigate(navigation.CAMERA_SCREEN)}
            />
            <LinkWithTooltip
                label={({ isOpen, textStyle }) => (
                    <>
                        <QuestionIcon
                            width={width * 0.02}
                            fill={isOpen ? COLOURS.yellow3 : COLOURS.grey2}
                        />
                        <Text
                            style={[
                                textStyle,
                                {
                                    color: isOpen
                                        ? COLOURS.yellow3
                                        : COLOURS.grey2,
                                },
                            ]}>
                            Need help?
                        </Text>
                    </>
                )}
                content={
                    <View style={{ width: width * 0.3 }}>
                        <Text style={styles.tooltipTitle}>
                            CLICK & UPLOAD INSTRUCTION
                        </Text>
                        <Text style={styles.tooltipText}>
                            1. Click on{" "}
                            <Text
                                style={{
                                    fontWeight: 700,
                                }}>
                                UPLOAD
                            </Text>{" "}
                            button and select the completed homework sheets
                            file. {"\n"}
                            2. Supported file formats (.JPG, .PDF) {"\n"}
                            3. Each file size should be less than 2MB. {"\n"}
                            4. You can select maximum of 5 files {"\n"}
                            5. Please ensure uploaded worksheet is not blurred
                            or too dark to read.
                        </Text>
                    </View>
                }
            />
        </>
    );
};
