import React from "react";
import { Image as DefaultImage } from "react-native";
import { imageCacheKey } from "../constants/app-config";
import CachedImage from "expo-cached-image";

export const Image = ({ cache = true, cacheKey, ...rest }) => {
    return cacheKey && cache ? (
        <CachedImage {...rest} cacheKey={imageCacheKey(cacheKey)} />
    ) : (
        <DefaultImage {...rest} />
    );
};
