import React, { createContext, useEffect, useState } from "react";
import { Audio } from "expo-av";
import { SOUNDS } from "../constants/sounds";
import { useSelector } from "react-redux";
import { getSoundStatus } from "../store/app/selectors";
import { useLocation } from "react-router-dom";
import { navigation } from "../constants";

const GlobalAudioContext = createContext({
    playClickSound: () => {},
    playTrainSound: () => {},
    playBgSound: () => {},
    stopBgSound: () => {},
    restrictAll: () => {},
    allowAll: () => {},
    bgSoundIsPlaying: false,
});
//interval in millisecond
const checkSoundStep = 250;
class AudioController {
    constructor() {
        Audio.Sound.createAsync(SOUNDS.trainSound).then((result) => {
            this.trainSound = result.sound;
        });
        Audio.Sound.createAsync(SOUNDS.backgroundSound).then((result) => {
            this.bgSound = result.sound;
        });
        Audio.Sound.createAsync(SOUNDS.pop_up).then((result) => {
            this.clickSound = result.sound;
        });
        this.endTime = 0;
        this.allowPlayer = true;
        setInterval(this.checkTrainSound.bind(this), 200);
    }
    async playClickSound() {
        if (this.clickSound) {
            await this.clickSound.setPositionAsync(0);
            await this.clickSound.playAsync();
        }
    }
    playBgSound() {
        if (this.bgSound && this.allowPlayer) {
            this.bgSound?.playAsync();
            // .then((r) => console.log(r));
        }
    }
    stopBgSound() {
        if (this.bgSound) {
            this.bgSound?.stopAsync();
            // .then((r) => console.log(r));
        }
    }
    async playTrainSound() {
        // if (!this.allowPlayer) {
        //     return;
        // }
        const status = await this.trainSound.getStatusAsync();
        if (status?.isPlaying) {
            this.endTime = Date.now() + checkSoundStep;
            return;
        }
        await this.trainSound?.setIsLoopingAsync(true);
        await this.trainSound?.playAsync();
        this.endTime(Date.now() + checkSoundStep);
    }
    async getBgSoundStatus() {
        const status = await this.bgSound.getStatusAsync();
        return status?.isPlaying;
    }
    checkTrainSound() {
        if (Date.now() > this.endTime) {
            this.trainSound?.stopAsync();
        }
    }
    restrictAll() {
        this.allowPlayer = false;
        if (this.bgSound) {
            this.bgSound.stopAsync().catch((e) => console.error(e));
        }
    }
    allowAll() {
        this.allowPlayer = true;
    }
}
const audioController = new AudioController();

export const GlobalAudioContextProvider = (props) => {
    const appSoundStatus = useSelector(getSoundStatus);
    let location = useLocation();
    const [bgSoundIsPlaying, setBgSoundIsPlaying] = useState();
    useEffect(() => {
        if (appSoundStatus) {
            audioController.allowAll();
            if (location.pathname === navigation.HOME) {
                audioController.playBgSound();
            }
        } else {
            audioController.restrictAll();
        }
    }, [appSoundStatus]);
    const playClickSound = () => {
        audioController.playClickSound();
    };
    const playTrainSound = async () => {
        await audioController.playTrainSound();
    };
    const playBgSound = async () => {
        audioController.playBgSound();
        setBgSoundIsPlaying(await audioController.getBgSoundStatus());
    };
    const stopBgSound = async () => {
        audioController.stopBgSound();
        setBgSoundIsPlaying(await audioController.getBgSoundStatus());
    };
    const restrictAll = () => {
        audioController.restrictAll();
    };
    const allowAll = () => {
        audioController.allowAll();
    };
    const contextValue = {
        playClickSound,
        playTrainSound,
        playBgSound,
        stopBgSound,
        restrictAll,
        allowAll,
        bgSoundIsPlaying,
    };
    return (
        <GlobalAudioContext.Provider value={contextValue}>
            {props.children}
        </GlobalAudioContext.Provider>
    );
};

export default GlobalAudioContext;
