import * as React from "react";
import Svg, { G, Path, Mask, Defs } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent(props) {
    return (
        <Svg
            width={124}
            height={116}
            viewBox="0 0 124 116"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <G filter="url(#filter0_d_0_191772)">
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.338 25.616C5.683 46.91 7.427 76.738 28.722 91.394c21.295 14.655 62.086 10.332 76.742-10.963 14.655-21.295 5.096-50.565-16.2-65.22C67.97.555 34.994 4.32 20.338 25.616"
                    fill="#FEA08A"
                />
                <Path
                    d="M20.338 25.616C5.683 46.91 7.427 76.738 28.722 91.394c21.295 14.655 62.086 10.332 76.742-10.963 14.655-21.295 5.096-50.565-16.2-65.22C67.97.555 34.994 4.32 20.338 25.616"
                    stroke="#EAD5FF"
                    strokeWidth={5}
                />
            </G>
            <Mask
                id="a"
                style={{
                    maskType: "luminance",
                }}
                maskUnits="userSpaceOnUse"
                x={43}
                y={27}
                width={37}
                height={52}>
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M43.368 27.703h36.554v50.959H43.368V27.703z"
                    fill="#fff"
                />
            </Mask>
            <G mask="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="#fff">
                <Path d="M75.827 31.294c-9.793 1.76-19.894 4.227-30.118 6.927-1.556.414-2.592 1.95-2.288 3.43.138.669.278 1.339.421 2.01 12.202-3.045 24.333-6.001 36.08-8.019a803.57 803.57 0 00-.595-2.057c-.444-1.52-2.01-2.56-3.5-2.29zM55.57 33.794c-.27-1.275.71-2.618 2.164-2.98 1.513-.375 3.02-.738 4.522-1.088 1.442-.337 2.86.452 3.174 1.74l.24.999c.71-.156 1.419-.309 2.126-.459l-.248-.998c-.589-2.329-3.135-3.778-5.719-3.163-1.49.351-2.987.718-4.49 1.097-2.607.662-4.397 3.104-3.929 5.398l.205.985a526.42 526.42 0 012.164-.542l-.21-.989zM48.974 75.423c.236 1.65 1.662 2.984 3.283 3.05 6.826.275 13.66.25 20.484-.075 1.62-.079 3.055-1.418 3.3-3.064 1.524-10.387 2.765-20.665 3.488-30.858a662.461 662.461 0 00-33.992-.046c.746 10.249 1.97 20.577 3.437 30.993zm19.3-4.75c.553-6.225 1.068-12.446 1.506-18.655.042-.596.55-1.056 1.165-1.054l.927.004a1.2 1.2 0 01.857.363c.222.23.336.538.314.85a668.714 668.714 0 01-1.592 18.573c-.059.595-.569 1.07-1.166 1.087l-.9.024a1.085 1.085 0 01-.825-.343 1.1 1.1 0 01-.287-.849m-7.372-18.582c0-.631.525-1.143 1.172-1.143h.933c.647 0 1.172.513 1.172 1.143.003 6.242-.018 12.483-.06 18.718a1.158 1.158 0 01-1.147 1.148l-.906.002a1.144 1.144 0 01-1.142-1.142c-.015-6.237-.023-12.482-.022-18.726m-8.552-.766c.222-.23.532-.362.857-.364l.926-.002a1.152 1.152 0 011.167 1.056c.44 6.223.942 12.455 1.469 18.688a1.097 1.097 0 01-1.117 1.2l-.901-.019a1.207 1.207 0 01-1.162-1.083 797.693 797.693 0 01-1.553-18.623 1.116 1.116 0 01.314-.853" />
            </G>
            <Defs></Defs>
        </Svg>
    );
}

export default SvgComponent;
