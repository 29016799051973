import * as React from "react"
import Svg, { Mask, Path, G } from "react-native-svg"

function SvgComponent(props) {
    return (
        <Svg
            width={110}
            height={33}
            viewBox="0 0 193 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Mask
                id="mask0_0_9294"
                style={{
                    maskType: "luminance"
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={193}
                height={58}
            >
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.419922 0.588867H192.219V57.6654H0.419922V0.588867Z"
                    fill="white"
                />
            </Mask>
            <G mask="url(#mask0_0_9294)">
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M163.053 11.3777H171.633C171.965 11.3777 172.233 11.109 172.233 10.7775V10.3181C172.233 9.98655 171.965 9.71777 171.633 9.71777H163.053C162.722 9.71777 162.453 9.98655 162.453 10.3181V10.7775C162.453 11.109 162.722 11.3777 163.053 11.3777Z"
                    fill="#4A443E"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M162.453 10.4062H172.233V11.4062H162.453V10.4062Z"
                    fill="#F08109"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M170.455 13.7744L170.439 14.7744H164.247L164.231 13.7744H170.455Z"
                    fill="black"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M170.156 17.5586L170.141 18.5586H164.544L164.53 17.5586H170.156Z"
                    fill="black"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M187.019 22.2515V36.3252C187.019 37.6179 185.971 38.6655 184.678 38.6655H155.488V19.9111H184.678C185.764 19.9111 186.677 20.6496 186.94 21.6523C186.992 21.8434 187.019 22.0446 187.019 22.2515"
                    fill="#30261A"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M184.727 38.6982L184.757 38.7689C182.735 40.2798 180.226 41.1747 177.508 41.1747H177.181C180.018 41.1747 182.633 40.2517 184.727 38.6982Z"
                    fill="#30261A"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M186.94 21.6523H155.488V19.9111H184.679C185.764 19.9111 186.677 20.6496 186.94 21.6523Z"
                    fill="#4A443E"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M189.334 49.4586H131.46V38.665H153.932V41.174H177.18C177.358 41.174 177.534 41.1622 177.71 41.1628C181.398 41.1744 184.676 38.7358 184.726 38.6976C184.741 38.6863 184.756 38.6763 184.77 38.665L188.816 48.6257L189.334 49.4586Z"
                    fill="#30261A"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M192.219 49.4584H183.027L182.975 49.274L182.795 48.6254L181.369 43.4894L180.726 41.1738L180.615 40.7712L180.599 40.7174L180.598 40.7119L180.03 38.6648L179.648 37.29C181.492 37.2957 183.395 37.7307 185.148 38.6175C185.179 38.6334 185.21 38.649 185.241 38.6648C186.445 39.2853 187.576 40.1194 188.565 41.1738C190.274 42.9914 191.567 45.4634 192.097 48.6254C192.144 48.8974 192.185 49.175 192.218 49.4584"
                    fill="#30261A"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M192.219 49.626H131.46V48.626H192.097C192.145 48.9525 192.185 49.2857 192.219 49.626"
                    fill="#1B0C00"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M188.565 41.174H131.46V38.665H185.241C186.445 39.2855 187.576 40.1196 188.565 41.174Z"
                    fill="#1B0C00"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M135.448 49.2003C135.448 53.6327 139.041 57.2258 143.473 57.2258C147.906 57.2258 151.498 53.6327 151.498 49.2003C151.498 44.7681 147.906 41.1748 143.473 41.1748C139.041 41.1748 135.448 44.7681 135.448 49.2003Z"
                    fill="#49423C"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M140.418 49.1992C140.418 50.8868 141.786 52.2549 143.474 52.2549C145.162 52.2549 146.53 50.8868 146.53 49.1992C146.53 47.5116 145.162 46.1436 143.474 46.1436C141.786 46.1436 140.418 47.5116 140.418 49.1992Z"
                    fill="#FF8604"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M154.566 49.2003C154.566 53.6327 158.159 57.2258 162.592 57.2258C167.024 57.2258 170.617 53.6327 170.617 49.2003C170.617 44.7681 167.024 41.1748 162.592 41.1748C158.159 41.1748 154.566 44.7681 154.566 49.2003Z"
                    fill="#49423C"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M159.536 49.1992C159.536 50.8868 160.904 52.2549 162.592 52.2549C164.279 52.2549 165.647 50.8868 165.647 49.1992C165.647 47.5116 164.279 46.1436 162.592 46.1436C160.904 46.1436 159.536 47.5116 159.536 49.1992Z"
                    fill="#FF8604"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M173.684 50.3351C173.684 54.1409 176.769 57.226 180.575 57.226C184.381 57.226 187.466 54.1409 187.466 50.3351C187.466 46.5294 184.381 43.4443 180.575 43.4443C176.769 43.4443 173.684 46.5294 173.684 50.3351Z"
                    fill="#49423C"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M177.951 50.3346C177.951 51.7837 179.126 52.9583 180.575 52.9583C182.024 52.9583 183.198 51.7837 183.198 50.3346C183.198 48.8855 182.024 47.7109 180.575 47.7109C179.126 47.7109 177.951 48.8855 177.951 50.3346Z"
                    fill="#FF8604"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M131.151 6.64677H155.795C156.667 6.64677 157.374 5.94007 157.374 5.06826V2.16738C157.374 1.29557 156.667 0.588867 155.795 0.588867H131.151C130.279 0.588867 129.573 1.29557 129.573 2.16738V5.06826C129.573 5.94007 130.279 6.64677 131.151 6.64677Z"
                    fill="#FDB801"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M131.151 7.6594H155.795C156.667 7.6594 157.374 6.92639 157.374 6.02213V3.01326C157.374 2.10899 156.667 1.37598 155.795 1.37598H131.151C130.279 1.37598 129.573 2.10899 129.573 3.01326V6.02213C129.573 6.9264 130.279 7.6594 131.151 7.6594Z"
                    fill="#FF8604"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M131.46 7.65918H155.488V38.6651H131.46V7.65918Z"
                    fill="#30261A"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M139.028 24.4105H147.918C148.252 24.4105 148.522 24.14 148.522 23.8063V10.822C148.522 10.4883 148.252 10.2178 147.918 10.2178H139.028C138.695 10.2178 138.424 10.4883 138.424 10.822V23.8063C138.424 24.14 138.695 24.4105 139.028 24.4105Z"
                    fill="#44A5C0"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M189.793 28.3182V30.2568C189.793 30.4018 189.782 30.5442 189.761 30.683C189.555 32.0126 188.407 33.0311 187.019 33.0311V25.5439C187.786 25.5439 188.479 25.855 188.98 26.3568C189.388 26.7636 189.668 27.2962 189.761 27.892C189.781 28.0308 189.793 28.1733 189.793 28.3182"
                    fill="#FFDB01"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M190.761 28.853V29.7211C190.761 30.1896 190.335 30.5824 189.761 30.6826C189.788 30.5438 189.803 30.4013 189.803 30.2564V28.3178C189.803 28.1729 189.788 28.0305 189.761 27.8916C190.001 27.9329 190.215 28.0269 190.383 28.1552C190.617 28.3336 190.761 28.5805 190.761 28.853"
                    fill="#FFDB01"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M74.7216 10.9953H124.772C125.644 10.9953 126.351 9.89922 126.351 8.54728V4.04857C126.351 2.69653 125.644 1.60059 124.772 1.60059H74.7216C73.8498 1.60059 73.1431 2.69654 73.1431 4.04857V8.54728C73.1431 9.89922 73.8498 10.9953 74.7216 10.9953Z"
                    fill="#FF8604"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.6435 10.5548H67.6944C68.5661 10.5548 69.2729 9.45879 69.2729 8.10685V3.60814C69.2729 2.2561 68.5661 1.16016 67.6944 1.16016H17.6435C16.7716 1.16016 16.0649 2.25611 16.0649 3.60814V8.10685C16.0649 9.45879 16.7716 10.5548 17.6435 10.5548Z"
                    fill="#FF8604"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M79.2484 39.3881H120.493L121.913 10.9951H77.8291L79.2484 39.3881Z"
                    fill="#30261A"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.1703 38.9477H63.4153L64.8347 10.5547H20.751L22.1703 38.9477Z"
                    fill="#30261A"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M74.334 41.2738V47.3352C74.334 47.9515 74.5262 48.5172 74.8466 48.9599C75.222 49.48 75.7751 49.8318 76.4023 49.8976C76.4743 49.9065 76.5473 49.9103 76.6215 49.9103H123.12C123.194 49.9103 123.267 49.9066 123.339 49.8976C123.966 49.8318 124.519 49.48 124.896 48.9599C125.216 48.5171 125.408 47.9515 125.408 47.3352V41.2738C125.408 39.8514 124.383 38.6973 123.12 38.6973H76.6215C75.358 38.6973 74.334 39.8514 74.334 41.2738"
                    fill="#30261A"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.2559 40.8334V46.8948C17.2559 47.511 17.4481 48.0767 17.7684 48.5195C18.1439 49.0396 18.697 49.3914 19.3242 49.4572C19.3961 49.4661 19.4692 49.4699 19.5434 49.4699H66.0417C66.1159 49.4699 66.1889 49.4661 66.2609 49.4572C66.8881 49.3914 67.4412 49.0396 67.8177 48.5195C68.1381 48.0767 68.3303 47.511 68.3303 46.8948V40.8334C68.3303 39.411 67.3051 38.2568 66.0417 38.2568H19.5434C18.2799 38.2568 17.2559 39.4109 17.2559 40.8334"
                    fill="#30261A"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M105.887 28.0864H114.777C115.111 28.0864 115.381 27.8159 115.381 27.4821V14.4978C115.381 14.1641 115.111 13.8936 114.777 13.8936H105.887C105.553 13.8936 105.283 14.1641 105.283 14.4978V27.4821C105.283 27.8159 105.553 28.0864 105.887 28.0864Z"
                    fill="#44A5C0"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M51.1232 27.8774H60.0136C60.3471 27.8774 60.6177 27.6069 60.6177 27.2731V14.2888C60.6177 13.9551 60.3471 13.6846 60.0136 13.6846H51.1232C50.7895 13.6846 50.519 13.9551 50.519 14.2888V27.2731C50.519 27.6069 50.7895 27.8774 51.1232 27.8774Z"
                    fill="#44A5C0"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M84.9641 28.0864H93.8543C94.188 28.0864 94.4586 27.8159 94.4586 27.4821V14.4978C94.4586 14.1641 94.188 13.8936 93.8543 13.8936H84.9641C84.6304 13.8936 84.3599 14.1641 84.3599 14.4978V27.4821C84.3599 27.8159 84.6304 28.0864 84.9641 28.0864Z"
                    fill="#44A5C0"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.2009 27.8774H39.0911C39.4248 27.8774 39.6954 27.6069 39.6954 27.2731V14.2888C39.6954 13.9551 39.4248 13.6846 39.0911 13.6846H30.2009C29.8672 13.6846 29.5967 13.9551 29.5967 14.2888V27.2731C29.5967 27.6069 29.8672 27.8774 30.2009 27.8774Z"
                    fill="#44A5C0"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M105.696 50.7745C105.696 54.5803 108.781 57.6654 112.587 57.6654C116.392 57.6654 119.478 54.5803 119.478 50.7745C119.478 46.9689 116.392 43.8838 112.587 43.8838C108.781 43.8838 105.696 46.9689 105.696 50.7745Z"
                    fill="#49423C"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M48.6182 50.3331C48.6182 54.1389 51.7032 57.224 55.509 57.224C59.3146 57.224 62.4 54.1389 62.4 50.3331C62.4 46.5275 59.3146 43.4424 55.509 43.4424C51.7032 43.4424 48.6182 46.5275 48.6182 50.3331Z"
                    fill="#49423C"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M109.963 50.7739C109.963 52.223 111.138 53.3977 112.587 53.3977C114.036 53.3977 115.21 52.223 115.21 50.7739C115.21 49.3251 114.036 48.1504 112.587 48.1504C111.138 48.1504 109.963 49.3251 109.963 50.7739Z"
                    fill="#FF8604"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M52.8848 50.3335C52.8848 51.7826 54.0594 52.9573 55.5084 52.9573C56.9574 52.9573 58.1321 51.7826 58.1321 50.3335C58.1321 48.8847 56.9574 47.71 55.5084 47.71C54.0595 47.71 52.8848 48.8846 52.8848 50.3335Z"
                    fill="#FF8604"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M80.2651 50.7745C80.2651 54.5803 83.3503 57.6654 87.156 57.6654C90.9617 57.6654 94.0468 54.5803 94.0468 50.7745C94.0468 46.9689 90.9617 43.8838 87.156 43.8838C83.3503 43.8838 80.2651 46.9689 80.2651 50.7745Z"
                    fill="#49423C"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.188 50.3331C23.188 54.1389 26.2731 57.224 30.0788 57.224C33.8845 57.224 36.9697 54.1389 36.9697 50.3331C36.9697 46.5275 33.8845 43.4424 30.0788 43.4424C26.2731 43.4424 23.188 46.5275 23.188 50.3331Z"
                    fill="#49423C"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M84.5327 50.7739C84.5327 52.223 85.7074 53.3977 87.1564 53.3977C88.6054 53.3977 89.7801 52.223 89.7801 50.7739C89.7801 49.3251 88.6054 48.1504 87.1564 48.1504C85.7074 48.1504 84.5327 49.3251 84.5327 50.7739Z"
                    fill="#FF8604"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.4551 50.3335C27.4551 51.7826 28.6298 52.9573 30.0787 52.9573C31.5277 52.9573 32.7025 51.7826 32.7025 50.3335C32.7025 48.8847 31.5277 47.71 30.0787 47.71C28.6298 47.71 27.4551 48.8846 27.4551 50.3335Z"
                    fill="#FF8604"
                />
            </G>
        </Svg>
    )
}

export default SvgComponent
