import React from "react";
import { homeWorkStatuses } from "../../../constants/home-work";

export const CardsStatus = ({
    status,
    style = {},
    statusList = homeWorkStatuses,
}) => {
    const { width, height, ...rest } = style;

    const statusObj = statusList.find(({ title }) => status === title);

    if (statusObj) {
        return <statusObj.Icon style={rest} width={width} height={height} />;
    }

    return <></>;
};
