import React, { useMemo } from "react";
import { navigation } from "../../constants";
import { useStylesUtils } from "../../hooks";
import { useLocation, useNavigate } from "react-router-native";
import { View, Image, Text } from "react-native";

export const homeWorkTabs = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { styles } = useStylesUtils(getStyles);

    return useMemo(() => {
        return [
            {
                id: 1,
                selected: location.pathname === navigation.HOME_WORK_SCREEN,
                onPress: () =>
                    navigate(navigation.HOME_WORK_SCREEN, { replace: true }),
                label: ({ style }) => (
                    <View style={styles.buttonWrapper}>
                        <Image
                            style={styles.btnImage}
                            source={require("../../assets/images/diaryIcons/homeWorkIconBtn.png")}
                            resizeMode={"contain"}
                        />
                        <Text style={style}>Homework</Text>
                    </View>
                ),
                textStyle: { fontSize: 20 },
            },
            // {
            //     id: 2,
            //     selected: location.pathname === navigation.CLASS_ACTIVITY_SCREEN,
            //     onPress: () => navigate(navigation.COMING_SOON_SCREEN),
            //     label: ({ style }) => (
            //         <View style={styles.buttonWrapper}>
            //             <Image
            //                 style={styles.btnImage}
            //                 source={require("../../../assets/images/diaryIcons/classActivityIconBtn.png")}
            //                 resizeMode={"contain"}
            //             />
            //             <Text style={style}>Class Activity</Text>
            //         </View>
            //     ),
            //     textStyle: {
            //         fontSize: 20,
            //     }
            // },
        ];
    }, [location]);
};

const getStyles = ({ width, sizes, biggerSize }) => {
    return {
        buttonWrapper: {
            width: "100%",
            paddingHorizontal: sizes.content * 0.5,
            zIndex: 10,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
        },
        btnImage: {
            height: biggerSize * 0.04,
            width: biggerSize * 0.05,
            marginRight: width * 0.01,
        },
    };
};
