import * as React from "react";
import Svg, { Path, G } from "react-native-svg";

export const OverdueStatusIcon = (props) => {
    return (
        <Svg
            width={76}
            height={70}
            viewBox="0 0 76 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M68.33 14.22c10.85 15.767 9.559 37.851-6.207 48.702-15.767 10.85-45.967 7.65-56.818-8.117-10.85-15.767-3.773-37.438 11.993-48.288C33.065-4.334 57.478-1.546 68.33 14.22z"
                fill="#FFDE35"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.376 8.984c-3.7 0-6.623 3.14-6.36 6.83l2.052 28.705a4.319 4.319 0 008.616 0l2.052-28.705a6.375 6.375 0 00-6.36-6.83zM44.526 57.157a5.15 5.15 0 11-10.301 0 5.15 5.15 0 0110.301 0z"
                fill="#350F69"
            />
        </Svg>
    );
};

export const CompleteStatusIcon = (props) => {
    return (
        <Svg
            width={78}
            height={71}
            viewBox="0 0 78 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M70.525 14.53C81.61 30.637 80.29 53.2 64.183 64.286c-16.109 11.086-46.965 7.816-58.051-8.293-11.086-16.109-3.855-38.25 12.254-49.336C34.494-4.428 59.438-1.58 70.525 14.529"
                fill="#6DB160"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.813 39.398c3.634 2.516 5.533 2.971 8.664 8.245 3.132 5.274 3.68 8.862 4.504 9.521.824.66 4.944-.164 7.086-1.318 2.143-1.154 2.223-1.79 4.223-6.469 2.774-6.485 7.278-14.448 10.74-19.062C54.49 25.7 61.775 19.63 62.105 18.477c.33-1.154-3.131-1.69-6.427-.7-3.296.988-10.24 8.625-14.432 14.634-4.658 6.676-6.313 12.31-7.137 12.146-.824-.165-2.434-3.525-4.741-5.997-2.308-2.472-4.739-4.147-7.54-3.653-2.802.495-7.692 3.33-6.016 4.491"
                fill="#fff"
            />
            <Path
                d="M15.813 39.398c3.634 2.516 5.533 2.971 8.664 8.245 3.132 5.274 3.68 8.862 4.504 9.521.824.66 4.944-.164 7.086-1.318 2.143-1.154 2.223-1.79 4.223-6.469 2.774-6.485 7.278-14.448 10.74-19.062C54.49 25.7 61.775 19.63 62.105 18.477c.33-1.154-3.131-1.69-6.427-.7-3.296.988-10.24 8.625-14.432 14.634-4.658 6.676-6.313 12.31-7.137 12.146-.824-.165-2.434-3.525-4.741-5.997-2.308-2.472-4.739-4.147-7.54-3.653-2.802.495-7.692 3.33-6.016 4.491"
                stroke="#fff"
                strokeWidth={2}
            />
        </Svg>
    );
};

export const PendingStatusIcon = (props) => {
    return (
        <Svg
            width={76}
            height={70}
            viewBox="0 0 76 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M68.33 14.822c10.85 15.767 9.559 37.85-6.207 48.701-15.767 10.85-45.967 7.65-56.818-8.117C-5.545 39.64 1.532 17.97 17.298 7.119c15.767-10.85 40.18-8.062 51.031 7.704z"
                fill="#FFDE35"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.742 37.052V25.057c0-2.578-3.998-2.574-3.998 0v9.996h-5.998c-2.577 0-2.573 3.998 0 3.998h7.997c1.105 0 1.999-.894 1.999-2M59.687 52.12c.047-15.069-23.944-15.069-23.9 1.776.038 15.143 23.947 15.143 23.9-1.776zm-11.94 6.98c-1.537 0-1.54-2.328-.016-2.336 1.52-.007 1.55 2.335.015 2.335m1.117-8.973l-.336 3.85c-.035.46-.113.81-.234 1.055-.23.47-.951.504-1.18.011-.109-.236-.187-.597-.234-1.081l-.25-3.741c-.047-.738-.303-1.969.27-2.581.429-.459 1.478-.547 1.792.101.339.691.25 1.646.172 2.386"
                fill="#350F69"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.807 54.265C24.05 52.16 19.54 45.945 18.296 39.05h3.453c2.577 0 2.573-3.998 0-3.998h-3.82c0-9.476 5.946-18.937 17.814-19.897v3.971c0 2.578 3.999 2.573 3.999 0v-3.973c12.237.938 18.124 10.414 17.99 19.906-.01 0-.02-.007-.031-.007h-3.998c-1.534 0-2.132 1.411-1.84 2.558 1.268.34 2.478.818 3.602 1.44h1.843c-.067.344-.177.676-.26 1.015 1.271.915 2.4 2.01 3.355 3.25 4.623-13.99-2.922-32.253-22.66-32.253-30.212 0-31.854 42.752-4.996 47.503a15.851 15.851 0 01-.94-4.3"
                fill="#350F69"
            />
        </Svg>
    );
};

export const LiveStatusIcon = (props) => {
    return (
        <Svg
            width={82}
            height={76}
            viewBox="0 0 82 76"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <G filter="url(#filter0_d_68_83963)">
                <Path
                    d="M71.242 16.698c10.851 15.767 9.56 37.85-6.207 48.7-15.767 10.849-45.967 7.65-56.818-8.12C-2.633 41.507 4.444 19.845 20.21 8.994 35.976-1.857 60.391.93 71.241 16.694"
                    fill="#fff"
                />
            </G>
            <Path
                d="M37.344 32.947a7.571 7.571 0 019.854.745c2.67 2.659 3.008 6.9.8 10.023-.126.016-.274-.005-.375.056-1.233.742-2.578.888-3.986.897-4.079.023-8.007-4.046-7.178-9.016.161-.963.726-1.76.885-2.707v.002zM24.854 59.951c-1.078 1.222-2.657 1.3-3.781.152-4.365-4.46-7.104-9.768-8.116-15.975-.314-1.92-.528-3.85-.435-5.793.18-3.775.9-7.433 2.406-10.918 1.487-3.439 3.522-6.488 6.145-9.136 1.13-1.141 2.7-1.07 3.784.152-.137.063-.297.096-.409.19-2.614 2.2-4.676 4.863-6.266 7.885-2.615 4.97-3.516 10.273-2.914 15.865.326 3.02 1.184 5.87 2.456 8.606 1.21 2.605 2.9 4.878 4.807 6.993.684.76 1.408 1.493 2.326 1.983l-.003-.004zM58.785 18.427c1.08-1.222 2.658-1.297 3.782-.152 4.373 4.456 7.089 9.776 8.116 15.974.317 1.92.523 3.85.437 5.794-.171 3.777-.916 7.43-2.41 10.918a29.18 29.18 0 01-6.143 9.138c-1.13 1.141-2.701 1.07-3.782-.152.136-.063.297-.096.408-.19 2.677-2.252 4.77-4.988 6.374-8.091 2.54-4.916 3.394-10.153 2.806-15.656-.323-3.02-1.177-5.873-2.456-8.605-1.183-2.526-2.794-4.763-4.643-6.826-.734-.82-1.501-1.62-2.487-2.15l-.002-.002z"
                fill="#E62F00"
            />
            <Path
                d="M24.854 59.952c-.917-.49-1.639-1.224-2.326-1.983-1.907-2.113-3.596-4.388-4.806-6.993-1.272-2.736-2.128-5.587-2.456-8.607-.604-5.591.297-10.895 2.914-15.864 1.59-3.022 3.651-5.685 6.266-7.886.111-.093.27-.127.408-.19.908 1.333.81 2.478-.363 3.696-2.66 2.764-4.629 5.954-5.705 9.656-2.464 8.465-1 16.181 4.48 23.08.434.545.928 1.039 1.388 1.562 1.006 1.148 1.066 2.261.202 3.527l-.002.002zM58.785 18.426c.986.53 1.753 1.33 2.487 2.15 1.847 2.063 3.458 4.3 4.643 6.826 1.28 2.732 2.134 5.585 2.456 8.605.588 5.506-.266 10.742-2.806 15.656-1.604 3.103-3.697 5.84-6.374 8.092-.111.093-.27.127-.408.19-.878-1.29-.812-2.489.254-3.581 3.608-3.7 5.854-8.121 6.64-13.237 1.1-7.16-.605-13.625-5.049-19.327-.49-.63-1.06-1.199-1.593-1.793-1.054-1.17-1.134-2.298-.254-3.585l.004.004z"
                fill="#E62F00"
            />
            <Path
                d="M32.087 52.604c-1.066 1.327-2.718 1.39-3.953.09-2.726-2.865-4.489-6.25-5.128-10.181-1.025-6.3.672-11.81 4.858-16.553 1.369-1.55 3.08-1.61 4.223-.186-1.396.74-2.376 1.944-3.328 3.154-2.813 3.568-3.84 7.7-3.41 12.168.288 3.01 1.4 5.775 3.263 8.176.992 1.278 2.019 2.54 3.475 3.334v-.002zM51.549 25.775c1.05-1.305 2.678-1.395 3.897-.146 2.532 2.595 4.225 5.69 4.996 9.243 1.215 5.59.178 10.768-3.027 15.49a15.694 15.694 0 01-2.023 2.446c-1.202 1.17-2.835 1.067-3.841-.203 1.394-.742 2.388-1.935 3.324-3.155 3.083-4.017 4.068-8.555 3.167-13.533-.534-2.955-1.845-5.535-3.8-7.764-.788-.897-1.553-1.869-2.695-2.376l.002-.002z"
                fill="#E62F00"
            />
            <Path
                d="M51.55 25.773c1.143.51 1.908 1.48 2.696 2.376 1.954 2.227 3.266 4.809 3.8 7.764.9 4.98-.085 9.518-3.167 13.533-.936 1.22-1.93 2.413-3.324 3.155-.856-1.19-.76-2.39.282-3.5 1.976-2.105 3.279-4.56 3.747-7.442.775-4.757-.52-8.874-3.753-12.386-1.041-1.13-1.144-2.286-.283-3.5h.003zM32.086 52.606c-1.455-.795-2.482-2.057-3.474-3.335-1.862-2.4-2.976-5.166-3.264-8.175-.43-4.47.596-8.601 3.41-12.17.953-1.209 1.932-2.412 3.328-3.153.848 1.202.757 2.399-.286 3.502-1.949 2.06-3.24 4.471-3.728 7.289-.825 4.772.433 8.93 3.678 12.48 1.087 1.188 1.206 2.329.336 3.56v.002zM37.344 32.945c-.159.947-.724 1.744-.885 2.707-.826 4.97 3.1 9.039 7.178 9.016 1.408-.008 2.753-.154 3.986-.897.101-.06.25-.04.375-.056-1.93 2.588-4.511 3.604-7.633 3.06-3.056-.535-5.514-3.023-6.044-6.11-.54-3.159.466-5.768 3.023-7.72z"
                fill="#E62F00"
            />
        </Svg>
    );
};
