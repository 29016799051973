import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
    return (
        <Svg
            width={1920}
            height={12}
            viewBox="0 0 1920 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M-151 0.666016H2015.23V7.00039H-151V0.666016Z"
                fill="#2C0866"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M540.579 7H618.792V13.3346H540.579V7Z"
                fill="#2C0866"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M376.211 7H454.424V13.3346H376.211V7Z"
                fill="#2C0866"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M211.842 7H290.055V13.3346H211.842V7Z"
                fill="#2C0866"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M47.4739 7H125.687V13.3346H47.4739V7Z"
                fill="#2C0866"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M710.871 7H789.084V13.3346H710.871V7Z"
                fill="#2C0866"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M881.163 7H959.376V13.3346H881.163V7Z"
                fill="#2C0866"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1051.46 7H1129.67V13.3346H1051.46V7Z"
                fill="#2C0866"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1221.75 7H1299.96V13.3346H1221.75V7Z"
                fill="#2C0866"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1392.04 7H1470.25V13.3346H1392.04V7Z"
                fill="#2C0866"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1562.33 7H1640.54V13.3346H1562.33V7Z"
                fill="#2C0866"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1732.62 7H1810.84V13.3346H1732.62V7Z"
                fill="#2C0866"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1902.92 7H1981.13V13.3346H1902.92V7Z"
                fill="#2C0866"
            />
        </Svg>
    )
}

export default SvgComponent
