import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-native";
import { authApi } from "../api/auth.api";
import { paymentApi } from "../api/payment.api";
import { navigation } from "../constants";
import { closeModal, startLoading, stopLoading } from "../store/app/slice";
import {
    getUserInfo,
    getUserAuthorized,
    getTimeLastLogin,
} from "../store/auth/selectors";
import {
    generateOTPThunk,
    getUidByPhoneThunk,
    loginThunk,
    loginWithOtpThunk,
    logoutThunk,
    setNewPasscodeThunk,
    verifyUidThunk,
    getMappingThunk,
    getUserDtoThunk,
} from "../store/auth/thunks";
import { transformAdmissionNumberResponse } from "../utils/transformData";
import { usePopups } from "./usePopups";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { timeToCheckToken } from "../constants/app-config";
import { authProductId, authTenantId } from "../api/constants";

export const useAuth = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loginError, paymentNotPaid, tokenIsExpired, logoutPopup } =
        usePopups();
    const { openErrorModal } = usePopups();
    const userInfo = useSelector(getUserInfo);
    const isAuthorized = useSelector(getUserAuthorized);
    const timeLastLogin = useSelector(getTimeLastLogin);

    const checkUserPaymentStatus = useSelector(
        (state) => state.app.checkUserPaymentStatus
    );

    const checkToIncorrectPassword = (result) => {
        const message = result?.payload?.message?.toLowerCase();

        return (
            message &&
            message.includes("incorrect") &&
            message.includes("password")
        );
    };

    const onLogin = async (body) => {
        const result = await dispatch(loginThunk(body));
        if (loginThunk.fulfilled.match(result)) {
            const userDtoResult = await dispatch(getUserDtoThunk());

            if (getUserDtoThunk.fulfilled.match(userDtoResult)) {
                return await mapping(userDtoResult.payload).then(() => {
                    navigate(navigation.HOME);
                });
            }
            if (getUserDtoThunk.rejected.match(result)) {
                throw Error(result);
            }
        }

        if (loginThunk.rejected.match(result)) {
            throw Error(result);
        }
    };

    const loginWithOtp = async (body, loginWithOtp) => {
        const result = await dispatch(loginWithOtpThunk(body));

        if (loginWithOtpThunk.fulfilled.match(result)) {
            const userDtoResult = await dispatch(getUserDtoThunk());

            if (getUserDtoThunk.fulfilled.match(userDtoResult)) {
                return await mapping(userDtoResult.payload).then(() => {
                    if (loginWithOtp || userDtoResult.payload.isPassSet) {
                        navigate(navigation.HOME);
                    } else {
                        navigate(navigation.SET_NEW_PASSCODE);
                    }
                });
            }
            if (getUserDtoThunk.rejected.match(result)) {
                throw Error(result);
            }
        }

        if (loginWithOtpThunk.rejected.match(result)) {
            throw Error(result);
        }
    };

    const mapping = async (user) => {
        if (!user) {
            return Promise.reject(new Error());
        }

        const result = await dispatch(
            getMappingThunk({
                examId: user.exams?.[0]?.examId,
                gradeId: user.grade?.gradeId,
            })
        );

        if (getMappingThunk.rejected.match(result)) {
            openErrorModal(`${result?.payload?.error}`, "Close", async () => {
                dispatch(closeModal);
                navigate(navigation.ENTER_ADMISSION_NUMBER);
                await AsyncStorage.clear();
            });
            throw Error(result);
        }
    };

    const verifyUid = async (admission_number) => {
        const result = await dispatch(verifyUidThunk({ admission_number }));
        if (verifyUidThunk.fulfilled.match(result)) {
            const {
                active,
                existingUser,
                profileCompleted,
                isPassSet,
                phone,
                isdCode,
            } = result.payload.data;

            if (!active) {
                return Promise.reject(new Error("Inactive uid"));
            }
            if (!existingUser) {
                return Promise.reject(new Error("Invalid Admission ID"));
            }
            if (active && existingUser && isPassSet && phone) {
                navigate(navigation.ENTER_PASS_CODE);
            } else if (!phone) {
                navigate(navigation.ENTER_REGISTERED_PHONE);
            } else if (!isPassSet) {
                await generateOtp(
                    {
                        phone: phone,
                        isd_code: isdCode,
                        admission_number: admission_number,
                        tenant_id: authTenantId,
                        product_id: authProductId,
                    },
                    navigation.ENTER_VERIFY_OTP
                );
            }

            return Promise.resolve(result);
        }

        if (verifyUidThunk.rejected.match(result)) {
            loginError({ result });
        }
    };
    const generateOtp = async (body, nextStep = null, stateObj = {}) => {
        await dispatch(verifyUidThunk(body));
        const result = await dispatch(generateOTPThunk({ body }));

        if (nextStep) {
            navigate(nextStep, { state: stateObj });
        }

        if (generateOTPThunk.rejected.match(result)) {
            loginError({ result });
        }
    };

    const getUidByPhone = async (body) => {
        const result = await dispatch(getUidByPhoneThunk(body));

        if (getUidByPhoneThunk.rejected.match(result)) {
            loginError({ result });
        }

        return transformAdmissionNumberResponse(result);
    };

    const setNewPasscode = async (body) => {
        const result = await dispatch(setNewPasscodeThunk(body));

        if (setNewPasscodeThunk.fulfilled.match(result)) {
            return Promise.resolve(result);
        }

        if (setNewPasscodeThunk.rejected.match(result)) {
            loginError({ result });
        }
    };

    const onLogOut = useCallback(async (showLogoutPopup) => {
        const out = async () => {
            await dispatch(logoutThunk());
            await dispatch(closeModal());
            navigate(navigation.LOGIN);
        };

        if (showLogoutPopup) {
            logoutPopup(out);
        } else {
            await out();
        }
    }, []);

    const checkToAuthorized = useCallback(async () => {
        if (isAuthorized) {
            if (
                new Date(new Date() - new Date(timeLastLogin)).getTime() >
                timeToCheckToken
            ) {
                await onLogOut(false);
                tokenIsExpired();
                return;
            }

            dispatch(startLoading());

            try {
                const result = await authApi.validateBearer();

                if (checkUserPaymentStatus) {
                    const paymentResult =
                        await paymentApi.studentPaymentStatus();

                    if (!paymentResult?.data?.status) {
                        paymentNotPaid();
                    }
                }

                if (result.data) {
                    navigate(navigation.HOME);
                } else {
                    await onLogOut(false);
                    tokenIsExpired();
                }
            } catch (e) {
                await AsyncStorage.clear();
                console.log("validateBearer error", e);
            } finally {
                dispatch(stopLoading());
            }
        }
    }, [isAuthorized, timeLastLogin, checkUserPaymentStatus]);

    const checkToGetMapping = useCallback(
        async (body = {}) => {
            const result = await dispatch(getMappingThunk(body));

            if (getMappingThunk.fulfilled.match(result)) {
                return Promise.resolve(result);
            }
        },
        [userInfo]
    );

    const checkRejectResponse = async (result) => {
        if (result && typeof result === "object" && !Array.isArray(result)) {
            const statusCode = result?.payload?.status || null;

            if (statusCode && (statusCode === 401 || statusCode === 403)) {
                await onLogOut(false);
                tokenIsExpired();
            }
        }
    };

    return {
        onLogin,
        loginWithOtp,
        onLogOut,
        verifyUid,
        getUidByPhone,
        generateOtp,
        setNewPasscode,
        checkToAuthorized,
        checkRejectResponse,
        checkToGetMapping,
    };
};
