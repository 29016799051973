import * as React from "react";
import { forwardRef } from "react";
import { View } from "react-native";
import Carousel from "react-native-reanimated-carousel";
import { useStylesUtils } from "../../../../hooks";
// import AutoHeightImage from "react-native-auto-height-image";

const GalleryCarousel = forwardRef(function ({ data = [], onSnapToItem }, ref) {
    const { styles, width, height } = useStylesUtils(getStyles());
    const baseOptions = {
        vertical: false,
        width: width,
        height: height * 0.55,
    };

    return (
        <Carousel
            {...baseOptions}
            style={{
                width: width,
            }}
            ref={ref}
            loop={false}
            pagingEnabled={true}
            snapEnabled={true}
            autoPlay={false}
            mode="parallax"
            modeConfig={{
                parallaxScrollingScale: 0.9,
                parallaxScrollingOffset: 280,
            }}
            onSnapToItem={onSnapToItem}
            data={data}
            renderItem={(item) => {
                if (item.item) {
                    return (
                        <View style={styles.container}>
                            {/*<AutoHeightImage*/}
                            {/*    width={width * 0.65}*/}
                            {/*    source={{ uri: item.item }}*/}
                            {/*/>*/}
                        </View>
                    );
                }
            }}
        />
    );
});
export default GalleryCarousel;

const getStyles =
    () =>
    ({ width }) => ({
        container: {
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
        },
        image: {
            width: width * 0.65,
            aspectRatio: 16 / 9,
            borderRadius: 30,
        },
    });
