import React, { useEffect, useRef, useState } from "react";
import { ImageBackground, Text, TouchableOpacity, View } from "react-native";
import Carousel from "react-native-reanimated-carousel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-native";
import { BackArrowIcon, RemoveBtn } from "../../../assets/svg";
import {
    AddMoreIcon,
    CropIcon,
    UploadIcon,
} from "../../../assets/svg/galleryIcons";
import { Button, DashedLine } from "../../../components";
import { COLOURS, navigation } from "../../../constants";
import { useHaptic, useStylesUtils } from "../../../hooks";
import { getUserImages } from "../../../store/main/selectors";
import GalleryCarousel from "./components/galleryCarousel";
import ImageManipulator from "./components/ImageManipulator";
import { useImages } from "../../../hooks/useImagesHook";

export const GalleryScreen = () => {
    const images = useSelector(getUserImages);
    const navigate = useNavigate();
    const imagesControl = useImages();
    const { hapticImpact } = useHaptic();
    const mainCarrouselRef = useRef(null);
    const footerCarrouselRef = useRef(null);

    const { styles, width, height, imageSizes } = useStylesUtils(getStyles());

    const baseOptions = {
        vertical: false,
        width: width / 5,
        style: {
            flex: 1,
            width: "100%",
        },
    };

    const [imageIndex, setImageIndex] = useState(0);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        mainCarrouselRef.current.scrollTo({
            index: imageIndex,
            animated: false,
        });
        footerCarrouselRef.current.scrollTo({
            index: imageIndex,
            animated: true,
        });
    }, [imageIndex]);

    const onSnapToItem = (itemIndex) => {
        hapticImpact(1);
        setImageIndex(itemIndex);
    };
    const onRemove = () => {
        imagesControl.removeImage(imageIndex);
    };
    const onImageEdited = (data) => {
        setIsEditing(false);
        const { uri } = data;
        imagesControl.editImage(imageIndex, uri);
    };
    const navigateBack = () => {
        navigate(-1);
    };

    if (isEditing) {
        return (
            <ImageManipulator
                photo={{ uri: images[imageIndex] }}
                isVisible={true}
                onPictureChoosed={(data) => {
                    onImageEdited(data);
                }}
                saveOptions={{
                    compress: 1,
                    format: "png",
                    base64: false,
                }}
                btnTexts={{
                    done: "Done",
                    crop: "Crop",
                    processing: "Processing",
                }}
            />
        );
    }

    return (
        <ImageBackground
            resizeMode="cover"
            style={styles.container}
            source={images.mainBGPurple}>
            <View style={styles.header}>
                <Button
                    onPress={() => setIsEditing(true)}
                    label={({ style }) => {
                        return (
                            <View
                                style={{
                                    flexDirection: "row",
                                    zIndex: 1000,
                                    alignItems: "center",
                                    justifyContent: "space-betwen",
                                }}>
                                <CropIcon
                                    height={width * 0.025}
                                    width={width * 0.025}
                                />
                                <Text style={style}>Edit</Text>
                            </View>
                        );
                    }}
                />
                <TouchableOpacity onPress={onRemove}>
                    <RemoveBtn {...imageSizes.standard} />
                </TouchableOpacity>
            </View>
            <View style={styles.carousel}>
                <GalleryCarousel
                    data={images}
                    onSnapToItem={onSnapToItem}
                    ref={mainCarrouselRef}
                />
            </View>
            <View style={styles.footer}>
                <View style={styles.footerCarousel}>
                    <Carousel
                        {...baseOptions}
                        loop={false}
                        ref={footerCarrouselRef}
                        autoPlay={false}
                        data={images}
                        pagingEnabled={true}
                        onSnapToItem={onSnapToItem}
                        renderItem={(item) => {
                            if (item.item) {
                                return (
                                    <View
                                        style={{
                                            flex: 1,
                                            justifyContent: "center",
                                        }}>
                                        {/*<AutoHeightImage*/}
                                        {/*    width={height * 0.3}*/}
                                        {/*    maxHeight={height * 0.175}*/}
                                        {/*    source={{ uri: item.item }}*/}
                                        {/*/>*/}
                                    </View>
                                );
                            }
                        }}
                    />
                </View>
                <DashedLine
                    vertical={true}
                    color={"#B38AD9"}
                    viewStyle={{
                        marginRight: "1%",
                    }}
                />
                <View style={styles.footerBtns}>
                    <Button
                        iconProps={{ ratio: 0.11 }}
                        onPress={() => navigate(navigation.CAMERA_SCREEN)}
                        label={({ style }) => {
                            return (
                                <View
                                    style={{
                                        flexDirection: "row",
                                        zIndex: 1000,
                                        alignItems: "center",
                                        justifyContent: "space-betwen",
                                    }}>
                                    <AddMoreIcon
                                        height={width * 0.025}
                                        width={width * 0.025}
                                    />
                                    <Text style={style}>Add more</Text>
                                </View>
                            );
                        }}
                    />
                    <Button
                        iconProps={{ ratio: 0.11 }}
                        label={({ style }) => {
                            return (
                                <View
                                    style={{
                                        flexDirection: "row",
                                        zIndex: 1000,
                                        alignItems: "center",
                                        justifyContent: "space-betwen",
                                    }}>
                                    <UploadIcon
                                        height={width * 0.025}
                                        width={width * 0.025}
                                    />
                                    <Text style={style}>Upload</Text>
                                </View>
                            );
                        }}
                    />
                </View>
            </View>
            <TouchableOpacity style={styles.backButton} onPress={navigateBack}>
                <BackArrowIcon {...imageSizes.standard} />
            </TouchableOpacity>
        </ImageBackground>
    );
};

const getStyles =
    () =>
    ({ height, width }) => ({
        container: {
            flex: 1,
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: COLOURS.purple3,
            flexDirection: "column",
            padding: 0,
        },
        header: {
            width: "20%",
            height: height * 0.15,
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
        },
        carousel: {
            width: "100%",
            height: height * 0.55,
        },
        carouselImage: {
            width: width * 0.15,
        },
        footer: {
            width: "100%",
            height: height * 0.2,
            backgroundColor: "#EFE2FE",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
        },
        footerBtns: {
            width: "25%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            overflow: "hidden",
            height: height * 0.2,
        },
        footerCarousel: {
            width: "70%",
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: width * 0.025,
        },
        backButton: {
            position: "absolute",
            zIndex: 100,
            top: 10,
            left: 0,
            marginTop: 10,
            marginLeft: 20,
        },
    });
