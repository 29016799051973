import { useStylesUtils } from "../../../../../hooks";
import { TouchableOpacity, View } from "react-native";
import { Image, MathJaxView } from "../../../../../components";
import { COLOURS, QUIZ_OPTION_STATUS } from "../../../../../constants";
import React, { useMemo, useContext } from "react";
import { QuizOptionMarker } from "../components/QuizOptionMarker";
import QuizContext from "../../QuizContext";

export const MatchOption = ({
    style: optionStyle,
    onOptionPick,
    isPickup,
    text,
    image = null,
    color,
    isCorrect,
}) => {
    const { isFinished } = useContext(QuizContext);

    const { bgStyles, borderStyles } = useMemo(() => {
        const borderStyles = {};
        const bgStyles = {
            backgroundColor: color ? color : COLOURS.yellow1,
        };

        if (isCorrect !== undefined) {
            bgStyles.backgroundColor = isCorrect
                ? COLOURS.green11
                : COLOURS.red8;

            borderStyles.borderWidth = 5;
            borderStyles.borderColor = isCorrect
                ? COLOURS.quizItemSuccess
                : COLOURS.quizItemError;
        }

        return {
            bgStyles,
            borderStyles,
        };
    }, [isCorrect, color]);

    const { styles, biggerSize } = useStylesUtils(getStyles(isPickup, image), {
        optionStyle,
        borderStyles,
    });

    return (
        <TouchableOpacity
            activeOpacity={isFinished ? 1 : 0.2}
            style={[styles.option, styles.optionStyle, bgStyles]}
            onPress={onOptionPick}>
            <View style={[styles.contentWrapper, styles.borderStyles]}>
                {image ? (
                    <Image
                        source={image}
                        style={{
                            width: "100%",
                            height: "100%",
                            maxWidth: "100%",
                            maxHeight: "100%",
                        }}
                        resizeMode="contain"
                    />
                ) : (
                    <MathJaxView
                        html={text}
                        isTextCentered={true}
                        textStyles={{ lineHeight: 6 }}
                    />
                )}
                {isCorrect !== undefined && (
                    <QuizOptionMarker
                        status={
                            isCorrect
                                ? QUIZ_OPTION_STATUS.SUCCESS
                                : QUIZ_OPTION_STATUS.ERROR
                        }
                        inversion={true}
                    />
                )}
            </View>
        </TouchableOpacity>
    );
};

const getStyles =
    (isPickup, image) =>
    ({ biggerSize }) => ({
        option: {
            flex: 1,
            borderRadius: 15,
            overflow: "hidden",
            // marginBottom: biggerSize * 0.01,
            padding: 0,
            paddingBottom: biggerSize * 0.005,
        },
        contentWrapper: {
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 15,
            overflow: "hidden",
            paddingVertical: biggerSize * 0.005,
            paddingHorizontal: biggerSize * 0.01,
            backgroundColor: COLOURS.white,
            opacity: isPickup ? 0.8 : 1,
        },
    });
