import React, { useCallback } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { MagnifyingGlassIcon, MicIcon } from "../../../assets/svg";
import { DashedLine } from "../../../components";
import { CustomScrollView } from "../../../components/CustomScrollView";
import { COLOURS } from "../../../constants";
import { useStylesUtils } from "../../../hooks";
import { Input } from "../../auth/components";
import { results } from "./results";

export const SearchField = ({
    pressOnMicrophone,
    setValue,
    value,
    recording,
}) => {
    const { styles, width, imageSizes } = useStylesUtils(getStyles);

    const checkTitle = useCallback(
        (str) => {
            const check = (str2) => {
                if (str2.toLowerCase().includes(value.toLowerCase())) {
                    const firsPart = str2.slice(
                        0,
                        str2.toLowerCase().indexOf(value.toLowerCase())
                    );

                    const middlePart = str2.slice(
                        firsPart.length,
                        firsPart.length + value.length
                    );

                    const lastPart = str2.slice(
                        firsPart.length + middlePart.length
                    );

                    return (
                        <>
                            <Text>{firsPart}</Text>
                            <Text style={styles.searchResultTextFounded}>
                                {middlePart}
                            </Text>
                            <Text>{check(lastPart)}</Text>
                        </>
                    );
                } else {
                    return str2;
                }
            };

            return <Text style={styles.searchResultText}>{check(str)}</Text>;
        },
        [value]
    );

    return (
        <View style={styles.container} pointerEvents="box-none">
            {value.length > 0 && (
                <View style={styles.searchResultBlock} pointerEvents="box-none">
                    <View
                        style={styles.resultScrollView}
                        pointerEvents="box-none">
                        <CustomScrollView showIndicator={true}>
                            {results.map(({ id, title }, i, all) => (
                                <TouchableOpacity key={"searchResult" + id}>
                                    {checkTitle(title)}
                                    {i !== all.length - 1 && (
                                        <DashedLine
                                            vertical={false}
                                            color={COLOURS.yellow5}
                                        />
                                    )}
                                </TouchableOpacity>
                            ))}
                        </CustomScrollView>
                    </View>
                </View>
            )}
            <View style={styles.inputBlock}>
                <Input
                    label={"User ID"}
                    style={styles.inputView}
                    inputState={{
                        value,
                        setValue,
                        style: { fontSize: 20 },
                    }}
                    type={2}>
                    <TouchableOpacity
                        style={{ position: "absolute", right: width * 0.03 }}>
                        <MagnifyingGlassIcon
                            width={width * 0.03}
                            height={width * 0.03}
                        />
                    </TouchableOpacity>
                </Input>
                <TouchableOpacity
                    style={styles.button}
                    onPress={pressOnMicrophone}>
                    <MicIcon
                        {...imageSizes.type1}
                        fill={recording ? "#E39463" : "#fff"}
                    />
                </TouchableOpacity>
            </View>
        </View>
    );
};

const getStyles = ({ height, width }) => ({
    container: {
        width: width,
        position: "absolute",
        top: -width * 0.07,
        alignItems: "center",
        justifyContent: "center",
    },
    inputBlock: {
        position: "absolute",
        top: 0,
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    inputView: {
        paddingHorizontal: width * 0.02,
        fontSize: 24,
    },
    searchResultBlock: {
        backgroundColor: "#fff",
        maxHeight: height * 0.6,
        width: width * 0.476,
        left: -width * 0.025,
        top: width * 0.01,
        borderColor: COLOURS.blue4,
        borderWidth: 2,
        borderRadius: 20,
        paddingRight: width * 0.002,
    },
    resultScrollView: {
        paddingHorizontal: width * 0.03,
        paddingBottom: width * 0.01,
        marginTop: width * 0.045,
    },
    searchResultText: {
        paddingVertical: width * 0.01,
        fontSize: 24,
        fontWeight: 700,
        color: COLOURS.grey3,
    },
    searchResultTextFounded: {
        color: COLOURS.dark_purple,
    },
});
