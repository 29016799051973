import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
    return (
        <Svg
            width={129}
            height={120}
            viewBox="0 0 129 120"
            fill="#073763"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M53.957 0c33.604 0 74.623 19.205 74.623 52.81 0 33.603-30.686 66.585-64.29 66.585C30.686 119.395 0 86.414 0 52.81 0 19.205 20.353 0 53.957 0z"
                fill="#fff"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M53 7c29.271 0 65 16.729 65 46s-26.729 58-56 58C32.73 111 6 82.271 6 53S23.73 7 53 7z"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M94.606 50.122c.896.96-28.66 2.302-39.265 0-1.583-2.536 0-11.413 0-11.413a1.27 1.27 0 00-.782-1.172 1.263 1.263 0 00-1.38.275c-8.999 5.307-16.054 11.877-20.266 20.291a1.268 1.268 0 000 1.793c5.788 8.624 12.354 15.75 20.266 20.291a1.266 1.266 0 002.162-.896V67.877c-.515 1.675 36.57 3.732 39.265 0 2.102-2.913 1.266-.567 1.266-1.268-1.298-3.738-.819-9.303 0-15.218 0-.7-.566-1.269-1.266-1.269"
                fill="#fff"
            />
        </Svg>
    )
}

export default SvgComponent
