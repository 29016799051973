import moment from "moment";

export const parseDatesToStrUtil = (obj) => {
    if (typeof obj !== "object" || Array.isArray(obj)) {
        return obj;
    }

    if (obj?.startDate && typeof obj.startDate === "string") {
        obj.startDate = moment(obj.startDate).format("YYYY-MM-DD");
    }

    if (obj?.endDate && typeof obj.endDate === "string") {
        obj.endDate = moment(obj.endDate).format("YYYY-MM-DD");
    }

    return obj;
};

export const timeLineDateUtil = (start, end, showToday) => {
    switch (true) {
        case moment(start).isSame(end, "day"):
            let text = moment(start).format("dddd, Do MMMM YYYY");
            if (moment(start).isSame(Date.now(), "day") && showToday) {
                text += " (today)";
            }
            return text;
        case moment(start).isSame(end, "month"):
            return (
                moment(start).format("Do") +
                " - " +
                moment(end).format("Do") +
                " " +
                moment(start).format("MMMM YYYY")
            );
        case moment(start).isSame(end, "year"):
            return (
                moment(start).format("Do MMMM") +
                " - " +
                moment(end).format("Do MMMM") +
                " " +
                moment(start).format("YYYY")
            );
        default:
            return (
                moment(start).format("Do MMMM YYYY") +
                " - " +
                moment(end).format("Do MMMM YYYY")
            );
    }
};
