export const firstLetterUppercase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeStr = (str) => {
    if (!str) {
        return "";
    }
    let splitter = " ";

    if (str?.includes("\n")) {
        splitter = "\n";
    }

    return str
        ?.split(splitter)
        ?.map((word) => firstLetterUppercase(word))
        ?.join(splitter);
};
