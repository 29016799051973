import React, { useCallback, useEffect, useState } from "react";
import { Image, Platform, Text, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AuthLayout } from "../../../components";
import { COLOURS, images } from "../../../constants";
import { useStylesUtils } from "../../../hooks";
import { useAuth } from "../../../hooks/useAuth";
import { getUserVerifyInfo } from "../../../store/auth/selectors";
import { maskPhone } from "../../../utils/transformData";
import { Button, PinInput } from "../components";
import { authProductId, authTenantId } from "../../../api/constants";

export const EnterVerifyOtp = () => {
    const { styles } = useStylesUtils(getStyles);

    const { loginWithOtp, generateOtp } = useAuth();
    const { state } = useLocation();
    const verifyInfo = useSelector(getUserVerifyInfo);
    const [userOtp, setUserOtp] = useState("");
    const [userOtpInvalid, setUserOtpInvalid] = useState(false);
    const [userOtpError, setUserOtpError] = useState(false);
    const [timerSeconds, setTimerSeconds] = useState(10);
    const [timerActive, setTimerActive] = useState(true);

    useEffect(() => {
        setUserOtpInvalid(false);
    }, [userOtp]);

    useEffect(() => {
        setUserOtpInvalid(userOtpError);
    }, [userOtpError]);
    const startTimer = () => {
        setTimerSeconds(10);
        setTimerActive(true);
    };
    useEffect(() => {
        let interval = null;
        if (timerActive) {
            interval = setInterval(() => {
                setTimerSeconds((seconds) => seconds - 1);
            }, 1000);
            if (!timerActive || timerSeconds <= 0) {
                clearInterval(interval);
                setTimerActive(false);
            }
        }
        return () => clearInterval(interval);
    }, [timerActive, timerSeconds]);

    const pressSubmit = useCallback(() => {
        loginWithOtp(
            { admission_number: verifyInfo.uid, otp: userOtp },
            state?.loginWithOtp
        )
            .then(() => {})
            .catch(() => {
                setUserOtp("");
                setUserOtpInvalid(true);
                setUserOtpError(true);
            });
    }, [userOtp]);

    const resendOtp = () => {
        if (!timerActive) {
            setUserOtp("");
            generateOtp({
                phone: verifyInfo?.phone,
                isd_code: verifyInfo?.isdCode,
                admission_number: verifyInfo?.uid,
                tenant_id: authTenantId,
                product_id: authProductId,
            });
            startTimer();
        }
    };

    return (
        <AuthLayout headerProps={{ backButton: true }}>
            <Text style={styles.title}>Verify OTP</Text>
            <Text style={styles.label}>{`Enter OTP sent to ${
                verifyInfo?.isdCode
            }-${maskPhone(verifyInfo?.phone)}`}</Text>
            <View style={styles.formContainer}>
                <PinInput
                    pinState={{
                        pin: userOtp,
                        setPin: setUserOtp,
                        isError: userOtpInvalid,
                    }}
                />
                <Button
                    disabled={userOtp.length !== 4}
                    label={"Verify"}
                    onPress={pressSubmit}
                    style={{ marginLeft: 20, marginTop: 5 }}
                />
            </View>
            <View style={styles.forgotCodeContainer}>
                <TouchableOpacity style={styles.flexRow} onPress={resendOtp}>
                    {userOtpError && (
                        <View style={[styles.flexRow, styles.divider]}>
                            <Image
                                style={styles.errorCodeIcon}
                                source={images.importantIcon}></Image>
                            <Text style={styles.errorCodeText}>
                                Incorrect OTP
                            </Text>
                        </View>
                    )}
                    <View style={styles.flexRow}>
                        {timerActive ? (
                            <Text style={styles.forgotCodeText}>
                                {`Resend OTP in 00:${timerSeconds}`}
                            </Text>
                        ) : (
                            <Text
                                style={[
                                    styles.forgotCodeText,
                                    styles.underlined,
                                ]}>
                                {`Resend OTP`}
                            </Text>
                        )}
                    </View>
                </TouchableOpacity>
            </View>
        </AuthLayout>
    );
};

const getStyles = {
    title: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        marginBottom: 10,
        fontSize: 30,
    },
    label: {
        fontWeight: 700,
        color: COLOURS.dark_purple,
        marginBottom: 10,
        fontSize: 16,
    },
    formContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 10,
        width: Platform.OS === "web" ? "auto" : 300,
    },
    forgotCodeContainer: {
        marginLeft: -90,
        justifyContent: "center",
    },
    forgotCodeText: {
        fontWeight: 700,
        color: COLOURS.purple,
        fontSize: 12,
    },
    divider: {
        borderRightWidth: 3,
        borderRightColor: COLOURS.orange,
        paddingRight: 10,
        marginRight: 10,
    },
    underlined: {
        textDecorationLine: "underline",
    },
    flexRow: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    errorCodeText: {
        fontWeight: 700,
        color: COLOURS.errorRed,
        fontSize: 12,
    },
    errorCodeIcon: {
        resizeMode: "contain",
        height: 15,
        width: 15,
        marginRight: 5,
    },
};
