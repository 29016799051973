import React from "react";
import { ActivityIndicator, Pressable, Text, View } from "react-native";
import { GameConsoleIcon } from "../../assets/svg";
import { COLOURS } from "../../constants";
import { useStylesUtils } from "../../hooks";
import { fillColorUtils } from "../../utils/fillColor.utils";
import { CardsFooterBar } from "./components/CardsFotterBar";
import { CardsImage } from "./components/CardsImage";
import { CardsStatus } from "./components/CardsStatus";

export const GameConsoleCard = ({
    title = "",
    colour = null,
    image,
    onPress = () => {},
    isActive,
    status = null,
    category = null,
    icon = null,
    fill,
}) => {
    const { styles, biggerSize } = useStylesUtils(getStyles);

    return (
        <Pressable style={styles.container} onPress={onPress}>
            <View style={styles.tvContainer}>
                <GameConsoleIcon
                    width={biggerSize * 0.305}
                    height={biggerSize * 0.2}
                    fill={fillColorUtils(isActive, colour, fill)}
                    style={styles.icon}
                />
                <View style={styles.activityIndicator}>
                    <ActivityIndicator size="large" color={COLOURS.orange} />
                </View>
                <CardsImage source={image} style={styles.cardImage} />
                <CardsFooterBar category={category} icon={icon} />
                <CardsStatus status={status} style={styles.statusImage} />
            </View>
            <Text numberOfLines={1} ellipsizeMode="tail" style={styles.title}>
                {title}
            </Text>
        </Pressable>
    );
};

const getStyles = ({ biggerSize, height }) => ({
    container: {
        marginRight: biggerSize * 0.02,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    tvContainer: {
        marginTop: biggerSize * 0.02,
        marginBottom: biggerSize * 0.008,
    },
    cardImage: {
        position: "absolute",
        width: biggerSize * 0.21,
        height: biggerSize * 0.16,
        zIndex: 10,
        top: biggerSize * 0.02,
        left: biggerSize * 0.05,
        borderRadius: 10,
    },
    activityIndicator: {
        position: "absolute",
        width: biggerSize * 0.21,
        height: biggerSize * 0.16,
        zIndex: 1,
        top: biggerSize * 0.02,
        left: biggerSize * 0.05,
        borderRadius: 10,
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: "center",
    },
    title: {
        fontSize: 20,
        textAlign: "center",
        fontWeight: 700,
        color: "#fff",
        maxWidth: biggerSize * 0.305,
    },
    icon: {
        zIndex: -5,
    },
    statusImage: {
        position: "absolute",
        zIndex: 100,
        right: -5,
        top: -biggerSize * 0.005,
        width: biggerSize * 0.04,
        height: biggerSize * 0.04,
    },
});
