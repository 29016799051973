import { View } from "react-native";
import { useStylesUtils } from "../../../hooks";
import { COLOURS } from "../../../constants";
import { CardsImage } from "./CardsImage";

export const CardsGrade = ({ grade, style, imageStyle }) => {
    const { styles } = useStylesUtils(getStyles(style), { imageStyle });

    return grade ? (
        <View
            style={[
                styles.container,
                style,
                { backgroundColor: COLOURS.yellow9 },
            ]}>
            <CardsImage source={grade.gradeIcon} style={styles.imageStyle} />
            {/*<Text style={[styles.text, { color: COLOURS.brown9 }]}>*/}
            {/*    {grade.displayMessage}*/}
            {/*</Text>*/}
        </View>
    ) : null;
};

const getStyles = (style) => () => ({
    container: {
        width: "100%",
        height: "10%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: style.height * 0.12,
        paddingHorizontal: style.height * 0.2,
        marginBottom: -style.height * 0.1,
        marginTop: style.height * 0.1,
    },
    text: {
        flex: 1,
        fontSize: 12,
        fontWeight: 700,
        textAlign: "center",
    },
});
