import { currentAllowedGradeIDs } from "../constants";

export const validateUid = (uid) => {
    return uid.trim().length >= 2;
};

export const parsePhoneNumber = (phoneNumber) => {
    const clearPhone = `${phoneNumber}`.replace(/\D+/g, "");
    const isd = "+" + clearPhone.slice(0, 2);
    const phone = clearPhone.slice(2, 20);

    return {
        valid: isd.length === 3 && phone.length === 10,
        isd,
        phone,
    };
};
export const validateGrade = (gradeId) => {
    return currentAllowedGradeIDs.includes(gradeId);
};
