import { View } from "react-native";
import { useStylesUtils } from "../../../hooks";

export const HeaderTitle = ({ children }) => {
    const { styles } = useStylesUtils(getStyles);

    return children ? <View style={styles.container}>{children}</View> : null;
};

const getStyles = {
    container: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
    },
};
