import {
    AcademicActivityIcon,
    AcademicBookIcon,
    AcademicDateIcon,
    AcademicDurationIcon,
    AcademicGameIcon,
    AcademicVideoIcon,
} from "../../../assets/svg/academic";

export const suggestionList = [
    { id: 1, title: "Suggestion 1" },
    { id: 2, title: "Suggestion 2" },
    { id: 3, title: "Suggestion 3" },
    { id: 4, title: "Suggestion 4" },
    { id: 5, title: "Suggestion 5" },
    { id: 6, title: "Suggestion 6" },
    { id: 7, title: "Suggestion 7" },
    { id: 8, title: "Suggestion 8" },
    { id: 9, title: "Suggestion 9" },
    { id: 10, title: "Suggestion 10" },
];

export const activityTable = {
    columns: [
        { id: 1, title: "Date", property: "date", Icon: AcademicDateIcon },
        {
            id: 2,
            title: "Activity",
            property: "activity",
            Icon: AcademicActivityIcon,
        },
        {
            id: 3,
            title: "Duration",
            property: "duration",
            Icon: AcademicDurationIcon,
        },
    ],
    rows: [
        {
            id: 1,
            date: "22nd March",
            activity: { title: "ABC Video", Icon: AcademicVideoIcon },
            duration: "15 mins",
        },
        {
            id: 2,
            date: "22nd March",
            activity: { title: "Abacus Activity", Icon: AcademicGameIcon },
            duration: "15 mins",
        },
        {
            id: 3,
            date: "22nd March",
            activity: { title: "ABC Video", Icon: AcademicBookIcon },
            duration: "15 mins",
        },
        {
            id: 4,
            date: "22nd March",
            activity: "ABC Video",
            duration: "15 mins",
        },
        {
            id: 5,
            date: "22nd March",
            activity: "ABC Video",
            duration: "15 mins",
        },
        {
            id: 6,
            date: "22nd March",
            activity: { title: "ABC Video", Icon: AcademicVideoIcon },
            duration: "15 mins",
        },
        {
            id: 7,
            date: "22nd March",
            activity: "ABC Video",
            duration: "15 mins",
        },
        {
            id: 8,
            date: "22nd March",
            activity: "ABC Video",
            duration: "15 mins",
        },
    ],
};

export const reportTable = {
    columns: [
        { id: 1, title: "Date", property: "date", Icon: AcademicDateIcon },
        {
            id: 2,
            title: "Test Name",
            property: "name",
            Icon: AcademicActivityIcon,
        },
        {
            id: 3,
            title: "Evaluation",
            property: "evaluation",
            Icon: AcademicDurationIcon,
        },
    ],
    rows: [
        {
            id: 1,
            date: "22nd March",
            name: { title: "ABC Video", Icon: AcademicVideoIcon },
            evaluation: { title: "Report pending", type: "important" },
        },
        {
            id: 2,
            date: "22nd March",
            name: { title: "Abacus Activity", Icon: AcademicGameIcon },
            evaluation: { title: "B grade", subTitle: "(report)" },
        },
        {
            id: 3,
            date: "22nd March",
            name: { title: "ABC Video", Icon: AcademicBookIcon },
            evaluation: {
                title: "B grade",
                subTitle: "(report)",
                type: "important",
            },
        },
        {
            id: 4,
            date: "22nd March",
            name: "ABC Video",
            evaluation: "15 mins",
        },
        {
            id: 5,
            date: "22nd March",
            name: "ABC Video",
            evaluation: "15 mins",
        },
        {
            id: 6,
            date: "22nd March",
            name: { title: "ABC Video", Icon: AcademicVideoIcon },
            evaluation: "15 mins",
        },
        {
            id: 7,
            date: "22nd March",
            name: "ABC Video",
            evaluation: "15 mins",
        },
        {
            id: 8,
            date: "22nd March",
            name: "ABC Video",
            evaluation: "15 mins",
        },
    ],
};

export const workReports = [
    { id: 1, title: "Listen", percent: 30 },
    { id: 2, title: "Speak", percent: 60 },
    { id: 3, title: "Read", percent: 80 },
    { id: 4, title: "Write", percent: 10 },
    { id: 5, title: "Listen", percent: 50 },
];
