import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
    return (
        <Svg
            width={258}
            height={237}
            viewBox="0 0 258 237"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M233.218 48.8557C270.195 102.584 265.795 177.84 212.066 214.817C158.338 251.793 55.4216 240.885 18.4452 187.157C-18.5307 133.428 5.58775 59.5794 59.3159 22.6029C113.044 -14.3735 196.242 -4.87238 233.218 48.8557"
                fill="#6DB160"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M107.688 194.965L84.8065 200.867C80.0437 202.096 75.1868 199.231 73.9583 194.468L53.5129 115.203C52.2844 110.44 55.1495 105.583 59.9124 104.354L82.7934 98.4524C87.5562 97.2239 92.4131 100.089 93.6416 104.852L114.087 184.117C115.315 188.88 112.45 193.737 107.688 194.965Z"
                fill="white"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M203.198 81.122C201.251 73.5757 195.275 69.299 187.154 70.7961C175.13 73.2039 162.273 76.586 151.564 80.1333C151.278 80.229 151.229 79.3265 151.144 78.5105C149.502 62.7183 153.138 59.4515 148.037 44.261C146.25 38.943 140.781 32.8945 135.484 31.6248C130.829 30.5096 120.13 31.908 122.524 43.2521C123.055 46.5081 124.043 50.5502 124.269 53.3679C125.534 69.1 115.81 83.3684 105.383 94.9877C104.673 95.7782 103.657 96.8883 102.647 97.9865C100.41 100.417 99.558 103.815 100.383 107.013L118.494 177.204C119.49 181.063 122.736 183.925 126.69 184.431C133.945 185.361 144.617 184.45 158.58 180.848C166.335 178.848 165.53 179.159 171.605 177.545C186.632 173.554 198.543 169.45 201.798 162.834C201.925 162.562 202.149 161.831 202.195 161.656C202.536 160.327 202.765 158.475 202.201 156.272L201.549 153.742C200.646 150.244 198.981 147.402 195.775 146.381C203.688 144.34 205.856 136.718 204.298 130.678L203.485 127.525C202.588 124.048 199.98 121.468 196.796 120.449L197.258 120.33C202.527 118.97 205.754 112.228 204.138 105.966L203.503 103.505C202.725 100.486 198.924 96.896 196.304 95.6716C202.397 93.6598 204.824 87.4259 203.198 81.122"
                fill="white"
            />
        </Svg>
    )
}

export default SvgComponent
