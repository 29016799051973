import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={125}
            height={101}
            viewBox="0 0 125 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M36.099 38.336c8.06 0 14.62-6.558 14.62-14.62 0-8.06-6.56-14.619-14.62-14.619-8.062 0-14.62 6.558-14.62 14.62 0 8.06 6.558 14.62 14.62 14.62zM84.997 32.653c8.79 0 15.94-7.15 15.94-15.94 0-8.789-7.15-15.939-15.94-15.939-8.789 0-15.939 7.15-15.939 15.94 0 8.789 7.15 15.939 15.94 15.939zM123.429 58.174l-16.26-17.267a14.176 14.176 0 00-10.281-4.44h-23.78a14.177 14.177 0 00-10.283 4.44l-2.984 3.111-2.506 2.598-1.447-1.555a9.942 9.942 0 00-7.255-3.16H23.565a9.942 9.942 0 00-7.256 3.16L1.45 61.03a4.833 4.833 0 00.01 6.576 4.838 4.838 0 006.545.457l10.542-8.51a1.802 1.802 0 012.904 1.729l-7.2 39.216h32.478v-8.303c0-.49-.199-.958-.552-1.298L32.07 77.308a5.04 5.04 0 01-.447-6.8 5.029 5.029 0 013.403-1.876 5.028 5.028 0 013.73 1.089L49.5 78.374c.066.052.134.098.204.14a16.528 16.528 0 0010.362 3.639c3.916 0 7.52-1.364 10.362-3.64.07-.041.139-.087.204-.14l10.744-8.652a5.029 5.029 0 013.73-1.09 5.028 5.028 0 013.403 1.877 5.04 5.04 0 01-.447 6.8l-14.107 13.59c-.353.34-.552.808-.552 1.298v8.303h27.535V56.515a1.803 1.803 0 012.972-1.37l12.353 10.552a5.184 5.184 0 006.991-.211c2.041-1.968 2.12-5.248.176-7.312M60.065 78.549c-7.17 0-13.005-5.834-13.005-13.005 0-7.171 5.834-13.005 13.005-13.005 7.171 0 13.005 5.834 13.005 13.005 0 7.17-5.834 13.005-13.005 13.005z"
                fill="#FEA08A"
            />
        </Svg>
    );
}

export default SvgComponent;
