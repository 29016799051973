export const popupTypes = {
    NONE: null,
    ERROR: "popup_type_error",
    SUCCESS: "popup_type_success",
    IMPORTANT: "popup_type_important",
    INFO: "popup_type_info",
};

export const popupCheckType = (type) => {
    if (Object.values(popupTypes).includes(type)) {
        return type;
    }

    return popupTypes.NONE;
};
