import AsyncStorage from "@react-native-async-storage/async-storage";
import { createSlice } from "@reduxjs/toolkit";
import {
    loginThunk,
    loginWithOtpThunk,
    logoutThunk,
    verifyUidThunk,
    getUserDtoThunk,
    getMappingThunk,
} from "./thunks";

const initialState = {
    user: null,
    accessToken: null,
    refreshToken: null,
    isAuthorized: false,
    mappingInfo: {},
    timeLastLogin: null,
    verifyUidInfo: {},
};

const clearState = () => initialState;

const slice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearAuthState: clearState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginThunk.fulfilled, userLoginReducer)
            .addCase(getUserDtoThunk.fulfilled, userDtoReducer)
            .addCase(loginWithOtpThunk.fulfilled, userLoginReducer)
            .addCase(verifyUidThunk.fulfilled, (state, { payload }) => {
                state.verifyUidInfo = {
                    ...state.verifyUidInfo,
                    ...payload.data,
                    uid: payload.uid,
                };
            })
            .addCase(logoutThunk.fulfilled, clearState)
            .addCase(logoutThunk.rejected, clearState)
            .addCase(getMappingThunk.fulfilled, (state, { payload }) => {
                if (typeof payload === "object" && !Array.isArray(payload)) {
                    state.mappingInfo = payload;
                }
            });
    },
});

export const { clearAuthState } = slice.actions;
export default slice.reducer;

const userLoginReducer = (state, { payload }) => {
    AsyncStorage.setItem(
        "info",
        JSON.stringify({
            accessToken: payload.accessToken,
        })
    );

    state.accessToken = payload.accessToken;
    state.refreshToken = payload.refreshToken;
    state.isAuthorized = true;
    state.timeLastLogin = new Date();
};
const userDtoReducer = (state, { payload }) => {
    AsyncStorage.getItem("info").then((prevData) => {
        const parsedPrevData = JSON.parse(prevData);
        const data = JSON.stringify({
            tenantId: payload.tenantId,
            subTenant: payload.ProgramId,
            userId: payload.userId,
            ...parsedPrevData,
        });
        AsyncStorage.setItem("info", data);
    });
    state.user = { ...payload, subTenant: payload.ProgramId };
};
