import React, { useState } from "react";
import {
    ImageBackground,
    SafeAreaView,
    ScrollView,
    Text,
    TouchableOpacity,
    View,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { CarrouselComponent } from "../../../components";
import { COLOURS, images } from "../../../constants";
import { useStylesUtils } from "../../../hooks";
import { AcademicTable } from "./AcademicTable";
import {
    activityTable,
    reportTable,
    suggestionList,
    workReports,
} from "./dataTest";
import { RepostCardView } from "./RepostCardView";

export const Content = ({ activeTab }) => {
    const insets = useSafeAreaInsets();
    const { styles } = useStylesUtils(getStyles(insets));

    const [showWorkReport, setShowWorkReport] = useState(false);

    const suggestionOnPress = () => setShowWorkReport(true);

    return (
        <View style={styles.content}>
            <ImageBackground
                source={images.academicContentBG}
                style={styles.contentBGImage}>
                {showWorkReport ? (
                    <SafeAreaView style={{ flex: 1 }}>
                        <CarrouselComponent
                            data={workReports}
                            CardView={RepostCardView}
                        />
                    </SafeAreaView>
                ) : (
                    <SafeAreaView style={{ flex: 1 }}>
                        {activeTab === 1 && (
                            <View style={{ flex: 0 }}>
                                <ScrollView
                                    horizontal={true}
                                    showsHorizontalScrollIndicator={true}
                                    style={styles.suggestionList}>
                                    {suggestionList.map((item) => (
                                        <TouchableOpacity
                                            onPress={suggestionOnPress}
                                            key={item.id}
                                            style={styles.suggestionListItem}>
                                            <Text
                                                style={
                                                    styles.suggestionListItemText
                                                }>
                                                {item.title}
                                            </Text>
                                        </TouchableOpacity>
                                    ))}
                                </ScrollView>
                            </View>
                        )}
                        <View style={styles.tableWrapper}>
                            <ScrollView style={styles.tableContainer}>
                                <AcademicTable
                                    data={
                                        activeTab === 1
                                            ? activityTable
                                            : reportTable
                                    }
                                />
                            </ScrollView>
                        </View>
                    </SafeAreaView>
                )}
            </ImageBackground>
        </View>
    );
};

const getStyles =
    (insets) =>
    ({ width }) => ({
        content: {
            flex: 1,
            backgroundColor: COLOURS.pink1,
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.23,
            shadowRadius: 2.62,
            elevation: 4,
        },
        contentBGImage: { width: "100%", height: "100%" },
        suggestionList: {
            flex: 0,
            paddingTop: width * 0.02,
            paddingHorizontal: insets.left || insets.right ? 0 : 20,
        },
        suggestionListItem: {
            overflow: "hidden",
            backgroundColor: "#fff",
            borderWidth: 2,
            borderColor: COLOURS.pink2,
            borderRadius: 10,
            paddingHorizontal: width * 0.02,
            paddingVertical: width * 0.01,
            marginRight: 10,
        },
        suggestionListItemText: {
            fontSize: 20,
            fontWeight: 700,
            color: COLOURS.blue4,
        },
        tableWrapper: {
            flex: 1,
            paddingTop: 20,
            paddingHorizontal: insets.left || insets.right ? 0 : 20,
            paddingBottom: insets.bottom ? 0 : 20,
        },
        tableContainer: {
            backgroundColor: COLOURS.pink3,
            borderRadius: 10,
            paddingTop: 10,
            paddingHorizontal: 10,
        },
    });
