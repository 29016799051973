import { projectType } from "../api/constants";

const getDomain = () => {
    switch (projectType) {
        case "Prod":
            return ".srichaitanyameta.com";
        default:
            return ".infinitylearn.com";
    }
};

const cookiesSeter = (policy, keyPairId, signature) => {
    console.log("try to set cookies");
    // document.cookie = `CloudFront-Policy = ${policy}; SameSite=none; Secure=true; path=/`;
    // document.cookie = `CloudFront-Key-Pair-Id=${keyPairId}; SameSite=none; Secure=true; path=/`;
    // document.cookie = `CloudFront-Signature=${signature}; SameSite=none; Secure=true; path=/`;

    document.cookie = `CloudFront-Policy = ${policy}; SameSite=none; Secure=true; path=/; domain=${getDomain()}`;
    document.cookie = `CloudFront-Key-Pair-Id=${keyPairId}; SameSite=none; Secure=true; path=/; domain=${getDomain()}`;
    document.cookie = `CloudFront-Signature=${signature}; SameSite=none; Secure=true; path=/; domain=${getDomain()}`;
};

const deleteAllCookies = () => {
    const expires = "Thu, 01 Jan 2010 00:00:00 UTC";

    document.cookie = `CloudFront-Policy=; path=/; domain=${getDomain()};expires=${expires}`;
    document.cookie = `CloudFront-Key-Pair-Id=; path=/; domain=${getDomain()};expires=${expires}`;
    document.cookie = `CloudFront-Signature=; path=/; domain=${getDomain()};expires=${expires}`;
};

export { cookiesSeter, deleteAllCookies };
