import React, { useMemo } from "react";
import { LayoutTabs } from "../../tabs/LayoutTabs";
import { ImageBackground, SafeAreaView, View } from "react-native";
import { useSelector } from "react-redux";
import { getScreenColors } from "../../../store/app/selectors";

export const useMainLayoutHook = ({
    headerProps,
    children,
    tabs,
    styles,
    isSaveArea,
}) => {
    const { image, bgColor, contentBgColor } = useSelector(getScreenColors);

    const { checkedHeaderProps, checkedChildren } = useMemo(() => {
        if (tabs && Array.isArray(tabs) && tabs.length > 0) {
            headerProps.titleContent = <LayoutTabs list={tabs} />;
            children = (
                <ImageBackground
                    resizeMode="cover"
                    source={image}
                    style={[
                        styles.contentBgStyle,
                        { backgroundColor: contentBgColor },
                    ]}>
                    {children}
                </ImageBackground>
            );
        }

        return {
            checkedHeaderProps: headerProps,
            checkedChildren: children,
        };
    }, [tabs, headerProps, children, contentBgColor, image]);

    const content = useMemo(() => {
        const ContentView = isSaveArea ? SafeAreaView : View;

        return (
            <ContentView
                style={[styles.content, styles.viewStyle]}
                pointerEvents="box-none">
                {checkedChildren}
            </ContentView>
        );
    }, [isSaveArea, styles, checkedChildren]);

    return {
        checkedHeaderProps,
        content,
        image,
        bgColor,
    };
};
