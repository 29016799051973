import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SVGComponent = (props) => (
    <Svg
        width={52}
        height={47}
        viewBox="0 0 52 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.58134 30.4084C6.89182 30.3962 6.45478 29.6315 6.80735 29.0642C9.00234 25.5659 11.264 22.0013 13.5435 18.4037C13.8938 17.8517 14.6931 17.844 15.0349 18.3924C16.1637 20.2043 17.3017 22.026 18.4464 23.8539C20.5309 20.5106 22.6351 17.1465 24.7142 13.7892C25.0592 13.2497 25.856 13.2495 26.1999 13.7883C27.9684 16.6432 29.7565 19.5048 31.5371 22.3553C32.1705 23.3698 33.4664 23.7024 34.5234 23.1551C36.4317 22.1675 38.5904 21.6219 40.8729 21.6304C40.8784 21.6304 40.8833 21.6306 40.8891 21.6304C41.7452 21.6337 42.4427 20.9649 42.4365 20.1173C42.3951 14.1957 42.2371 8.27519 41.9644 2.36485C41.9243 1.51021 41.2043 0.775638 40.3676 0.737318C27.7264 0.161868 15.1608 0.338968 2.47368 1.26736C1.63412 1.32948 0.894653 2.06551 0.835613 2.89798C0.0841532 13.623 -0.0738767 24.3791 0.362063 35.117C0.396753 35.9509 1.12824 36.6657 1.9816 36.7033C9.80139 37.0397 17.615 37.2109 25.4286 37.2175C26.2735 37.2184 26.9455 36.5111 26.9459 35.6486C26.9459 35.6435 26.9466 35.6379 26.9459 35.6325C26.9475 34.596 27.0587 33.5862 27.2716 32.6143C27.5035 31.5569 26.6903 30.5617 25.6188 30.5615C19.6051 30.56 13.5907 30.509 7.58133 30.4084M13.4101 7.58077C15.4515 7.25835 17.164 8.84538 17.1369 10.8883C17.111 12.7812 15.5773 14.3101 13.7149 14.3358C11.7922 14.361 10.2746 12.821 10.4007 10.8885C10.5069 9.25771 11.7995 7.83452 13.4101 7.58077"
            fill="#EFE2FE"
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.8732 24.75C34.9304 24.7522 30.0868 29.6315 30.054 35.6189C30.0452 41.6039 34.6448 46.432 40.3528 46.1552C46.0646 45.8631 51.15 40.8077 51.4032 35.1163C51.646 29.4219 46.813 24.7687 40.8732 24.75"
            fill="#D6B9F7"
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.0713 34.2514C46.491 34.2514 46.8408 34.387 47.1206 34.658C47.4004 34.9291 47.5403 35.2745 47.5403 35.6942C47.5403 36.1139 47.4004 36.4593 47.1206 36.7303C46.8408 37.0014 46.491 37.1369 46.0713 37.1369H42.3989V40.5733C42.3989 41.0629 42.2371 41.4651 41.9136 41.7799C41.5901 42.0947 41.1835 42.2521 40.6938 42.2521C40.2041 42.2521 39.7976 42.0947 39.474 41.7799C39.1505 41.4651 38.9887 41.0629 38.9887 40.5733V37.1369H35.395C34.9753 37.1369 34.6255 36.997 34.3457 36.7172C34.0659 36.4374 33.926 36.0876 33.926 35.6679C33.926 35.2482 34.0659 34.9072 34.3457 34.6449C34.6255 34.3826 34.9753 34.2514 35.395 34.2514H38.9887V30.3429C38.9887 29.8532 39.1505 29.451 39.474 29.1362C39.7976 28.8215 40.2129 28.6641 40.72 28.6641C41.2097 28.6641 41.6119 28.8215 41.9267 29.1362C42.2415 29.451 42.3989 29.8532 42.3989 30.3429V34.2514L46.0713 34.2514Z"
            fill="white"
        />
    </Svg>
);
export default SVGComponent;
