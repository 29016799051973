import React, { useMemo, useState } from "react";
import { Pressable, Text, View } from "react-native";
import Tooltip from "react-native-walkthrough-tooltip";
import { useStylesUtils } from "../hooks";
import { COLOURS } from "../constants";

export const LinkWithTooltip = ({ label = " ", content }) => {
    const [isOpen, changeOpen] = useState(false);
    const { styles, width } = useStylesUtils(getStyles);

    const title = useMemo(() => {
        if (typeof label === "string") {
            return label;
        }

        if (typeof label === "function") {
            return label({ isOpen, textStyle: styles.text });
        }

        return <></>;
    }, [label, isOpen]);

    return (
        <>
            <Tooltip
                isVisible={isOpen}
                content={content}
                placement="top"
                contentStyle={{
                    backgroundColor: COLOURS.yellow4,
                    borderRadius: 10,
                    padding: 30,
                }}
                onClose={() => changeOpen(false)}>
                <Pressable
                    style={styles.container}
                    onPress={() => changeOpen((prev) => !prev)}>
                    {title}
                </Pressable>
            </Tooltip>
        </>
    );
};
const getStyles = {
    container: {
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
    },
    text: {
        fontWeight: 700,
        color: COLOURS.grey2,
        fontSize: 14,
        marginLeft: 5,
        textDecorationLine: "underline",
    },
};
