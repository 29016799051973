import React from "react";
import { Text, View } from "react-native";
import {
    CelebrationIcon,
    ExamIcon,
    HolidayIcon,
    PtmIcon,
} from "../../../../assets/svg";
import { DashedLine } from "../../../../components";
import { COLOURS } from "../../../../constants";
import { useStylesUtils } from "../../../../hooks";

export const EventCard = () => {
    const { styles, height, width, imageSizes } = useStylesUtils(getStyles());
    const getIcon = (type) => {
        const iconType = type.toLowerCase();
        switch (iconType) {
            case "celebration":
                return <CelebrationIcon {...imageSizes.type1} />;
            case "exam":
                return <ExamIcon {...imageSizes.type1} />;
            case "holiday":
                return <HolidayIcon {...imageSizes.type1} />;
            case "ptm":
                return <PtmIcon {...imageSizes.type1} />;
        }
    };

    return (
        <View style={styles.container}>
            <View style={styles.informationContainer}>
                {getIcon("exam")}
                <View style={styles.textContainer}>
                    <Text style={styles.dateLable}>December 1st</Text>
                    <Text style={styles.titleLable}>XYZ day holiday</Text>
                </View>
            </View>
            <DashedLine
                vertical={false}
                color={"#B38AD9"}
                viewStyle={{ marginTop: height * 0.025 }}
            />
        </View>
    );
};

const getStyles =
    () =>
    ({ height, width }) => ({
        container: {
            width: "100%",
            height: height * 0.15,
            justifyContent: "center",
        },
        informationContainer: {
            flexDirection: "row",
            alignItems: "center",
            justifyItems: "flex-start",
        },
        textContainer: {
            color: COLOURS.dark_purple,
            marginLeft: width * 0.015,
        },
        dateLable: {
            fontWeight: 700,
            fontSize: 15,
            color: COLOURS.dark_purple,
        },
        titleLable: {
            fontWeight: 700,
            fontSize: 20,
            color: COLOURS.dark_purple,
        },
    });
