import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    useContext,
} from "react";
import { ImageBackground, TouchableOpacity, Text, View } from "react-native";
import { interpolate } from "react-native-reanimated";
import Carousel from "react-native-reanimated-carousel";
import { useDispatch, useSelector } from "react-redux";
import { COLOURS, images } from "../../../constants";
import { useHaptic, useLearnHook, useStylesUtils } from "../../../hooks";
import { getCurrentSubjectIndex } from "../../../store/app/selectors";
import {
    setSelectedSubjectId,
    setSelectedSubjectIndex,
} from "../../../store/app/slice";
import { getCategoriesListRedux } from "../../../store/main/selectors";
import { parseListsUtils } from "../../../utils/parse-lists.utils";
import { CardsImage } from "../../../components/carrouselCards/components/CardsImage";
import GlobalAudioContext from "../../../providers/GlobalAudioContext";

export const TitleContent = ({ selectedCategoryID }) => {
    const dispatch = useDispatch();
    const currentSubjectIndex = useSelector(getCurrentSubjectIndex);
    const { playClickSound } = useContext(GlobalAudioContext);
    const { width, biggerSize, height, styles } = useStylesUtils(getStyles);

    const carouselRef = useRef(null);

    const itemSize = biggerSize * 0.12;
    const centerOffset = (biggerSize * 0.7) / 2 - itemSize / 2;

    const { getBooksAndAssetCount } = useLearnHook();
    const { hapticImpact } = useHaptic();

    const categories = useSelector(getCategoriesListRedux);

    const [selectedCategory, setSelectedCategory] = useState();

    const animationStyle = useCallback(
        (value) => {
            "worklet";
            const itemGap = interpolate(
                value,
                [-3, -2, -1, 0, 1, 2, 3],
                [-30, -15, 0, 0, 0, 15, 30]
            );

            const translateX =
                interpolate(value, [-1, 0, 1], [-itemSize, 0, itemSize]) +
                centerOffset -
                itemGap;

            const translateY = interpolate(
                value,
                [-1, -0.5, 0, 0.5, 1],
                [25, 10, 5, 10, 25]
            );

            const scale = interpolate(
                value,
                [-1, -0.5, 0, 0.5, 1],
                [0.8, 0.85, 1.1, 0.85, 0.8]
            );

            return {
                transform: [{ translateX }, { translateY }, { scale }],
            };
        },
        [centerOffset]
    );

    const getChacheKey = () => {};

    const parseCategories = useMemo(() => {
        const filteredCategories = categories?.data?.childNodes?.filter(
            (cat) => cat?.examSubjectStatusName === "Active"
        );

        return parseListsUtils(
            filteredCategories,
            ({ id, icon, examSubjectThumbnailUrl }) => ({
                image: examSubjectThumbnailUrl ? examSubjectThumbnailUrl : icon,
                isSelected: id === selectedCategoryID,
            })
        );
    }, [categories, selectedCategoryID]);

    const changeActiveTab = useCallback(
        (id, carouselIndex) => {
            setSelectedCategory(id);
            if (id && id !== selectedCategoryID) {
                if (carouselIndex) {
                    dispatch(setSelectedSubjectIndex(carouselIndex));
                    carouselRef.current.scrollTo({
                        index: carouselIndex,
                        animated: false,
                    });
                }
                getBooksAndAssetCount({ subjectId: id });
            }
        },
        [selectedCategoryID]
    );
    const onSnapToItem = (itemIndex) => {
        hapticImpact(1);
        const selectedCat = parseCategories[itemIndex]?.id;
        dispatch(setSelectedSubjectIndex(itemIndex));
        setSelectedCategory(selectedCat);
        changeActiveTab(selectedCat);
    };

    const initialize = () => {
        setSelectedCategory(
            parseCategories[currentSubjectIndex]?.id || parseCategories[0]?.id
        );
        const id = parseCategories[currentSubjectIndex]?.id;
        getBooksAndAssetCount({ subjectId: id });

        carouselRef.current.scrollTo({
            index: currentSubjectIndex,
            animated: false,
        });
    };

    useEffect(() => {
        if (currentSubjectIndex && parseCategories[currentSubjectIndex]?.id) {
            initialize();
        } else {
            setSelectedCategory(parseCategories[0]?.id);
        }
    }, []);

    useEffect(() => {
        dispatch(setSelectedSubjectId(selectedCategory));
    }, [selectedCategory]);

    return (
        <Carousel
            ref={carouselRef}
            width={biggerSize * 0.15}
            height={biggerSize * 0.1}
            onSnapToItem={onSnapToItem}
            style={{
                flex: 1,
                maxWidth: biggerSize * 0.7,
                height: biggerSize * 0.15,
            }}
            loop={false}
            autoPlay={false}
            customAnimation={animationStyle}
            data={parseCategories}
            renderItem={(item) => (
                <TouchableOpacity
                    key={item?.item?.id}
                    onPress={() => {
                        playClickSound();
                        changeActiveTab(item?.item?.id, item.index);
                    }}>
                    {item?.item?.id === selectedCategory && (
                        <>
                            <ImageBackground
                                style={styles.bgImage}
                                source={images.categoryPlaceholderIcon}
                            />
                            {/*<ActivityIndicator*/}
                            {/*    size="large"*/}
                            {/*    style={styles.bgImage}*/}
                            {/*/>*/}
                            <Text
                                style={styles.title}
                                numberOfLines={1}
                                ellipsizeMode="tail">
                                {item?.item?.title}
                            </Text>
                        </>
                    )}
                    {item?.item?.image ? (
                        <CardsImage
                            style={{
                                width: biggerSize * 0.08,
                                aspectRatio: 1,
                                position: "absolute",
                                top: biggerSize * 0.01,
                            }}
                            source={
                                item?.item?.image?.url
                                    ? item?.item?.image?.url
                                    : item?.item?.image
                            }
                            resizeMode="contain"
                        />
                    ) : (
                        <View
                            style={{
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: COLOURS.lightBlue2,
                                borderRadius: height * 0.3,
                                width: biggerSize * 0.095,
                            }}>
                            <Text style={{ textAlign: "center" }}>
                                {item?.item?.title}
                            </Text>
                        </View>
                    )}
                </TouchableOpacity>
            )}
        />
    );
};

const getStyles = ({ biggerSize, height }) => ({
    bgImage: {
        width: biggerSize * 0.11,
        height: biggerSize * 0.1,
        zIndex: -1,
        position: "absolute",
        left: -biggerSize * 0.015,
    },
    title: {
        position: "absolute",
        left: -biggerSize * 0.035,
        bottom: -biggerSize * 0.13,
        zIndex: 9999,
        textAlign: "center",
        verticalAlign: "center",
        fontWeight: 700,
        fontSize: 23,
        width: biggerSize * 0.2,
    },
});
