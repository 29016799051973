import AsyncStorage from "@react-native-async-storage/async-storage";
import { APP_KEY, APP_SECRET } from "../api/constants";

export const getSavedRootInfo = async () => {
    try {
        const result = JSON.parse(await AsyncStorage.getItem("info"));
        return result;
    } catch (e) {
        console.log("getSavedInfo error", e);
    }
};

export const getToken = async () => {
    try {
        const { accessToken } = await getSavedRootInfo();
        return accessToken;
    } catch (e) {
        console.log("getToken error", e);
    }
};

export const addTokenToConfig = async (config) => {
    const accessToken = await getToken();

    if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
};

export const addAppKeyToConfig = async (config) => {
    config.headers["appkey"] = APP_KEY;
    config.headers["appsecret"] = APP_SECRET;
    return config;
};

export const addAllInfoToConfig = async (
    config,
    {
        needUserId = true,
        needTenantId = true,
        needSubTenant = true,
        needAccessToken = true,
        needSecurity = true,
    } = {}
) => {
    const { tenantId, subTenant, accessToken, userId } =
        await getSavedRootInfo();

    if (userId && needUserId) {
        config.headers["userid"] = userId;
    }

    if (tenantId && needTenantId) {
        config.headers["tenantid"] = tenantId;
    }

    if (subTenant && needSubTenant) {
        config.headers["subtenantid"] = subTenant;
    }

    if (accessToken && needAccessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    if (needSecurity) {
        config.headers["security"] = "infinitylearn";
    }

    return config;
};
