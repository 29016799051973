import moment from "moment/moment";
import { Text, TouchableOpacity, View } from "react-native";
import { ArrowIcon } from "../../../../assets/svg";
import { COLOURS, quicksandFont } from "../../../../constants";
import { useStylesUtils } from "../../../../hooks";

export const CalendarHeader = ({ headerRef }) => {
    const { styles, screenOptions, width, imageSizes } = useStylesUtils(
        getStyles()
    );

    return (
        <View style={styles.calendarHeader}>
            <View style={styles.dateHeader}>
                <TouchableOpacity onPress={() => headerRef.addMonth(-1)}>
                    <ArrowIcon {...imageSizes.type1} />
                </TouchableOpacity>
                <Text style={styles.headerTitle}>
                    {`${moment(headerRef?.month.getMonth() + 1, "M").format(
                        "MMMM"
                    )} ${headerRef?.month.getFullYear()}`}
                </Text>
                <TouchableOpacity onPress={() => headerRef.addMonth(+1)}>
                    <ArrowIcon
                        {...imageSizes.type1}
                        transform={[{ rotateY: "180deg" }]}
                    />
                </TouchableOpacity>
            </View>
            <View style={styles.dayLine}>
                <Text style={{ ...styles.dayNames, color: COLOURS.red2 }}>
                    S
                </Text>
                <Text style={styles.dayNames}>M</Text>
                <Text style={styles.dayNames}>T</Text>
                <Text style={styles.dayNames}>W</Text>
                <Text style={styles.dayNames}>T</Text>
                <Text style={styles.dayNames}>F</Text>
                <Text style={{ ...styles.dayNames, color: COLOURS.red2 }}>
                    S
                </Text>
            </View>
        </View>
    );
};

const getStyles =
    () =>
    ({ height }) => ({
        calendarHeader: {
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: COLOURS.purple15,
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
        },
        dateHeader: {
            width: "105%",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundColor: COLOURS.purple15,
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
        },
        headerTitle: {
            color: COLOURS.dark_purple,
            fontSize: 25,
            fontFamily: quicksandFont.Quicksand_700Bold,
            fontWeight: 700,
        },
        dayLine: {
            backgroundColor: COLOURS.purple19,
            width: "105%",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            paddingHorizontal: "3%",
        },
        dayNames: {
            color: COLOURS.dark_purple,
            fontSize: 17,
            lineHeight: height * 0.025,
            fontFamily: quicksandFont.Quicksand_700Bold,
            fontWeight: 500,
        },
    });
