import { CMSInstance, SLMSInstance } from "./config";

export const mainApi = {
    getUserStoriesAndDetailsForChapter: async (params) => {
        return await CMSInstance.get(
            "/articulation/getUserStoriesAndDetailsforChapter",
            { params }
        );
    },
    getBooksAndAssetCount: async ({ examId, subjectId, subtenantId }) => {
        return await CMSInstance.get(
            `/examtoc/getBooksAndAssetCount/${examId}/${subjectId}/${subtenantId}`
        );
    },
    getSubjectsForExam: async ({ examId, subtenantId }) => {
        return await CMSInstance.get(
            `/examtoc/v2/getSubjectsForExam/${examId}?status=active&subtenantIds=${subtenantId}`
        );
    },
    getPlaylistsFor: async ({ params }) => {
        return await CMSInstance.get(`/video_playlist/get/filtered?`, {
            params,
        });
    },
    getChaptersForExamSubjectSubtenant: async (params) => {
        return await CMSInstance.get(
            `/examtoc/getChaptersForExamSubjectSubtenant`,
            { params }
        );
    },
    getVideoPlaylist: async (playlistId) => {
        return await CMSInstance.get(`/video_playlist/get/${playlistId}`);
    },
    getTopicsAndVideosForExamChapterSubtenant: async (params) => {
        return await CMSInstance.get(
            `/examtoc/getTopicsAndVideosForExamChapterSubtenant`,
            { params }
        );
    },
    getStoriesForExamNodeIds: async (params) => {
        return await CMSInstance.get(`/story/getStoriesForExamNodeIds`, {
            params,
        });
    },
    getStory: async ({ storyId, tenantId }) => {
        // return await CMSComunityInstance.get(`/story/get/${storyId}`);
        return await CMSInstance.get(`/story/get/v2/${storyId}/${tenantId}`);
    },
    getHomeworks: async (params) => {
        return await SLMSInstance.post("/v2/homework/getStudentHomeworkList", {
            ...params,
        });
    },
    setHomeworkStatusAsComplited: async (params) => {
        return await SLMSInstance.post(
            "/v1/homework/studentHomeworkAssetFinish",
            {
                ...params,
            }
        );
    },
    getHomeworkSubjects: async () => {
        return await CMSInstance.get(`/examtoc/v2/getSubjectsForExam/45`, {
            params: {
                status: "active",
                subtenantIds: 1107,
            },
        });
    },
    getDocumentByDocumentId: async ({ documentId }) => {
        return await CMSInstance.get(`/document/get/${documentId}`);
    },
    getVideoByVideoId: async ({ videoID }) => {
        return await CMSInstance.get(`/videos/get_video/${videoID}`);
    },

    // getHomeworkVideoByVideoId: async ({ videoID }) => {
    //     return await SLMSInstance.get(`/videos/get_video/${videoID}`);
    // },

    setHomeworkVideoProgress: async (params) => {
        return await SLMSInstance.post(
            `/v1/homework/updateHomeworkVideoProgress`,
            { ...params }
        );
    },
};
