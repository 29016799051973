import React from "react";
import { ImageBackground, Text, View } from "react-native";
import { COLOURS, images } from "../../../../../constants";
import { useStylesUtils } from "../../../../../hooks";

export const TitleContent = ({ totalPages = 0, currentPage = 0 }) => {
    const { styles } = useStylesUtils(getStyles);

    return (
        <View style={styles.container}>
            <ImageBackground
                source={images.quizzStepBg}
                resizeMode="stretch"
                style={styles.image}>
                <Text
                    style={styles.text}>{`${currentPage}/${totalPages}`}</Text>
            </ImageBackground>
        </View>
    );
};

const getStyles = ({ biggerSize, height, OS }) => ({
    container: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "100%",
        width: "100%",
    },
    image: {
        width: biggerSize * 0.11,
        height: (biggerSize * 0.11) / 2.87,
        justifyContent: "center",
    },
    text: {
        fontSize: 24,
        lineHeight: 13,
        textAlign: "center",
        fontWeight: 700,
        color: COLOURS.purple23,
        verticalAlign: "middle",
    },
});
