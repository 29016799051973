import axios from "axios";
import { Platform } from "react-native";
import {
    addAllInfoToConfig,
    addAppKeyToConfig,
    addTokenToConfig,
} from "../utils/storage.utils";
import {
    authAccept,
    authContentType,
    authProductId,
    authTenantId,
    currentApiConstants,
    xAppName,
    xAppVersion,
    xProduct,
    xTenant,
} from "./constants";

const { OS } = Platform;

const headers = {
    "x-app-name": xAppName,
    "x-app-version": xAppVersion,
    "x-tenant": xTenant,
    "x-product": xProduct,
    "x-platform": OS,
};

const authHeaders = {
    accept: authAccept,
    "product-id": authProductId,
    "tenant-id": authTenantId,
    platform: OS,
    "Content-Type": authContentType,
};

const {
    UAM_BASE_URL,
    AUTH_BASE_URL,
    OTP_BASE_URL,
    CMS_BASE_URL,
    SLMS_BASE_URL,
    SELFLERN_BASE_URL,
    TESTS_BASE_URL,
} = currentApiConstants;

const AUTH = axios.create({ baseURL: AUTH_BASE_URL, headers: authHeaders });
const OTP = axios.create({ baseURL: OTP_BASE_URL, headers: authHeaders });
const UAM = axios.create({ baseURL: UAM_BASE_URL, headers });
const CMS = axios.create({ baseURL: CMS_BASE_URL, headers });
const SLMS = axios.create({ baseURL: SLMS_BASE_URL, headers });
const TESTS = axios.create({ baseURL: TESTS_BASE_URL, headers });
const SELFLERN = axios.create({ baseURL: SELFLERN_BASE_URL, headers });

const instance = () => {
    const handleError =
        (title = "", request) =>
        (error) => {
            console.log(
                OS,
                "ERROR",
                title,
                request ? "request" : "response",
                error
            );
            return Promise.reject(error);
        };

    AUTH.interceptors.request.use(async (config) => {
        config = await addTokenToConfig(config);
        config = await addAppKeyToConfig(config);
        return config;
    }, handleError("AUTH interceptor", true));
    AUTH.interceptors.response.use(
        (response) => response,
        handleError("AUTH interceptor", false)
    );
    OTP.interceptors.request.use(async (config) => {
        config = await addTokenToConfig(config);
        config = await addAppKeyToConfig(config);
        return config;
    }, handleError("OTP interceptor", true));
    OTP.interceptors.response.use(
        (response) => response,
        handleError("OTP interceptor", false)
    );
    UAM.interceptors.request.use(async (config) => {
        config = await addTokenToConfig(config);
        config = await addAppKeyToConfig(config);

        return config;
    }, handleError("UAM interceptor", true));

    UAM.interceptors.response.use(
        (response) => response,
        handleError("UAM interceptor", false)
    );

    CMS.interceptors.request.use(async (config) => {
        config = await addAllInfoToConfig(config);
        return config;
    }, handleError("CMS interceptor", true));

    CMS.interceptors.response.use((response) => {
        return response;
    }, handleError("CMS interceptor", false));

    SLMS.interceptors.request.use(async (config) => {
        config = await addTokenToConfig(config);
        return config;
    }, handleError("SLMS interceptor", true));

    SLMS.interceptors.response.use((response) => {
        return response;
    }, handleError("SLMS interceptor", false));

    SELFLERN.interceptors.request.use(async (config) => {
        return config;
    }, handleError("SELFLERN interceptor", true));

    SELFLERN.interceptors.response.use((response) => {
        return response;
    }, handleError("SELFLERN interceptor", false));

    TESTS.interceptors.request.use(async (config) => {
        config = await addAllInfoToConfig(config, {
            needUserId: false,
            needTenantId: false,
            needSubTenant: false,
            needSecurity: false,
        });
        return config;
    }, handleError("TESTS interceptor", true));

    TESTS.interceptors.response.use((response) => {
        return response;
    }, handleError("TESTS interceptor", false));

    return {
        UAMInstance: UAM,
        AUTHInstance: AUTH,
        OTPInstance: OTP,
        CMSInstance: CMS,
        SLMSInstance: SLMS,
        TESTSInstance: TESTS,
        SELFLERNInstance: SELFLERN,
    };
};

export const {
    UAMInstance,
    AUTHInstance,
    OTPInstance,
    CMSInstance,
    SLMSInstance,
    SELFLERNInstance,
    TESTSInstance,
} = instance();
