import {
    OverdueStatusIcon,
    CompleteStatusIcon,
    PendingStatusIcon,
    LiveStatusIcon,
} from "../assets/svg";
import { HTMLElementModel, HTMLContentModel } from "react-native-render-html";
import { COLOURS } from "./colours";

export const testsStatuses = [
    { id: 1, title: "COMPLETED", Icon: CompleteStatusIcon },
    { id: 2, title: "PENDING", Icon: PendingStatusIcon },
    { id: 3, title: "MISSED", Icon: OverdueStatusIcon },
    { id: 4, title: "ONGOING", Icon: LiveStatusIcon },
];

export const getTestFinishedStatus = (test) => {
    const { status = null } = test;
    const find = testsStatuses.find(({ title }) => title === status);
    return find ? find.id === 1 || find.id === 3 : false;
};

export const getTestStatusTab = (test) => {
    const { status = null } = test;
    const find = testsStatuses.find(({ title }) => title === status);

    return {
        isPast: !!(find?.id === 1 || find?.id === 3),
        isScheduled: !!(find?.id === 2 || find?.id === 4),
    };
};

export const QUIZ_OPTION_STATUS = Object.freeze({
    NONE: "option_none",
    CHECKED: "option_checked",
    SUCCESS: "option_success",
    ERROR: "option_error",
});

export const QUIZ_QUESTION_STATUS = Object.freeze({
    NEW: "question_new",
    VISITED: "question_visited",
    SUCCESS: "question_success",
    ERROR: "question_error",
    SEMI_ERROR: "question_semi_error",
});

export const QUIZ_QUESTION_TYPE = Object.freeze({
    SINGLE_CORRECT: "SINGLE_CORRECT",
    MULTI_CORRECT: "MULTI_CORRECT",
    TRUE_FALSE: "TRUE_FALSE",
    FILL_IN_THE_BLANKS_REGULAR: "FILL_IN_THE_BLANKS_REGULAR",
    FILL_IN_THE_BLANKS_NUMERIC: "FILL_IN_THE_BLANKS_NUMERIC",
    FILL_IN_THE_BLANKS_INTEGER: "FILL_IN_THE_BLANKS_INTEGER",
    MATCH_THE_OPTIONS: "MATCH_THE_OPTIONS",
    NONE: "NONE",
});

export const transformTagsToBasic = [
    "mglyph",
    "math",
    "mi",
    "mfenced",
    "mrow",
    "mn",
    "mo",
    "msup",
    "mmultiscripts",
    "none",
    "mprescripts",
    "munderover",
    "mtable",
    "mtr",
    "mtd",
    "mfrac",
    "sup",
    "msub",
    "msubsup",
    "mspace",
    "mtext",
    "menclose",
    "merror",
    "mphantom",
    "mroot",
    "msqrt",
    "mstyle",
    "mover",
    "munder",
    "maction",
    "annotation",
    "semantics",
    "annotation-xml",
];

export const mmlOptions = (props = {}) => ({
    ...props,
    jax: ["input/MathML"],
});

const baseMixedUAStyles = {
    fontFamily: "Quicksand_400Regular",
    fontSize: 14,
    fontWeight: "700",
};

export const customHTMLElementModelsReplaceStandardTags = ({
    styles = {},
}) => ({
    p: HTMLElementModel.fromCustomModel({
        tagName: "p",
        mixedUAStyles: {
            ...baseMixedUAStyles,
            ...styles,
        },
        contentModel: HTMLContentModel.textual,
    }),
    span: HTMLElementModel.fromCustomModel({
        tagName: "span",
        mixedUAStyles: {
            ...baseMixedUAStyles,
            ...styles,
        },
        contentModel: HTMLContentModel.textual,
    }),
    img: HTMLElementModel.fromCustomModel({
        tagName: "img",
        mixedUAStyles: {
            ...baseMixedUAStyles,
            ...{
                width: 50,
                height: 50,
            },
        },
        contentModel: HTMLContentModel.block,
    }),
});

export const connectFonts = `<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">`;

export const testsMatchColors = [
    COLOURS.quizItemVisited,
    COLOURS.quizItemSuccess,
    COLOURS.quizItemSuccessShadow,
    COLOURS.quizItemSemiError,
    COLOURS.quizItemError,
    COLOURS.quizItemErrorShadow,
    COLOURS.quizItemChecked,
    COLOURS.quizItemCheckedShadow,
    COLOURS.pink2,
    COLOURS.brown6,
    COLOURS.dark_purple,
    COLOURS.turquoise,
    COLOURS.red9,
    COLOURS.brown11,
];
