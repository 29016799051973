import { createSlice } from "@reduxjs/toolkit";
import {
    getStudentTestWithParamsThunk,
    getStudentTestDetailsThunk,
    getStudentSpentTimeRedisThunk,
    studentTestResultReviewThunk,
    studentTestFinishThunk,
} from "./thunks";
import { parseListsUtils } from "../../utils/parse-lists.utils";
import { remapQuestionDetails } from "../../screens/main/QuizV3/utils/QuizParser";

const initialState = {
    testsList: [],
    pageLimit: 7,
    testCategory: 1,
    pageNo: 1,
    totalPages: 0,
    hasMoreRecords: false,
    filter: {
        status: "SCHEDULED",
        testCategories: [0],
    },
    currentTest: {
        isFinished: false,
        details: {},
        detailsSimplified: {},
        spentTimeRedis: 0,
    },
    currentTestResultReview: {
        testResult: {},
        testDetails: {},
    },
};

const slice = createSlice({
    name: "tests",
    initialState,
    reducers: {
        resetTestState: (state) => {
            const { filter, ...rest } = initialState;

            return {
                ...state,
                ...rest,
            };
        },
        resetTestFilter: (state) => {
            return {
                ...state,
                filter: initialState.filter,
            };
        },
        toggleTestFilterCategories: (state, { payload }) => {
            let list = state.filter.testCategories;

            if (list.includes(0)) {
                list = [];
            }
            if (list.includes(payload)) {
                list = list.filter((filterID) => filterID !== payload);
            } else {
                list = [...list, payload];
            }

            state.filter.testCategories = list.length ? list : [0];
        },
        resetTestFilterCategories: (state) => {
            state.filter.testCategories = [0];
        },
        changeTestFilterStatus: (state, { payload }) => {
            state.filter.status = payload;
        },
        changeCurrentTestFinishedStatus: (state, { payload }) => {
            state.currentTest.isFinished = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                getStudentTestWithParamsThunk.fulfilled,
                (state, { payload }) => {
                    const papers = payload.data.data || [];

                    state.testsList = parseListsUtils(
                        payload.addToExist
                            ? [...state.testsList, ...papers]
                            : papers
                    );
                    state.pageNo = payload.params.pageNo;
                    state.totalPages = Math.max(
                        Math.ceil(
                            (payload.data.totalElement || 0) /
                                payload.params.pageLimit
                        ),
                        1
                    );
                    state.hasMoreRecords =
                        payload?.data?.paginationDetails?.hasMoreRecords;
                }
            )
            .addCase(
                getStudentTestDetailsThunk.fulfilled,
                (state, { payload }) => {
                    state.currentTest.details = payload?.details;
                    state.currentTest.detailsSimplified = remapQuestionDetails(
                        payload?.details
                    );
                }
            )
            // .addCase(
            //     studentTestResultReviewThunk.fulfilled,
            //     (state, { payload }) => {
            //         state.currentTestResultReview = payload;
            //     }
            // )
            .addCase(
                studentTestResultReviewThunk.fulfilled,
                (state, { payload }) => {
                    state.currentTest.details = payload?.testDetails;
                    state.currentTest.isFinished = true;
                    state.currentTest.detailsSimplified = remapQuestionDetails(
                        payload?.testDetails
                    );
                }
            )
            .addCase(studentTestFinishThunk.fulfilled, (state, { payload }) => {
                state.currentTestResultReview = {
                    ...state.currentTestResultReview,
                    testResult: payload.score.data,
                };
            })
            .addCase(
                getStudentSpentTimeRedisThunk.fulfilled,
                (state, { payload }) => {
                    if (payload) {
                        state.currentTest.spentTimeRedis = payload;
                    }
                }
            );
    },
});

export const {
    resetTestState,
    resetTestFilter,
    resetTestFilterCategories,
    toggleTestFilterCategories,
    changeTestFilterStatus,
    changeCurrentTestFinishedStatus,
} = slice.actions;
export default slice.reducer;
