import * as React from "react";
import Svg, { Path } from "react-native-svg";

export const ScanAndUploadCardIcon = ({ img, ...rest }) => {
    return (
        <Svg
            width={511}
            height={385}
            viewBox="0 0 511 385"
            fill="#5F3389"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...rest}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M483.941 16.83c-9.136-.655-18.273-1.27-27.4-1.845-.63-6.828-6.071-12.621-12.364-13.03a3029.548 3029.548 0 00-33.363-1.94c-6.288-.335-11.15 4.958-11.141 11.884a3172.439 3172.439 0 00-35.456-1.41c-7.222-.25-13.469 3.62-17.171 9.69-108.757-2.944-217.279-.645-326.428 6.902-8.552.602-15.96 8.498-16.478 17.587a2633.448 2633.448 0 00.577 305.16c.555 9.087 7.98 17.017 16.509 17.664 108.935 8.102 217.098 10.57 325.562 7.409 3.673 6.077 9.906 9.918 17.103 9.651a2999.776 2999.776 0 00119.418-6.781c11.735-.923 21.97-11.882 22.749-24.382a2634.648 2634.648 0 00.59-312.269c-.729-12.519-10.956-23.427-22.707-24.29m-6.025 315.903c-.166 3.514-2.957 6.526-6.23 6.713-146.061 8.264-291.398 8.168-437.479-.291-3.271-.192-6.067-3.202-6.237-6.709a2915.07 2915.07 0 01-.498-269.537c.151-3.507 2.938-6.489 6.221-6.664a4202.152 4202.152 0 01438.496-.265c3.287.17 6.071 3.155 6.219 6.669a2980.601 2980.601 0 01-.492 270.084"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M-129.649 11.773h627.263v372.414h-627.263V11.772z"
                fill="url(#pattern0)"
            />
            <Path
                opacity={0.4}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M49.6 214.45c-1.872.141-3.743.292-5.613.449v98.587c-.001 5.52 4.186 10.389 9.33 10.758a613.915 613.915 0 0094.205-.718v-6.041a690.387 690.387 0 01-94.206.639c-2.046-.128-3.715-2.052-3.717-4.273V214.45zM457.41 179.27c1.87-.14 3.741-.291 5.612-.448V80.235c-.001-5.52-4.181-10.39-9.329-10.758a613.804 613.804 0 00-94.206.717v6.041a690.54 690.54 0 0194.206-.638c2.045.128 3.717 2.052 3.717 4.272v99.401z"
                fill="#7F52AA"
            />
        </Svg>
    );
};
