import React, { useContext } from "react";
import { Pressable, View } from "react-native";
import { RoundedIcon } from "../assets/svg";
import { useStylesUtils } from "../hooks";
import GlobalAudioContext from "../providers/GlobalAudioContext";

export const RoundedButton = ({
    children,
    onPress,
    style = {},
    imageStyle = {},
    viewStyle = {},
    pressImageStyle = {},
}) => {
    const { styles } = useStylesUtils(getStyles);
    const { playClickSound } = useContext(GlobalAudioContext);

    const checkImageProps = [
        "fill",
        "stroke",
        "width",
        "height",
        "strokeOpacity",
    ];

    const rewriteOnPress = (...props) => {
        playClickSound();
        onPress(...props);
    };

    const imageProps = (pressed) => {
        const props = {};
        const styless = {};
        const entries = pressed
            ? { ...imageStyle, ...pressImageStyle }
            : imageStyle;

        Object.entries(entries).forEach(([key, value]) => {
            if (checkImageProps.includes(key)) {
                props[key] = value;
            } else {
                styless[key] = value;
            }
        });

        return { ...props, style: [styles.image, styless] };
    };

    return (
        <Pressable onPress={rewriteOnPress} style={[styles.container, style]}>
            {({ pressed }) => (
                <>
                    <RoundedIcon strokeOpacity={1} {...imageProps(pressed)} />
                    {children && (
                        <View style={[styles.view, viewStyle]}>{children}</View>
                    )}
                </>
            )}
        </Pressable>
    );
};

const getStyles = {
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    image: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
    },
    view: {
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
};
