import {
    Quicksand_300Light,
    Quicksand_400Regular,
    Quicksand_500Medium,
    Quicksand_600SemiBold,
    Quicksand_700Bold,
} from "@expo-google-fonts/quicksand";

export const fontWeight = {
    default: "Quicksand_400Regular",
    ["300"]: "Quicksand_300Light",
    ["400"]: "Quicksand_400Regular",
    ["500"]: "Quicksand_500Medium",
    ["600"]: "Quicksand_600SemiBold",
    ["700"]: "Quicksand_700Bold",
};

export const quicksandFont = {
    Quicksand_300Light,
    Quicksand_400Regular,
    Quicksand_500Medium,
    Quicksand_600SemiBold,
    Quicksand_700Bold,
};
