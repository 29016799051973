import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
    return (
        <Svg
            width={85}
            height={77}
            viewBox="0 0 85 77"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M76.187 15.724c11.998 17.434 10.57 41.853-6.863 53.851C51.89 81.573 18.495 78.034 6.497 60.6-5.5 43.166 2.325 19.204 19.76 7.206c17.434-11.998 44.43-8.916 56.428 8.518z"
                fill="#A0DDFD"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.792 42.494c1.254.962 3.557 3.044 5.212 4.615 2.229.305 4.555.464 6.929.464 5.224 0 10.169-.768 14.553-2.132-.84-1.327-2.497-3.544-4.373-3.885-2.38-.436-7.022.37-12.019 1.694a2.478 2.478 0 01-2.334-.573 119.52 119.52 0 00-2.293-2.095c-1.303-1.144-2.63-2.252-3.483-2.79-.999-.608-3.007-1.875-11.958 4.519 1.68.95 3.52 1.779 5.48 2.484 1.803-1.133 3.422-2.008 4.286-2.301M58.947 43.662c.51.33 1.059.549 1.618.683a33.223 33.223 0 005.846-2.984 5.14 5.14 0 00-1.948-6.369 5.129 5.129 0 00-7.088 1.583 5.139 5.139 0 001.572 7.087"
                fill="#006093"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M75.157 31.363h-2.312a.708.708 0 00-.704.625l-1.063 8.845c-6.41 5.56-16.64 9.176-28.146 9.176s-21.726-3.616-28.144-9.178l-1.062-8.843a.71.71 0 00-.705-.625H10.71a.709.709 0 00-.708.742l1.428 30.735a.708.708 0 00.708.676h4.578a.71.71 0 00.705-.794l-2.153-17.914c4.681 7.688 15.299 13.067 27.665 13.067 12.369 0 22.988-5.38 27.668-13.07l-2.154 17.917a.71.71 0 00.705.794h4.58a.71.71 0 00.707-.676l1.428-30.735a.71.71 0 00-.709-.742M42.933 14.55a6.424 6.424 0 00-6.424 6.426 6.424 6.424 0 1012.85 0 6.425 6.425 0 00-6.426-6.425M42.933 12.539c.854 0 1.547-.693 1.547-1.548V8.434a1.548 1.548 0 00-3.094 0v2.557c0 .855.693 1.548 1.547 1.548zM34.78 15.01c.302.303.697.454 1.094.454a1.548 1.548 0 001.094-2.643l-1.808-1.806a1.549 1.549 0 00-2.189 2.189l1.809 1.806zM34.495 20.978c0-.854-.694-1.548-1.547-1.548H30.39a1.548 1.548 0 100 3.096h2.556c.854 0 1.548-.694 1.548-1.548z"
                fill="#006093"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.78 26.943l-1.808 1.807a1.546 1.546 0 001.093 2.642c.397 0 .79-.151 1.093-.453l1.807-1.806a1.546 1.546 0 10-2.185-2.19M42.933 29.416c-.854 0-1.547.693-1.547 1.547v2.556c0 .855.693 1.549 1.547 1.549s1.547-.694 1.547-1.549v-2.556c0-.854-.693-1.547-1.547-1.547zM51.087 26.944a1.547 1.547 0 00-2.185 2.19l1.808 1.805a1.539 1.539 0 002.186-.002 1.544 1.544 0 00-.001-2.186l-1.808-1.807zM55.477 19.43h-2.558a1.548 1.548 0 000 3.095h2.558a1.547 1.547 0 000-3.095zM49.994 15.464a1.54 1.54 0 001.095-.454l1.807-1.807a1.546 1.546 0 000-2.188 1.546 1.546 0 00-2.189 0L48.9 12.82a1.548 1.548 0 001.095 2.643z"
                fill="#006093"
            />
        </Svg>
    );
}

export default SvgComponent;
