import * as React from "react";
import Svg, { G, Path, Defs } from "react-native-svg";

/* SVGR has dropped some elements not supported by react-native-svg: filter */
function SvgComponent(props) {
    return (
        <Svg
            width={162}
            height={151}
            viewBox="0 0 162 146"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <G filter="url(#filter0_d_4887_1327)">
                <Path
                    d="M69.068.57c39.919 0 88.646 22.815 88.646 62.734s-36.453 79.1-76.371 79.1c-39.918 0-76.375-39.181-76.375-79.1C4.968 23.383 29.146.57 69.068.57z"
                    fill="#fff"
                />
                <Path
                    d="M67.928 8.886c34.772 0 77.215 19.873 77.215 54.645 0 34.772-31.752 68.9-66.523 68.9S12.096 98.302 12.096 63.53 33.156 8.886 67.928 8.886z"
                    fill="#0A3056"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M75.283 98.815c2.735 1.938 6.534 0 6.534-3.335V45.836c0-3.333-3.8-5.272-6.534-3.334L52.89 58.364H40.492c-2.282 0-4.132 1.835-4.132 4.098v16.393c0 2.263 1.85 4.098 4.132 4.098H52.89l22.393 15.862z"
                    fill="#fff"
                />
                <Path
                    d="M110.982 68.785c3.199 3.106 6.257 6.128 8.815 8.832.864.93 1.188 1.704 1.027 2.378-.16.673-.943 1.52-2.288 2.558-1.346 1.037-2.365 1.6-3.093 1.673-.736.076-1.444-.371-2.249-1.38-2.187-2.75-4.962-5.767-7.939-8.841-2.971 3.08-5.746 6.094-7.936 8.846-.834 1.01-1.547 1.454-2.25 1.382-.704-.07-1.719-.64-3.1-1.691-1.382-1.052-2.172-1.898-2.324-2.545-.153-.647.18-1.42 1.073-2.375 2.56-2.706 5.62-5.727 8.819-8.837-3.2-3.104-6.26-6.127-8.819-8.832-.902-.901-1.243-1.661-1.083-2.337.16-.675.95-1.529 2.316-2.58 1.365-1.05 2.389-1.62 3.117-1.694.729-.076 1.444.372 2.25 1.38 2.19 2.753 4.965 5.77 7.939 8.846 2.977-3.076 5.747-6.093 7.939-8.842.836-1.006 1.54-1.451 2.249-1.38.708.071 1.711.636 3.072 1.67 1.36 1.036 2.153 1.9 2.319 2.6.167.7-.164 1.463-1.037 2.343-2.559 2.701-5.619 5.725-8.815 8.83"
                    fill="#fff"
                />
            </G>
            <Defs></Defs>
        </Svg>
    );
}
export default SvgComponent;
