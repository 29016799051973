import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { CarrouselComponent, MainLayout } from "../../../components";
import { useLearnHook } from "../../../hooks";
import { getBooksListRedux } from "../../../store/main/selectors";
import { parseListsUtils } from "../../../utils/parse-lists.utils";
import { TitleContent } from "./TitleContent";

export const BooksScreen = () => {
    const { getSubjects } = useLearnHook();

    const books = useSelector(getBooksListRedux);

    const { list, selectedCategoryID } = useMemo(() => {
        const selectedCategoryID = books?.params?.subjectId || 0;

        return {
            selectedCategoryID,
            list: parseListsUtils(books?.data?.books, (props) => ({
                onPress: () =>
                    getSubjects({
                        subjectId: selectedCategoryID,
                        bookId: props.id,
                        title: props.title,
                    }),
            })),
        };
    }, [books]);

    return (
        <MainLayout
            headerProps={{
                backButton: true,
                homeButton: true,
                titleContent: (
                    <TitleContent selectedCategoryID={selectedCategoryID} />
                ),
            }}>
            <CarrouselComponent data={list} type={"book"} />
        </MainLayout>
    );
};
