import { BackHandler } from "react-native";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-native";
import { navigation } from "../constants";

export const HardwareBackProvider = ({ children }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const backActionHandler = () => {
        switch (pathname) {
            case navigation.HOME:
                BackHandler.exitApp();
                return true;
            case navigation.LOGIN:
                BackHandler.exitApp();
                return true;
            default:
                navigate(-1);
                return true;
        }
    };

    useEffect(() => {
        BackHandler.addEventListener("hardwareBackPress", backActionHandler);
        return () =>
            BackHandler.removeEventListener(
                "hardwareBackPress",
                backActionHandler
            );
    }, []);

    return children;
};
